export default {
  "locale": "fr",
  "backend": {
    "name": "git-gateway",
    "branch": "master",
    "commit_messages": {
      "create": "Crée {{slug}}",
      "update": "Met à jour {{slug}}",
      "delete": "Supprime {{slug}}",
      "uploadMedia": "Nouvelle image {{path}}",
      "deleteMedia": "Suppression de l'image {{path}}"
    }
  },
  "media_folder": "public/img",
  "public_folder": "img",
  "publish_mode": "editorial_workflow",
  "root": "../../..",
  "patterns": {
    "pattern_url": [
      "^(https?://)",
      "Le champ doit contenir une url valide"
    ]
  },
  "fields": {
    "field_empty": {
      "widget": "object",
      "fields": [
        {
          "label": "empty",
          "widget": "hidden",
          "name": "empty"
        }
      ]
    },
    "field_number": {
      "widget": "object",
      "fields": [
        {
          "label": "Opérateur",
          "name": "operator",
          "widget": "select",
          "options": [
            {
              "label": "Supérieur à",
              "value": ">"
            },
            {
              "label": "Supérieur ou égal à",
              "value": ">="
            },
            {
              "label": "Égal à",
              "value": "="
            },
            {
              "label": "Inférieur à",
              "value": "<"
            },
            {
              "label": "Inférieur ou égal à",
              "value": "<="
            }
          ]
        },
        {
          "label": "Valeur",
          "name": "value",
          "widget": "number",
          "step": -1
        }
      ]
    },
    "field_age": {
      "widget": "object",
      "fields": [
        {
          "label": "Opérateur",
          "name": "operator",
          "widget": "select",
          "options": [
            {
              "label": "Supérieur à",
              "value": ">"
            },
            {
              "label": "Supérieur ou égal à",
              "value": ">="
            },
            {
              "label": "Égal à",
              "value": "="
            },
            {
              "label": "Inférieur à",
              "value": "<"
            },
            {
              "label": "Inférieur ou égal à",
              "value": "<="
            }
          ]
        },
        {
          "label": "Valeur",
          "name": "value",
          "widget": "number",
          "step": -1
        }
      ],
      "label": "Age",
      "name": "age"
    },
    "field_regions": {
      "label": "Condition géographique régionale",
      "name": "regions",
      "widget": "object",
      "fields": [
        {
          "label": "Régions",
          "name": "values",
          "widget": "select",
          "multiple": true,
          "options": [
            {
              "label": "Auvergne-Rhône-Alpes",
              "value": "84"
            },
            {
              "label": "Bourgogne-Franche-Comté",
              "value": "27"
            },
            {
              "label": "Bretagne",
              "value": "53"
            },
            {
              "label": "Centre-Val de Loire",
              "value": "24"
            },
            {
              "label": "Corse",
              "value": "94"
            },
            {
              "label": "Grand Est",
              "value": "44"
            },
            {
              "label": "Guadeloupe",
              "value": "01"
            },
            {
              "label": "Guyane",
              "value": "03"
            },
            {
              "label": "Hauts-de-France",
              "value": "32"
            },
            {
              "label": "Île-de-France",
              "value": "11"
            },
            {
              "label": "La Réunion",
              "value": "04"
            },
            {
              "label": "Martinique",
              "value": "02"
            },
            {
              "label": "Mayotte",
              "value": "06"
            },
            {
              "label": "Normandie",
              "value": "28"
            },
            {
              "label": "Nouvelle-Aquitaine",
              "value": "75"
            },
            {
              "label": "Occitanie",
              "value": "76"
            },
            {
              "label": "Pays de la Loire",
              "value": "52"
            },
            {
              "label": "Provence-Alpes-Côte d'Azur",
              "value": "93"
            }
          ]
        }
      ]
    },
    "field_departements": {
      "label": "Condition géographique départementale",
      "name": "departements",
      "widget": "object",
      "fields": [
        {
          "label": "Code des départements",
          "name": "values",
          "widget": "list",
          "hint": "Pour saisir plusieurs département, séparez les codes des départements par une virgule."
        }
      ]
    },
    "field_communes": {
      "label": "Condition géographique communale",
      "name": "communes",
      "widget": "object",
      "fields": [
        {
          "label": "Code INSEE des communes",
          "name": "values",
          "widget": "list",
          "hint": "Pour saisir plusieurs communes, séparez les codes INSEE des communes par une virgule."
        }
      ]
    },
    "field_boursier": {
      "widget": "object",
      "fields": [
        {
          "label": "empty",
          "widget": "hidden",
          "name": "empty"
        }
      ],
      "label": "Boursier",
      "name": "boursier"
    },
    "field_mention_baccalaureat": {
      "label": "Mention au bac",
      "name": "mention_baccalaureat",
      "widget": "object",
      "fields": [
        {
          "label": "Valeurs",
          "name": "values",
          "widget": "select",
          "multiple": true,
          "options": [
            {
              "label": "Mention assez bien",
              "value": "mention_assez_bien"
            },
            {
              "label": "Mention bien",
              "value": "mention_bien"
            },
            {
              "label": "Mention très bien",
              "value": "mention_tres_bien"
            },
            {
              "label": "Mention très bien avec félicitations du jury",
              "value": "mention_tres_bien_felicitations_jury"
            },
            {
              "label": "Autre",
              "value": "non_renseignee"
            }
          ]
        }
      ]
    },
    "field_select_regime_securite_sociale": {
      "widget": "select",
      "multiple": true,
      "required": false,
      "options": [
        {
          "label": "Régime général",
          "value": "regime_general"
        },
        {
          "label": "Régime agricole",
          "value": "regime_agricole"
        },
        {
          "label": "Autres régimes",
          "value": "autres_regimes"
        }
      ]
    },
    "field_regime_securite_sociale": {
      "label": "Régime de sécurité sociale",
      "name": "regime_securite_sociale",
      "widget": "object",
      "fields": [
        {
          "widget": "select",
          "multiple": true,
          "required": false,
          "options": [
            {
              "label": "Régime général",
              "value": "regime_general"
            },
            {
              "label": "Régime agricole",
              "value": "regime_agricole"
            },
            {
              "label": "Autres régimes",
              "value": "autres_regimes"
            }
          ],
          "label": "Inclus",
          "name": "includes"
        },
        {
          "widget": "select",
          "multiple": true,
          "required": false,
          "options": [
            {
              "label": "Régime général",
              "value": "regime_general"
            },
            {
              "label": "Régime agricole",
              "value": "regime_agricole"
            },
            {
              "label": "Autres régimes",
              "value": "autres_regimes"
            }
          ],
          "label": "Exclus",
          "name": "excludes"
        }
      ]
    },
    "field_geographical_type": [
      {
        "label": "Organisation nationale",
        "value": "national"
      },
      {
        "label": "Région",
        "value": "region"
      },
      {
        "label": "Département",
        "value": "departement"
      },
      {
        "label": "CAF locale",
        "value": "caf"
      },
      {
        "label": "EPCI (Métropole, inter-communauté, etc.)",
        "value": "epci"
      },
      {
        "label": "Commune",
        "value": "commune"
      },
      {
        "label": "Autre",
        "value": "autre"
      }
    ],
    "field_quotient_familial": {
      "label": "Quotient familial",
      "name": "quotient_familial",
      "widget": "object",
      "fields": [
        {
          "label": "Plafond",
          "name": "floor",
          "widget": "number",
          "step": -1
        },
        {
          "label": "Période",
          "name": "period",
          "widget": "select",
          "options": [
            {
              "label": "Par mois",
              "value": "month"
            },
            {
              "label": "Par an",
              "value": "year"
            }
          ]
        }
      ]
    },
    "field_formation_sanitaire_social": {
      "widget": "object",
      "fields": [
        {
          "label": "empty",
          "widget": "hidden",
          "name": "empty"
        }
      ],
      "label": "En formation sanitaire et sociale",
      "name": "formation_sanitaire_social"
    },
    "field_beneficiaire_rsa": {
      "widget": "object",
      "fields": [
        {
          "label": "empty",
          "widget": "hidden",
          "name": "empty"
        }
      ],
      "label": "Bénéficiaire RSA",
      "name": "beneficiaire_rsa"
    },
    "field_benefit_image": {
      "label": "Logo",
      "name": "imgSrc",
      "widget": "hidden",
      "allow_multiple": false,
      "required": false,
      "public_folder": "img/benefits",
      "media_folder": "/public/img/benefits",
      "hint": "Utile si vous voulez afficher le logo de l'aide à la place de l'image de l'institution."
    },
    "field_benefit_name": {
      "label": "Nom de l'aide",
      "name": "label",
      "widget": "string"
    },
    "field_institution": {
      "label": "Institution",
      "name": "institution",
      "widget": "institution",
      "collection": "institutions",
      "value_field": "{{slug}}",
      "display_fields": [
        "{{name}}"
      ],
      "search_fields": [
        "slug",
        "name"
      ],
      "allow_multiple": false,
      "options_length": -1,
      "filter": {
        "label": "Type :",
        "default": "*",
        "target": "type",
        "fields": [
          {
            "label": "Organisation nationale",
            "value": "national"
          },
          {
            "label": "Région",
            "value": "region"
          },
          {
            "label": "Département",
            "value": "departement"
          },
          {
            "label": "CAF locale",
            "value": "caf"
          },
          {
            "label": "EPCI (Métropole, inter-communauté, etc.)",
            "value": "epci"
          },
          {
            "label": "Commune",
            "value": "commune"
          },
          {
            "label": "Autre",
            "value": "autre"
          }
        ]
      },
      "hint": "Si votre institution n'apparaît pas dans la liste, vous pouvez [l'ajouter ici](https://contribuer-aides-jeunes.netlify.app/admin/#/collections/institutions/new)."
    },
    "field_description": {
      "label": "Courte description",
      "name": "description",
      "widget": "description"
    },
    "field_unmodeled_conditions": {
      "label": "Conditions non prises en compte par le simulateur",
      "label_singular": "Condition non prise en compte par le simulateur",
      "hint": "Certains critères d'éligibilité ne peuvent pas être demandés (trop précis)\nni pris en compte (trop complexe) dans le cadre d'un simulateur grand public.\nCette liste permet d'informer les usagers sur ces critères supplémentaires.\nPar exemple : Signer un contrat d’engagement réciproque (CER) avec votre département\nou un Projet Personnalisé d’Accès à l’Emploi (PPAE) avec Pôle emploi.\n",
      "name": "conditions",
      "widget": "list",
      "collapsed": false,
      "field": {
        "label": "Texte",
        "name": "text",
        "widget": "string",
        "pattern": [
          "\\.$",
          "La condition doit commencer par un verbe à l'infinitif et se terminer par un point"
        ]
      }
    },
    "field_link_information": {
      "label": "Lien vers la page d'informations de référence",
      "hint": "Vers un site institutionnel de préférence (par exemple, pour les aides nationales il s'agit souvent de service-public.fr)",
      "name": "link",
      "widget": "string",
      "pattern": [
        "^(https?://)",
        "Le champ doit contenir une url valide"
      ]
    },
    "field_link_instructions": {
      "label": "Lien vers des instructions à suivre",
      "name": "instructions",
      "widget": "string",
      "pattern": [
        "^(https?://)",
        "Le champ doit contenir une url valide"
      ],
      "required": false,
      "required_group": "cta"
    },
    "field_link_form": {
      "label": "Lien vers un formulaire à imprimer",
      "name": "form",
      "widget": "string",
      "pattern": [
        "^(https?://)",
        "Le champ doit contenir une url valide"
      ],
      "required": false,
      "required_group": "cta"
    },
    "field_link_teleservice": {
      "label": "Lien vers un téléservice",
      "hint": "Lorsqu'il y a la possibilité de faire la démarche en ligne.",
      "name": "teleservice",
      "widget": "string",
      "pattern": [
        "^(https?://)",
        "Le champ doit contenir une url valide"
      ],
      "required": false,
      "required_group": "cta"
    },
    "field_definite_article": {
      "label": "Article défini",
      "name": "prefix",
      "hint": "L'ajout d'un article défini permet la formation de phrase grammaticalement correcte.\nPar exemple, dans la phrase « Comment obtenir l'aide exceptionnelle ? », on choisit le préfixe « l' ». Pour « Comment obtenir les aides au logement ? », on choisit le préfixe « les ».\n",
      "widget": "select",
      "required": true,
      "options": [
        "le",
        "la",
        "les",
        "l’",
        "une",
        "un",
        "l’aide"
      ]
    },
    "field_result_type": {
      "label": "Type du résultat",
      "name": "type",
      "default": "float",
      "widget": "select",
      "options": [
        {
          "label": "Valeur numérique",
          "value": "float"
        },
        {
          "label": "Éligibilité (Oui / Non)",
          "value": "bool"
        },
        {
          "label": "Autre",
          "value": "mixed"
        }
      ]
    },
    "field_result_unit": {
      "label": "Unité lorsque le type de résultat est une valeur numérique",
      "hint": "Il s'agit de spécifier l'unité du montant.",
      "name": "unit",
      "widget": "select",
      "default": "€",
      "required": false,
      "options": [
        {
          "label": "€",
          "value": "€"
        },
        {
          "label": "%",
          "value": "%"
        },
        {
          "label": "séances",
          "value": "séances"
        }
      ]
    },
    "field_amount_legend": {
      "label": "Légende associée à la valeur numérique",
      "hint": "Cette légende permet d'ajouter des informations complémentaires sur le montant. Par exemple, si vous écrivez \"maximum\" dans ce champ, et que vous avez indiqué \"200 €\" en montant, cela affichera \"200 € maximum\".",
      "name": "legend",
      "widget": "string",
      "required": false
    },
    "field_amount_reliability": {
      "label": "Niveau de fiabilité lorsque le résultat est un montant",
      "name": "floorAt",
      "widget": "select",
      "required": false,
      "options": [
        {
          "label": "10 (pour arrondir à la dizaine inférieure)",
          "value": 10
        },
        {
          "label": "1 (pour l'euro inférieur)",
          "value": 1
        },
        {
          "label": "0.01 (pour éviter l'arrondi)",
          "value": 0.01
        }
      ]
    },
    "field_specific_interest": {
      "label": "Faut-il limiter l'affichage de l'aide en fonction d'un intérêt particulier ?",
      "name": "interestFlag",
      "hint": "En fin de parcours, des questions sont posées pour connaitre certains intérêts des usagers.\nCela permet d'éviter d'afficher certaines aides qui ne seraient pas pertinentes pour les usagers.\n",
      "widget": "select",
      "required": false,
      "options": [
        {
          "label": "Oui, l'afficher en cas d'intérêt pour le BAFA ou le BAFD.",
          "value": "_interetBafa"
        },
        {
          "label": "Oui, l'afficher en cas d'intérêt pour passer le permis de conduire.",
          "value": "_interetPermisDeConduire"
        },
        {
          "label": "Oui, l'afficher en cas d'intérêt pour faire des études à l'étranger.",
          "value": "_interetEtudesEtranger"
        },
        {
          "label": "Oui, l'afficher en cas d'intérêt pour faire une formation dans le sanitaire et social.",
          "value": "_interetAidesSanitaireSocial"
        }
      ]
    },
    "field_openfisca_linked_entity": {
      "label": "Entité de rattachement de la variable dans OpenFisca",
      "name": "entity",
      "widget": "select",
      "hint": "Cette variable fait le lien avec le moteur de calculs. Tant que vous ne savez pas quoi y indiquer, nous recommandons de laisser Ménage.",
      "options": [
        {
          "label": "Famille",
          "value": "familles"
        },
        {
          "label": "Foyer Fiscal",
          "value": "foyers_fiscaux"
        },
        {
          "label": "Individu",
          "value": "individus"
        },
        {
          "label": "Ménage",
          "value": "menages"
        }
      ]
    },
    "field_openfisca_period": {
      "label": "Période de la variable dans OpenFisca",
      "name": "openfiscaPeriod",
      "widget": "select",
      "hint": "Cette variable fait le lien avec le moteur de calculs. Tant que vous ne savez pas quoi y indiquer, nous recommandons de laisser Mois.",
      "options": [
        {
          "label": "Mois",
          "value": "thisMonth"
        },
        {
          "label": "Année",
          "value": "thisYear"
        }
      ]
    },
    "field_benefit_max_amount": {
      "label": "Montant maximal de l'aide",
      "name": "montant",
      "widget": "number",
      "required": false,
      "step": -1
    },
    "field_benefit_amount_periodicity": {
      "label": "Périodicité de l'aide",
      "name": "periodicite",
      "widget": "select",
      "hint": "Cette variable permet de définir la fréquence de versement de l'aide.",
      "required": true,
      "default": "autre",
      "options": [
        {
          "label": "Ponctuelle",
          "value": "ponctuelle"
        },
        {
          "label": "Mensuelle (/ mois)",
          "value": "mensuelle"
        },
        {
          "label": "Annuelle (/ an)",
          "value": "annuelle"
        },
        {
          "label": "Autre",
          "value": "autre"
        }
      ]
    },
    "field_eligibility_profile": {
      "label": "profil d'éligibilité",
      "label_singular": "profils d'éligibilité",
      "hint": "Cette aide est-elle destinée à des publics spécifiques ? (lycéen(ne)s, apprenti(e)s, en recherche d'emploi, etc.)",
      "name": "profils",
      "widget": "list",
      "collapsed": false,
      "default": [],
      "types": [
        {
          "label": "Lycéen·ne",
          "name": "lyceen",
          "widget": "object",
          "fields": [
            {
              "label": "Conditions à satisfaire simultanément",
              "label_singular": "Condition à satisfaire",
              "name": "conditions",
              "default": [],
              "widget": "list",
              "collapsed": false,
              "types": [
                {
                  "widget": "object",
                  "fields": [
                    {
                      "label": "Opérateur",
                      "name": "operator",
                      "widget": "select",
                      "options": [
                        {
                          "label": "Supérieur à",
                          "value": ">"
                        },
                        {
                          "label": "Supérieur ou égal à",
                          "value": ">="
                        },
                        {
                          "label": "Égal à",
                          "value": "="
                        },
                        {
                          "label": "Inférieur à",
                          "value": "<"
                        },
                        {
                          "label": "Inférieur ou égal à",
                          "value": "<="
                        }
                      ]
                    },
                    {
                      "label": "Valeur",
                      "name": "value",
                      "widget": "number",
                      "step": -1
                    }
                  ],
                  "label": "Age",
                  "name": "age"
                },
                {
                  "widget": "object",
                  "fields": [
                    {
                      "label": "empty",
                      "widget": "hidden",
                      "name": "empty"
                    }
                  ],
                  "label": "Boursier",
                  "name": "boursier"
                },
                {
                  "widget": "object",
                  "fields": [
                    {
                      "label": "empty",
                      "widget": "hidden",
                      "name": "empty"
                    }
                  ],
                  "label": "En formation sanitaire et sociale",
                  "name": "formation_sanitaire_social"
                },
                {
                  "label": "Année d'étude",
                  "name": "annee_etude",
                  "widget": "object",
                  "fields": [
                    {
                      "label": "Valeurs",
                      "name": "values",
                      "widget": "select",
                      "multiple": true,
                      "options": [
                        {
                          "label": "CAP - 1ère année",
                          "value": "cap_1"
                        },
                        {
                          "label": "CAP - 2ème année",
                          "value": "cap_2"
                        },
                        {
                          "label": "Seconde",
                          "value": "seconde"
                        },
                        {
                          "label": "Première",
                          "value": "premiere"
                        },
                        {
                          "label": "Terminale",
                          "value": "terminale"
                        },
                        {
                          "label": "Autre",
                          "value": "autre"
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "label": "Dans l'enseignement supérieur",
          "name": "enseignement_superieur",
          "widget": "object",
          "fields": [
            {
              "label": "Conditions à satisfaire simultanément",
              "label_singular": "Condition à satisfaire",
              "name": "conditions",
              "default": [],
              "widget": "list",
              "collapsed": false,
              "types": [
                {
                  "widget": "object",
                  "fields": [
                    {
                      "label": "Opérateur",
                      "name": "operator",
                      "widget": "select",
                      "options": [
                        {
                          "label": "Supérieur à",
                          "value": ">"
                        },
                        {
                          "label": "Supérieur ou égal à",
                          "value": ">="
                        },
                        {
                          "label": "Égal à",
                          "value": "="
                        },
                        {
                          "label": "Inférieur à",
                          "value": "<"
                        },
                        {
                          "label": "Inférieur ou égal à",
                          "value": "<="
                        }
                      ]
                    },
                    {
                      "label": "Valeur",
                      "name": "value",
                      "widget": "number",
                      "step": -1
                    }
                  ],
                  "label": "Age",
                  "name": "age"
                },
                {
                  "widget": "object",
                  "fields": [
                    {
                      "label": "empty",
                      "widget": "hidden",
                      "name": "empty"
                    }
                  ],
                  "label": "Boursier",
                  "name": "boursier"
                },
                {
                  "label": "Mention au bac",
                  "name": "mention_baccalaureat",
                  "widget": "object",
                  "fields": [
                    {
                      "label": "Valeurs",
                      "name": "values",
                      "widget": "select",
                      "multiple": true,
                      "options": [
                        {
                          "label": "Mention assez bien",
                          "value": "mention_assez_bien"
                        },
                        {
                          "label": "Mention bien",
                          "value": "mention_bien"
                        },
                        {
                          "label": "Mention très bien",
                          "value": "mention_tres_bien"
                        },
                        {
                          "label": "Mention très bien avec félicitations du jury",
                          "value": "mention_tres_bien_felicitations_jury"
                        },
                        {
                          "label": "Autre",
                          "value": "non_renseignee"
                        }
                      ]
                    }
                  ]
                },
                {
                  "widget": "object",
                  "fields": [
                    {
                      "label": "empty",
                      "widget": "hidden",
                      "name": "empty"
                    }
                  ],
                  "label": "En formation sanitaire et sociale",
                  "name": "formation_sanitaire_social"
                },
                {
                  "label": "Année d'étude",
                  "name": "annee_etude",
                  "widget": "object",
                  "fields": [
                    {
                      "label": "Valeurs",
                      "name": "values",
                      "widget": "select",
                      "multiple": true,
                      "options": [
                        {
                          "label": "BTS",
                          "value": "bts_1"
                        },
                        {
                          "label": "BUT",
                          "value": "but_1"
                        },
                        {
                          "label": "CPGE",
                          "value": "cpge_1"
                        },
                        {
                          "label": "Licence - 1ère année",
                          "value": "licence_1"
                        },
                        {
                          "label": "Licence - 2ème année",
                          "value": "licence_2"
                        },
                        {
                          "label": "Licence - 3ème année",
                          "value": "licence_3"
                        },
                        {
                          "label": "Master - 1ère année",
                          "value": "master_1"
                        },
                        {
                          "label": "Master - 2ème année",
                          "value": "master_2"
                        },
                        {
                          "label": "Doctorat - 1ère année",
                          "value": "doctorat_1"
                        },
                        {
                          "label": "Doctorat - 2ème année",
                          "value": "doctorat_2"
                        },
                        {
                          "label": "Doctorat - 3ème année",
                          "value": "doctorat_3"
                        },
                        {
                          "label": "Autre",
                          "value": "autre"
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "label": "Scolarisé·e",
          "name": "etudiant",
          "widget": "object",
          "fields": [
            {
              "label": "Conditions à satisfaire simultanément",
              "label_singular": "Condition à satisfaire",
              "name": "conditions",
              "default": [],
              "widget": "list",
              "collapsed": false,
              "types": [
                {
                  "widget": "object",
                  "fields": [
                    {
                      "label": "Opérateur",
                      "name": "operator",
                      "widget": "select",
                      "options": [
                        {
                          "label": "Supérieur à",
                          "value": ">"
                        },
                        {
                          "label": "Supérieur ou égal à",
                          "value": ">="
                        },
                        {
                          "label": "Égal à",
                          "value": "="
                        },
                        {
                          "label": "Inférieur à",
                          "value": "<"
                        },
                        {
                          "label": "Inférieur ou égal à",
                          "value": "<="
                        }
                      ]
                    },
                    {
                      "label": "Valeur",
                      "name": "value",
                      "widget": "number",
                      "step": -1
                    }
                  ],
                  "label": "Age",
                  "name": "age"
                },
                {
                  "widget": "object",
                  "fields": [
                    {
                      "label": "empty",
                      "widget": "hidden",
                      "name": "empty"
                    }
                  ],
                  "label": "En formation sanitaire et sociale",
                  "name": "formation_sanitaire_social"
                },
                {
                  "label": "Année d'étude",
                  "name": "annee_etude",
                  "widget": "object",
                  "fields": [
                    {
                      "label": "Valeurs",
                      "name": "values",
                      "widget": "select",
                      "multiple": true,
                      "options": [
                        {
                          "label": "CAP - 1ère année",
                          "value": "cap_1"
                        },
                        {
                          "label": "CAP - 2ème année",
                          "value": "cap_2"
                        },
                        {
                          "label": "Seconde",
                          "value": "seconde"
                        },
                        {
                          "label": "Première",
                          "value": "premiere"
                        },
                        {
                          "label": "Terminale",
                          "value": "terminale"
                        },
                        {
                          "label": "BTS",
                          "value": "bts_1"
                        },
                        {
                          "label": "BUT",
                          "value": "but_1"
                        },
                        {
                          "label": "CPGE",
                          "value": "cpge_1"
                        },
                        {
                          "label": "Licence - 1ère année",
                          "value": "licence_1"
                        },
                        {
                          "label": "Licence - 2ème année",
                          "value": "licence_2"
                        },
                        {
                          "label": "Licence - 3ème année",
                          "value": "licence_3"
                        },
                        {
                          "label": "Master - 1ère année",
                          "value": "master_1"
                        },
                        {
                          "label": "Master - 2ème année",
                          "value": "master_2"
                        },
                        {
                          "label": "Doctorat - 1ère année",
                          "value": "doctorat_1"
                        },
                        {
                          "label": "Doctorat - 2ème année",
                          "value": "doctorat_2"
                        },
                        {
                          "label": "Doctorat - 3ème année",
                          "value": "doctorat_3"
                        },
                        {
                          "label": "Autre",
                          "value": "autre"
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "label": "Stagiaire",
          "name": "stagiaire",
          "widget": "object",
          "fields": [
            {
              "label": "Conditions à satisfaire simultanément",
              "label_singular": "Condition à satisfaire",
              "name": "conditions",
              "default": [],
              "widget": "list",
              "collapsed": false,
              "types": [
                {
                  "widget": "object",
                  "fields": [
                    {
                      "label": "empty",
                      "widget": "hidden",
                      "name": "empty"
                    }
                  ],
                  "label": "En formation sanitaire et sociale",
                  "name": "formation_sanitaire_social"
                },
                {
                  "widget": "object",
                  "fields": [
                    {
                      "label": "Opérateur",
                      "name": "operator",
                      "widget": "select",
                      "options": [
                        {
                          "label": "Supérieur à",
                          "value": ">"
                        },
                        {
                          "label": "Supérieur ou égal à",
                          "value": ">="
                        },
                        {
                          "label": "Égal à",
                          "value": "="
                        },
                        {
                          "label": "Inférieur à",
                          "value": "<"
                        },
                        {
                          "label": "Inférieur ou égal à",
                          "value": "<="
                        }
                      ]
                    },
                    {
                      "label": "Valeur",
                      "name": "value",
                      "widget": "number",
                      "step": -1
                    }
                  ],
                  "label": "Age",
                  "name": "age"
                },
                {
                  "widget": "object",
                  "fields": [
                    {
                      "label": "empty",
                      "widget": "hidden",
                      "name": "empty"
                    }
                  ],
                  "label": "Boursier",
                  "name": "boursier"
                }
              ]
            }
          ]
        },
        {
          "label": "Apprenti·e",
          "name": "apprenti",
          "widget": "object",
          "fields": [
            {
              "label": "Conditions à satisfaire simultanément",
              "label_singular": "Condition à satisfaire",
              "name": "conditions",
              "default": [],
              "widget": "list",
              "collapsed": false,
              "types": [
                {
                  "widget": "object",
                  "fields": [
                    {
                      "label": "empty",
                      "widget": "hidden",
                      "name": "empty"
                    }
                  ],
                  "label": "En formation sanitaire et sociale",
                  "name": "formation_sanitaire_social"
                },
                {
                  "widget": "object",
                  "fields": [
                    {
                      "label": "Opérateur",
                      "name": "operator",
                      "widget": "select",
                      "options": [
                        {
                          "label": "Supérieur à",
                          "value": ">"
                        },
                        {
                          "label": "Supérieur ou égal à",
                          "value": ">="
                        },
                        {
                          "label": "Égal à",
                          "value": "="
                        },
                        {
                          "label": "Inférieur à",
                          "value": "<"
                        },
                        {
                          "label": "Inférieur ou égal à",
                          "value": "<="
                        }
                      ]
                    },
                    {
                      "label": "Valeur",
                      "name": "value",
                      "widget": "number",
                      "step": -1
                    }
                  ],
                  "label": "Age",
                  "name": "age"
                },
                {
                  "widget": "object",
                  "fields": [
                    {
                      "label": "empty",
                      "widget": "hidden",
                      "name": "empty"
                    }
                  ],
                  "label": "Boursier",
                  "name": "boursier"
                }
              ]
            }
          ]
        },
        {
          "label": "En contrat de professionnalisation",
          "name": "professionnalisation",
          "widget": "object",
          "fields": [
            {
              "label": "Conditions à satisfaire simultanément",
              "label_singular": "Condition à satisfaire",
              "name": "conditions",
              "default": [],
              "widget": "list",
              "collapsed": false,
              "types": [
                {
                  "widget": "object",
                  "fields": [
                    {
                      "label": "empty",
                      "widget": "hidden",
                      "name": "empty"
                    }
                  ],
                  "label": "En formation sanitaire et sociale",
                  "name": "formation_sanitaire_social"
                },
                {
                  "widget": "object",
                  "fields": [
                    {
                      "label": "Opérateur",
                      "name": "operator",
                      "widget": "select",
                      "options": [
                        {
                          "label": "Supérieur à",
                          "value": ">"
                        },
                        {
                          "label": "Supérieur ou égal à",
                          "value": ">="
                        },
                        {
                          "label": "Égal à",
                          "value": "="
                        },
                        {
                          "label": "Inférieur à",
                          "value": "<"
                        },
                        {
                          "label": "Inférieur ou égal à",
                          "value": "<="
                        }
                      ]
                    },
                    {
                      "label": "Valeur",
                      "name": "value",
                      "widget": "number",
                      "step": -1
                    }
                  ],
                  "label": "Age",
                  "name": "age"
                }
              ]
            }
          ]
        },
        {
          "label": "En recherche d'emploi",
          "name": "chomeur",
          "widget": "object",
          "fields": [
            {
              "label": "Conditions à satisfaire simultanément",
              "label_singular": "Condition à satisfaire",
              "name": "conditions",
              "default": [],
              "widget": "list",
              "collapsed": false,
              "types": [
                {
                  "widget": "object",
                  "fields": [
                    {
                      "label": "Opérateur",
                      "name": "operator",
                      "widget": "select",
                      "options": [
                        {
                          "label": "Supérieur à",
                          "value": ">"
                        },
                        {
                          "label": "Supérieur ou égal à",
                          "value": ">="
                        },
                        {
                          "label": "Égal à",
                          "value": "="
                        },
                        {
                          "label": "Inférieur à",
                          "value": "<"
                        },
                        {
                          "label": "Inférieur ou égal à",
                          "value": "<="
                        }
                      ]
                    },
                    {
                      "label": "Valeur",
                      "name": "value",
                      "widget": "number",
                      "step": -1
                    }
                  ],
                  "label": "Age",
                  "name": "age"
                }
              ]
            }
          ]
        },
        {
          "label": "Salarié·e",
          "name": "salarie",
          "widget": "object",
          "fields": [
            {
              "label": "Conditions à satisfaire simultanément",
              "label_singular": "Condition à satisfaire",
              "name": "conditions",
              "default": [],
              "widget": "list",
              "collapsed": false,
              "types": [
                {
                  "widget": "object",
                  "fields": [
                    {
                      "label": "Opérateur",
                      "name": "operator",
                      "widget": "select",
                      "options": [
                        {
                          "label": "Supérieur à",
                          "value": ">"
                        },
                        {
                          "label": "Supérieur ou égal à",
                          "value": ">="
                        },
                        {
                          "label": "Égal à",
                          "value": "="
                        },
                        {
                          "label": "Inférieur à",
                          "value": "<"
                        },
                        {
                          "label": "Inférieur ou égal à",
                          "value": "<="
                        }
                      ]
                    },
                    {
                      "label": "Valeur",
                      "name": "value",
                      "widget": "number",
                      "step": -1
                    }
                  ],
                  "label": "Age",
                  "name": "age"
                }
              ]
            }
          ]
        },
        {
          "label": "Travailleur·euse indépendant·e",
          "name": "independant",
          "widget": "object",
          "fields": [
            {
              "label": "Conditions à satisfaire simultanément",
              "label_singular": "Condition à satisfaire",
              "name": "conditions",
              "default": [],
              "widget": "list",
              "collapsed": false,
              "types": [
                {
                  "widget": "object",
                  "fields": [
                    {
                      "label": "Opérateur",
                      "name": "operator",
                      "widget": "select",
                      "options": [
                        {
                          "label": "Supérieur à",
                          "value": ">"
                        },
                        {
                          "label": "Supérieur ou égal à",
                          "value": ">="
                        },
                        {
                          "label": "Égal à",
                          "value": "="
                        },
                        {
                          "label": "Inférieur à",
                          "value": "<"
                        },
                        {
                          "label": "Inférieur ou égal à",
                          "value": "<="
                        }
                      ]
                    },
                    {
                      "label": "Valeur",
                      "name": "value",
                      "widget": "number",
                      "step": -1
                    }
                  ],
                  "label": "Age",
                  "name": "age"
                }
              ]
            }
          ]
        },
        {
          "label": "En service civique",
          "name": "service_civique",
          "widget": "object",
          "fields": [
            {
              "label": "Conditions à satisfaire simultanément",
              "label_singular": "Condition à satisfaire",
              "name": "conditions",
              "default": [],
              "widget": "list",
              "collapsed": false,
              "types": [
                {
                  "widget": "object",
                  "fields": [
                    {
                      "label": "Opérateur",
                      "name": "operator",
                      "widget": "select",
                      "options": [
                        {
                          "label": "Supérieur à",
                          "value": ">"
                        },
                        {
                          "label": "Supérieur ou égal à",
                          "value": ">="
                        },
                        {
                          "label": "Égal à",
                          "value": "="
                        },
                        {
                          "label": "Inférieur à",
                          "value": "<"
                        },
                        {
                          "label": "Inférieur ou égal à",
                          "value": "<="
                        }
                      ]
                    },
                    {
                      "label": "Valeur",
                      "name": "value",
                      "widget": "number",
                      "step": -1
                    }
                  ],
                  "label": "Age",
                  "name": "age"
                }
              ]
            }
          ]
        },
        {
          "label": "Bénéficiaire RSA",
          "name": "beneficiaire_rsa",
          "widget": "object",
          "fields": [
            {
              "label": "Conditions à satisfaire simultanément",
              "label_singular": "Condition à satisfaire",
              "name": "conditions",
              "default": [],
              "widget": "list",
              "collapsed": false,
              "types": [
                {
                  "widget": "object",
                  "fields": [
                    {
                      "label": "Opérateur",
                      "name": "operator",
                      "widget": "select",
                      "options": [
                        {
                          "label": "Supérieur à",
                          "value": ">"
                        },
                        {
                          "label": "Supérieur ou égal à",
                          "value": ">="
                        },
                        {
                          "label": "Égal à",
                          "value": "="
                        },
                        {
                          "label": "Inférieur à",
                          "value": "<"
                        },
                        {
                          "label": "Inférieur ou égal à",
                          "value": "<="
                        }
                      ]
                    },
                    {
                      "label": "Valeur",
                      "name": "value",
                      "widget": "number",
                      "step": -1
                    }
                  ],
                  "label": "Age",
                  "name": "age"
                }
              ]
            }
          ]
        }
      ]
    },
    "field_private_benefit": {
      "label": "Masquer l'aide",
      "name": "private",
      "required": false,
      "widget": "hidden"
    },
    "field_benefit_partial_support": {
      "label": "L'aide ne couvre qu'une partie des frais",
      "name": "participation",
      "widget": "hidden"
    },
    "field_general_conditions": {
      "label": "Conditions générales à satisfaire simultanément",
      "label_singular": "Condition générale à satisfaire",
      "name": "conditions_generales",
      "default": [],
      "widget": "list",
      "collapsed": false,
      "types": [
        {
          "widget": "object",
          "fields": [
            {
              "label": "Opérateur",
              "name": "operator",
              "widget": "select",
              "options": [
                {
                  "label": "Supérieur à",
                  "value": ">"
                },
                {
                  "label": "Supérieur ou égal à",
                  "value": ">="
                },
                {
                  "label": "Égal à",
                  "value": "="
                },
                {
                  "label": "Inférieur à",
                  "value": "<"
                },
                {
                  "label": "Inférieur ou égal à",
                  "value": "<="
                }
              ]
            },
            {
              "label": "Valeur",
              "name": "value",
              "widget": "number",
              "step": -1
            }
          ],
          "label": "Age",
          "name": "age"
        },
        {
          "label": "Condition géographique régionale",
          "name": "regions",
          "widget": "object",
          "fields": [
            {
              "label": "Régions",
              "name": "values",
              "widget": "select",
              "multiple": true,
              "options": [
                {
                  "label": "Auvergne-Rhône-Alpes",
                  "value": "84"
                },
                {
                  "label": "Bourgogne-Franche-Comté",
                  "value": "27"
                },
                {
                  "label": "Bretagne",
                  "value": "53"
                },
                {
                  "label": "Centre-Val de Loire",
                  "value": "24"
                },
                {
                  "label": "Corse",
                  "value": "94"
                },
                {
                  "label": "Grand Est",
                  "value": "44"
                },
                {
                  "label": "Guadeloupe",
                  "value": "01"
                },
                {
                  "label": "Guyane",
                  "value": "03"
                },
                {
                  "label": "Hauts-de-France",
                  "value": "32"
                },
                {
                  "label": "Île-de-France",
                  "value": "11"
                },
                {
                  "label": "La Réunion",
                  "value": "04"
                },
                {
                  "label": "Martinique",
                  "value": "02"
                },
                {
                  "label": "Mayotte",
                  "value": "06"
                },
                {
                  "label": "Normandie",
                  "value": "28"
                },
                {
                  "label": "Nouvelle-Aquitaine",
                  "value": "75"
                },
                {
                  "label": "Occitanie",
                  "value": "76"
                },
                {
                  "label": "Pays de la Loire",
                  "value": "52"
                },
                {
                  "label": "Provence-Alpes-Côte d'Azur",
                  "value": "93"
                }
              ]
            }
          ]
        },
        {
          "label": "Condition géographique départementale",
          "name": "departements",
          "widget": "object",
          "fields": [
            {
              "label": "Code des départements",
              "name": "values",
              "widget": "list",
              "hint": "Pour saisir plusieurs département, séparez les codes des départements par une virgule."
            }
          ]
        },
        {
          "label": "Condition géographique communale",
          "name": "communes",
          "widget": "object",
          "fields": [
            {
              "label": "Code INSEE des communes",
              "name": "values",
              "widget": "list",
              "hint": "Pour saisir plusieurs communes, séparez les codes INSEE des communes par une virgule."
            }
          ]
        },
        {
          "label": "Régime de sécurité sociale",
          "name": "regime_securite_sociale",
          "widget": "object",
          "fields": [
            {
              "widget": "select",
              "multiple": true,
              "required": false,
              "options": [
                {
                  "label": "Régime général",
                  "value": "regime_general"
                },
                {
                  "label": "Régime agricole",
                  "value": "regime_agricole"
                },
                {
                  "label": "Autres régimes",
                  "value": "autres_regimes"
                }
              ],
              "label": "Inclus",
              "name": "includes"
            },
            {
              "widget": "select",
              "multiple": true,
              "required": false,
              "options": [
                {
                  "label": "Régime général",
                  "value": "regime_general"
                },
                {
                  "label": "Régime agricole",
                  "value": "regime_agricole"
                },
                {
                  "label": "Autres régimes",
                  "value": "autres_regimes"
                }
              ],
              "label": "Exclus",
              "name": "excludes"
            }
          ]
        },
        {
          "label": "Quotient familial",
          "name": "quotient_familial",
          "widget": "object",
          "fields": [
            {
              "label": "Plafond",
              "name": "floor",
              "widget": "number",
              "step": -1
            },
            {
              "label": "Période",
              "name": "period",
              "widget": "select",
              "options": [
                {
                  "label": "Par mois",
                  "value": "month"
                },
                {
                  "label": "Par an",
                  "value": "year"
                }
              ]
            }
          ]
        },
        {
          "widget": "object",
          "fields": [
            {
              "label": "empty",
              "widget": "hidden",
              "name": "empty"
            }
          ],
          "label": "En formation sanitaire et sociale",
          "name": "formation_sanitaire_social"
        },
        {
          "widget": "object",
          "fields": [
            {
              "label": "empty",
              "widget": "hidden",
              "name": "empty"
            }
          ],
          "label": "Bénéficiaire RSA",
          "name": "beneficiaire_rsa"
        }
      ]
    },
    "field_benefit_position": {
      "label": "Pondération de l'aide sur la page de résultat",
      "name": "top",
      "widget": "hidden"
    },
    "field_benefit_warning_message": {
      "label": "Affiche un message d'avertissement spécifique",
      "name": "warning",
      "widget": "hidden"
    },
    "field_openfisca_eligibility_source": {
      "label": "Référence à la règle de calcul OpenFisca",
      "name": "openfisca_eligibility_source",
      "widget": "hidden"
    },
    "field_benefit_teleservices": {
      "label": "Liens vers plusieurs téléservices",
      "name": "teleservices",
      "widget": "hidden"
    },
    "field_benefit_ressources_year_minus_two": {
      "label": "Aide calculée en fonction des revenus N-2",
      "name": "isBaseRessourcesYearMinusTwo",
      "widget": "hidden"
    },
    "field_benefit_msa_specific": {
      "label": "Aide restreinte aux usagers de la MSA",
      "name": "msa",
      "widget": "hidden"
    },
    "field_benefit_forms": {
      "label": "Liens vers des formulaires de demande de l'aide",
      "name": "forms",
      "widget": "hidden"
    },
    "field_benefit_participation": {
      "label": "Liens vers des formulaires de demande de l'aide",
      "name": "forms",
      "widget": "hidden"
    },
    "field_benefit_institution": {
      "label": "Référence aux institutions supervisant l'aide",
      "name": "etablissements",
      "widget": "hidden"
    },
    "field_benefit_set_to_zero_recently": {
      "label": "L'aide OpenFisca a été remise à zéro récemment",
      "name": "setToZeroRecently",
      "widget": "hidden"
    },
    "field_benefit_capital_dependant": {
      "label": "L'attribution de l'aide est dépendante du capital personnel",
      "name": "isBaseRessourcesPatrimoine",
      "widget": "hidden"
    },
    "field_benefit_openfisca_mapping": {
      "label": "Identifiant OpenFisca de l'aide",
      "name": "openfisca_eligibility_source",
      "widget": "hidden"
    }
  },
  "collections": {
    "benefits_javascript": {
      "name": "benefits_javascript",
      "label": "Aides avec critères d'éligibilités",
      "label_singular": "Aide avec critères d'éligibilités",
      "identifier_field": "label",
      "folder": "data/benefits/javascript",
      "create": true,
      "delete": false,
      "editor": {
        "preview": true
      },
      "slug": "{{fields.institution}}-{{label}}",
      "summary": "{{label}} ({{fields.institution}})",
      "extension": "yml",
      "fields": [
        {
          "label": "Nom de l'aide",
          "name": "label",
          "widget": "string"
        },
        {
          "label": "Logo",
          "name": "imgSrc",
          "widget": "hidden",
          "allow_multiple": false,
          "required": false,
          "public_folder": "img/benefits",
          "media_folder": "/public/img/benefits",
          "hint": "Utile si vous voulez afficher le logo de l'aide à la place de l'image de l'institution."
        },
        {
          "label": "Institution",
          "name": "institution",
          "widget": "institution",
          "collection": "institutions",
          "value_field": "{{slug}}",
          "display_fields": [
            "{{name}}"
          ],
          "search_fields": [
            "slug",
            "name"
          ],
          "allow_multiple": false,
          "options_length": -1,
          "filter": {
            "label": "Type :",
            "default": "*",
            "target": "type",
            "fields": [
              {
                "label": "Organisation nationale",
                "value": "national"
              },
              {
                "label": "Région",
                "value": "region"
              },
              {
                "label": "Département",
                "value": "departement"
              },
              {
                "label": "CAF locale",
                "value": "caf"
              },
              {
                "label": "EPCI (Métropole, inter-communauté, etc.)",
                "value": "epci"
              },
              {
                "label": "Commune",
                "value": "commune"
              },
              {
                "label": "Autre",
                "value": "autre"
              }
            ]
          },
          "hint": "Si votre institution n'apparaît pas dans la liste, vous pouvez [l'ajouter ici](https://contribuer-aides-jeunes.netlify.app/admin/#/collections/institutions/new)."
        },
        {
          "label": "Courte description",
          "name": "description",
          "widget": "description"
        },
        {
          "label": "Article défini",
          "name": "prefix",
          "hint": "L'ajout d'un article défini permet la formation de phrase grammaticalement correcte.\nPar exemple, dans la phrase « Comment obtenir l'aide exceptionnelle ? », on choisit le préfixe « l' ». Pour « Comment obtenir les aides au logement ? », on choisit le préfixe « les ».\n",
          "widget": "select",
          "required": true,
          "options": [
            "le",
            "la",
            "les",
            "l’",
            "une",
            "un",
            "l’aide"
          ]
        },
        {
          "label": "Conditions générales à satisfaire simultanément",
          "label_singular": "Condition générale à satisfaire",
          "name": "conditions_generales",
          "default": [],
          "widget": "list",
          "collapsed": false,
          "types": [
            {
              "widget": "object",
              "fields": [
                {
                  "label": "Opérateur",
                  "name": "operator",
                  "widget": "select",
                  "options": [
                    {
                      "label": "Supérieur à",
                      "value": ">"
                    },
                    {
                      "label": "Supérieur ou égal à",
                      "value": ">="
                    },
                    {
                      "label": "Égal à",
                      "value": "="
                    },
                    {
                      "label": "Inférieur à",
                      "value": "<"
                    },
                    {
                      "label": "Inférieur ou égal à",
                      "value": "<="
                    }
                  ]
                },
                {
                  "label": "Valeur",
                  "name": "value",
                  "widget": "number",
                  "step": -1
                }
              ],
              "label": "Age",
              "name": "age"
            },
            {
              "label": "Condition géographique régionale",
              "name": "regions",
              "widget": "object",
              "fields": [
                {
                  "label": "Régions",
                  "name": "values",
                  "widget": "select",
                  "multiple": true,
                  "options": [
                    {
                      "label": "Auvergne-Rhône-Alpes",
                      "value": "84"
                    },
                    {
                      "label": "Bourgogne-Franche-Comté",
                      "value": "27"
                    },
                    {
                      "label": "Bretagne",
                      "value": "53"
                    },
                    {
                      "label": "Centre-Val de Loire",
                      "value": "24"
                    },
                    {
                      "label": "Corse",
                      "value": "94"
                    },
                    {
                      "label": "Grand Est",
                      "value": "44"
                    },
                    {
                      "label": "Guadeloupe",
                      "value": "01"
                    },
                    {
                      "label": "Guyane",
                      "value": "03"
                    },
                    {
                      "label": "Hauts-de-France",
                      "value": "32"
                    },
                    {
                      "label": "Île-de-France",
                      "value": "11"
                    },
                    {
                      "label": "La Réunion",
                      "value": "04"
                    },
                    {
                      "label": "Martinique",
                      "value": "02"
                    },
                    {
                      "label": "Mayotte",
                      "value": "06"
                    },
                    {
                      "label": "Normandie",
                      "value": "28"
                    },
                    {
                      "label": "Nouvelle-Aquitaine",
                      "value": "75"
                    },
                    {
                      "label": "Occitanie",
                      "value": "76"
                    },
                    {
                      "label": "Pays de la Loire",
                      "value": "52"
                    },
                    {
                      "label": "Provence-Alpes-Côte d'Azur",
                      "value": "93"
                    }
                  ]
                }
              ]
            },
            {
              "label": "Condition géographique départementale",
              "name": "departements",
              "widget": "object",
              "fields": [
                {
                  "label": "Code des départements",
                  "name": "values",
                  "widget": "list",
                  "hint": "Pour saisir plusieurs département, séparez les codes des départements par une virgule."
                }
              ]
            },
            {
              "label": "Condition géographique communale",
              "name": "communes",
              "widget": "object",
              "fields": [
                {
                  "label": "Code INSEE des communes",
                  "name": "values",
                  "widget": "list",
                  "hint": "Pour saisir plusieurs communes, séparez les codes INSEE des communes par une virgule."
                }
              ]
            },
            {
              "label": "Régime de sécurité sociale",
              "name": "regime_securite_sociale",
              "widget": "object",
              "fields": [
                {
                  "widget": "select",
                  "multiple": true,
                  "required": false,
                  "options": [
                    {
                      "label": "Régime général",
                      "value": "regime_general"
                    },
                    {
                      "label": "Régime agricole",
                      "value": "regime_agricole"
                    },
                    {
                      "label": "Autres régimes",
                      "value": "autres_regimes"
                    }
                  ],
                  "label": "Inclus",
                  "name": "includes"
                },
                {
                  "widget": "select",
                  "multiple": true,
                  "required": false,
                  "options": [
                    {
                      "label": "Régime général",
                      "value": "regime_general"
                    },
                    {
                      "label": "Régime agricole",
                      "value": "regime_agricole"
                    },
                    {
                      "label": "Autres régimes",
                      "value": "autres_regimes"
                    }
                  ],
                  "label": "Exclus",
                  "name": "excludes"
                }
              ]
            },
            {
              "label": "Quotient familial",
              "name": "quotient_familial",
              "widget": "object",
              "fields": [
                {
                  "label": "Plafond",
                  "name": "floor",
                  "widget": "number",
                  "step": -1
                },
                {
                  "label": "Période",
                  "name": "period",
                  "widget": "select",
                  "options": [
                    {
                      "label": "Par mois",
                      "value": "month"
                    },
                    {
                      "label": "Par an",
                      "value": "year"
                    }
                  ]
                }
              ]
            },
            {
              "widget": "object",
              "fields": [
                {
                  "label": "empty",
                  "widget": "hidden",
                  "name": "empty"
                }
              ],
              "label": "En formation sanitaire et sociale",
              "name": "formation_sanitaire_social"
            },
            {
              "widget": "object",
              "fields": [
                {
                  "label": "empty",
                  "widget": "hidden",
                  "name": "empty"
                }
              ],
              "label": "Bénéficiaire RSA",
              "name": "beneficiaire_rsa"
            }
          ]
        },
        {
          "label": "profil d'éligibilité",
          "label_singular": "profils d'éligibilité",
          "hint": "Cette aide est-elle destinée à des publics spécifiques ? (lycéen(ne)s, apprenti(e)s, en recherche d'emploi, etc.)",
          "name": "profils",
          "widget": "list",
          "collapsed": false,
          "default": [],
          "types": [
            {
              "label": "Lycéen·ne",
              "name": "lyceen",
              "widget": "object",
              "fields": [
                {
                  "label": "Conditions à satisfaire simultanément",
                  "label_singular": "Condition à satisfaire",
                  "name": "conditions",
                  "default": [],
                  "widget": "list",
                  "collapsed": false,
                  "types": [
                    {
                      "widget": "object",
                      "fields": [
                        {
                          "label": "Opérateur",
                          "name": "operator",
                          "widget": "select",
                          "options": [
                            {
                              "label": "Supérieur à",
                              "value": ">"
                            },
                            {
                              "label": "Supérieur ou égal à",
                              "value": ">="
                            },
                            {
                              "label": "Égal à",
                              "value": "="
                            },
                            {
                              "label": "Inférieur à",
                              "value": "<"
                            },
                            {
                              "label": "Inférieur ou égal à",
                              "value": "<="
                            }
                          ]
                        },
                        {
                          "label": "Valeur",
                          "name": "value",
                          "widget": "number",
                          "step": -1
                        }
                      ],
                      "label": "Age",
                      "name": "age"
                    },
                    {
                      "widget": "object",
                      "fields": [
                        {
                          "label": "empty",
                          "widget": "hidden",
                          "name": "empty"
                        }
                      ],
                      "label": "Boursier",
                      "name": "boursier"
                    },
                    {
                      "widget": "object",
                      "fields": [
                        {
                          "label": "empty",
                          "widget": "hidden",
                          "name": "empty"
                        }
                      ],
                      "label": "En formation sanitaire et sociale",
                      "name": "formation_sanitaire_social"
                    },
                    {
                      "label": "Année d'étude",
                      "name": "annee_etude",
                      "widget": "object",
                      "fields": [
                        {
                          "label": "Valeurs",
                          "name": "values",
                          "widget": "select",
                          "multiple": true,
                          "options": [
                            {
                              "label": "CAP - 1ère année",
                              "value": "cap_1"
                            },
                            {
                              "label": "CAP - 2ème année",
                              "value": "cap_2"
                            },
                            {
                              "label": "Seconde",
                              "value": "seconde"
                            },
                            {
                              "label": "Première",
                              "value": "premiere"
                            },
                            {
                              "label": "Terminale",
                              "value": "terminale"
                            },
                            {
                              "label": "Autre",
                              "value": "autre"
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "label": "Dans l'enseignement supérieur",
              "name": "enseignement_superieur",
              "widget": "object",
              "fields": [
                {
                  "label": "Conditions à satisfaire simultanément",
                  "label_singular": "Condition à satisfaire",
                  "name": "conditions",
                  "default": [],
                  "widget": "list",
                  "collapsed": false,
                  "types": [
                    {
                      "widget": "object",
                      "fields": [
                        {
                          "label": "Opérateur",
                          "name": "operator",
                          "widget": "select",
                          "options": [
                            {
                              "label": "Supérieur à",
                              "value": ">"
                            },
                            {
                              "label": "Supérieur ou égal à",
                              "value": ">="
                            },
                            {
                              "label": "Égal à",
                              "value": "="
                            },
                            {
                              "label": "Inférieur à",
                              "value": "<"
                            },
                            {
                              "label": "Inférieur ou égal à",
                              "value": "<="
                            }
                          ]
                        },
                        {
                          "label": "Valeur",
                          "name": "value",
                          "widget": "number",
                          "step": -1
                        }
                      ],
                      "label": "Age",
                      "name": "age"
                    },
                    {
                      "widget": "object",
                      "fields": [
                        {
                          "label": "empty",
                          "widget": "hidden",
                          "name": "empty"
                        }
                      ],
                      "label": "Boursier",
                      "name": "boursier"
                    },
                    {
                      "label": "Mention au bac",
                      "name": "mention_baccalaureat",
                      "widget": "object",
                      "fields": [
                        {
                          "label": "Valeurs",
                          "name": "values",
                          "widget": "select",
                          "multiple": true,
                          "options": [
                            {
                              "label": "Mention assez bien",
                              "value": "mention_assez_bien"
                            },
                            {
                              "label": "Mention bien",
                              "value": "mention_bien"
                            },
                            {
                              "label": "Mention très bien",
                              "value": "mention_tres_bien"
                            },
                            {
                              "label": "Mention très bien avec félicitations du jury",
                              "value": "mention_tres_bien_felicitations_jury"
                            },
                            {
                              "label": "Autre",
                              "value": "non_renseignee"
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "widget": "object",
                      "fields": [
                        {
                          "label": "empty",
                          "widget": "hidden",
                          "name": "empty"
                        }
                      ],
                      "label": "En formation sanitaire et sociale",
                      "name": "formation_sanitaire_social"
                    },
                    {
                      "label": "Année d'étude",
                      "name": "annee_etude",
                      "widget": "object",
                      "fields": [
                        {
                          "label": "Valeurs",
                          "name": "values",
                          "widget": "select",
                          "multiple": true,
                          "options": [
                            {
                              "label": "BTS",
                              "value": "bts_1"
                            },
                            {
                              "label": "BUT",
                              "value": "but_1"
                            },
                            {
                              "label": "CPGE",
                              "value": "cpge_1"
                            },
                            {
                              "label": "Licence - 1ère année",
                              "value": "licence_1"
                            },
                            {
                              "label": "Licence - 2ème année",
                              "value": "licence_2"
                            },
                            {
                              "label": "Licence - 3ème année",
                              "value": "licence_3"
                            },
                            {
                              "label": "Master - 1ère année",
                              "value": "master_1"
                            },
                            {
                              "label": "Master - 2ème année",
                              "value": "master_2"
                            },
                            {
                              "label": "Doctorat - 1ère année",
                              "value": "doctorat_1"
                            },
                            {
                              "label": "Doctorat - 2ème année",
                              "value": "doctorat_2"
                            },
                            {
                              "label": "Doctorat - 3ème année",
                              "value": "doctorat_3"
                            },
                            {
                              "label": "Autre",
                              "value": "autre"
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "label": "Scolarisé·e",
              "name": "etudiant",
              "widget": "object",
              "fields": [
                {
                  "label": "Conditions à satisfaire simultanément",
                  "label_singular": "Condition à satisfaire",
                  "name": "conditions",
                  "default": [],
                  "widget": "list",
                  "collapsed": false,
                  "types": [
                    {
                      "widget": "object",
                      "fields": [
                        {
                          "label": "Opérateur",
                          "name": "operator",
                          "widget": "select",
                          "options": [
                            {
                              "label": "Supérieur à",
                              "value": ">"
                            },
                            {
                              "label": "Supérieur ou égal à",
                              "value": ">="
                            },
                            {
                              "label": "Égal à",
                              "value": "="
                            },
                            {
                              "label": "Inférieur à",
                              "value": "<"
                            },
                            {
                              "label": "Inférieur ou égal à",
                              "value": "<="
                            }
                          ]
                        },
                        {
                          "label": "Valeur",
                          "name": "value",
                          "widget": "number",
                          "step": -1
                        }
                      ],
                      "label": "Age",
                      "name": "age"
                    },
                    {
                      "widget": "object",
                      "fields": [
                        {
                          "label": "empty",
                          "widget": "hidden",
                          "name": "empty"
                        }
                      ],
                      "label": "En formation sanitaire et sociale",
                      "name": "formation_sanitaire_social"
                    },
                    {
                      "label": "Année d'étude",
                      "name": "annee_etude",
                      "widget": "object",
                      "fields": [
                        {
                          "label": "Valeurs",
                          "name": "values",
                          "widget": "select",
                          "multiple": true,
                          "options": [
                            {
                              "label": "CAP - 1ère année",
                              "value": "cap_1"
                            },
                            {
                              "label": "CAP - 2ème année",
                              "value": "cap_2"
                            },
                            {
                              "label": "Seconde",
                              "value": "seconde"
                            },
                            {
                              "label": "Première",
                              "value": "premiere"
                            },
                            {
                              "label": "Terminale",
                              "value": "terminale"
                            },
                            {
                              "label": "BTS",
                              "value": "bts_1"
                            },
                            {
                              "label": "BUT",
                              "value": "but_1"
                            },
                            {
                              "label": "CPGE",
                              "value": "cpge_1"
                            },
                            {
                              "label": "Licence - 1ère année",
                              "value": "licence_1"
                            },
                            {
                              "label": "Licence - 2ème année",
                              "value": "licence_2"
                            },
                            {
                              "label": "Licence - 3ème année",
                              "value": "licence_3"
                            },
                            {
                              "label": "Master - 1ère année",
                              "value": "master_1"
                            },
                            {
                              "label": "Master - 2ème année",
                              "value": "master_2"
                            },
                            {
                              "label": "Doctorat - 1ère année",
                              "value": "doctorat_1"
                            },
                            {
                              "label": "Doctorat - 2ème année",
                              "value": "doctorat_2"
                            },
                            {
                              "label": "Doctorat - 3ème année",
                              "value": "doctorat_3"
                            },
                            {
                              "label": "Autre",
                              "value": "autre"
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "label": "Stagiaire",
              "name": "stagiaire",
              "widget": "object",
              "fields": [
                {
                  "label": "Conditions à satisfaire simultanément",
                  "label_singular": "Condition à satisfaire",
                  "name": "conditions",
                  "default": [],
                  "widget": "list",
                  "collapsed": false,
                  "types": [
                    {
                      "widget": "object",
                      "fields": [
                        {
                          "label": "empty",
                          "widget": "hidden",
                          "name": "empty"
                        }
                      ],
                      "label": "En formation sanitaire et sociale",
                      "name": "formation_sanitaire_social"
                    },
                    {
                      "widget": "object",
                      "fields": [
                        {
                          "label": "Opérateur",
                          "name": "operator",
                          "widget": "select",
                          "options": [
                            {
                              "label": "Supérieur à",
                              "value": ">"
                            },
                            {
                              "label": "Supérieur ou égal à",
                              "value": ">="
                            },
                            {
                              "label": "Égal à",
                              "value": "="
                            },
                            {
                              "label": "Inférieur à",
                              "value": "<"
                            },
                            {
                              "label": "Inférieur ou égal à",
                              "value": "<="
                            }
                          ]
                        },
                        {
                          "label": "Valeur",
                          "name": "value",
                          "widget": "number",
                          "step": -1
                        }
                      ],
                      "label": "Age",
                      "name": "age"
                    },
                    {
                      "widget": "object",
                      "fields": [
                        {
                          "label": "empty",
                          "widget": "hidden",
                          "name": "empty"
                        }
                      ],
                      "label": "Boursier",
                      "name": "boursier"
                    }
                  ]
                }
              ]
            },
            {
              "label": "Apprenti·e",
              "name": "apprenti",
              "widget": "object",
              "fields": [
                {
                  "label": "Conditions à satisfaire simultanément",
                  "label_singular": "Condition à satisfaire",
                  "name": "conditions",
                  "default": [],
                  "widget": "list",
                  "collapsed": false,
                  "types": [
                    {
                      "widget": "object",
                      "fields": [
                        {
                          "label": "empty",
                          "widget": "hidden",
                          "name": "empty"
                        }
                      ],
                      "label": "En formation sanitaire et sociale",
                      "name": "formation_sanitaire_social"
                    },
                    {
                      "widget": "object",
                      "fields": [
                        {
                          "label": "Opérateur",
                          "name": "operator",
                          "widget": "select",
                          "options": [
                            {
                              "label": "Supérieur à",
                              "value": ">"
                            },
                            {
                              "label": "Supérieur ou égal à",
                              "value": ">="
                            },
                            {
                              "label": "Égal à",
                              "value": "="
                            },
                            {
                              "label": "Inférieur à",
                              "value": "<"
                            },
                            {
                              "label": "Inférieur ou égal à",
                              "value": "<="
                            }
                          ]
                        },
                        {
                          "label": "Valeur",
                          "name": "value",
                          "widget": "number",
                          "step": -1
                        }
                      ],
                      "label": "Age",
                      "name": "age"
                    },
                    {
                      "widget": "object",
                      "fields": [
                        {
                          "label": "empty",
                          "widget": "hidden",
                          "name": "empty"
                        }
                      ],
                      "label": "Boursier",
                      "name": "boursier"
                    }
                  ]
                }
              ]
            },
            {
              "label": "En contrat de professionnalisation",
              "name": "professionnalisation",
              "widget": "object",
              "fields": [
                {
                  "label": "Conditions à satisfaire simultanément",
                  "label_singular": "Condition à satisfaire",
                  "name": "conditions",
                  "default": [],
                  "widget": "list",
                  "collapsed": false,
                  "types": [
                    {
                      "widget": "object",
                      "fields": [
                        {
                          "label": "empty",
                          "widget": "hidden",
                          "name": "empty"
                        }
                      ],
                      "label": "En formation sanitaire et sociale",
                      "name": "formation_sanitaire_social"
                    },
                    {
                      "widget": "object",
                      "fields": [
                        {
                          "label": "Opérateur",
                          "name": "operator",
                          "widget": "select",
                          "options": [
                            {
                              "label": "Supérieur à",
                              "value": ">"
                            },
                            {
                              "label": "Supérieur ou égal à",
                              "value": ">="
                            },
                            {
                              "label": "Égal à",
                              "value": "="
                            },
                            {
                              "label": "Inférieur à",
                              "value": "<"
                            },
                            {
                              "label": "Inférieur ou égal à",
                              "value": "<="
                            }
                          ]
                        },
                        {
                          "label": "Valeur",
                          "name": "value",
                          "widget": "number",
                          "step": -1
                        }
                      ],
                      "label": "Age",
                      "name": "age"
                    }
                  ]
                }
              ]
            },
            {
              "label": "En recherche d'emploi",
              "name": "chomeur",
              "widget": "object",
              "fields": [
                {
                  "label": "Conditions à satisfaire simultanément",
                  "label_singular": "Condition à satisfaire",
                  "name": "conditions",
                  "default": [],
                  "widget": "list",
                  "collapsed": false,
                  "types": [
                    {
                      "widget": "object",
                      "fields": [
                        {
                          "label": "Opérateur",
                          "name": "operator",
                          "widget": "select",
                          "options": [
                            {
                              "label": "Supérieur à",
                              "value": ">"
                            },
                            {
                              "label": "Supérieur ou égal à",
                              "value": ">="
                            },
                            {
                              "label": "Égal à",
                              "value": "="
                            },
                            {
                              "label": "Inférieur à",
                              "value": "<"
                            },
                            {
                              "label": "Inférieur ou égal à",
                              "value": "<="
                            }
                          ]
                        },
                        {
                          "label": "Valeur",
                          "name": "value",
                          "widget": "number",
                          "step": -1
                        }
                      ],
                      "label": "Age",
                      "name": "age"
                    }
                  ]
                }
              ]
            },
            {
              "label": "Salarié·e",
              "name": "salarie",
              "widget": "object",
              "fields": [
                {
                  "label": "Conditions à satisfaire simultanément",
                  "label_singular": "Condition à satisfaire",
                  "name": "conditions",
                  "default": [],
                  "widget": "list",
                  "collapsed": false,
                  "types": [
                    {
                      "widget": "object",
                      "fields": [
                        {
                          "label": "Opérateur",
                          "name": "operator",
                          "widget": "select",
                          "options": [
                            {
                              "label": "Supérieur à",
                              "value": ">"
                            },
                            {
                              "label": "Supérieur ou égal à",
                              "value": ">="
                            },
                            {
                              "label": "Égal à",
                              "value": "="
                            },
                            {
                              "label": "Inférieur à",
                              "value": "<"
                            },
                            {
                              "label": "Inférieur ou égal à",
                              "value": "<="
                            }
                          ]
                        },
                        {
                          "label": "Valeur",
                          "name": "value",
                          "widget": "number",
                          "step": -1
                        }
                      ],
                      "label": "Age",
                      "name": "age"
                    }
                  ]
                }
              ]
            },
            {
              "label": "Travailleur·euse indépendant·e",
              "name": "independant",
              "widget": "object",
              "fields": [
                {
                  "label": "Conditions à satisfaire simultanément",
                  "label_singular": "Condition à satisfaire",
                  "name": "conditions",
                  "default": [],
                  "widget": "list",
                  "collapsed": false,
                  "types": [
                    {
                      "widget": "object",
                      "fields": [
                        {
                          "label": "Opérateur",
                          "name": "operator",
                          "widget": "select",
                          "options": [
                            {
                              "label": "Supérieur à",
                              "value": ">"
                            },
                            {
                              "label": "Supérieur ou égal à",
                              "value": ">="
                            },
                            {
                              "label": "Égal à",
                              "value": "="
                            },
                            {
                              "label": "Inférieur à",
                              "value": "<"
                            },
                            {
                              "label": "Inférieur ou égal à",
                              "value": "<="
                            }
                          ]
                        },
                        {
                          "label": "Valeur",
                          "name": "value",
                          "widget": "number",
                          "step": -1
                        }
                      ],
                      "label": "Age",
                      "name": "age"
                    }
                  ]
                }
              ]
            },
            {
              "label": "En service civique",
              "name": "service_civique",
              "widget": "object",
              "fields": [
                {
                  "label": "Conditions à satisfaire simultanément",
                  "label_singular": "Condition à satisfaire",
                  "name": "conditions",
                  "default": [],
                  "widget": "list",
                  "collapsed": false,
                  "types": [
                    {
                      "widget": "object",
                      "fields": [
                        {
                          "label": "Opérateur",
                          "name": "operator",
                          "widget": "select",
                          "options": [
                            {
                              "label": "Supérieur à",
                              "value": ">"
                            },
                            {
                              "label": "Supérieur ou égal à",
                              "value": ">="
                            },
                            {
                              "label": "Égal à",
                              "value": "="
                            },
                            {
                              "label": "Inférieur à",
                              "value": "<"
                            },
                            {
                              "label": "Inférieur ou égal à",
                              "value": "<="
                            }
                          ]
                        },
                        {
                          "label": "Valeur",
                          "name": "value",
                          "widget": "number",
                          "step": -1
                        }
                      ],
                      "label": "Age",
                      "name": "age"
                    }
                  ]
                }
              ]
            },
            {
              "label": "Bénéficiaire RSA",
              "name": "beneficiaire_rsa",
              "widget": "object",
              "fields": [
                {
                  "label": "Conditions à satisfaire simultanément",
                  "label_singular": "Condition à satisfaire",
                  "name": "conditions",
                  "default": [],
                  "widget": "list",
                  "collapsed": false,
                  "types": [
                    {
                      "widget": "object",
                      "fields": [
                        {
                          "label": "Opérateur",
                          "name": "operator",
                          "widget": "select",
                          "options": [
                            {
                              "label": "Supérieur à",
                              "value": ">"
                            },
                            {
                              "label": "Supérieur ou égal à",
                              "value": ">="
                            },
                            {
                              "label": "Égal à",
                              "value": "="
                            },
                            {
                              "label": "Inférieur à",
                              "value": "<"
                            },
                            {
                              "label": "Inférieur ou égal à",
                              "value": "<="
                            }
                          ]
                        },
                        {
                          "label": "Valeur",
                          "name": "value",
                          "widget": "number",
                          "step": -1
                        }
                      ],
                      "label": "Age",
                      "name": "age"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "label": "Conditions non prises en compte par le simulateur",
          "label_singular": "Condition non prise en compte par le simulateur",
          "hint": "Certains critères d'éligibilité ne peuvent pas être demandés (trop précis)\nni pris en compte (trop complexe) dans le cadre d'un simulateur grand public.\nCette liste permet d'informer les usagers sur ces critères supplémentaires.\nPar exemple : Signer un contrat d’engagement réciproque (CER) avec votre département\nou un Projet Personnalisé d’Accès à l’Emploi (PPAE) avec Pôle emploi.\n",
          "name": "conditions",
          "widget": "list",
          "collapsed": false,
          "field": {
            "label": "Texte",
            "name": "text",
            "widget": "string",
            "pattern": [
              "\\.$",
              "La condition doit commencer par un verbe à l'infinitif et se terminer par un point"
            ]
          }
        },
        {
          "label": "Faut-il limiter l'affichage de l'aide en fonction d'un intérêt particulier ?",
          "name": "interestFlag",
          "hint": "En fin de parcours, des questions sont posées pour connaitre certains intérêts des usagers.\nCela permet d'éviter d'afficher certaines aides qui ne seraient pas pertinentes pour les usagers.\n",
          "widget": "select",
          "required": false,
          "options": [
            {
              "label": "Oui, l'afficher en cas d'intérêt pour le BAFA ou le BAFD.",
              "value": "_interetBafa"
            },
            {
              "label": "Oui, l'afficher en cas d'intérêt pour passer le permis de conduire.",
              "value": "_interetPermisDeConduire"
            },
            {
              "label": "Oui, l'afficher en cas d'intérêt pour faire des études à l'étranger.",
              "value": "_interetEtudesEtranger"
            },
            {
              "label": "Oui, l'afficher en cas d'intérêt pour faire une formation dans le sanitaire et social.",
              "value": "_interetAidesSanitaireSocial"
            }
          ]
        },
        {
          "label": "Type du résultat",
          "name": "type",
          "default": "float",
          "widget": "select",
          "options": [
            {
              "label": "Valeur numérique",
              "value": "float"
            },
            {
              "label": "Éligibilité (Oui / Non)",
              "value": "bool"
            },
            {
              "label": "Autre",
              "value": "mixed"
            }
          ]
        },
        {
          "label": "Unité lorsque le type de résultat est une valeur numérique",
          "hint": "Il s'agit de spécifier l'unité du montant.",
          "name": "unit",
          "widget": "select",
          "default": "€",
          "required": false,
          "options": [
            {
              "label": "€",
              "value": "€"
            },
            {
              "label": "%",
              "value": "%"
            },
            {
              "label": "séances",
              "value": "séances"
            }
          ]
        },
        {
          "label": "Périodicité de l'aide",
          "name": "periodicite",
          "widget": "select",
          "hint": "Cette variable permet de définir la fréquence de versement de l'aide.",
          "required": true,
          "default": "autre",
          "options": [
            {
              "label": "Ponctuelle",
              "value": "ponctuelle"
            },
            {
              "label": "Mensuelle (/ mois)",
              "value": "mensuelle"
            },
            {
              "label": "Annuelle (/ an)",
              "value": "annuelle"
            },
            {
              "label": "Autre",
              "value": "autre"
            }
          ]
        },
        {
          "label": "Légende associée à la valeur numérique",
          "hint": "Cette légende permet d'ajouter des informations complémentaires sur le montant. Par exemple, si vous écrivez \"maximum\" dans ce champ, et que vous avez indiqué \"200 €\" en montant, cela affichera \"200 € maximum\".",
          "name": "legend",
          "widget": "string",
          "required": false
        },
        {
          "label": "Montant maximal de l'aide",
          "name": "montant",
          "widget": "number",
          "required": false,
          "step": -1
        },
        {
          "label": "Lien vers la page d'informations de référence",
          "hint": "Vers un site institutionnel de préférence (par exemple, pour les aides nationales il s'agit souvent de service-public.fr)",
          "name": "link",
          "widget": "string",
          "pattern": [
            "^(https?://)",
            "Le champ doit contenir une url valide"
          ]
        },
        {
          "label": "Lien vers un téléservice",
          "hint": "Lorsqu'il y a la possibilité de faire la démarche en ligne.",
          "name": "teleservice",
          "widget": "string",
          "pattern": [
            "^(https?://)",
            "Le champ doit contenir une url valide"
          ],
          "required": false,
          "required_group": "cta"
        },
        {
          "label": "Lien vers un formulaire à imprimer",
          "name": "form",
          "widget": "string",
          "pattern": [
            "^(https?://)",
            "Le champ doit contenir une url valide"
          ],
          "required": false,
          "required_group": "cta"
        },
        {
          "label": "Lien vers des instructions à suivre",
          "name": "instructions",
          "widget": "string",
          "pattern": [
            "^(https?://)",
            "Le champ doit contenir une url valide"
          ],
          "required": false,
          "required_group": "cta"
        },
        {
          "label": "Niveau de fiabilité lorsque le résultat est un montant",
          "name": "floorAt",
          "widget": "select",
          "required": false,
          "options": [
            {
              "label": "10 (pour arrondir à la dizaine inférieure)",
              "value": 10
            },
            {
              "label": "1 (pour l'euro inférieur)",
              "value": 1
            },
            {
              "label": "0.01 (pour éviter l'arrondi)",
              "value": 0.01
            }
          ]
        },
        {
          "label": "Masquer l'aide",
          "name": "private",
          "required": false,
          "widget": "hidden"
        },
        {
          "label": "L'aide ne couvre qu'une partie des frais",
          "name": "participation",
          "widget": "hidden"
        }
      ],
      "items": [
        {
          "slug": "aide-au-bafa-pour-une-session-dapprofondissement-ou-de-qualification-caf-de-la-haute-savoie",
          "label": "aide au BAFA pour une session d'approfondissement ou de qualification",
          "institution": "caf_haute_savoie",
          "description": "L'aide au BAFA pour une session de formation générale est une aide locale mise à disposition par la CAF de la Haute-Savoie pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.<br><br>Pour bénéficier de cette aide, contactez directement la CAF de la Haute-Savoie pour connaître les démarches à suivre.",
          "conditions": [
            "Avoir commencé une formation pour obtenir le brevet d’aptitude à la fonction d’animateur de centre de vacances et de loisirs (BAFA)."
          ],
          "link": "https://www.caf.fr/allocataires/caf-de-la-haute-savoie/contacter-ma-caf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-la-haute-savoie/contacter-ma-caf",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 109,
          "interestFlag": "_interetBafa",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "74"
              ]
            }
          ],
          "profils": []
        },
        {
          "slug": "aide-au-bafa-pour-une-session-de-formation-générale-caf-de-la-haute-savoie",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_haute_savoie",
          "description": "L'aide au BAFA pour une session de formation générale est une aide locale mise à disposition par la CAF de la Haute-Savoie pour vous aider à financer votre session en complément de  l'aide nationale apportée par la caf.  <br> <br> Pour bénéficier de cette aide, contactez directement la CAF de la Haute-Savoie pour connaître les démarches à suivre.",
          "conditions": [],
          "link": "https://www.caf.fr/allocataires/caf-de-la-haute-savoie/contacter-ma-caf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-la-haute-savoie/contacter-ma-caf",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 200,
          "interestFlag": "_interetBafa",
          "conditions_generales": [
            {
              "type": "departements",
              "values": [
                "74"
              ]
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            }
          ],
          "profils": []
        },
        {
          "slug": "auvergne-rhone-alpes-bourse-merite",
          "label": "bourse au mérite",
          "institution": "region_auvergne_rhone_alpes",
          "description": "La bourse au mérite permet de récompenser les lycéen·ne·s et les élèves en formation professionnelle méritants par un versement en une fois de 500 euros. La région souhaite les aider à poursuivre leurs études dans de bonnes conditions et veut encourager l’émulation pour développer des parcours de réussite dans notre système éducatif.",
          "conditions": [
            "Être titulaire d’une mention « Très Bien » à la dernière session du baccalauréat ou avoir au moins 16 sur 20 au Certificat d’Aptitude Professionnelle (CAP) ou au Brevet professionnel (BP).",
            "Poursuivre un parcours d’études ou professionnel."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "84"
              ]
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": [
                {
                  "type": "mention_baccalaureat",
                  "values": [
                    "mention_tres_bien",
                    "mention_tres_bien_felicitations_jury"
                  ]
                }
              ]
            },
            {
              "type": "apprenti"
            }
          ],
          "link": "https://rhone-alpes.commeunservice.com/bourses/jsp/nouveauContexte.action?codeAction=M42-ACCUEIL#BAM",
          "teleservice": "https://rhone-alpes.commeunservice.com/bourses/jsp/nouveauContexte.action?codeAction=M42-ACCUEIL#BAM",
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "floorAt": 10,
          "montant": 500
        },
        {
          "slug": "auvergne-rhone-alpes-pass-region",
          "label": "pass'Région",
          "institution": "region_auvergne_rhone_alpes",
          "description": "Le Pass'Région est une carte délivrée par la Région Auvergne-Rhône-Alpes qui permet d'accéder à de nombreux avantages en lien avec la formation (gratuité des manuels scolaires, financement de l'équipement), la culture, le sport, la santé et, sous certaines conditions, une aide financière pour le permis, le BAFA/BAFD ou le BNSSA.",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 16
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "regions",
              "values": [
                "84"
              ]
            }
          ],
          "profils": [
            {
              "type": "apprenti",
              "conditions": []
            },
            {
              "type": "lyceen",
              "conditions": []
            }
          ],
          "link": "https://jeunes.auvergnerhonealpes.fr/106-pass-region.htm",
          "teleservice": "https://auvergnerhonealpes.zecarte.fr/ARA/Forms/InscriptionBeneficiaire/InfosCarte.aspx",
          "instructions": "https://www.auvergnerhonealpes.fr/aide/173/289-faire-le-plein-d-avantages-avec-le-pass-region-education-lycees.htm",
          "prefix": "le",
          "type": "bool",
          "unit": null,
          "periodicite": "annuelle"
        },
        {
          "slug": "benefit_front_test",
          "private": true,
          "label": "Aide de test pour les calculs hors d'OpenFisca",
          "institution": "etat",
          "description": "Cette aide permet aux apprentis majeurs de financer une partie du coût de la formation au permis de conduire.",
          "link": "https://travail-emploi.gouv.fr/formation-professionnelle/formation-en-alternance-10751/apprentissage/apprentis-aide-permis",
          "instructions": "https://travail-emploi.gouv.fr/formation-professionnelle/formation-en-alternance-10751/apprentissage/apprentis-aide-permis#Comment-obtenir-l-aide-au-financement-du-permis-de-conduire-B-nbsp",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "floorAt": 1,
          "montant": 200,
          "periodicite": "ponctuelle",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 16
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "departements",
              "values": [
                "64",
                "45",
                "12",
                "55"
              ]
            }
          ],
          "profils": [
            {
              "type": "chomeur"
            },
            {
              "type": "enseignement_superieur",
              "conditions": [
                {
                  "type": "boursier"
                },
                {
                  "type": "mention_baccalaureat",
                  "values": [
                    "mention_tres_bien",
                    "mention_tres_bien_felicitations_jury"
                  ]
                }
              ]
            },
            {
              "type": "independant"
            },
            {
              "type": "service_civique"
            }
          ]
        },
        {
          "slug": "bourgogne-franche-comte-dynastage",
          "label": "Dynastage - Apprenti",
          "institution": "region_bourgogne_franche_comte",
          "description": "L’aide Dynastage est une aide financière versée aux apprentis qui permet d’effectuer des stages en entreprise dans le monde entier.",
          "conditions": [
            "Être un apprenti infra-Bac inscrit dans un CFA.",
            "Effectuer un stage d'une durée de deux semaines à 6 mois."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "27"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "year",
              "floor": 25830
            }
          ],
          "profils": [
            {
              "type": "apprenti"
            }
          ],
          "link": "https://www.bourgognefranchecomte.fr/node/332",
          "prefix": "l’aide",
          "type": "float",
          "unit": "€",
          "periodicite": "autre",
          "legend": "maximum",
          "montant": 2280,
          "interestFlag": "_interetEtudesEtranger"
        },
        {
          "slug": "bretagne-aide-code-permis",
          "label": "aide à la formation au code et au permis de conduire",
          "institution": "region_bretagne",
          "description": "Cette aide vise à favoriser l’accès à la formation et l’insertion professionnelle des jeunes breton·ne·s en prenant en charge le financement de leur permis de conduire.",
          "conditions": [
            "Etre suivi·e par une mission locale : <a target=\"_blank\" rel=\"noopener\" title=\"Voir les missions locales - Nouvelle fenêtre\" href=\"https://www.unml.info/les-missions-locales/annuaire/bretagne/\">contacter la mission locale la plus proche</a>.",
            "Pouvoir montrer que l’absence de permis est un frein à la réalisation de votre projet.",
            "Bénéficier de l’accompagnement à la qualification de la Région Bretagne ou <a target=\"_blank\" rel=\"noopener\" title=\"Formation QUALIF Emploi - Nouvelle fenêtre\" href=\"https://www.bretagne.bzh/aides/fiches/copie-de-qualif-emploi/\">suivre une formation QUALIF Emploi</a>."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 18
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "regions",
              "values": [
                "53"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.bretagne.bzh/aides/fiches/aide-a-la-formation-au-code-et-au-permis-de-conduire/",
          "instructions": "https://www.bretagne.bzh/aides/fiches/aide-a-la-formation-au-code-et-au-permis-de-conduire/",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 1500,
          "interestFlag": "_interetPermisDeConduire"
        },
        {
          "slug": "bretagne-jeune-international",
          "label": "bourse jeunes à l’international",
          "institution": "region_bretagne",
          "description": "La bourse « Jeunes à l’international » vise accompagner les étudiants dans leur projet de stage professionnel ou de séjour d’études à l’étranger.",
          "conditions": [
            "Ne pas être bénéficiaire d'une autre aide publique à la mobilité internationale (européenne, nationale, régionale, départementale, locale)."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "53"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "year",
              "floor": 25000
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur"
            }
          ],
          "link": "https://www.bretagne.bzh/aides/fiches/bourse-jeunes-a-linternational-etudiants-des-universites/",
          "teleservice": "https://extranets.region-bretagne.fr/Portail-Aides/jsp/nouveauContexte.action?codeAction=M42-CONNEXION",
          "prefix": "la",
          "type": "bool",
          "periodicite": "ponctuelle",
          "interestFlag": "_interetEtudesEtranger"
        },
        {
          "slug": "caf-aide-nationale-bafa",
          "label": "aide nationale au Brevet d'aptitude aux fonctions d'animateur (BAFA)",
          "institution": "caf",
          "description": "La Caisse Nationale d'Allocations Familiales vous aide à financer une partie de votre BAFA afin d'obtenir une formation qualifiante d’animateur. <br>Les étapes de la formation du Bafa sont les suivantes : <br>1) la session de formation générale, <br>2) le stage pratique, <br>3) la session d’approfondissement ou de qualification.<br>Cette aide est accessible aux non-allocataires et allocataires de la CAF.",
          "conditions": [
            "Avoir réalisé la session de formation générale et le stage pratique dans un\n    délai maximum de 18 mois.",
            "Suivre la session d'approfondissement et de qualification dans la limite de\n    30 mois à compter de l'inscription.",
            "Envoyer le formulaire à votre Caf en <a target=\"_blank\" title=\"Connexion au compte - Nouvelle fenêtre\" rel=\"noopener\" href=\"https://connect.caf.fr/connexionappli/dist/?forceReload=20211220&contexteAppel=caffr&urlredirect=%2Fwps%2Fmyportal%2Fcaffr#/login\">vous connectant à votre compte</a>, dans ”Mes démarches” > ”À transmettre”."
          ],
          "link": "https://caf.fr/sites/default/files/medias/598/Documents/Bafa/DEMANDE%20AIDE%20BAFA.pdf",
          "form": "https://caf.fr/sites/default/files/medias/598/Documents/Bafa/DEMANDE%20AIDE%20BAFA.pdf",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 106,
          "interestFlag": "_interetBafa",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            }
          ],
          "profils": []
        },
        {
          "slug": "caf-ain-aide-bafa-session-approfondissement-qualification",
          "label": "aide au BAFA pour une session de formation d’approfondissement ou de qualification",
          "institution": "caf_ain",
          "description": "L'aide au BAFA pour une session d'approfondissement ou de qualification est une aide locale mise à disposition par la CAF de l'Ain pour vous aider à financer votre session en complément de  l'aide nationale apportée par la caf.",
          "conditions": [
            "Avoir commencé une formation pour obtenir le brevet d’aptitude à la fonction d’animateur de centre de vacances et de loisirs (BAFA)."
          ],
          "link": "https://www.caf.fr/allocataires/caf-de-l-ain/offre-de-service/enfance-et-jeunesse/le-bafa",
          "form": "https://www.caf.fr/sites/default/files/caf/011/Images/Offres%20de%20service/Enfance%20et%20Jeunesse/Demande%20BAFA%203.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-l-ain/offre-de-service/enfance-et-jeunesse/le-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 50,
          "interestFlag": "_interetBafa",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "01"
              ]
            }
          ],
          "profils": []
        },
        {
          "slug": "caf-ain-aide-bafa-session-generale",
          "label": "aide locale au BAFA pour une session de formation générale",
          "institution": "caf_ain",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de l'Ain pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "link": "https://www.caf.fr/allocataires/caf-de-l-ain/offre-de-service/enfance-et-jeunesse/le-bafa",
          "form": "https://www.caf.fr/sites/default/files/caf/011/Images/Offres%20de%20service/Logement/2021/ANNEXE%204.1%20_BAFA_session%20de%20form%20g%C3%A9n%C3%A9rale%20RIAS%202021.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-l-ain/offre-de-service/enfance-et-jeunesse/le-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "",
          "montant": 160,
          "interestFlag": "_interetBafa",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "01"
              ]
            }
          ],
          "profils": []
        },
        {
          "slug": "caf-allier-aide-bafa-session-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_allier",
          "description": "L'aide au BAFA pour une session de formation générale est une aide locale mise à disposition par la CAF de l'Allier pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.\n",
          "conditions": [
            "Etre allocataire de la CAF."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 29
            },
            {
              "type": "departements",
              "values": [
                "03"
              ]
            },
            {
              "type": "quotient_familial",
              "floor": 900,
              "period": "month"
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-l-allier/offre-de-service/vie-professionnelle/le-bafa-brevet-d-aptitude-aux-fonctions-d-animateur",
          "form": "https://www.caf.fr/sites/default/files/caf/031/Enfance%20et%20jeunesse/BAFA/DBAFA.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-l-allier/offre-de-service/vie-professionnelle/le-bafa-brevet-d-aptitude-aux-fonctions-d-animateur",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 280,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-alpes-de-haute-provence-aide-a-lautonomisation-des-jeunes",
          "label": "aide à l'Autonomisation des Jeunes (AAJ)",
          "institution": "caf_alpes_de_haute_provence",
          "description": "Par cette prestation, la Caf des Alpes de Haute–Provence soutient financièrement les familles dont le(s) jeune(s) âgé·e(s) de 18 à 25 ans poursuive(nt) des études post-baccalauréat engageant des frais d’hébergement.",
          "conditions": [
            "Poursuivre des études nécessitant une décohabitation et le paiement de frais d’hébergement (l’hébergement à titre gracieux ne sera pas pris en compte).",
            "Avoir un quotient familial inférieur à 950€.",
            "Être fiscalement à charge de vos parents.",
            "Ne pas percevoir de ressources supérieures ou égales à 55% du SMIC."
          ],
          "conditions_generales": [
            {
              "type": "departements",
              "values": [
                "04"
              ]
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": []
            },
            {
              "type": "etudiant",
              "conditions": [
                {
                  "type": "age",
                  "operator": ">",
                  "value": 18
                },
                {
                  "type": "age",
                  "operator": "<",
                  "value": 25
                }
              ]
            }
          ],
          "link": "https://www.caf.fr/partenaires/caf-des-alpes-de-haute-provence/partenaires-locaux/aide-a-l-autonomisation-des-jeunes",
          "form": "https://www.caf.fr/sites/default/files/caf/041/AFI/IMPRIME%20AAJ%2010-2021%20pour%20utilisation%202022.pdf",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "mensuelle",
          "legend": "maximum",
          "montant": 120
        },
        {
          "slug": "caf-alpes-maritimes-aide-autonomie-jeunes",
          "label": "aide à l'autonomie des jeunes",
          "institution": "caf_alpes_maritimes",
          "description": "La Caf des Alpes-Maritimes peut verser à vos parents une Aide à l'autonomie des jeunes (Aaj).",
          "conditions": [
            "Etre domicilié·e et à la charge de vos parents.",
            "Etre titulaire du Bac et poursuivre des études supérieures en France ou à Monaco et, en cas de cumul des études avec une activité professionnelle, avoir un revenu inférieur ou égal à 55 % du smic.",
            "Ne pas percevoir une aide au logement à titre personnel."
          ],
          "conditions_generales": [
            {
              "type": "departements",
              "values": [
                "06"
              ]
            },
            {
              "type": "quotient_familial",
              "floor": 800,
              "period": "month"
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 20
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": [
                {
                  "type": "age",
                  "operator": "<=",
                  "value": 25
                }
              ]
            }
          ],
          "link": "https://www.caf.fr/allocataires/caf-des-alpes-maritimes/offre-de-service/enfance-et-jeunesse/l-aide-a-l-autonomie-des-jeunes-aaj",
          "form": "https://www.caf.fr/sites/default/files/caf/061/Documents/OFFRESCE/ENFANCEJEUNESSE/2020/Demande_2020_aide_autonomie_jeunes.pdf",
          "instructions": "https://www.caf.fr/sites/default/files/caf/061/Documents/OFFRESCE/ENFANCEJEUNESSE/2021/NOTICE%20-%20DEMANDE%20AAJ%20%20sept%2021%20juin%2022%20%28MAJ%2005%2010%2021%29.pdf",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "versée en deux fois",
          "montant": 1520
        },
        {
          "slug": "caf-alpes-maritimes-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_alpes_maritimes",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF des Alpes-Maritimes pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "06"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-des-alpes-maritimes/offre-de-service/enfance-et-jeunesse/le-financement-du-bafa",
          "form": "https://www.caf.fr/sites/default/files/caf/061/Documents/OFFRESCE/ENFANCEJEUNESSE/2021/Demande%20bourse%20BAFA_200421.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-des-alpes-maritimes/offre-de-service/enfance-et-jeunesse/le-financement-du-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 150,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-alpes-maritimes-aide-bafa-generale",
          "label": "Aide au BAFA pour une session de formation générale",
          "institution": "caf_alpes_maritimes",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF des Alpes-Maritimes pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.\n",
          "link": "https://www.caf.fr/allocataires/caf-des-alpes-maritimes/offre-de-service/enfance-et-jeunesse/le-financement-du-bafa",
          "form": "https://www.caf.fr/sites/default/files/caf/061/Documents/OFFRESCE/ENFANCEJEUNESSE/2021/Demande%20bourse%20BAFA_200421.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-des-alpes-maritimes/offre-de-service/enfance-et-jeunesse/le-financement-du-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 250,
          "interestFlag": "_interetBafa",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "06"
              ]
            }
          ],
          "profils": []
        },
        {
          "slug": "caf-ardeche-aide-bafa",
          "label": "aide au BAFA pour une session de formation d'approfondissement ou de qualification",
          "institution": "caf_ardeche",
          "description": "La CAF de l'Ardèche peut financer une partie de la session d'approfondissement au BAFA. Cette aide n'est pas cumulable avec l'aide nationale apportée par la caf.",
          "conditions": [
            "Avoir déjà suivi la session de formation générale au BAFA.",
            "Avoir déjà effectué le stage pratique d'animation."
          ],
          "link": "https://www.caf.fr/allocataires/caf-de-l-ardeche/offre-de-service/enfance-et-jeunesse/le-bafa",
          "form": "https://www.caf.fr/sites/default/files/caf/078/Documents/demande_daide_bafa.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-l-ardeche/offre-de-service/enfance-et-jeunesse/le-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 300,
          "interestFlag": "_interetBafa",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "07"
              ]
            }
          ],
          "profils": []
        },
        {
          "slug": "caf-ardennes-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_ardennes",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF des Ardennes pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.\n",
          "conditions": [
            "Etre rattaché au régime général de la Sécurité Sociale.",
            "Engager votre formation avec un organisme ayant son siège ou une antenne dans les départements de la Marne, des Ardennes, de la Haute Marne, et de l'Aube."
          ],
          "link": "https://www.caf.fr/allocataires/caf-des-ardennes/offre-de-service/enfance-et-jeunesse/le-bafa",
          "teleservice": "https://wwwd.caf.fr/wps/portal/caffr/aidesetservices/lesservicesenligne/faireunedemandedeprestation#/autres",
          "instructions": "https://www.caf.fr/allocataires/caf-des-ardennes/offre-de-service/enfance-et-jeunesse/le-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 330,
          "interestFlag": "_interetBafa",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "08"
              ]
            }
          ],
          "profils": []
        },
        {
          "slug": "caf-ardennes-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_ardennes",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF des Ardennes pour vous aider à financer votre session en complément de l’aide nationale l'aide nationale apportée par la caf.",
          "conditions": [
            "Etre rattaché au régime général de la sécurité sociale.",
            "Engager votre formation avec un organisme ayant son siège ou une antenne dans les départements de la Marne, des Ardennes, de la Haute Marne, et de l'Aube."
          ],
          "link": "https://www.caf.fr/allocataires/caf-des-ardennes/offre-de-service/enfance-et-jeunesse/le-bafa",
          "teleservice": "https://wwwd.caf.fr/wps/portal/caffr/aidesetservices/lesservicesenligne/faireunedemandedeprestation#/autres",
          "instructions": "https://www.caf.fr/allocataires/caf-des-ardennes/offre-de-service/enfance-et-jeunesse/le-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 280,
          "interestFlag": "_interetBafa",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "08"
              ]
            }
          ],
          "profils": []
        },
        {
          "slug": "caf-aube-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_aube",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de l'Aube pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Etre allocataire ou rattaché au dossier allocataire de ses parents.",
            "Avoir effectué, après la session théorique de 8 jours, le stage pratique dans un organisme de vacances et/ou de loisirs aubois."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "10"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 1456
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-l-aube/offre-de-service/petite-enfance/bourses-bafa-et-bafd",
          "form": "https://www.caf.fr/sites/default/files/caf/101/Documents/Bafa/formation%20BAFA%20aide%20complementaire.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-l-aube/offre-de-service/petite-enfance/bourses-bafa-et-bafd",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 250,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-aube-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_aube",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de l'Aube pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Etre allocataire ou rattaché au dossier allocataire de ses parents.",
            "Avoir effectué, après la session théorique de 8 jours, le stage pratique dans un organisme de vacances et/ou de loisirs aubois."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "10"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 1456
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-l-aube/offre-de-service/petite-enfance/bourses-bafa-et-bafd",
          "form": "https://www.caf.fr/sites/default/files/caf/101/Documents/Bafa/Bourse%20Compl%C3%A9mentaire%20BAFA%20stage%20de%20base.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-l-aube/offre-de-service/petite-enfance/bourses-bafa-et-bafd",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "",
          "montant": 250,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-aveyron-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_aveyron",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de l'Aveyron pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Effectuer votre stage auprès d’un organisme habilité.",
            "Avoir suivi les 3 sessions de formation : base, pratique, perfectionnement."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "12"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-l-aveyron/offre-de-service/enfance-et-jeunesse/le-bafa",
          "form": "https://www.caf.fr/sites/default/files/caf/121/Documents/Offre%20de%20service/demande_daide_bafa_r4.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-l-aveyron/offre-de-service/enfance-et-jeunesse/le-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 200,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-bas-rhin-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_bas_rhin",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF du Bas-Rhin pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions": [],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "67"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.bafa-bafd.jeunes.gouv.fr/Region.aspx?MJSReg=67",
          "instructions": "https://www.caf.fr/allocataires/caf-du-bas-rhin/offre-de-service/enfance-et-jeunesse/l-aide-au-bafa-brevet-d-aptitude-aux-fonctions-d-animateur",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 300,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-bas-rhin-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_bas_rhin",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF du Bas-Rhin pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Effectuer votre stage de formation de base dans l'un des organismes de formation habilités par les services de la Jeunesse et des Sports."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "67"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.bafa-bafd.jeunes.gouv.fr/Region.aspx?MJSReg=67",
          "form": "",
          "instructions": "https://www.caf.fr/allocataires/caf-du-bas-rhin/offre-de-service/enfance-et-jeunesse/l-aide-au-bafa-brevet-d-aptitude-aux-fonctions-d-animateur",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 300,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-bouches-du-rhone-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_bouches_du_rhone",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF des Bouches du Rhône pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Effectuer votre stage en France.",
            "Être à la charge de vos parents bénéficiaires de prestations familiales pour vous si vous avez entre 17 et 20 ans."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "13"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 900
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-des-bouches-du-rhone/offre-de-service/petite-enfance-enfance-et-jeunesse",
          "instructions": "https://www.caf.fr/allocataires/caf-des-bouches-du-rhone/offre-de-service/petite-enfance-enfance-et-jeunesse",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 180,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-bouches-du-rhone-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_bouches_du_rhone",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF des Bouches-du-Rhône pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Effectuer votre stage en France.",
            "Être à la charge de vos parents bénéficiaires de prestations familiales pour vous si vous avez entre 17 et 20 ans."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "13"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 900
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-des-bouches-du-rhone/offre-de-service/petite-enfance-enfance-et-jeunesse",
          "instructions": "https://www.caf.fr/allocataires/caf-des-bouches-du-rhone/offre-de-service/petite-enfance-enfance-et-jeunesse",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 350,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-calvados-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_calvados",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF du Calvados pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Etre allocataire ou rattaché au dossier allocataire de vos parents.",
            "Etre stagiaire ou bénéficiaire de l'aide au logement au moment du stage."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "14"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 850
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-du-calvados/offre-de-service/enfance-et-jeunesse/bafa-bafd",
          "form": "https://www.caf.fr/sites/default/files/caf/141/Caf.fr/Offre%20de%20service/Enfance%20Jeunesse/Dem%20BAFA%20approfondis%202017.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-du-calvados/offre-de-service/enfance-et-jeunesse/bafa-bafd",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 215,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-calvados-aide-bafa-générale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_calvados",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF du Calvados pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Etre allocataire ou rattaché au dossier allocataire de vos parents.",
            "Etre stagiaire ou bénéficiaire de l'aide au logement au moment du stage."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "14"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 850
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-du-calvados/offre-de-service/enfance-et-jeunesse/bafa-bafd",
          "form": "https://www.caf.fr/sites/default/files/caf/141/Caf.fr/Offre%20de%20service/Petite%20Enfance/2021/16_02/ASD019%20-%20Dem%20BAFA%20Grale%202021.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-du-calvados/offre-de-service/enfance-et-jeunesse/bafa-bafd",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 215,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-charente-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_charente",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de la Charente pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Effectuer la session de formation auprès d’un <a target=\"_blank\" title=\"Etablissements conventionnés - Nouvelle fenêtre\" rel=\"noopener\"href=\"https://www.caf.fr/sites/default/files/caf/171/Documents/Offre%20de%20service/Bafa_Bafd/Organismes_conv_fevrier2020.pdf\">établissement conventionné.",
            "Signer un contrat d’engagement pour le suivi intégral de la formation."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "departements",
              "values": [
                "16"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 1200
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-la-charente/offre-de-service/enfance-et-jeunesse/le-bafa-vous-tente",
          "instructions": "https://www.caf.fr/allocataires/caf-de-la-charente/offre-de-service/enfance-et-jeunesse/le-bafa-vous-tente",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 300,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-charente-maritime-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_charente_maritime",
          "description": "L'aide au BAFA pour une session d'approfondissement ou de qualification est une aide locale mise à disposition par la CAF de la Charente-Maritime pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Etre allocataire ou rattaché au dossier allocataire de ses parents.",
            "Effectuer la session de formation auprès d’un <a target=\"_blank\" rel=\"noopener\" title=\"Voir les établissements conventionnés - Nouvelle fenêtre\" href=\"https://www.caf.fr/sites/default/files/caf/171/Documents/Offre%20de%20service/Bafa_Bafd/Organismes_conv_fevrier2020.pdf\"> établissement conventionné</a>.",
            "Signer la charte d’engagement pour le suivi intégral de la formation (voir avec l'organisme de formation).",
            "Ne pas être pris en charge à 100 % par un employeur."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "departements",
              "values": [
                "17"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 760
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-la-charente-maritime/offre-de-service-0/enfance-et-jeunesse/l-aide-a-la-formation-bafabafd",
          "instructions": "https://www.caf.fr/allocataires/caf-de-la-charente-maritime/offre-de-service-0/enfance-et-jeunesse/l-aide-a-la-formation-bafabafd",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 150,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-charente-maritime-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_charente_maritime",
          "description": "L'aide au BAFA pour une session de formation générale est une aide locale mise à disposition par la CAF de la Charente-Maritime pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.\n",
          "conditions": [
            "Etre allocataire ou rattaché au dossier allocataire de vos parents.",
            "Effectuer la session de formation auprès d’un <a target=\"_blank\" rel=\"noopener\" title=\"Voir les établissements conventionnés - Nouvelle fenêtre\" href=\"https://www.caf.fr/sites/default/files/caf/171/Documents/Offre%20de%20service/Bafa_Bafd/Organismes_conv_fevrier2020.pdf\"> établissement conventionné.",
            "Signer la charte d’engagement pour le suivi intégral de la formation (voir avec l'organisme de formation).",
            "Ne pas être pris en charge à 100 % par un employeur."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "departements",
              "values": [
                "17"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 760
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-la-charente-maritime/offre-de-service-0/enfance-et-jeunesse/l-aide-a-la-formation-bafabafd",
          "instructions": "https://www.caf.fr/allocataires/caf-de-la-charente-maritime/offre-de-service-0/enfance-et-jeunesse/l-aide-a-la-formation-bafabafd",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "",
          "montant": 150,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-correze-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_correze",
          "description": "L'aide au BAFA pour une session de formation générale est une aide locale mise à disposition par la CAF de la Corrèze pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Etre allocataire ou rattaché·e au dossier allocataire de ses parents.",
            "Effectuer son stage auprès d’un\n<a target=\"_blank\" rel=\"noopener\" title=\"Voir les organismes conventionnés - Nouvelle fenêtre\" href=\"https://www.caf.fr/sites/default/files/caf/191/Documents/Action%20sociale/BAFA_liste%20org%20conv%20caf.fr%202021.pdf\">organisme conventionné</a>.",
            "Signer un contrat d’engagement pour le suivi intégral de la formation (voir avec l'organisme de formation).",
            "Ne pas être pris en charge à 100 % par un employeur."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "departements",
              "values": [
                "19"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 1200
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-la-correze/offre-de-service/enfance-et-jeunesse/le-bafa",
          "instructions": "https://www.caf.fr/allocataires/caf-de-la-correze/offre-de-service/enfance-et-jeunesse/le-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 300,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-corse-du-sud-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_corse_du_sud",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de Corse du Sud pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Être allocataire ou rattaché·e au dossier allocataire de vos parents, ou bien être sans emploi.",
            "Remplir les conditions d'attribution de l'aide légale de la CNAF."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "departements",
              "values": [
                "2A"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-corse-du-sud/offre-de-service/enfance-et-jeunesse/le-brevet-d-aptitude-aux-fonctions-d-animateur-bafa",
          "form": "https://www.caf.fr/sites/default/files/caf/201/Documents/_demande_bourse_bafa-majoration.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-corse-du-sud/offre-de-service/enfance-et-jeunesse/le-brevet-d-aptitude-aux-fonctions-d-animateur-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 207,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-cotes-armor-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_cotes_armor",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF des Côtes d'Armor pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Etre allocataire ou rattaché au dossier allocataire de vos parents.",
            "Percevoir une prestation légale de la CAF des Côtes d’Armor.",
            "Avoir réalisé les 3 stages de formation."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "22"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 900
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-des-cotes-d-armor/offre-de-service/enfance-et-jeunesse/bafa-et-bafd",
          "form": "https://www.caf.fr/sites/default/files/caf/221/offre_services/Enfance_jeunesse/bafa/20160418_demande_aide_bafa-bafd.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-des-cotes-d-armor/offre-de-service/enfance-et-jeunesse/bafa-et-bafd",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 450,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-creuse-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_creuse",
          "description": "L'aide au BAFA pour une session générale est une aide locale mise à disposition par la CAF de la Creuse pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Etre allocataire ou rattaché au dossier allocataire de vos parents.",
            "Effectuer la session de formation auprès d’un <a target=\"_blank\" rel=\"noopener\" title=\"Voir les établissements conventionnés - Nouvelle fenêtre\" href=\"https://www.caf.fr/sites/default/files/caf/171/Documents/Offre%20de%20service/Bafa_Bafd/Organismes_conv_fevrier2020.pdf\"> établissement conventionné</a>.",
            "Signer une charte d’engagement pour le suivi intégral de la formation.",
            "Ne pas être pris en charge à 100 % par un employeur."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "departements",
              "values": [
                "23"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 1200
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-la-creuse/offre-de-service/enfance-et-jeunesse/aides-pour-le-financement-du-bafa",
          "instructions": "https://www.caf.fr/allocataires/caf-de-la-creuse/offre-de-service/enfance-et-jeunesse/aides-pour-le-financement-du-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 300,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-deux-sevres-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_deux_sevres",
          "description": "L'aide au BAFA pour une session de formation générale est une aide locale mise à disposition par la CAF des Deux-Sèvres pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Etre allocataire ou rattaché au dossier allocataire de ses parents en Deux-Sèvres.",
            "Effectuer la session de formation auprès d’un <a target=\"_blank\" rel=\"noopener\" title=\"Voir les établissements conventionnés - Nouvelle fenêtre\" href=\"https://www.caf.fr/sites/default/files/caf/171/Documents/Offre%20de%20service/Bafa_Bafd/Organismes_conv_fevrier2020.pdf\"> établissement conventionné</a>.",
            "Signer la charte d’engagement pour le suivi intégral de la formation (voir avec l'organisme de formation).",
            "Ne pas être pris en charge à 100 % par l'employeur."
          ],
          "link": "https://www.caf.fr/allocataires/caf-des-deux-sevres/offre-de-service/enfance-et-jeunesse/bafa-l-aide-pour-la-formation-generale-coup-de-pouce-bafa",
          "instructions": "https://www.caf.fr/allocataires/caf-des-deux-sevres/offre-de-service/enfance-et-jeunesse/bafa-l-aide-pour-la-formation-generale-coup-de-pouce-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 300,
          "interestFlag": "_interetBafa",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "departements",
              "values": [
                "79"
              ]
            }
          ],
          "profils": []
        },
        {
          "slug": "caf-doubs-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_doubs",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF du Doubs pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions": [],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "25"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-du-doubs/offre-de-service/enfance-et-jeunesse/le-bafa-et-le-bafd",
          "form": "https://www.caf.fr/sites/default/files/caf/253/Pages%20locales%202021/demande_daide_bafa.pdf",
          "instructions": "https://www.caf.fr/sites/default/files/caf/253/Pages%20locales%202021/NOTICE%20BAFA%20BAFD.pdf",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 300,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-doubs-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_doubs",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF du Doubs pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "25"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-du-doubs/offre-de-service/enfance-et-jeunesse/le-bafa-et-le-bafd",
          "instructions": "https://www.caf.fr/sites/default/files/caf/253/Pages%20locales%202021/NOTICE%20BAFA%20BAFD.pdf",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 300,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-drome-aide-bafa-session-approfondissement-qualification",
          "label": "aide au BAFA pour une session d’approfondissement ou de qualification",
          "institution": "caf_drome",
          "description": "L'aide au BAFA pour une session d'approfondissement ou de qualification est une aide locale mise à disposition par la CAF de la Drôme pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.\n",
          "conditions": [
            "Avoir commencé une formation pour obtenir le brevet d’aptitude à la fonction d’animateur de centre de vacances et de loisirs (BAFA).",
            "Ne pas avoir déjà obtenu la totalité du financement par d'autres organismes."
          ],
          "link": "https://wwwd.caf.fr/wps/portal/caffr/aidesetservices/lesservicesenligne/faireunedemandedeprestation#/autres",
          "instructions": "https://www.caf.fr/allocataires/caf-de-la-drome/offre-de-service/jeune-de-11-a-25-ans/aide-pour-passer-le-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 225,
          "interestFlag": "_interetBafa",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "26"
              ]
            }
          ],
          "profils": []
        },
        {
          "slug": "caf-drome-aide-bafa-session-generale",
          "label": "aide locale au BAFA pour une session de formation générale",
          "institution": "caf_drome",
          "description": "L'aide au BAFA pour une session de formation générale est une aide locale mise à disposition par la CAF de la Drôme pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.\n",
          "conditions": [
            "Etre allocataire de la Caf de la Drôme au moment du stage (ou enfant d'un allocataire drômois).",
            "Ne pas avoir déjà obtenu la totalité du financement du Bafa par d'autres organismes."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "26"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 750
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-la-drome/offre-de-service/jeune-de-11-a-25-ans/aide-pour-passer-le-bafa",
          "form": "https://www.caf.fr/sites/default/files/caf/261/offre-de-service/enfance-jeunesse/aides-projets-jeunes-bafa/demande-aide-session-formation-generale-bafa2021.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-la-drome/offre-de-service/jeune-de-11-a-25-ans/aide-pour-passer-le-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 250,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-eure-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_eure",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de l'Eure pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Etre allocataire ou rattaché au dossier allocataire de vos parents.",
            "Dépendre du régime général de la sécurité sociale."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "27"
              ]
            },
            {
              "type": "quotient_familial",
              "floor": 600,
              "period": "month"
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-l-eure/offre-de-service/enfance-et-jeunesse/le-brevet-d-aptitude-aux-fonctions-d-animateur-bafa",
          "form": "https://www.caf.fr/sites/default/files/caf/271/Documents/DEM_BAFA_BAFD.pdf",
          "instructions": "https://www.caf.fr/sites/default/files/caf/271/Documents/Notice%20BAFA3.pdf",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 250,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-eure-et-loir-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_eure_et_loir",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de l'Eure-et-Loir pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions": [],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "28"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-l-eure-et-loir/offre-de-service/enfance-et-jeunesse/les-aides-au-brevet-d-aptitude-aux-fonctions-d-animateur-bafa",
          "form": "https://www.caf.fr/sites/default/files/caf/281/Documents/Bafa/demande_daide_bafa_r4.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-l-eure-et-loir/offre-de-service/enfance-et-jeunesse/les-aides-au-brevet-d-aptitude-aux-fonctions-d-animateur-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 241,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-guyane-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session d'approfondissement",
          "institution": "caf_guyane",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de Guyane pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Le délai entre la 1ère et la dernière session ne peut être supérieur à 18 mois.",
            "La demande doit parvenir à la Caf dans un délai de 3 mois, suite à la dernière session."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "973"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-la-guyane/offre-de-service/enfance-et-jeunesse/aide-au-brevet-d-aptitude-aux-fonctions-d-animation-bafa",
          "form": "https://www.caf.fr/sites/default/files/caf/973/Documents/formulaires/bafa.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-la-guyane/offre-de-service/enfance-et-jeunesse/aide-au-brevet-d-aptitude-aux-fonctions-d-animation-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 491,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-haute-loire-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session d’approfondissement ou de qualification",
          "institution": "caf_haute_loire",
          "description": "L'aide au BAFA pour une session d'approfondissement ou de qualification est une aide locale mise à disposition par la CAF de la Haute-Loire pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Avoir commencé une formation pour obtenir le brevet d’aptitude à la fonction d’animateur de centre de vacances et de loisirs (BAFA)."
          ],
          "link": "https://www.caf.fr/allocataires/caf-de-la-haute-loire/offre-de-service/enfance-et-jeunesse/les-aides-a-la-formation-bafa-et-bafd",
          "form": "https://www.caf.fr/sites/default/files/caf/431/FORMULAIRES_ACSO/BAFA-Cerfa11381-02.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-la-haute-loire/offre-de-service/enfance-et-jeunesse/les-aides-a-la-formation-bafa-et-bafd",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 206,
          "interestFlag": "_interetBafa",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "43"
              ]
            }
          ],
          "profils": []
        },
        {
          "slug": "caf-haute-loire-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_haute_loire",
          "description": "L'aide au BAFA pour une session de formation générale est une aide locale mise à disposition par la CAF de la Haute-Loire pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "link": "https://www.caf.fr/allocataires/caf-de-la-haute-loire/offre-de-service/enfance-et-jeunesse/les-aides-a-la-formation-bafa-et-bafd",
          "form": "https://www.caf.fr/sites/default/files/caf/431/Offre%20de%20service/FORMULAIRE_%20BAFA%201_CAF43.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-la-haute-loire/offre-de-service/enfance-et-jeunesse/les-aides-a-la-formation-bafa-et-bafd",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 100,
          "interestFlag": "_interetBafa",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "43"
              ]
            },
            {
              "type": "regime_securite_sociale",
              "includes": [
                "regime_general"
              ]
            }
          ],
          "profils": []
        },
        {
          "slug": "caf-haute-marne-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_haute_marne",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de Haute-Marne pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Etre allocataire ou rattaché·e au dossier allocataire de vos parents au moment de la demande et de la réalisation du stage.",
            "Etre inscrit au stage de formation générale."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "52"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-la-haute-marne/offre-de-service/enfance-et-jeunesse",
          "instructions": "https://www.caf.fr/allocataires/caf-de-la-haute-marne/offre-de-service/enfance-et-jeunesse",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 300,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-haute-marne-aide-bafd",
          "label": "aide au Brevet d'Aptitude aux Fonctions de Directeur (BAFD)",
          "institution": "caf_haute_marne",
          "description": "L'aide proposée par la CAF de la Haute-Marne peut vous aider à financer votre session de formation au BAFD. ",
          "conditions": [
            "Résider en Haute-Marne.",
            "Être titulaire du BAFA.",
            "Être inscrit·e au stage de formation générale pour la préparation du brevet d’aptitude aux fonctions de Directeur."
          ],
          "conditions_generales": [
            {
              "type": "departements",
              "values": [
                "52"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-la-haute-marne/offre-de-service/enfance-et-jeunesse",
          "form": "https://www.caf.fr/sites/default/files/caf/521/IMPRIME%20BAFD.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-la-haute-marne/offre-de-service/enfance-et-jeunesse",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 400,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-haute-marne-aide-equipement-informatique",
          "label": "aide à l’équipement informatique pour les jeunes",
          "institution": "caf_haute_marne",
          "description": "La CAF de la Haute-Marne propose une aide à l'équipement informatique destinée aux jeunes de 10 à 15 ans dont le matériel n'est pas mis à disposition par l'école/le collège. L'aide concerne l’acquisition d’un ordinateur (dans la limite de 600€) et d’une imprimante et ses consommables (dans la limite de 200 €), sous réserve des disponibilités budgétaires de la CAF.",
          "conditions": [
            "Transmettre une attestation de l’école garantissant que le matériel informatique n’est pas mis à disposition par l’école/le collège à la famille, et qu’il est indispensable à la réalisation des devoirs.",
            "Transmettre une attestation des parents attestant que le foyer n’est pas équipé d’un ordinateur, ou que l’équipement est insuffisant compte-tenu de la composition de la famille."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 10
            },
            {
              "type": "age",
              "operator": "<",
              "value": 15
            },
            {
              "type": "departements",
              "values": [
                "52"
              ]
            },
            {
              "type": "quotient_familial",
              "floor": 800,
              "period": "month"
            }
          ],
          "profils": [],
          "link": "https://clg-vincenot.monbureaunumerique.fr/lectureFichiergw.do?ID_FICHIER=5901#:~:text=La%20Caisse%20d'Allocations%20Familiales,vous%20soutenir%20dans%20cette%20acquisition.&text=Pour%20les%20allocataires%20avec%20un,aller%20jusqu'%C3%A0%20800%E2%82%AC.",
          "instructions": "https://clg-vincenot.monbureaunumerique.fr/lectureFichiergw.do?ID_FICHIER=5901#:~:text=La%20Caisse%20d'Allocations%20Familiales,vous%20soutenir%20dans%20cette%20acquisition.&text=Pour%20les%20allocataires%20avec%20un,aller%20jusqu'%C3%A0%20800%E2%82%AC.",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "autre",
          "legend": "maximum",
          "montant": 600
        },
        {
          "slug": "caf-haute-marne-aide-parcours-jeunes",
          "label": "aide au parcours des jeunes",
          "institution": "caf_haute_marne",
          "description": "Vous devez faire face à des dépenses supplémentaires suite à un changement dans votre parcours scolaire ou professionnel ? La Caf de la Haute-Marne peut vous accompagner pour vous soutenir dans vos projets.",
          "conditions": [
            "Être allocataire de la CAF de la Haute-Marne et ouvrir droit à l'action sociale de la CAF.",
            "Être toujours rattaché·e au dossier du/des parent(s) bénéficiaire(s) potentiel(s) de l'action sociale de la CAF."
          ],
          "conditions_generales": [
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 800
            },
            {
              "type": "departements",
              "values": [
                "52"
              ]
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 16
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/sites/default/files/caf/521/Flyer%20Parcours%20Jeunes%202021.pdf#pdfjs.action=download",
          "instructions": "https://www.caf.fr/sites/default/files/caf/521/Flyer%20Parcours%20Jeunes%202021.pdf#pdfjs.action=download",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "autre",
          "legend": "maximum",
          "montant": 500,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-haute-saone-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_haute_saone",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de la Haute-Saône pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Être allocataire ou rattaché·e au dossier allocataire de vos parents."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 33
            },
            {
              "type": "departements",
              "values": [
                "70"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-la-haute-saone/offre-de-service-0/enfance-et-jeunesse/bafa-bafd-dispositif-regional",
          "form": "https://www.caf.fr/sites/default/files/caf/701/SITE/Offre%20de%20service/Enfance%20jeunesse/Aide%20r%C3%A9gionale%20BAFA%20BAFD/BAFA-BAFD%202017%20REGIONAL.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-la-haute-saone/offre-de-service-0/enfance-et-jeunesse/bafa-bafd-dispositif-regional",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 150,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-haute-saone-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_haute_saone",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de la Haute-Saône pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Être allocataire ou rattaché·e au dossier allocataire de vos parents."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 30
            },
            {
              "type": "departements",
              "values": [
                "70"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-la-haute-saone/offre-de-service-0/enfance-et-jeunesse/bafa-bafd-dispositif-regional",
          "form": "https://www.caf.fr/sites/default/files/caf/701/SITE/Offre%20de%20service/Enfance%20jeunesse/Aide%20r%C3%A9gionale%20BAFA%20BAFD/BAFA-BAFD%202017%20REGIONAL.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-la-haute-saone/offre-de-service-0/enfance-et-jeunesse/bafa-bafd-dispositif-regional",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 150,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-haute-vienne-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_haute_vienne",
          "description": "L'aide au BAFA pour une session de formation générale est une aide locale mise à disposition par la CAF de la Haute-Vienne pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Etre allocataire ou rattaché·e au dossier allocataire de vos parents.",
            "Effectuer votre stage auprès <a target=\"_blank\" rel=\"noopener\" title=\"Voir les organismes conventionnés - Nouvelle fenêtre\" href=\"https://www.caf.fr/sites/default/files/caf/871/Documents/ODS%20-%20Enfance%20et%20jeunesse/BAFA/BAFA_liste%20org%20conv%20caf.fr%202021.pdf\">d’un organisme conventionné</a>.",
            "Signer un contrat d’engagement pour le suivi intégral de la formation (voir avec l’organisme de formation)."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "departements",
              "values": [
                "87"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 1200
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-la-haute-vienne/offre-de-service/enfance-et-jeunesse/vous-voulez-passer-le-bafa",
          "instructions": "https://www.caf.fr/allocataires/caf-de-la-haute-vienne/offre-de-service/enfance-et-jeunesse/vous-voulez-passer-le-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 300,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-hautes-alpes-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_hautes_alpes",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF des Hautes-Alpes pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Être membre d'une famille percevant des prestations familiales au titre d'enfant à charge."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "05"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-des-hautes-alpes/offre-de-service-0/enfance-et-jeunesse",
          "form": "https://www.caf.fr/sites/default/files/caf/051/Documents/BAFA%20formulaire%20stage1.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-des-hautes-alpes/offre-de-service-0/enfance-et-jeunesse",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 200,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-hautes-pyrenees-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_hautes_pyrenees",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF des Hautes-Pyrénées pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Être allocataire ou rattaché·e au dossier allocataire de vos parents."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "65"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 750
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-des-hautes-pyrenees/offre-de-service/solidarite-et-insertion/vous-souhaitez-financer-votre-formation-d-animateur",
          "form": "https://www.caf.fr/sites/default/files/caf/651/Images/Actualite/Plaquette%20social/FORMULAIRE%20DEMANDE%20AIDE%20EXCEPTIONNELLE%20A%20LA%20FORMATION%20BAFA%20proposition.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-des-hautes-pyrenees/offre-de-service/solidarite-et-insertion/vous-souhaitez-financer-votre-formation-d-animateur",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 320,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-indre-et-loire-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_indre_et_loire",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de l'Indre-et-Loire pour vous aider à financer votre session d’approfondissement ou de qualification et inclut l'aide nationale apportée par la caf.",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "37"
              ]
            },
            {
              "type": "quotient_familial",
              "floor": 830,
              "period": "month"
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-touraine/offre-de-service/enfance-et-jeunesse/vous-avez-plus-de-17-ans-et-vous-suivez-une-formation-pour-obtenir-le-bafa",
          "form": "https://www.caf.fr/sites/default/files/caf/371/2%20-%20Offre%20de%20services/Enfance%20et%20jeunesse/Imprim%C3%A9%20BAFA%20Unique%20-%20Annexe%202_%C3%A0_remplir%2005.21.pdf",
          "instructions": "",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 400,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-indre-et-loire-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_indre_et_loire",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de l'Indre-et-Loire pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "37"
              ]
            },
            {
              "type": "quotient_familial",
              "floor": 830,
              "period": "month"
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-touraine/offre-de-service/enfance-et-jeunesse/vous-avez-plus-de-17-ans-et-vous-suivez-une-formation-pour-obtenir-le-bafa",
          "form": "https://www.caf.fr/sites/default/files/caf/371/2%20-%20Offre%20de%20services/Enfance%20et%20jeunesse/Imprim%C3%A9%20BAFA%20Unique%20-%20Annexe%202_%C3%A0_remplir%2005.21.pdf",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 250,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-jura-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_jura",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF du Jura pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Etre allocataire ou rattaché·e au dossier allocataire de vos parents."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 30
            },
            {
              "type": "departements",
              "values": [
                "39"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-du-jura/offre-de-service/enfance-et-jeunesse/l-aide-a-la-formation-bafa",
          "instructions": "https://www.caf.fr/allocataires/caf-du-jura/offre-de-service/enfance-et-jeunesse/l-aide-a-la-formation-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "",
          "montant": 150,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-jura-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_jura",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF du Jura pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Etre allocataire ou rattaché·e au dossier allocataire de vos parents."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 30
            },
            {
              "type": "departements",
              "values": [
                "39"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-du-jura/offre-de-service/enfance-et-jeunesse/l-aide-a-la-formation-bafa",
          "instructions": "https://www.caf.fr/allocataires/caf-du-jura/offre-de-service/enfance-et-jeunesse/l-aide-a-la-formation-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 150,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-loir-et-cher-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_loir_et_cher",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF du Loir-et-Cher pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Etre allocataire ou rattaché·e au dossier allocataire de vos parents."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 30
            },
            {
              "type": "departements",
              "values": [
                "41"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 800
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-loir-et-cher/offre-de-service/enfance-et-jeunesse/financer-son-bafa",
          "form": "https://www.caf.fr/sites/default/files/caf/411/PDF%202021/Formulaire%20demande%20aide%20Bafa%2041%20v5.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-loir-et-cher/offre-de-service/enfance-et-jeunesse/financer-son-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 250
        },
        {
          "slug": "caf-loir-et-cher-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_loir_et_cher",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de Loir-et-Cher pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Etre allocataire ou rattaché·e au dossier allocataire de vos parents."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 30
            },
            {
              "type": "departements",
              "values": [
                "41"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 800
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-loir-et-cher/offre-de-service/enfance-et-jeunesse/financer-son-bafa",
          "form": "https://www.caf.fr/sites/default/files/caf/411/PDF%202021/Formulaire%20demande%20aide%20Bafa%2041%20v5.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-loir-et-cher/offre-de-service/enfance-et-jeunesse/financer-son-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 350,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-loire-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session d’approfondissement ou de qualification",
          "institution": "caf_loire",
          "description": "L'aide au BAFA pour une session d'approfondissement ou de qualification est une aide locale mise à disposition par la CAF de la Loire pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.\n",
          "conditions": [
            "Avoir commencé une formation pour obtenir le brevet d’aptitude à la fonction d’animateur de centre de vacances et de loisirs (BAFA)."
          ],
          "link": "https://www.caf.fr/sites/default/files/caf/428/Documents/Rias%202020/Caf%20Loire%20-%20Allocataires%20-%20Aide%20Bafa%202020.pdf",
          "teleservice": "https://wwwd.caf.fr/wps/portal/caffr/aidesetservices/lesservicesenligne/faireunedemandedeprestation#/autres?codePrestation=DBAFA#prestation-DBAFA-cnaf",
          "instructions": "https://www.caf.fr/sites/default/files/caf/428/Documents/Rias%202020/Caf%20Loire%20-%20Allocataires%20-%20Aide%20Bafa%202020.pdf",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 250,
          "interestFlag": "_interetBafa",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "42"
              ]
            }
          ],
          "profils": []
        },
        {
          "slug": "caf-loire-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_loire",
          "description": "L'aide au BAFA pour une session de formation générale est une aide locale mise à disposition par la CAF de la Loire pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "link": "https://www.caf.fr/sites/default/files/caf/428/Documents/Rias%202020/Caf%20Loire%20-%20Allocataires%20-%20Aide%20Bafa%202020.pdf",
          "form": "https://www.caf.fr/sites/default/files/caf/428/Documents/Bafa/51%2060%20A%2007_DBAFA.pdf",
          "instructions": "https://www.caf.fr/sites/default/files/caf/428/Documents/Rias%202020/Caf%20Loire%20-%20Allocataires%20-%20Aide%20Bafa%202020.pdf",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 150,
          "interestFlag": "_interetBafa",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "42"
              ]
            }
          ],
          "profils": []
        },
        {
          "slug": "caf-loire-atlantique-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_loire_atlantique",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de Loire-Atlantique pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Avoir validé la session de formation générale et son stage pratique.",
            "Etre inscrit en session d'approfondissement ou de qualification et déposer votre demande dans les trois mois suivant l'inscription."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "44"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-loire-atlantique/offre-de-service/enfance-et-jeunesse/l-aide-locale-au-financement-du-bafa",
          "teleservice": "https://wwwd.caf.fr/wps/portal/caffr/aidesetservices/lesservicesenligne/faireunedemandedeprestation#/autres?codePrestation=DBAFA#prestation-DBAFA-cnaf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-loire-atlantique/offre-de-service/enfance-et-jeunesse/l-aide-locale-au-financement-du-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 208,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-loiret-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_loiret",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF du Loiret pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Etre allocataire ou rattaché·e au dossier allocataire de vos parents.",
            "Adresser votre demande à la Caf, dans un délai maximum de trois mois après la date de début de la session d'approfondissement."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 26
            },
            {
              "type": "departements",
              "values": [
                "45"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/sites/default/files/caf/451/Livret%20action%20sociale%202020/Info%20et%20dde%20BAFA%20CAF%202020.pdf",
          "form": "https://www.caf.fr/sites/default/files/caf/451/Livret%20action%20sociale%202020/Info%20et%20dde%20BAFA%20CAF%202020.pdf",
          "instructions": "https://www.caf.fr/sites/default/files/caf/451/Livret%20action%20sociale%202020/Info%20et%20dde%20BAFA%20CAF%202020.pdf",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 250,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-loiret-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_loiret",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF du Loiret pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Etre allocataire ou rattaché·e au dossier allocataire de vos parents.",
            "Adresser votre demande à la CAF, dans un délai maximum de trois mois après la date de début de la formation générale."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 26
            },
            {
              "type": "departements",
              "values": [
                "45"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/sites/default/files/caf/451/Livret%20action%20sociale%202020/Info%20et%20dde%20BAFA%20CAF%202020.pdf",
          "form": "https://www.caf.fr/sites/default/files/caf/451/Livret%20action%20sociale%202020/Info%20et%20dde%20BAFA%20CAF%202020.pdf",
          "instructions": "https://www.caf.fr/sites/default/files/caf/451/Livret%20action%20sociale%202020/Info%20et%20dde%20BAFA%20CAF%202020.pdf",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 350,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-lot-et-garonne-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_lot_et_garonne",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF du Lot-et-Garonne pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions": [],
          "conditions_generales": [
            {
              "type": "departements",
              "values": [
                47
              ]
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 856
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-du-lot-et-garonne/offre-de-service/enfance-et-jeunesse/bafa",
          "form": "https://www.caf.fr/sites/default/files/caf/471/Documents/offre_de_service/enfance_jeunesse/Demande%20Bafa.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-du-lot-et-garonne/offre-de-service/enfance-et-jeunesse/bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 400,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-lot-et-garonne-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_lot_et_garonne",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF du Lot-et-Garonne pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "47"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 856
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-du-lot-et-garonne/offre-de-service/enfance-et-jeunesse/bafa",
          "instructions": "https://www.caf.fr/sites/default/files/caf/471/Bafa_conditions_2021.pdf",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "",
          "montant": 400,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-martinique-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_martinique",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de Martinique pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Suivre la formation générale dans <a target=\"_blank\" rel=\"noopener\" title=\"Voir les organismes de formation - Nouvelle fenêtre\" href=\"https://www.bafa-bafd.jeunes.gouv.fr/coms/listeOf.aspx\">l'un des organismes de formation habilités par\nle ministère de l’éducation nationale, de la jeunesse et de la vie associative</a>."
          ],
          "conditions_generales": [
            {
              "type": "departements",
              "values": [
                "972"
              ]
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/sites/default/files/caf/972/Documents/LE%20BAFA%20VOUS%20TENTE.pdf",
          "instructions": "https://www.caf.fr/sites/default/files/caf/972/Documents/LE%20BAFA%20VOUS%20TENTE.pdf",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 200,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-mayenne-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_mayenne",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de la Mayenne pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "53"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-la-mayenne/offre-de-service/enfance-et-jeunesse/bafabafd-votre-caf-vous-aide",
          "teleservice": "https://wwwd.caf.fr/wps/portal/caffr/aidesetservices/lesservicesenligne/faireunedemandedeprestation#/autres",
          "instructions": "https://www.caf.fr/allocataires/caf-de-la-mayenne/offre-de-service/enfance-et-jeunesse/bafabafd-votre-caf-vous-aide",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 200
        },
        {
          "slug": "caf-mayenne-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_mayenne",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de la Mayenne pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "53"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-la-mayenne/offre-de-service/enfance-et-jeunesse/bafabafd-votre-caf-vous-aide",
          "form": "http://www.caf53.fr/ods/pdf/caf53_bafa_demande_aide_formation.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-la-mayenne/offre-de-service/enfance-et-jeunesse/bafabafd-votre-caf-vous-aide",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 400,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-meurthe-et-moselle-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_meurthe_et_moselle",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de Meurthe-et-Moselle pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Etre allocataire ou rattaché·e au dossier allocataire de vos parents."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "54"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 800
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-meurthe-et-moselle/offre-de-service/enfance-et-jeunesse/la-caf-peut-participer-au-financement-de-la-formation-au-bafa-ou-au-bafd",
          "instructions": "https://www.caf.fr/allocataires/caf-de-meurthe-et-moselle/offre-de-service/enfance-et-jeunesse/la-caf-peut-participer-au-financement-de-la-formation-au-bafa-ou-au-bafd",
          "prefix": "l’",
          "type": "bool",
          "unit": "€",
          "periodicite": "ponctuelle"
        },
        {
          "slug": "caf-meurthe-et-moselle-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_meurthe_et_moselle",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de Meurthe-et-Moselle pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Etre allocataire ou rattaché·e au dossier allocataire de vos parents."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "54"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 800
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-meurthe-et-moselle/offre-de-service/enfance-et-jeunesse/la-caf-peut-participer-au-financement-de-la-formation-au-bafa-ou-au-bafd",
          "teleservice": "https://wwwd.caf.fr/wps/portal/caffr/aidesetservices/lesservicesenligne/faireunedemandedeprestation#/autres?codePrestation=DBAFA#prestation-DBAFA-cnaf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-meurthe-et-moselle/offre-de-service/enfance-et-jeunesse/la-caf-peut-participer-au-financement-de-la-formation-au-bafa-ou-au-bafd",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 230,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-meuse-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_meuse",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de la Meuse pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "55"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-la-meuse/offre-de-service/enfance-et-jeunesse/l-aide-a-la-formation-bafa-bafd",
          "form": "",
          "instructions": "https://www.caf.fr/sites/default/files/caf/551/Documents/Offre%20de%20service/enfance%20jeunesse/BAFA%20BAFD/notice_BAFA_BAFD%20202101.pdf",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 100,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-morbihan-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_morbihan",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF du Morbihan pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Être allocataire ou rattaché·e au dossier allocataire de vos parents."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "departements",
              "values": [
                "56"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-du-morbihan/offre-de-service/enfance-et-jeunesse/bourses-bafa-brevet-d-aptitude-aux-fonctions-d-animateur-et-bafd-brevet-d-aptitude-aux-fonctions-de-directeur",
          "form": "https://www.caf.fr/sites/default/files/caf/561/Documents/enfance%20jeunesse/bafa.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-du-morbihan/offre-de-service/enfance-et-jeunesse/bourses-bafa-brevet-d-aptitude-aux-fonctions-d-animateur-et-bafd-brevet-d-aptitude-aux-fonctions-de-directeur",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 194,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-nord-aide-achat-equipement",
          "label": "aide à l'achat d'équipement",
          "institution": "caf_nord",
          "description": "La Caf vous propose un prêt équipement à taux 0 pour l'achat d’un ordinateur, d'électroménager, d'équipement mobilier, de matériel de puériculture, ou d'un vélo pour vous ou pour votre enfant.",
          "conditions": [
            "Faire partie d'un foyer ayant un enfant à naître ou au moins un enfant à charge de moins de 20 ans.",
            "Être allocataire de la Caf."
          ],
          "conditions_generales": [
            {
              "type": "departements",
              "values": [
                "59"
              ]
            },
            {
              "type": "quotient_familial",
              "floor": 700,
              "period": "month"
            }
          ],
          "profils": [],
          "link": "https://caf.fr/allocataires/caf-du-nord/offre-de-service/logement-et-cadre-de-vie/une-aide-pour-l-achat-d-un-ordinateur",
          "form": "https://caf.fr/sites/default/files/caf/598/IMPRIMES/DEQUIP_2021.pdf",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "autre",
          "montant": 1000
        },
        {
          "slug": "caf-nord-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement ou de qualification",
          "institution": "caf_nord",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la Caf du Nord pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Déposer votre demande dans les trois mois suivant l’inscription au stage d’approfondissement ou de qualification."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "59"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-du-nord/offre-de-service/enfance-et-jeunesse/bafabafd-la-caf-du-nord-peut-vous-aider",
          "form": "https://www.caf.fr/sites/default/files/DEMANDE%20AIDE%20BAFA.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-du-nord/offre-de-service/enfance-et-jeunesse/bafabafd-la-caf-du-nord-peut-vous-aider",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 150,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-nord-bafd",
          "label": "aide complémentaire au Brevet d'aptitude aux fonctions de directeur (BAFD)",
          "institution": "caf_nord",
          "description": "L'aide complémentaire au BAFD est une aide locale mise à disposition par la Caf du Nord pour vous aider à financer votre formation.",
          "conditions": [
            "Avoir une expérience d'animation.",
            "Résider dans le département du Nord au moment de la demande.",
            "Être inscrit·e à la session de perfectionnement.",
            "Déposer sa demande dans les 3 mois suivant l’inscription au stage de perfectionnement.",
            "Joindre un RIB si vous n’êtes pas allocataire."
          ],
          "conditions_generales": [
            {
              "type": "departements",
              "values": [
                "59"
              ]
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 21
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-du-nord/offre-de-service/enfance-et-jeunesse/bafabafd-la-caf-du-nord-peut-vous-aider",
          "form": "https://www.caf.fr/sites/default/files/caf/598/BAFA/2021_06_07_bafd.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-du-nord/offre-de-service/enfance-et-jeunesse/bafabafd-la-caf-du-nord-peut-vous-aider",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 250,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-orne-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_orne",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de l'Orne pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Être allocataire ou rattaché·e au dossier allocataire de vos parents."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "regime_securite_sociale",
              "includes": [
                "regime_general"
              ]
            },
            {
              "type": "departements",
              "values": [
                "61"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-l-orne/offre-de-service/enfance-et-jeunesse/la-formation-bafa-bafd",
          "instructions": "https://www.caf.fr/allocataires/caf-de-l-orne/offre-de-service/enfance-et-jeunesse/la-formation-bafa-bafd",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 350,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-pas-de-calais-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_pas_de_calais",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF du Pas-de-Calais pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Effectuer votre formation en externat et en demi-pension.",
            "Être allocataire ou rattaché·e au dossier allocataire de vos parents."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "departements",
              "values": [
                "62"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-du-pas-de-calais/offre-de-service/enfance-et-jeunesse/les-aides-de-la-caf-pour-le-stage-de-base-et-d-approfondissement",
          "instructions": "https://www.caf.fr/allocataires/caf-du-pas-de-calais/offre-de-service/enfance-et-jeunesse/les-aides-de-la-caf-pour-le-stage-de-base-et-d-approfondissement",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 200,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-puy-de-dome-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_puy_de_dome",
          "description": "L'aide au BAFA pour une session de formation générale est une aide locale mise à disposition par la CAF du Puy-de-Dôme pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Etre inscrit à la session de formation générale BAFA."
          ],
          "link": "https://www.caf.fr/allocataires/caf-du-puy-de-dome/offre-de-service/enfance-et-jeunesse/les-aides-a-la-formation-bafa",
          "form": "https://www.caf.fr/sites/default/files/caf/631/Documents/Partenaires/BAFA_mai%20_2021.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-du-puy-de-dome/offre-de-service/enfance-et-jeunesse/les-aides-a-la-formation-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 100,
          "interestFlag": "_interetBafa",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "63"
              ]
            }
          ],
          "profils": []
        },
        {
          "slug": "caf-pyrenees-orientales-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_pyrenees_orientales",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF des Pyrénées-Orientales pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Effectuer votre stage dans son intégralité.",
            "Déposer votre demande dans les 6 mois suivant la fin du stage."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "regime_securite_sociale",
              "includes": [
                "regime_general"
              ]
            },
            {
              "type": "departements",
              "values": [
                "66"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-des-pyrenees-orientales/offre-de-service/enfance-et-jeunesse/l-aide-aux-stagiaires-en-formation-bafa",
          "instructions": "https://www.caf.fr/allocataires/caf-des-pyrenees-orientales/offre-de-service/enfance-et-jeunesse/l-aide-aux-stagiaires-en-formation-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 140,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-pyrenees-orientales-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_pyrenees_orientales",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF des Pyrénées-Orientales pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Effectuer votre stage dans son intégralité.",
            "Déposer votre demande  dans les 6 mois suivant la fin du stage."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "regime_securite_sociale",
              "includes": [
                "regime_general"
              ]
            },
            {
              "type": "departements",
              "values": [
                "66"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-des-pyrenees-orientales/offre-de-service/enfance-et-jeunesse/l-aide-aux-stagiaires-en-formation-bafa",
          "instructions": "https://www.caf.fr/allocataires/caf-des-pyrenees-orientales/offre-de-service/enfance-et-jeunesse/l-aide-aux-stagiaires-en-formation-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 150,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-reunion-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_la_reunion",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de la Réunion pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Etre inscrit auprès de la Direction Régionale de la Jeunesse, des Sports et de la Cohésion sociale (DRJSCS) de votre lieu de résidence."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "974"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-la-reunion/offre-de-service/enfance-et-jeunesse/le-bafa-ou-le-bafd-vous-tente-votre-caf-peut-vous-aider",
          "form": "https://www.caf.fr/sites/default/files/caf/974/2019/ACTION%20SOCIALE/demande_daide_bafa-2019.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-la-reunion/offre-de-service/enfance-et-jeunesse/le-bafa-ou-le-bafd-vous-tente-votre-caf-peut-vous-aider",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 307,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-reunion-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_la_reunion",
          "description": "L'aide au BAFA pour une session de formation générale est une aide locale mise à disposition par la CAF de la Réunion pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Etre inscrit auprès de la Direction Régionale de la Jeunesse, des Sports et de la Cohésion sociale (DRJSCS) de votre lieu de résidence."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "974"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-la-reunion/offre-de-service/enfance-et-jeunesse/le-bafa-ou-le-bafd-vous-tente-votre-caf-peut-vous-aider",
          "instructions": "https://www.caf.fr/allocataires/caf-de-la-reunion/offre-de-service/enfance-et-jeunesse/le-bafa-ou-le-bafd-vous-tente-votre-caf-peut-vous-aider",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 100,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-saone-et-loire-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_saone_et_loire",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de Saône-et-Loire pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Avoir déposé une demande dans les 3 mois qui suivent l’inscription au premier stage."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "71"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-saone-et-loire/actualites/annee/2020/bafa-savez-vous-que-la-caf-peut-financer-une-partie-de-la-formation",
          "form": "http://blog.caf71.fr/wp-content/uploads/2020/02/BOURSE-BAFA-CAF.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-saone-et-loire/actualites/annee/2020/bafa-savez-vous-que-la-caf-peut-financer-une-partie-de-la-formation",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 400,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-sarthe-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_sarthe",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de la Sarthe pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Être inscrit·e pour le troisième module de formation à une session d’approfondissement centrée sur le handicap, la petite enfance, l'accueil de loisirs et le temps périscolaire."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "72"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 700
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-la-sarthe/offre-de-service/enfance-et-jeunesse/aides-aux-projets-pour-les-jeunes-0",
          "instructions": "https://www.caf.fr/allocataires/caf-de-la-sarthe/offre-de-service/enfance-et-jeunesse/aides-aux-projets-pour-les-jeunes-0",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 300,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-sarthe-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_sarthe",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de la Sarthe pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "72"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 700
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-la-sarthe/offre-de-service/enfance-et-jeunesse/aides-aux-projets-pour-les-jeunes-0",
          "instructions": "https://www.caf.fr/allocataires/caf-de-la-sarthe/offre-de-service/enfance-et-jeunesse/aides-aux-projets-pour-les-jeunes-0",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 400,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-savoie-aide-bafa",
          "label": "aide locale au BAFA pour une session de formation générale",
          "institution": "caf_savoie",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de la Savoie pour vous aider à financer votre session de formation générale en complément de l'aide nationale apportée par la caf.\n",
          "conditions": [
            "Suivre une formation assurée par un organisme de formation à but non lucratif habilité par le ministère de la Jeunesse, des Sports et de la Vie Associative.",
            "Être allocataire de la CAF de la Savoie."
          ],
          "link": "https://www.caf.fr/allocataires/caf-de-la-savoie/offre-de-service-0/enfance-et-jeunesse/les-aides-a-la-formation-au-bafa-bafd",
          "form": "https://www.caf.fr/sites/default/files/caf/731/Documents/bafa_bafd/BAFA%20convention%202017.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-la-savoie/offre-de-service-0/enfance-et-jeunesse/les-aides-a-la-formation-au-bafa-bafd",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 350,
          "interestFlag": "_interetBafa",
          "conditions_generales": [
            {
              "type": "departements",
              "values": [
                "73"
              ]
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "regime_securite_sociale",
              "includes": [
                "regime_general"
              ]
            }
          ],
          "profils": []
        },
        {
          "slug": "caf-seine-et-marne-aide-bafa-generale",
          "label": " aide au BAFA pour une session de formation générale",
          "institution": "caf_seine_et_marne",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de Seine-et-Marne pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 15
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "departements",
              "values": [
                "77"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-seine-et-marne/offre-de-service/enfance-et-jeunesse/les-aides-a-la-formation-bafa",
          "teleservice": "https://wwwd.caf.fr/wps/portal/caffr/aidesetservices/lesservicesenligne/faireunedemandedeprestation#/autres?codePrestation=DBAFA#prestation-DBAFA-cnaf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-seine-et-marne/offre-de-service/enfance-et-jeunesse/les-aides-a-la-formation-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 150,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-seine-maritime-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_seine_maritime",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de Seine-Maritime pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Être allocataire ou rattaché·e au dossier allocataire de vos parents.",
            "Effectuer votre stage pratique auprès d'un organisme habilité par la Direction départementale de la cohésion sociale, dans un délai de 18 mois après le stage de base."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "76"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 850
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-seine-maritime/offre-de-service/enfance-et-jeunesse/l-aide-a-la-formation-au-brevet-d-aptitude-aux-fonctions-d-animateurs-caf-de-seine-maritime",
          "form": "https://www.caf.fr/sites/default/files/caf/768/Offre%20de%20service/enfance%20et%20jeunesse%20%286%20%C3%A0%2018%20nas%29/bafacnaf.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-seine-maritime/offre-de-service/enfance-et-jeunesse/l-aide-a-la-formation-au-brevet-d-aptitude-aux-fonctions-d-animateurs-caf-de-seine-maritime",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 550,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-seine-st-denis-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session d'approfondissement (spécialisation handicap ou citoyenneté)",
          "institution": "caf_seine_st_denis",
          "description": "L’aide à la formation du BAFA est une aide locale mise à disposition par la CAF de Seine-Saint-Denis pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Être inscrit·e en session d’approfondissement portant sur le handicap ou sur la citoyenneté.",
            "Fournir une attestation de l’établissement de formation."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "93"
              ]
            }
          ],
          "profils": [],
          "link": "https://caf.fr/allocataires/caf-de-la-seine-saint-denis/offre-de-service/vie-personnelle/l-aide-la-formation-bafa-session-d-approfondissement",
          "instructions": "https://caf.fr/allocataires/caf-de-la-seine-saint-denis/offre-de-service/vie-personnelle/l-aide-la-formation-bafa-session-d-approfondissement",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 100,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-somme-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_somme",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de la Somme pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Suivre votre formation dans un organisme conventionné (Céméa de Picardie, Fédération Départementale Familles Rurales de la Somme, foyers ruraux, UFCV, scouts et guides de France)."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "80"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-la-somme/offre-de-service/enfance-et-jeunesse/l-aide-au-bafa",
          "form": "https://www.caf.fr/sites/default/files/caf/801/PDF/Bafa%20Cerfa%2011381%2002.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-la-somme/offre-de-service/enfance-et-jeunesse/l-aide-au-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 200,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-somme-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_somme",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de la Somme pour vous aider à financer votre session de formation générale en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Suivre votre formation dans un organisme conventionné (Céméa de Picardie, Fédération Départementale Familles Rurales de la Somme, foyers ruraux, UFCV, scouts et guides de France)."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "80"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-la-somme/offre-de-service/enfance-et-jeunesse/l-aide-au-bafa",
          "instructions": "https://www.caf.fr/allocataires/caf-de-la-somme/offre-de-service/enfance-et-jeunesse/l-aide-au-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 200,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-tarn-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_tarn",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF du Tarn pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Vous adresser au service Jeunesse & Sports de la DDCSPP de votre département pour vous inscrire et connaître les organismes de formation : <a target=\"_blank\" title=\"Informations de contact - Nouvelle fenêtre\" rel=\"noopener\" href=\"https://lannuaire.service-public.fr/occitanie/tarn/sdjes-81004-01\">informations de contact<a/>."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "81"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-du-tarn/offre-de-service/enfance-et-jeunesse/aides-a-la-formation-et-a-l-inclusion-numerique",
          "form": "https://www.caf.fr/sites/default/files/caf/811/Imprim%C3%A9%20BAFA%20CAF81.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-du-tarn/offre-de-service/enfance-et-jeunesse/aides-a-la-formation-et-a-l-inclusion-numerique",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 550,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-tarn-et-garonne-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_tarn_et_garonne",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF du Tarn-et-Garonne pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Être considéré·e à charge de vos parents ou être placé·e par décision judiciaire sous l'autorité des services de l'Etat.",
            "Être allocataire ou rattaché·e au dossier allocataire de vos parents."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "departements",
              "values": [
                "82"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 820
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-tarn-et-garonne/offre-de-service/enfance-et-jeunesse/la-formation-au-bafa",
          "form": "https://www.caf.fr/sites/default/files/caf/821/Documents/BAFA%20imprime%20NB.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-tarn-et-garonne/offre-de-service/enfance-et-jeunesse/la-formation-au-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 350,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-tarn-et-garonne-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_tarn_et_garonne",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF du Tarn-et-Garonne pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Être considéré·e à charge de vos parents ou être placé·e par décision judiciaire sous l'autorité des services de l'Etat.",
            "Être allocataire ou rattaché·e au dossier allocataire de vos parents."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "departements",
              "values": [
                "82"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 820
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-tarn-et-garonne/offre-de-service/enfance-et-jeunesse/la-formation-au-bafa",
          "form": "https://www.caf.fr/sites/default/files/caf/821/Documents/BAFA%20imprime%20NB.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-tarn-et-garonne/offre-de-service/enfance-et-jeunesse/la-formation-au-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 300,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-territoire-de-belfort-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_territoire_de_belfort",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF du Territoire de Belfort pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Vous engager à suivre la totalité de la formation.",
            "Vous engager à exercer votre activité d'animateur pendant au moins deux années.",
            "Suivre votre formation dans un organisme habilité."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 30
            },
            {
              "type": "departements",
              "values": [
                "90"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-du-territoire-de-belfort/offre-de-service/Enfance-et-jeunesse/la-bourse-de-stage-d-animateur-ou-de-directeur-de-centres-de-vacances-et-de-loisirs",
          "form": "https://www.caf.fr/sites/default/files/caf/901/BAFA/BAFA%20Perfectionnement.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-du-territoire-de-belfort/offre-de-service/Enfance-et-jeunesse/la-bourse-de-stage-d-animateur-ou-de-directeur-de-centres-de-vacances-et-de-loisirs",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 150,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-territoire-de-belfort-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_territoire_de_belfort",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF du Territoire de Belfort pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Vous engager à suivre la totalité de la formation.",
            "Vous engager à exercer votre activité d'animateur pendant au moins deux années.",
            "Suivre votre formation dans un organisme habilité."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 30
            },
            {
              "type": "departements",
              "values": [
                "90"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-du-territoire-de-belfort/offre-de-service/Enfance-et-jeunesse/la-bourse-de-stage-d-animateur-ou-de-directeur-de-centres-de-vacances-et-de-loisirs",
          "form": "https://www.caf.fr/sites/default/files/caf/901/BAFA/BAFA%20BASE.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-du-territoire-de-belfort/offre-de-service/Enfance-et-jeunesse/la-bourse-de-stage-d-animateur-ou-de-directeur-de-centres-de-vacances-et-de-loisirs",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "",
          "montant": 150,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-val-de-marne-aide-bafa-approfondissement-qualification",
          "label": "aide au Bafa pour une session d’approfondissement ou de qualification",
          "institution": "caf_val_de_marne",
          "description": "L'aide au Bafa pour une session d'approfondissement ou de qualification est une aide locale mise à disposition par la CAF du Val-de-Marne pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Avoir commencé une formation pour obtenir le brevet d’aptitude à la fonction d’animateur de centre de vacances et de loisirs (BAFA)."
          ],
          "link": "https://www.caf.fr/allocataires/caf-du-val-de-marne/offre-de-service/enfance-et-jeunesse/l-aide-au-bafa",
          "form": "https://www.caf.fr/sites/default/files/caf/941/Documents/Offre-services/demande_daide_bafa.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-du-val-de-marne/offre-de-service/enfance-et-jeunesse/l-aide-au-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 100,
          "interestFlag": "_interetBafa",
          "conditions_generales": [
            {
              "type": "departements",
              "values": [
                "94"
              ]
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            }
          ],
          "profils": []
        },
        {
          "slug": "caf-val-de-marne-aide-bafa-formation-generale",
          "label": "aide locale au BAFA pour une session de formation générale",
          "institution": "caf_val_de_marne",
          "description": "L'aide au BAFA pour une session de formation générale est une aide locale mise à disposition par la CAF du Val-de-Marne pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Vous engagez à achever votre formation dans les délais prévus par la réglementation (30 mois)."
          ],
          "link": "https://www.caf.fr/allocataires/caf-du-val-de-marne/offre-de-service/enfance-et-jeunesse/l-aide-au-bafa",
          "form": "https://www.caf.fr/sites/default/files/caf/941/Documents/Offre-services/BAFA%201%20Lettre%20d%27engagement%20-RAS%2001.09.2019.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-du-val-de-marne/offre-de-service/enfance-et-jeunesse/l-aide-au-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "",
          "montant": 200,
          "interestFlag": "_interetBafa",
          "conditions_generales": [
            {
              "type": "departements",
              "values": [
                "94"
              ]
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "regime_securite_sociale",
              "excludes": [
                "regime_agricole"
              ]
            }
          ],
          "profils": []
        },
        {
          "slug": "caf-var-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_var",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF du Var pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Être allocataire ou rattaché·e au dossier allocataire de vos parents, ou bien être stagiaire orphelin·e ou placé·e en famille d’accueil."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 26
            },
            {
              "type": "departements",
              "values": [
                "83"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 1000
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-du-var/offre-de-service/j-ai-un-ou-plusieurs-enfants-de-6-a-18-ans/aide-aux-formations-bafa",
          "form": "https://www.caf.fr/sites/default/files/caf/831/Documents/2020%20BAFA/Demande%20aide%20Bafa%20Caf%20du%20Var.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-du-var/offre-de-service/j-ai-un-ou-plusieurs-enfants-de-6-a-18-ans/aide-aux-formations-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 400,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-vaucluse-aide-bafa-approfondissement",
          "label": "aide au BAFA pour une session de formation d'approfondissement",
          "institution": "caf_vaucluse",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF du Vaucluse pour vous aider à financer votre session d’approfondissement ou de qualification en complément de l'aide nationale apportée par la caf.\n",
          "conditions": [
            "Être allocataire ou rattaché·e au dossier allocataire de vos parents."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 26
            },
            {
              "type": "departements",
              "values": [
                "84"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 396
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/sites/default/files/caf/841/Images/pages-partenaires/newpartenaires/documents/FLYER%20-%20BAFA%2072.pdf",
          "form": "https://www.caf.fr/sites/default/files/caf/841/Documents/Offredeservice/demande_daide_bafa.pdf",
          "instructions": "https://www.caf.fr/sites/default/files/caf/841/Images/pages-partenaires/newpartenaires/documents/FLYER%20-%20BAFA%2072.pdf",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 198,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-vaucluse-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_vaucluse",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF du Vaucluse pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Être allocataire ou rattaché·e au dossier allocataire de vos parents.",
            "Être sans emploi fixe rémunéré.",
            "Suivre votre stage dans un organisme de formation domicilié en PACA."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "departements",
              "values": [
                "84"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 396
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/partenaires/caf-de-vaucluse/partenaires-locaux/jeunesse/brevet-d-aptitude-aux-fonctions-d-animateurs-bafa-les-aides-de-la-caf#:~:text=La%20CAF%20de%20Vaucluse%20apporte,dans%20leur%20d%C3%A9marche%20de%20formation.&text=%3E%20L'organisme%20de%20formation%20doit,Administration%20est%20de%20198%20%E2%82%AC.",
          "instructions": "https://www.caf.fr/partenaires/caf-de-vaucluse/partenaires-locaux/jeunesse/brevet-d-aptitude-aux-fonctions-d-animateurs-bafa-les-aides-de-la-caf#:~:text=La%20CAF%20de%20Vaucluse%20apporte,dans%20leur%20d%C3%A9marche%20de%20formation.&text=%3E%20L'organisme%20de%20formation%20doit,Administration%20est%20de%20198%20%E2%82%AC.",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 198,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-vienne-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_vienne",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de la Vienne pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Être allocataire ou rattaché·e au dossier allocataire de vos parents.",
            "Effectuer votre stage auprès d’un organisme conventionné.",
            "Ne pas être pris en charge à 100 % par un employeur pour la session d’approfondissement ou de qualification."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "departements",
              "values": [
                "86"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 1200
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-la-vienne/offre-de-service/enfance-et-jeunesse/vous-recherchez-des-informations-sur-le-bafa",
          "instructions": "https://www.caf.fr/allocataires/caf-de-la-vienne/offre-de-service/enfance-et-jeunesse/vous-recherchez-des-informations-sur-le-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 300,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-vosges-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_vosges",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF des Vosges pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Suivre votre stage dans un organisme agréé par les services de la DDSCPP (ex. DDJS)."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "regime_securite_sociale",
              "includes": [
                "regime_general"
              ]
            },
            {
              "type": "departements",
              "values": [
                "88"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 670
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-des-vosges/offre-de-service/enfance-et-jeunesse/les-bourses-bafa-et-bafd",
          "instructions": "https://www.caf.fr/allocataires/caf-des-vosges/offre-de-service/enfance-et-jeunesse/les-bourses-bafa-et-bafd",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 300,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf-yvelines-aide-bafa-generale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_yvelines",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF des Yvelines pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "78"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-des-yvelines/offre-de-service/enfance-et-jeunesse/l-aide-au-bafa-et-au-bafd",
          "form": "https://www.caf.fr/sites/default/files/caf/781/Documents/Action-sociale/Enfance-jeunesse/BAFA/7.5%2806-2021%29-bafa-bafd-3.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-des-yvelines/offre-de-service/enfance-et-jeunesse/l-aide-au-bafa-et-au-bafd",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 300,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf_alpes_maritimes-une-aide-au-départ-en-vacances-pour-les-jeunes-le-dispositif-sac-ados",
          "label": "aide au départ en vacances pour les jeunes : le dispositif Sac Ados",
          "institution": "caf_alpes_maritimes",
          "description": "Créé par l’association « Vacances Ouvertes », c'est une aide aux projets de départs en vacances autonomes. Il s'agit de 150 € en chèques vacances pour les départs en France et 250 € en carte prépayée pour les départs vers l'Europe (uniquement pour les majeurs).",
          "conditions": [
            "Contacter l'une des structures présentes dans cette <a target=\"_blank\" rel=\"noopener\" title=\"Voir les structures - Nouvelle fenêtre\" href=\"https://www.caf.fr/sites/default/files/caf/061/Documents/OFFRESCE/ENFANCEJEUNESSE/2022/Partenaires%20Sac%20Ados%202022%20-%20maj%2005avr22.pdf\">liste</a>."
          ],
          "conditions_generales": [
            {
              "type": "departements",
              "values": [
                "06"
              ]
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 16
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-des-alpes-maritimes/offre-de-service/enfance-et-jeunesse/le-dispositif-sac-ados",
          "form": "",
          "instructions": "https://fb.watch/bVzOfhtnp2/",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 250
        },
        {
          "slug": "caf_moselle-aide-au-bafa-pour-une-session-de-formation-générale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_moselle",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF de la Moselle pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Être allocataire ou rattaché·e au dossier allocataire de vos parents."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "departements",
              "values": [
                "57"
              ]
            },
            {
              "type": "regime_securite_sociale",
              "excludes": [
                "regime_agricole"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-de-la-moselle/offre-de-service/enfance-et-jeunesse/l-aide-a-la-formation-bafa",
          "form": "https://www.caf.fr/sites/default/files/caf/571/pdfDivers/Bafa/Bafa_aideFormGle_dec2020.pdf",
          "instructions": "https://www.caf.fr/allocataires/caf-de-la-moselle/offre-de-service/enfance-et-jeunesse/l-aide-a-la-formation-bafa",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 200,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf_pas_de_calais-aide-au-bafa-pour-une-session-de-formation-générale",
          "label": "aide au BAFA pour une session de formation générale",
          "institution": "caf_pas_de_calais",
          "description": "L'aide à la formation du BAFA est une aide locale mise à disposition par la CAF du Pas-de-Calais pour vous aider à financer votre session en complément de l'aide nationale apportée par la caf.",
          "conditions": [
            "Effectuer votre formation en externat et en demi-pension.",
            "Être allocataire ou rattaché·e au dossier allocataire de vos parents."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "departements",
              "values": [
                "62"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "month",
              "floor": 1000
            }
          ],
          "profils": [],
          "link": "https://www.caf.fr/allocataires/caf-du-pas-de-calais/offre-de-service/enfance-et-jeunesse/les-aides-de-la-caf-pour-le-stage-de-base-et-d-approfondissement",
          "instructions": "https://www.caf.fr/allocataires/caf-du-pas-de-calais/offre-de-service/enfance-et-jeunesse/les-aides-de-la-caf-pour-le-stage-de-base-et-d-approfondissement",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 200,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "caf_pas_de_calais-aide-à-loutillage",
          "label": "aide à l'outillage",
          "institution": "caf_pas_de_calais",
          "description": "Les allocataires de la Caf du Pas-de-Calais peuvent bénéficier d’une aide pour les enfants poursuivant des études supérieures nécessitant un outillage spécifique demandé par l’établissement scolaire et lié à l’exercice de la profession choisie (apprentissage, alternance, BACPRO, BTS...).",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 14
            },
            {
              "type": "age",
              "operator": "<",
              "value": 20
            },
            {
              "type": "departements",
              "values": [
                "62"
              ]
            },
            {
              "type": "quotient_familial",
              "floor": 617,
              "period": "year"
            }
          ],
          "profils": [
            {
              "type": "apprenti",
              "conditions": [
                {
                  "type": "age",
                  "operator": ">=",
                  "value": 14
                },
                {
                  "type": "age",
                  "operator": "<",
                  "value": 20
                }
              ]
            },
            {
              "type": "professionnalisation",
              "conditions": [
                {
                  "type": "age",
                  "operator": ">=",
                  "value": 14
                },
                {
                  "type": "age",
                  "operator": "<",
                  "value": 20
                }
              ]
            }
          ],
          "link": "https://www.caf.fr/allocataires/caf-du-pas-de-calais/offre-de-service/solidarite-et-insertion/prime-a-l-outillage",
          "instructions": "https://www.caf.fr/allocataires/caf-du-pas-de-calais/offre-de-service/solidarite-et-insertion/prime-a-l-outillage",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "",
          "montant": 400
        },
        {
          "slug": "caf_saone_et_loire-aide-à-lautonomie-des-jeunes",
          "label": "Aide à l'autonomie des jeunes",
          "institution": "caf_saone_et_loire",
          "description": "La Caf attribue une aide pour favoriser l'autonomie des jeunes qui sont dans un parcours de formation ou d'insertion professionnelle en prenant en charge une partie des frais liés au logement ou à la mobilité.",
          "conditions_generales": [
            {
              "type": "age",
              "operator": "<=",
              "value": 30
            },
            {
              "type": "departements",
              "values": [
                "71"
              ]
            },
            {
              "type": "quotient_familial",
              "floor": 1200,
              "period": "month"
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 16
            }
          ],
          "profils": [
            {
              "type": "lyceen",
              "conditions": []
            },
            {
              "type": "enseignement_superieur",
              "conditions": []
            },
            {
              "type": "etudiant",
              "conditions": []
            },
            {
              "type": "stagiaire",
              "conditions": []
            },
            {
              "type": "apprenti",
              "conditions": []
            },
            {
              "type": "professionnalisation",
              "conditions": []
            },
            {
              "type": "salarie",
              "conditions": []
            },
            {
              "type": "service_civique",
              "conditions": []
            }
          ],
          "link": "https://caf.fr/allocataires/caf-de-saone-et-loire/offre-de-service/jeunesse/l-aide-a-l-autonomie-des-jeunes",
          "form": "https://caf.fr/sites/default/files/caf/711/Offre%20de%20services/Formulaires/Demande%20aide%20%C3%A0%20l%27autonomie%20des%20jeunes.pdf",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 1000
        },
        {
          "slug": "centre-val-de-loire-aide-au-1er-equipement-professionnel",
          "label": "aide au premier équipement professionnel",
          "institution": "region_centre_val_de_loire",
          "description": "L'aide au premier équipement professionnel est une aide financière qui vous est versée si vous êtes lycéen·ne. Elle vous aidera à acheter l’équipement professionnel indispensable à votre activité (outillage, vêtements ou équipements spéciaux).",
          "conditions": [
            "Être lycéen·ne·s en 1ère année de bac pro dont la formation professionnelle nécessite l’achat d’équipements, ou bien être en 2ème année après avoir suivi un autre cursus."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "24"
              ]
            }
          ],
          "profils": [
            {
              "type": "lyceen",
              "conditions": []
            }
          ],
          "link": "https://www.yeps.fr/articles/947C9784-CA1E-4CA1-870B-90406FC60EAF",
          "instructions": "https://www.yeps.fr/articles/947C9784-CA1E-4CA1-870B-90406FC60EAF",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 50
        },
        {
          "slug": "centre-val-de-loire-aide-depot-garantie",
          "label": "aide au dépôt de garantie",
          "institution": "region_centre_val_de_loire",
          "description": "L’aide régionale au dépôt de garantie permet de financer la caution demandée par votre propriétaire. Il s’agit d’une avance remboursable, correspondant au montant de la caution exigée.",
          "conditions": [
            "Vous installer dans votre premier logement.",
            "Constituer un dossier avec les pièces justificatives demandées (voir les <a target=\"_blank\" title=\"Voir les instructions - Nouvelle fenêtre\" rel=\"noopener\" href=\"https://www.yeps.fr/articles/9373061B-A260-4626-ABA2-BEEC54AD5782\">instructions</a>).",
            "Adresser le dossier 3 mois maximum après la signature du bail."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": "<=",
              "value": 26
            },
            {
              "type": "regions",
              "values": [
                "24"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.yeps.fr/articles/9373061B-A260-4626-ABA2-BEEC54AD5782",
          "instructions": "https://www.yeps.fr/articles/9373061B-A260-4626-ABA2-BEEC54AD5782",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "autre",
          "legend": "maximum",
          "montant": 300
        },
        {
          "slug": "centre-val-de-loire-cheque-emploi",
          "label": "chèque emploi",
          "institution": "region_centre_val_de_loire",
          "description": "Le chéquier régional pour l’emploi permet aux demandeurs d’emploi de voyager gratuitement en région Centre-Val de Loire pour leurs recherches. Il est valable 12 mois et comporte 20 chèques pour voyager dans tous les trains et cars REMI circulant en région Centre-Val de Loire.",
          "conditions": [],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "24"
              ]
            }
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "link": "https://www.remi-centrevaldeloire.fr/tarifsetachats/choisirmontitre/cheque-emploi/",
          "instructions": "https://www.remi-centrevaldeloire.fr/tarifsetachats/choisirmontitre/cheque-emploi/",
          "prefix": "le",
          "type": "bool",
          "unit": "€",
          "periodicite": "annuelle"
        },
        {
          "slug": "centre-val-de-loire-pass-yeps",
          "label": "Pass Yeps",
          "institution": "region_centre_val_de_loire",
          "description": "La Région vous accompagne pour une rentrée réussie : transports, culture, mobilité internationale, équipement professionnel, etc. Découvrez tous les avantages dont vous pouvez profiter et les nouveautés mises en place.\n",
          "link": "https://www.centre-valdeloire.fr/lactualite-de-la-region-centre-valdeloire/les-bons-plans-de-la-rentree-en-centre-val-de-loire",
          "instructions": "https://www.centre-valdeloire.fr/lactualite-de-la-region-centre-valdeloire/les-bons-plans-de-la-rentree-en-centre-val-de-loire",
          "teleservice": "",
          "prefix": "le",
          "type": "bool",
          "periodicite": "annuelle",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "24"
              ]
            }
          ],
          "profils": [
            {
              "type": "lyceen",
              "conditions": [
                {
                  "type": "age",
                  "operator": ">=",
                  "value": 15
                },
                {
                  "type": "age",
                  "operator": "<=",
                  "value": 25
                }
              ]
            }
          ]
        },
        {
          "slug": "charente-aide-reparation-vehicule",
          "label": "aide à la réparation de véhicule",
          "institution": "departement_de_la_charente",
          "description": "Cette aide vise à soutenir l'insertion des allocataires du RSA. Elle peut être mobilisée pour la prise en charge d'une partie des frais de réparation d'un véhicule.",
          "conditions": [
            "Être bénéficiaire du RSA.",
            "Avoir un contrat d’engagements réciproques (CER) ou un projet personnalisé d’accès à l’emploi (PPAE)."
          ],
          "conditions_generales": [
            {
              "type": "departements",
              "values": [
                "16"
              ]
            }
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "link": "https://mes-aides.pole-emploi.fr/departement-charente/aide-a-la-reparation-de-vehicule",
          "teleservice": "https://mes-aides.pole-emploi.fr/departement-charente/aide-a-la-reparation-de-vehicule",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 600
        },
        {
          "slug": "cohesion-territoires-conseillers-numeriques-france-services",
          "label": "Conseillers numériques France Services",
          "institution": "cohesion_territoires",
          "description": "Grâce à ce dispositif, faites-vous accompagner par des conseiller·e·s qui vous accompagnent pour devenir autonome dans les démarches numériques. Des ateliers individuels ou collectifs sont proposés <a target=\"_blank\" title=\"Ateliers numériques - Nouvelle fenêtre\" rel=\"noopener\" href=\"https://cartographie.conseiller-numerique.gouv.fr/\">près de chez vous</a>.",
          "prefix": "les",
          "conditions_generales": [],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            },
            {
              "type": "lyceen",
              "conditions": []
            },
            {
              "type": "beneficiaire_rsa",
              "conditions": []
            },
            {
              "type": "professionnalisation",
              "conditions": []
            }
          ],
          "conditions": [],
          "type": "bool",
          "unit": null,
          "periodicite": "ponctuelle",
          "link": "https://www.conseiller-numerique.gouv.fr/",
          "teleservice": "",
          "form": "",
          "instructions": "https://www.conseiller-numerique.gouv.fr/"
        },
        {
          "slug": "collectivite-europeenne-alsace-fonds-aide-jeunes",
          "label": "fonds d'Aide aux Jeunes",
          "institution": "collectivité-européenne-dalsace",
          "description": "Ce dispositif est destiné à prévenir les risques d’exclusions économiques et sociales et à favoriser vos démarches d’insertion (aide alimentaire et pour l’hygiène, secours exceptionnels, hébergement, aides à la formation, l'emploi et la mobilité).",
          "prefix": "les",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 18
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "departements",
              "values": [
                "67",
                "68"
              ]
            }
          ],
          "profils": [],
          "conditions": [
            "Être en difficulté financière.",
            "Effectuer votre demande auprès de votre Mission Locale."
          ],
          "interestFlag": null,
          "type": "bool",
          "unit": "€",
          "periodicite": "ponctuelle",
          "link": "https://www.haut-rhin.fr/content/des-aides-pour-les-jeunes",
          "instructions": "https://www.haut-rhin.fr/content/des-aides-pour-les-jeunes"
        },
        {
          "slug": "departement-corse-bourse-animazioni",
          "label": "bourse Animazioni",
          "institution": "region_corse",
          "description": "La bourse Animazioni prend en charge une partie du coût de la formation pour les Brevets d’Aptitude aux Fonctions d’Animateur et de Directeur (BAFA et BAFD) et Brevet National de Sécurité et de Sauveteur Aquatique (BNSSA).",
          "conditions": [
            "Résider en Corse depuis 2 ans.",
            "Déposer une demande écrite motivée.",
            "Réaliser sa formation et ses stages d’approfondissement en Corse.",
            "Suivre sa formation dans un organisme de formation habilité par décision du Ministre chargé de la jeunesse.",
            "Ne pas avoir débuté sa formation au moment du dépôt de la candidature."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 16
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 30
            },
            {
              "type": "regions",
              "values": [
                "94"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.isula.corsica/jeunesse/Bourse-Animazioni_a269.html",
          "instructions": "https://www.isula.corsica/jeunesse/Bourse-Animazioni_a269.html",
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "periodicite": "autre",
          "legend": "maximum",
          "montant": 600
        },
        {
          "slug": "departement-val-d-oise-bourse-aux-apprentis",
          "label": "bourse aux apprenti·e·s",
          "institution": "departement_val_d_oise",
          "description": "Le Conseil départemental du Val d’Oise attribue une bourse départementale aux apprenti·e·s.",
          "conditions": [
            "Etre domicilié·e fiscalement dans le Val d’Oise."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": "<",
              "value": 25
            },
            {
              "type": "departements",
              "values": [
                "95"
              ]
            }
          ],
          "profils": [
            {
              "type": "apprenti",
              "conditions": []
            }
          ],
          "link": "https://mes-aides.pole-emploi.fr/departement-val-d-oise/aide-departementale-bourse-aux-apprentis",
          "instructions": "https://mes-aides.pole-emploi.fr/departement-val-d-oise/aide-departementale-bourse-aux-apprentis",
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "periodicite": "annuelle",
          "legend": "maximum",
          "montant": 230
        },
        {
          "slug": "grand-est-aide-mobilite-internationale-etudiants",
          "label": "aide à la mobilité internationale des étudiants",
          "institution": "grand_est",
          "description": "L'aide à la mobilité internationale des étudiants a pour but de soutenir la mobilité internationale des étudiants de la région Grand Est.",
          "conditions": [
            "Effectuer sa période d’études ou de stage doit au sein d’un organisme à l’étranger (université, laboratoire ou entreprise).",
            "Choisir n'importe quelle destination à l’exception de la France (DOM-TOM inclus), et des principautés d’Andorre et de Monaco."
          ],
          "link": "https://www.grandest.fr/vos-aides-regionales/aide-a-mobilite-internationale-etudiants/",
          "instructions": "https://www.grandest.fr/vos-aides-regionales/aide-a-mobilite-internationale-etudiants/",
          "prefix": "l’",
          "type": "bool",
          "interestFlag": "_interetEtudesEtranger",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "44"
              ]
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": [
                {
                  "type": "age",
                  "operator": "<=",
                  "value": 29
                }
              ]
            }
          ],
          "periodicite": "ponctuelle"
        },
        {
          "slug": "grand-est-bourse-du-secteur-sanitaire-et-social",
          "label": "bourse du secteur sanitaire et social",
          "institution": "grand_est",
          "description": "La région Grand Est peut octroyer des bourses d’études à caractère social aux étudiant·e·s inscrit·e·s dans les formations du secteur sanitaire et social agréées et financées par la Région Grand Est.",
          "conditions": [
            "Vérifier que vous correspondez aux <a target=\"_blank\" rel=\"noopener\" title =\"Voir les critères de ressources - Nouvelle fenêtre\" href=\"https://boursesanitaireetsociale.grandest.fr/BSS-WEB/jsp/nouveauContexte.action?codeAction=M42-PORTAILSIMULATION\"> critères de ressources définis par la région</a>."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "44"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "etudiant"
            }
          ],
          "link": "https://www.grandest.fr/vos-aides-regionales/bourse-secteur-sanitaire-social/",
          "teleservice": "https://boursesanitaireetsociale.grandest.fr/BSS-WEB/jsp/nouveauContexte.action?codeAction=M42-PORTAILSIMULATION",
          "instructions": "https://www.grandest.fr/vos-aides-regionales/bourse-secteur-sanitaire-social/",
          "prefix": "la",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre",
          "interestFlag": "_interetAidesSanitaireSocial"
        },
        {
          "slug": "grand-est-experiences-de-jeunesse",
          "label": "expériences de Jeunesse",
          "institution": "grand_est",
          "description": "La Région accompagne les jeunes du Grand Est de 15 à 29 ans engagé·e·s sur un projet d’engagement citoyen ou porteurs d’un projet professionnalisant.",
          "conditions": [
            "Être porteur/porteuse de projets favorisant l’engagement citoyen, seul·e ou en groupe, ou bien avoir un projet faisant apparaître un projet professionnel clair, nécessitant un soutien.",
            "Remplir le dossier de candidature <a target=\"_blank\" rel=\"noopener\" title =\"Candidater - Nouvelle fenêtre\" href=\"https://messervices.grandest.fr/account-management/crge-demandeurs/ux/#/login?redirectTo=https:%2F%2Fmesservices.grandest.fr%2Faides%2F%23%2Fcrge%2Fconnecte%2FF_TEL0004%2Fdepot%2Fsimple&jwtKey=jwt-crge-portail-depot-demande-aides&footer=https:%2F%2Fwww.grandest.fr%2Fmentions-legales%2F,Mentions%20l%C3%A9gales,_blank\">à ce lien</a> (attention, cette aide nécessite un passage en commission)."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 15
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 29
            },
            {
              "type": "regions",
              "values": [
                "44"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.grandest.fr/vos-aides-regionales/experience-de-jeunesse/",
          "instructions": "https://www.grandest.fr/vos-aides-regionales/experience-de-jeunesse/",
          "prefix": "l’aide",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 2000
        },
        {
          "slug": "grand-est-jeunest",
          "label": "jeun'Est",
          "institution": "grand_est",
          "description": "Jeun'Est est un portail, une application et une carte virtuelle (ou physique si vous êtes au lycée), qui vous donne droit à des avantages culture, sport ou tourisme et à des bons plans.",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 15
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 29
            },
            {
              "type": "regions",
              "values": [
                "44"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.grandest.fr/vos-aides-regionales/jeunest-pour-les-15-29-ans/",
          "teleservice": "https://beneficiaire.jeunest.fr/Connexion.aspx?ReturnUrl=%2f",
          "instructions": "https://www.grandest.fr/vos-aides-regionales/jeunest-pour-les-15-29-ans/",
          "prefix": "l’aide",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "grand-est-mobilite-internationale-sanitaire-et-sociale",
          "label": "aide à la mobilité internationale des étudiant·e·s en formation sanitaire et sociale",
          "institution": "grand_est",
          "description": "Cette aide vise à favoriser la mobilité internationale des élèves et étudiant·e·s en formation dans les instituts de formations sanitaire et sociale de la région Grand Est. Ce dispositif peut s’appliquer en complément d’une aide à la mobilité ERASMUS +.",
          "conditions": [
            "Être inscrit·e dans un institut de formations sanitaires ou sociales agréé par la Région Grand Est (hors IBODE, IADE et Cadre de santé).",
            "Ne pas être salarié·e (promotion professionnelle CIF, apprentis).",
            "Souhaiter effectuer une période d’études ou de stage d’une durée minimale de 5 semaines dans un même organisme à l’étranger."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "44"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "etudiant",
              "conditions": []
            }
          ],
          "link": "https://www.grandest.fr/vos-aides-regionales/formation-sanitaire-et-sociale-aide-a-la-mobilite-internationale/",
          "instructions": "https://www.grandest.fr/vos-aides-regionales/formation-sanitaire-et-sociale-aide-a-la-mobilite-internationale/",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "annuelle",
          "legend": "maximum",
          "montant": 700,
          "interestFlag": "_interetEtudesEtranger"
        },
        {
          "slug": "grand-est-parcours-acquisition-des-competences-en-entreprises",
          "label": "parcours d’acquisition des compétences en entreprises (PACE)",
          "institution": "grand_est",
          "description": "Ce dispositif permet aux jeunes de 18 à 29 ans, diplômé·e·s ou non, d’acquérir des compétences et une première expériences professionnelle tutorée d’une durée de six mois, au sein d’une entreprise.",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 18
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 29
            },
            {
              "type": "regions",
              "values": [
                "44"
              ]
            }
          ],
          "profils": [],
          "link": "http://www.pace-grandest.fr/",
          "teleservice": "https://docs.google.com/forms/d/e/1FAIpQLScCXPV5JorT_JapH36mzwvFFMN_tlhXNCYibuU1Ow3qdykYkA/viewform",
          "instructions": "http://www.pace-grandest.fr/",
          "prefix": "le",
          "type": "float",
          "unit": "€",
          "periodicite": "mensuelle",
          "montant": 500
        },
        {
          "slug": "grand-est-passetudes",
          "label": "pass'Études",
          "institution": "grand_est",
          "description": "Avec la carte Pass Études, vous bénéficiez de 63% de réduction sur vos trajets effectués entre votre domicile et votre lieu d’études, sur le réseau TER (train ou autocar) et d'une réduction des mêmes réductions que la carte Primo (50 % en semaine et 70 % le week-end sur l’achat de tous vos billets TER Grand Est).",
          "conditions": [
            "Poursuivre vos études dans les départements de la Côte d'Or (21), du Doubs (25), de la Nièvre (58), de la Haute-Saône (70), de l'Yonne (89), ou du Territoire de Belfort (90).",
            "Vous procurer la carte Pass'Études pour le prix de 64 € par an."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": "<=",
              "value": 26
            },
            {
              "type": "departements",
              "values": [
                "21",
                "25",
                "58",
                "70",
                "89",
                "90"
              ]
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": []
            }
          ],
          "link": "https://www.ter.sncf.com/grand-est/offres/forfaits/champagne-ardenne/champagne-ardenne_pass-etudes#:~:text=Avec%20la%20carte%20PASS'%C3%89TUDES,vos%20billets%20TER%20Grand%20Est.",
          "form": "https://cdn.ter.sncf.com/medias/PDF/grand_est/2021%20formulaire%20demande%20de%20carte%20PASS%20ETUDES_tcm75-151436_tcm75-136212.pdf",
          "prefix": "le",
          "type": "bool",
          "unit": "€",
          "periodicite": "annuelle"
        },
        {
          "slug": "grand_est-pass-mobilite-formation",
          "label": "Pass mobilité formation",
          "imgSrc": "",
          "institution": "grand_est",
          "description": "Cette aide est destinée aux stagiaires de la formation professionnelle inscrits dans une formation financée par la Région Grand Est sur des métiers identifiés en tension. Elle permet de contribuer à l'achat d'une voiture ou d'un deux-roues Crit'air 1 ou 2, d'un abonnement TER couplé à l'achat d'un vélo, ou la préparation du permis de conduire (partie conduite).",
          "conditions": [
            "Effectuer la demande d'aide via votre organisme de formation.",
            "Être inscrit·e dans une formation financée par la Région Grand Est concernant les métiers identifiés en tension."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "44"
              ]
            }
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "link": "https://www.grandest.fr/vos-aides-regionales/pass-mobilite-formation/",
          "instructions": "https://www.grandest.fr/vos-aides-regionales/pass-mobilite-formation/",
          "prefix": "le",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 1000
        },
        {
          "slug": "guadeloupe-aide-au-permis-de-conduire-des-apprenti-e-s",
          "label": "aide au permis de conduire des apprenti·e·s",
          "institution": "guadeloupe",
          "description": "La Région Guadeloupe fournit une aide financière aux apprenti·e·s souhaitant passer le permis de conduire, en complément de <a target=\"_blank\" rel=\"noopener\" title =\"Voir l'aide de l'Etat - Nouvelle fenêtre\" href=\"https://travail-emploi.gouv.fr/formation-professionnelle/formation-en-alternance-10751/apprentissage/apprentis-aide-permis\">l'aide de l'Etat</a>.",
          "conditions": [
            "Être admis·e à vos examens de fin de cycle de formation."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "01"
              ]
            }
          ],
          "profils": [
            {
              "type": "apprenti",
              "conditions": []
            }
          ],
          "link": "https://www.regionguadeloupe.fr/les-aides-les-services/guide-des-aides/detail/actualites/aide-au-permis-de-conduire-des-apprentis/categorie/etudiant/#_",
          "instructions": "https://www.regionguadeloupe.fr/les-aides-les-services/guide-des-aides/detail/actualites/aide-au-permis-de-conduire-des-apprentis/categorie/etudiant/#_",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 300,
          "interestFlag": "_interetPermisDeConduire"
        },
        {
          "slug": "guadeloupe-aide-au-permis-de-conduire-des-jeunes-en-service-civique",
          "label": "aide au permis de conduire des jeunes en service civique",
          "institution": "guadeloupe",
          "description": "La Région Guadeloupe fournit une aide financière aux jeunes en service civique souhaitant passer le permis de conduire.",
          "conditions": [
            "Vous référer à votre tuteur ou tutrice qui se rapprochera de la Direction de la Jeunesse des Sports et de la Cohésion Sociale pour constituer le dossier complet."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "01"
              ]
            }
          ],
          "profils": [
            {
              "type": "service_civique",
              "conditions": [
                {
                  "type": "age",
                  "operator": ">=",
                  "value": 16
                },
                {
                  "type": "age",
                  "operator": "<=",
                  "value": 25
                }
              ]
            }
          ],
          "link": "https://www.regionguadeloupe.fr/les-aides-les-services/guide-des-aides/detail/actualites/aide-au-permis-de-conduire-des-jeunes-en-service-civique/categorie/etudiant/#_",
          "instructions": "https://www.regionguadeloupe.fr/les-aides-les-services/guide-des-aides/detail/actualites/aide-au-permis-de-conduire-des-jeunes-en-service-civique/categorie/etudiant/#_",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "autre",
          "legend": "maximum",
          "montant": 800,
          "interestFlag": "_interetPermisDeConduire"
        },
        {
          "slug": "guadeloupe-bourse-sanitaire",
          "label": "bourse sanitaire",
          "institution": "guadeloupe",
          "description": "La bourse sanitaire attribuée par la Région Guadeloupe contribue à soutenir financièrement les stagiaires, élèves, étudiant·e·s poursuivant des formations sanitaires dans les établissements de formation agréés par la collectivité régionale.",
          "conditions": [
            "Poursuivre une formation sociale dans un des établissements de formation listés <a target=\"_blank\" rel=\"noopener\" title =\"Voir les établissements de formation - Nouvelle fenêtre\" href=\"https://www.regionguadeloupe.fr/les-aides-les-services/guide-des-aides/detail/actualites/bourse-sanitaire/categorie/formation-enseignement-jeunesse/#_\">sur cette page</a>.",
            "Être français·e ou ressortissant·e d’un pays de l’union européenne, ou étranger·e en situation régulière en France depuis le 1er janvier de l’année de début du cycle de formation.",
            "Justifier d’un niveau de ressources permettant de situer la demande dans le barème qui permet de déterminer le taux de bourse appliqué."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "01"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": []
            },
            {
              "type": "stagiaire",
              "conditions": []
            }
          ],
          "link": "https://www.regionguadeloupe.fr/les-aides-les-services/guide-des-aides/detail/actualites/bourse-sanitaire/categorie/formation-enseignement-jeunesse/#_",
          "teleservice": "https://aides.regionguadeloupe.fr/gesaides_web/jsp/nouveauContexte.action?codeAction=M42-ACCUEIL",
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "periodicite": "annuelle",
          "legend": "maximum",
          "montant": 5736,
          "interestFlag": "_interetAidesSanitaireSocial"
        },
        {
          "slug": "guadeloupe-bourse-sociale",
          "label": "bourse sociale",
          "institution": "guadeloupe",
          "description": "La bourse sociale attribuée par la Région Guadeloupe contribue à soutenir financièrement les stagiaires, élèves, étudiant·e·s poursuivant des formations sociales dans les établissements de formation agréés par la collectivité régionale.",
          "conditions": [
            "Poursuivre une formation sociale dans un des établissements de formation listés <a target=\"_blank\" rel=\"noopener\" title =\"Voir les établissements de formation - Nouvelle fenêtre\" href=\"https://www.regionguadeloupe.fr/les-aides-les-services/guide-des-aides/detail/actualites/bourse-sociale/categorie/formation-enseignement-jeunesse/#_\">sur cette page</a>.",
            "Être français·e ou ressortissant·e d’un pays de l’union européenne, ou étranger·e en situation régulière en France depuis le 1er janvier de l’année de début du cycle de formation.",
            "Justifier d’un niveau de ressources permettant de situer la demande dans le barème qui permet de déterminer le taux de bourse appliqué."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "01"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "stagiaire",
              "conditions": []
            },
            {
              "type": "enseignement_superieur",
              "conditions": []
            }
          ],
          "link": "https://www.regionguadeloupe.fr/les-aides-les-services/guide-des-aides/detail/actualites/bourse-sociale/categorie/formation-enseignement-jeunesse/#_",
          "teleservice": "https://aides.regionguadeloupe.fr/gesaides_web/jsp/nouveauContexte.action?codeAction=M42-ACCUEIL",
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "periodicite": "annuelle",
          "legend": "maximum",
          "montant": 5736,
          "interestFlag": "_interetAidesSanitaireSocial"
        },
        {
          "slug": "guadeloupe-hebergement-cite-internationale-universitaire-de-paris",
          "label": "Hébergement à la Cité Internationale Universitaire de Paris",
          "institution": "guadeloupe",
          "description": "La Région vous aide à vous loger en région parisienne si vous poursuivez des études d’un niveau master en France hexagonale.",
          "prefix": "l’",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "01"
              ]
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 30
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": [
                {
                  "type": "annee_etude",
                  "values": [
                    "master_1"
                  ]
                },
                {
                  "type": "annee_etude",
                  "values": [
                    "master_2"
                  ]
                }
              ]
            }
          ],
          "conditions": [
            "Être inscrit·e au sein d’un établissement d’enseignement supérieur privé ou public relevant de l’Académie de Paris, Créteil ou de Versailles."
          ],
          "interestFlag": "_interetEtudesEtranger",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre",
          "link": "https://www.regionguadeloupe.fr/les-aides-les-services/guide-des-aides/detail/actualites/demande-dhebergement-a-la-cite-internationale-universitaire-de-paris-pour-les-etudiants-de-guadelou/categorie/formation-enseignement-jeunesse/#_",
          "teleservice": "https://aides.regionguadeloupe.fr/gesaides_web/jsp/nouveauContexte.action?codeAction=M42-ACCUEIL",
          "instructions": "https://www.regionguadeloupe.fr/les-aides-les-services/guide-des-aides/detail/actualites/demande-dhebergement-a-la-cite-internationale-universitaire-de-paris-pour-les-etudiants-de-guadelou/categorie/formation-enseignement-jeunesse/#_"
        },
        {
          "slug": "guadeloupe-pret-etudiant·e-taux-zero",
          "label": "prêt étudiant·e à taux zéro",
          "institution": "guadeloupe",
          "description": "La Région vous propose de prendre en charge les intérêts de votre prêt d'un montant maximum de 15 000€.",
          "prefix": "le",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 18
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 29
            },
            {
              "type": "regions",
              "values": [
                "01"
              ]
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": []
            }
          ],
          "conditions": [
            "Souscrire un prêt auprès d'une <a target=\"_blank\" title=\"Banques partenaires - Nouvelle fenêtre\" rel=\"noopener\" href=\"https://www.regionguadeloupe.fr/les-aides-les-services/guide-des-aides/detail/actualites/pret-etudiant-a-taux-zero/categorie/formation-enseignement-jeunesse/#_\">banque partenaire</a>.",
            "Si vous avez plus de 25 ans, ne pas avoir interrompu vos études."
          ],
          "type": "bool",
          "unit": "€",
          "periodicite": "ponctuelle",
          "link": "https://www.regionguadeloupe.fr/les-aides-les-services/guide-des-aides/detail/actualites/pret-etudiant-a-taux-zero/categorie/formation-enseignement-jeunesse/#_",
          "instructions": "https://www.regionguadeloupe.fr/les-aides-les-services/guide-des-aides/detail/actualites/pret-etudiant-a-taux-zero/categorie/formation-enseignement-jeunesse/#_"
        },
        {
          "slug": "guadeloupe-stage-regional-jeunes-en-entreprise",
          "label": "stage Régional - Jeunes en Entreprise",
          "institution": "guadeloupe",
          "description": "La Région vous permet d'effectuer une première expérience au sein d’une entreprise locale et de percevoir une gratification.",
          "prefix": "le",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "01"
              ]
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 18
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            }
          ],
          "profils": [
            {
              "type": "lyceen",
              "conditions": []
            },
            {
              "type": "enseignement_superieur",
              "conditions": []
            }
          ],
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 500,
          "link": "https://www.regionguadeloupe.fr/les-aides-les-services/guide-des-aides/detail/actualites/stage-regional-jeunes-en-entreprise/categorie/economie-emploi-entreprises/#_",
          "teleservice": "https://aides.regionguadeloupe.fr/gesaides_web/jsp/nouveauContexte.action?codeAction=M42-ACCUEIL",
          "instructions": "https://www.regionguadeloupe.fr/les-aides-les-services/guide-des-aides/detail/actualites/stage-regional-jeunes-en-entreprise/categorie/economie-emploi-entreprises/#_"
        },
        {
          "slug": "guyane-fonds-aide-jeunes",
          "label": "fonds d'Aide aux Jeunes (FAJ)",
          "institution": "guyane",
          "description": "Il s'agit d'un dispositif destiné à prévenir les risques d’exclusions économiques et sociales des jeunes et à favoriser les démarches d’insertion des jeunes en difficulté (aide alimentaire et pour l’hygiène, secours exceptionnels, hébergement, aides à la formation, l'emploi et la mobilité, dont une aide à l'obtention du permis B).",
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 16
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "regions",
              "values": [
                "03"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.ctguyane.fr/la-collectivite-territoriale-de-guyane-finance-annuellement-le-fonds-daide-aux-jeunes-a-hauteur-de-300-000-euros/",
          "form": "https://dl.airtable.com/.attachments/f542bd6d6c0b574c70d8e63772863a4b/2ebe2da8/formaulaire-faj-individuel-2019-0-20121.pdf",
          "instructions": "https://www.ctguyane.fr/la-collectivite-territoriale-de-guyane-finance-annuellement-le-fonds-daide-aux-jeunes-a-hauteur-de-300-000-euros/",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 1000,
          "interestFlag": null
        },
        {
          "slug": "haut-de-france-aide-transports-salaries",
          "label": "aide au Transport aux Particuliers - Salarié·e·s",
          "institution": "hauts_de_france",
          "description": "Cette aide permet la prise en charge d'une partie des frais liés au trajet domicile-travail pour les salarié·e·s qui utilisent leur véhicule pour aller travailler.",
          "conditions": [
            "Utiliser un véhicule particulier ou un deux-roues motorisé pour la majorité de vos déplacements quotidiens domicile - travail.",
            "Etre salarié·e en CDI ou en CDD d'au moins un mois.",
            "Avoir une distance routière entre le domicile et le lieu de travail supérieure ou égale à 20 kilomètres (1 trajet).",
            "Percevoir un salaire net inférieur ou égal à 2 fois le SMIC."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "32"
              ]
            }
          ],
          "profils": [
            {
              "type": "salarie",
              "conditions": []
            },
            {
              "type": "apprenti",
              "conditions": []
            }
          ],
          "link": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=458",
          "teleservice": "https://aides.hautsdefrance.fr/sub/tiers/aides/details/?sigle=ATPS22",
          "instructions": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=458",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "mensuelle",
          "legend": "maximum",
          "montant": 20
        },
        {
          "slug": "hauts-de-france-aide-restauration-etudiants-boursiers",
          "label": "aide régionale à la restauration des étudiants boursiers",
          "institution": "hauts_de_france",
          "description": "Cette aide permet d'offrir la gratuité des repas aux étudiant·e·s dans les restaurants universitaires pour équilibrer l'alimentation des étudiant·e·s, à raison d'un repas par service, au déjeuner et/ou au dîner, jusqu'à 100 repas sur l'année universitaire.",
          "conditions": [
            "Être étudiant·e inscrit·e dans un établissement d'enseignement supérieur, public ou privé, partenaire de la Région, ou inscrit·e·s dans un établissement dispensant une formation sanitaire ou sociale géré par la Région.",
            "Être boursiers du CROUS ou de la Région Hauts-de-France, à l'un des échelons de bourse 4, 5, 6, 7 ou bien signalés par le service social du CROUS."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": "<=",
              "value": 35
            },
            {
              "type": "regions",
              "values": [
                "32"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": []
            }
          ],
          "link": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=54",
          "instructions": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=54",
          "prefix": "l’",
          "type": "bool",
          "unit": null,
          "periodicite": "annuelle"
        },
        {
          "slug": "hauts-de-france-aide-à-la-restauration-des-étudiants-boursiers-en-filière-sanitaire-et-sociale",
          "label": "aide à la restauration des étudiant·e·s boursier·e·s en filière sanitaire et sociale",
          "institution": "hauts_de_france",
          "description": "Cette aide vise à offrir la gratuité des repas dans les restaurants universitaires aux étudiant·e·s boursier·e·s en filière sanitaire et sociale (3,30 € par repas dans la limite de 100 repas sur l'année scolaire).",
          "conditions": [
            "Être inscrit·e dans un établissement d'enseignement supérieur partenaire de la Région et reconnu par le ministère de l'Enseignement supérieur dans les Hauts-de-France, ou dans un établissement dispensant une formation sanitaire ou sociale géré par la Région.",
            "Être boursier·e de la Région Hauts-de-France, à l'un des échelons de bourse 4, 5, 6, 7 ou bien être signalé·e par le service social du CROUS."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "32"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "lyceen",
              "conditions": [
                {
                  "type": "boursier"
                }
              ]
            },
            {
              "type": "enseignement_superieur",
              "conditions": [
                {
                  "type": "boursier"
                }
              ]
            }
          ],
          "link": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=385",
          "instructions": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=385",
          "prefix": "l’",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre",
          "interestFlag": "_interetAidesSanitaireSocial"
        },
        {
          "slug": "hauts-de-france-billet-enfant-1euro",
          "label": "billet enfant 1 €",
          "institution": "hauts_de_france",
          "description": "Les adultes titulaires d'un titre de transport Hauts-de-France (billets ou abonnements) peuvent bénéficier du billet à 1 € pour les enfants les accompagnants âgés de 4 à 12 ans. Les enfants de moins 4 ans voyagent gratuitement.",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "32"
              ]
            }
          ],
          "profils": [],
          "link": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=801",
          "teleservice": "https://m.ter.sncf.com/hauts-de-france/tarifs-cartes/billets/enfant-1-euro",
          "form": "",
          "instructions": "",
          "prefix": "le",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "hauts-de-france-bourse-mobilite-mermoz",
          "label": "bourse de mobilité internationale MERMOZ",
          "institution": "hauts_de_france",
          "description": "La Région Hauts-de-France a mis en place ce dispositif afin d'aider les étudiant·e·s à suivre à l'étranger un parcours de formation dans un établissement d'enseignement supérieur, un stage, ou un séjour de recherche dans un laboratoire.",
          "conditions": [
            "vérifier que vous correspondez aux <a target=\"_blank\" rel=\"noopener\" title =\"Conditions spécifiques - Nouvelle fenêtre\" href=\"https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=929\">conditions spécifiques</a> à chaque situation (étudiant·e, stagiaire, en séjour de recherche).",
            "présenter votre projet de mobilité au service de Relations Internationales ou au service « Stage » de votre établissement avant de déposer votre demande."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "32"
              ]
            },
            {
              "type": "quotient_familial",
              "floor": 30000,
              "period": "year"
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": []
            },
            {
              "type": "apprenti",
              "conditions": []
            },
            {
              "type": "stagiaire",
              "conditions": []
            }
          ],
          "link": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=929",
          "teleservice": "https://aides.hautsdefrance.fr/sub/tiers/aides/details/?sigle=MERMOZ22",
          "instructions": "",
          "prefix": "les",
          "type": "float",
          "unit": null,
          "periodicite": "mensuelle",
          "legend": "maximum",
          "montant": 400
        },
        {
          "slug": "hauts-de-france-bourses-etudes-sanitaires-et-sociales",
          "label": "bourse d’Études Sanitaires et Sociales",
          "institution": "hauts_de_france",
          "description": "Cette aide de la Région des Hauts-de-France vise à soutenir financièrement les étudiant·e·s qui préparent un diplôme du secteur sanitaire et social.",
          "conditions": [
            "Être inscrit·e dans un établissement de formation des travailleurs sociaux et professions de santé des Hauts-de-France agréé et financé par la Région.",
            "Suivre l'une des <a target=\"_blank\" rel=\"noopener\" title =\"Formations éligibles - Nouvelle fenêtre\" href=\"https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=667\">formations éligibles</a> à la Bourse d'Etudes Sanitaires et Sociales (BESS)."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "32"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "etudiant",
              "conditions": []
            },
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "link": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=667",
          "teleservice": "https://aides.hautsdefrance.fr/sub/tiers/aides/details/?sigle=BESS-01%2F22",
          "instructions": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=667",
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "periodicite": "autre",
          "legend": "maximum",
          "montant": 5676,
          "interestFlag": "_interetAidesSanitaireSocial"
        },
        {
          "slug": "hauts-de-france-carte-generation-apprentis-aide-equipement",
          "label": "carte Génération - Apprentis : aide à l'équipement",
          "institution": "hauts_de_france",
          "description": "Grâce à la carte Génération - Apprentis, vous pouvez bénéficier d'une aide de rentrée forfaitaire individuelle de 200€ pour couvrir tout ou partie des frais d'acquisition ou de location des livres scolaires et équipements professionnels et de sécurité en lien avec la certification préparée.",
          "conditions": [
            "Être primo-entrant·e à l'apprentissage."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "32"
              ]
            }
          ],
          "profils": [
            {
              "type": "apprenti",
              "conditions": []
            }
          ],
          "link": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=595",
          "instructions": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=595",
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 200
        },
        {
          "slug": "hauts-de-france-carte-generation-apprentis-aide-restauration",
          "label": "carte Génération - Apprentis : aide à la restauration",
          "institution": "hauts_de_france",
          "description": "Grâce à la carte Génération - Apprentis, bénéficiez d'une réduction des frais de restauration engagés au cours de votre formation en centre de formation ou en entreprise.",
          "conditions": [
            "Être inscrit·e dans un CFA ou un organisme de formation sur le territoire des Hauts de France.",
            "OU être inscrit·e dans un CFA ou un organisme de formation hors région Hauts-de-France si l'offre de formation n'existe pas régionalement et que vous êtes employé·e par une entreprise du territoire des Hauts-de-France, sous réserve que vous ne perceviez pas d'aide d'une autre Région."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "32"
              ]
            }
          ],
          "profils": [
            {
              "type": "apprenti",
              "conditions": []
            }
          ],
          "link": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=857",
          "instructions": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=857",
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "periodicite": "annuelle",
          "montant": 100
        },
        {
          "slug": "hauts-de-france-carte-generation-apprentis-aide-transport",
          "label": "Carte Génération - Apprentis : aide au transport",
          "institution": "hauts_de_france",
          "description": "Grâce à cette aide, la région participe à financer vos trajets pour vous rendre à votre centre de formation.",
          "conditions": [
            "Être inscrit·e dans un CFA ou un organisme de formation sur le territoire des Hauts de France.",
            "OU être inscrit·e dans un CFA ou un organisme de formation hors région Hauts-de-France si l'offre de formation n'existe pas régionalement et que vous êtes employé·e par une entreprise du territoire des Hauts-de-France, sous réserve que vous ne perceviez pas d'aide d'une autre Région."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "32"
              ]
            }
          ],
          "profils": [
            {
              "type": "apprenti",
              "conditions": []
            }
          ],
          "link": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=858",
          "instructions": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=858",
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "periodicite": "annuelle",
          "legend": "maximum",
          "montant": 200
        },
        {
          "slug": "hauts-de-france-carte-generation-hebergement",
          "label": "carte Génération - Apprentis : aide à l'hébergement",
          "institution": "hauts_de_france",
          "description": "Grâce à la carte Génération - Apprentis, bénéficiez d'une aide pour faciliter votre recours à l'hébergement pour éviter des déplacements trop importants au détriment des conditions de suivi de votre formation.",
          "conditions": [],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "32"
              ]
            }
          ],
          "profils": [
            {
              "type": "apprenti",
              "conditions": []
            }
          ],
          "link": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=856",
          "teleservice": "",
          "instructions": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=856",
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "periodicite": "annuelle",
          "montant": 80
        },
        {
          "slug": "hauts-de-france-carte-génération-lyceens",
          "label": "carte Génération Lycéens",
          "institution": "hauts_de_france",
          "description": "Grâce à la carte Génération #HDF - Lycéens, bénéficiez d'une aide de rentrée forfaitaire individuelle pour couvrir tout ou partie des frais d'acquisition ou de location des livres scolaires et équipements professionnels et de sécurité.",
          "conditions": [
            "Veiller à utiliser le montant des aides accordées chez les commerçants ou les associations partenaires de la Région avant le 30 avril qui suit la rentrée scolaire."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "32"
              ]
            }
          ],
          "profils": [
            {
              "type": "lyceen",
              "conditions": []
            }
          ],
          "link": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=594",
          "teleservice": "https://www.cartegeneration.hautsdefrance.fr/Views/Accueil.aspx",
          "instructions": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=594",
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 100
        },
        {
          "slug": "hauts-de-france-en-route-pour-lemploi-parc-auto-1euro",
          "label": "en route pour l'emploi (Parc auto à 1 € par jour)",
          "institution": "hauts_de_france",
          "description": "La Région met à disposition des bénéficiaires des véhicules pour 1 euro par jour travaillé. L'assurance du véhicule est également prise en charge par la Région. Le carburant est à la charge du bénéficiaire ainsi que les dépenses de péage et de parking.",
          "conditions": [
            "vérifier que vous correspondez à l'une des catégories détaillées dans <a target=\"_blank\" title=\"Liste des catégories - Nouvelle fenêtre\" rel=\"noopener\" href=\"https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=632\">cette page</a>, à la rubrique ”Bénéficiaires”."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "32"
              ]
            }
          ],
          "profils": [],
          "link": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=632",
          "teleservice": "https://enroutepourlemploi.hautsdefrance.fr/public/compte/login",
          "prefix": "l’aide",
          "type": "bool",
          "unit": "€",
          "periodicite": "mensuelle"
        },
        {
          "slug": "hauts-de-france-fonds-regional-aide-urgence-sanitaire-social",
          "label": "fonds régional d'aide d'urgence pour les étudiant·e·s du secteur sanitaire et social",
          "institution": "hauts_de_france",
          "description": "Cette aide vise à apporter rapidement une aide financière ponctuelle et personnalisée aux étudiant·e·s du secteur sanitaire et social en difficulté financière. Elle est renouvelable une fois durant l'année scolaire.",
          "conditions": [
            "Être inscrit·e dans un établissement de formation des travailleurs sociaux et professions de santé des Hauts-de-France agréé et financé par la Région.",
            "Suivre l'une des <a target=\"_blank\" title =\"Formations éligibles - Nouvelle fenêtre\" rel=\"noopener\"href=\"https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=667\">formations éligibles</a> à la Bourse d'Etudes Sanitaires et Sociales (BESS).",
            "Rencontrer des difficultés financières.",
            "Faire valider votre dossier par par un·e travailleur/euse social·e habilité·e (Conseil Départemental, Mission Locale, Etablissement, CROUS…)."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "32"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "etudiant",
              "conditions": []
            }
          ],
          "link": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=341",
          "instructions": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=341",
          "prefix": "les",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "",
          "montant": 250,
          "interestFlag": "_interetAidesSanitaireSocial"
        },
        {
          "slug": "hauts-de-france-formations-sanitaires-sociales-niveaux-3-4",
          "label": "financement des formations sanitaires et sociales de niveau 3 et 4",
          "institution": "hauts_de_france",
          "description": "Cette aide permet d'offrir la gratuité d'accès aux formations professionnelles sanitaires et sociales (SASO) de niveau 3 et 4 à toute personne cherchant à s'insérer sur le marché du travail.",
          "conditions": [
            "Choisir l'une de ces formations : technicien·ne de l'intervention sociale et familiale, moniteur/monitrice éducateur/éducatrice, accompagnant·e éducatif et social, aide-soignant·e, auxiliaire de puériculture, ambulancier·e.",
            "Vous rapprocher de l'institut de formation souhaitée."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "32"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            },
            {
              "type": "etudiant",
              "conditions": []
            }
          ],
          "link": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=656",
          "instructions": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=656",
          "prefix": "le",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre",
          "interestFlag": "_interetAidesSanitaireSocial"
        },
        {
          "slug": "hauts-de-france-indemnites-protec-soc-formation-pro",
          "label": "rémunération, indemnités de transport et protection sociale des stagiaires de la formation professionnelle",
          "institution": "hauts_de_france",
          "description": "Cette aide permet de couvrir la rémunération, l'indemnisation des frais de transport, la protection sociale des stagiaires de la formation professionnelle.",
          "conditions": [
            "Être en recherche d'emploi sans droit ouvert au titre de l'assurance chômage.",
            "Suivre une formation dont le coût pédagogique est financé par le Conseil régional des Hauts-de-France.",
            "Suivre un stage de <a target=\"_blank\" rel=\"noopener\" title =\"Formations professionnelles - Nouvelle fenêtre\" href=\"https://formations-en-hauts-de-france.c2rp.fr/\">formation professionnelle</a> de plus de 70 heures en centre agréé par la Région Hauts-de-France.",
            "Compléter un dossier de demande de rémunération (remis par l'organisme de formation) au plus tard le 1er jour du stage."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "32"
              ]
            }
          ],
          "profils": [
            {
              "type": "stagiaire",
              "conditions": []
            },
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "link": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=147",
          "instructions": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=147",
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "periodicite": "mensuelle",
          "legend": "maximum",
          "montant": 685
        },
        {
          "slug": "hauts-de-france-ma-carte-ter",
          "label": "ma carte TER Hauts-de-France",
          "institution": "hauts_de_france",
          "description": "Grâce à cette carte, les habitants de la région Hauts-de-France bénéficient d'un tarif réduit de 50% pour leurs déplacements occasionnels en TER. Elle est accessible à tous et toutes, sans conditions.",
          "conditions": [
            "Acheter la carte pour le montant de 30 €."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "32"
              ]
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 26
            }
          ],
          "profils": [],
          "link": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=802",
          "teleservice": "https://m.ter.sncf.com/hauts-de-france/tarifs-cartes/ma-carte-ter-hauts-de-france/+26-ans",
          "prefix": "l’aide",
          "type": "bool",
          "unit": "€",
          "periodicite": "annuelle"
        },
        {
          "slug": "hauts-de-france-mon-abo-etudiant-ter",
          "label": "mon Abo Etudiant TER",
          "institution": "hauts_de_france",
          "description": "Mon Abo Etudiant TER permet d'avoir des tarifs réduits voire une totale gratuité sur les trajets en TER entre le lieu de résidence et le lieu d'études/de stage/d'apprentissage.",
          "prefix": "l’aide",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "32"
              ]
            }
          ],
          "profils": [
            {
              "type": "apprenti",
              "conditions": [
                {
                  "type": "age",
                  "operator": "<",
                  "value": 26
                }
              ]
            },
            {
              "type": "apprenti",
              "conditions": [
                {
                  "type": "boursier"
                }
              ]
            },
            {
              "type": "enseignement_superieur",
              "conditions": [
                {
                  "type": "age",
                  "operator": "<",
                  "value": 26
                }
              ]
            },
            {
              "type": "enseignement_superieur",
              "conditions": [
                {
                  "type": "boursier"
                }
              ]
            },
            {
              "type": "stagiaire",
              "conditions": [
                {
                  "type": "age",
                  "operator": "<",
                  "value": 26
                }
              ]
            },
            {
              "type": "stagiaire",
              "conditions": [
                {
                  "type": "boursier"
                }
              ]
            }
          ],
          "conditions": [
            "Effectuer un stage d'une durée supérieure ou égale à 4 semaines, lorsque vous bénéficiez de cette aide en tant que stagiaire."
          ],
          "type": "bool",
          "periodicite": "autre",
          "link": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=813",
          "teleservice": "https://aspqol.sphinxonline.net/surveyserver/s/CRCAmiens/PASS2021/saisieetudiant.htm"
        },
        {
          "slug": "hauts-de-france-transport-etudiants",
          "label": "aide au Transport aux Particuliers - Étudiant·e·s",
          "institution": "hauts_de_france",
          "description": "Cette aide est destinée aux étudiant·e·s qui utilisent leur véhicule pour rejoindre leur lieu d'étude. Elle s'effectue par la prise en charge d'une partie des frais liés au trajet domicile-étude.",
          "conditions": [
            "Utiliser un véhicule particulier ou un deux-roues motorisé.",
            "Parcourir au moins 100 km de façon hebdomadaire.",
            "Ne pas bénéficier d'un autre financement pour vos déplacements (aide non cumulable)."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "32"
              ]
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": []
            }
          ],
          "link": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=780",
          "teleservice": "https://aides.hautsdefrance.fr/sub/tiers/aides/details/?sigle=ATPEF21%2F22",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "mensuelle",
          "montant": 20
        },
        {
          "slug": "hauts_de_france-aide-à-la-garde-denfants",
          "label": "aide à la garde d'enfants",
          "institution": "hauts_de_france",
          "description": "Cette aide permet de faciliter l'accès aux services de garde d'enfants pour le(s) parent(s) aux revenus modestes.",
          "conditions": [
            "Être parent(s) d'un enfant de moins de 3 ans et domicilié(s) en région Hauts-de-France.",
            "Recourir à un mode de garde déclaré.",
            "Recourir à une garde d'au moins 20 heures par semaine."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "32"
              ]
            }
          ],
          "profils": [
            {
              "type": "salarie",
              "conditions": []
            }
          ],
          "link": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=636",
          "teleservice": "https://aides.hautsdefrance.fr/sub/tiers/aides/details/?sigle=AGE%2009%2F21",
          "instructions": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=636",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "mensuelle",
          "legend": "maximum",
          "montant": 30
        },
        {
          "slug": "ile-de-france-aide-au-merite",
          "label": "aide au mérite",
          "institution": "region_ile_de_france",
          "description": "La Région récompense les bacheliers boursiers ayant obtenu la mention « très bien » au baccalauréat en Île-de-France, et qui poursuivent leurs études dans un établissement francilien d'enseignement supérieur ou de formation sanitaire et sociale.",
          "conditions": [],
          "link": "https://www.iledefrance.fr/aide-aux-bacheliers-meritants",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "floorAt": 10,
          "montant": 1000,
          "periodicite": "ponctuelle",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "11"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": [
                {
                  "type": "boursier"
                },
                {
                  "type": "mention_baccalaureat",
                  "values": [
                    "mention_tres_bien",
                    "mention_tres_bien_felicitations_jury"
                  ]
                }
              ]
            }
          ]
        },
        {
          "slug": "martinique-bourses-sanitaires-et-sociales",
          "label": "bourses sanitaires et sociales",
          "institution": "martinique",
          "description": "La Collectivité Territoriale de Martinique verse des bourses aux étudiant·e·s inscrit·e·s en formation sanitaire et sociale, école paramédicale ou de sage-femme dans les établissements agréés dispensant des formations sociales et sanitaires en Martinique.",
          "conditions": [
            "Être de nationalité française ou ressortissant·e d’un pays de l’Union Européenne.",
            "Remplir les <a target=\"_blank\" rel=\"noopener\" title =\"Critères d'éligibilité - Nouvelle fenêtre\" href=\"https://www.collectivitedemartinique.mq/wp-content/uploads/2021/07/Pr_Broch_a5_Espace-Etudiants_Form-Sanitaires_2107.pdf\">critères d'éligibilité</a>."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "02"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": []
            }
          ],
          "link": "https://www.collectivitedemartinique.mq/wp-content/uploads/2021/07/Pr_Broch_a5_Espace-Etudiants_Form-Sanitaires_2107.pdf",
          "teleservice": "https://etudiants.collectivitedemartinique.mq/",
          "prefix": "les",
          "type": "float",
          "unit": "€",
          "periodicite": "annuelle",
          "legend": "maximum",
          "montant": 4339,
          "interestFlag": "_interetAidesSanitaireSocial"
        },
        {
          "slug": "martinique-fonds-aide-jeunes",
          "label": "fonds d'Aide aux Jeunes (FAJ)",
          "institution": "martinique",
          "description": "Il s'agit d'un dispositif destiné à prévenir les risques d’exclusions économiques et sociales des jeunes et à favoriser les démarches d’insertion des jeunes en difficulté (aide alimentaire et pour l’hygiène, secours exceptionnels, hébergement, aides à la formation, l'emploi et la mobilité, dont une aide à l'obtention du permis B).",
          "conditions": [
            "Être français·e ou étranger·e·s en situation de séjour régulier en France.",
            "Être sans revenus ou avec de faibles ressources.",
            "Constituer un dossier auprès d'une Mission Locale ou d'une circonscription d’Action Sociale."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "02"
              ]
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 18
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            }
          ],
          "profils": [],
          "link": "https://www.collectivitedemartinique.mq/le-fonds-daide-aux-jeunes/",
          "instructions": "https://www.collectivitedemartinique.mq/le-fonds-daide-aux-jeunes/",
          "prefix": "l’",
          "type": "bool",
          "unit": "€",
          "periodicite": "ponctuelle"
        },
        {
          "slug": "nouvelle-aquitaine-aide-passage-permis-conduire-b",
          "label": "aide au passage du permis de conduire B",
          "institution": "region_nouvelle_aquitaine",
          "description": "Cette aide favorise l'accès à l'emploi en proposant une aide au financement du <a target=\"_blank\" rel=\"noopener\" title =\"permis de conduire B - Nouvelle fenêtre\" href=\"https://www.service-public.fr/particuliers/vosdroits/F2828\">permis de conduire B</a> pour les jeunes et les plus précaires qui s’engagent vers une insertion professionnelle.",
          "conditions": [
            "Être diplômé de la filière professionnelle ou agricole.",
            "Ou être en insertion professionnelle suivi par une mission locale.",
            "Ou être scolarisé en Établissement Régional d’Enseignement Adapté (EREAS).",
            "Ou être en contrat d’engagement de Service Civique."
          ],
          "link": "https://les-aides.nouvelle-aquitaine.fr/amenagement-du-territoire/aide-au-passage-du-permis-de-conduire-b",
          "instructions": "https://les-aides.nouvelle-aquitaine.fr/amenagement-du-territoire/aide-au-passage-du-permis-de-conduire-b",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "legend": "maximum",
          "floorAt": 10,
          "interestFlag": "_interetPermisDeConduire",
          "montant": 1200,
          "periodicite": "ponctuelle",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "75"
              ]
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 26
            }
          ],
          "profils": [
            {
              "type": "etudiant"
            },
            {
              "type": "service_civique"
            },
            {
              "type": "chomeur"
            },
            {
              "type": "inactif"
            }
          ]
        },
        {
          "slug": "occitanie-bourse-etudes-sanitaires-et-sociales",
          "label": "bourse d’Etudes Sanitaires et Sociales",
          "institution": "region_occitanie",
          "description": "Cette bourse est destinée aux élèves et étudiant·e·s inscrit·e·s en formations paramédicales, de sages-femmes ou en travail social dans un centre de formation agréé par la Région Occitanie/Pyrénées-Méditerranée.",
          "conditions": [
            "Être inscrit·e en formation sanitaire ou sociale dans un <a target=\"_blank\" rel=\"noopener\" title =\"Centre de formation agréé - Nouvelle fenêtre\" href=\"https://www.laregion.fr/bourses-etudes-sanitaires-sociales#centres\">centre de formation agréé</a> par la Région Occitanie/Pyrénées-Méditerranée.",
            "Vérifier que vous correspondez aux conditions fixées par la Région (voir rubrique <a target=\"_blank\" rel=\"noopener\" title =\"Conditions d'éligibilité - Nouvelle fenêtre\" href=\"https://www.laregion.fr/bourses-etudes-sanitaires-sociales\">Conditions d’éligibilité</a>).",
            "Déposer la demande de bourse dans les délais demandés : jusqu'au 31 juillet pour les 2ème, 3ème et 4ème année et jusqu'au 20 octobre 2022 pour les 1ère année."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "76"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "etudiant",
              "conditions": []
            }
          ],
          "link": "https://www.laregion.fr/bourses-etudes-sanitaires-sociales",
          "instructions": "https://www.laregion.fr/bourses-etudes-sanitaires-sociales",
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "periodicite": "annuelle",
          "legend": "maximum",
          "montant": 5736,
          "interestFlag": "_interetAidesSanitaireSocial"
        },
        {
          "slug": "occitanie-premier-equipement-professionnel",
          "label": "Premier Équipement Professionnel",
          "institution": "region_occitanie",
          "description": "L’opération Gratuité Premier Équipement Professionnel est destinée à aider les lycéen·ne·s en formation professionnelle de la région Occitanie en fournissant outillages et vêtements nécessaires au suivi de la formation.",
          "conditions": [
            "Être en possession de la <a href=\"https://www.laregion.fr/-cartejeune-\" target=\"_blank\" title=\"Carte Jeune Région - Nouvelle fenêtre\" rel=\"noopener\">Carte Jeune Région</a>.",
            "Suivre <a href=\"https://www.laregion.fr/IMG/pdf/23_-_liste_des_formations_eligibles_au_premier_equipement_lyceens_2021_2022.pdf\" target=\"_blank\" title=\"Formations éligibles - Nouvelle fenêtre\" rel=\"noopener\">une formation éligible</a> au premier équipement lycéen."
          ],
          "link": "https://www.laregion.fr/Premier-equipement-professionnel",
          "instructions": "https://www.laregion.fr/Premier-equipement-professionnel",
          "prefix": "l’aide",
          "type": "bool",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "76"
              ]
            }
          ],
          "profils": [
            {
              "type": "lyceen"
            }
          ],
          "periodicite": "ponctuelle"
        },
        {
          "slug": "pays-de-la-loire-aide-acquisition-ordinateur-portable",
          "label": "Aide à l'acquisition d'un ordinateur portable",
          "institution": "region_pays_de_la_loire",
          "description": "Pour la rentrée 2021, la Région des Pays de la Loire fournit un ordinateur portable à chaque élève de seconde et de première année de CAP des lycées régionaux publics et privés. Cet outil de travail numérique individuel accompagnera les jeunes tout au long de leur scolarité, puis dans la poursuite de leurs études.",
          "link": "https://www.paysdelaloire.fr/jeunesse-et-education/un-ordinateur-portable-fourni-par-la-region-aux-eleves-de-seconde-et-1ere-annee-de-cap",
          "instructions": "https://www.paysdelaloire.fr/les-aides/mon-ordi-au-lycee",
          "prefix": "l’",
          "type": "bool",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "52"
              ]
            }
          ],
          "profils": [
            {
              "type": "lyceen",
              "conditions": [
                {
                  "type": "annee_etude",
                  "values": [
                    "cap_1",
                    "seconde"
                  ]
                }
              ]
            }
          ],
          "private": true,
          "periodicite": "ponctuelle"
        },
        {
          "slug": "pays-de-la-loire-bourse-regionale-merite",
          "label": "bourse régionale au mérite",
          "institution": "region_pays_de_la_loire",
          "description": "La région Pays de la Loire récompense les étudiants ayant reçu une mention \"très bien\" durant la dernière session du baccalauréat.",
          "conditions": [
            "Résidez dans le Pays de la Loire l'année de votre terminale."
          ],
          "link": "https://www.paysdelaloire.fr/les-aides/bourse-regionale-au-merite",
          "instructions": "https://www.paysdelaloire.fr/les-aides/bourse-regionale-au-merite",
          "prefix": "la",
          "type": "bool",
          "floorAt": 10,
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "52"
              ]
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": [
                {
                  "type": "mention_baccalaureat",
                  "values": [
                    "mention_tres_bien",
                    "mention_tres_bien_felicitations_jury"
                  ]
                }
              ]
            }
          ],
          "periodicite": "ponctuelle"
        },
        {
          "slug": "pole-emploi-aif",
          "label": "aide Individuelle à la Formation (AIF)",
          "institution": "pole_emploi",
          "description": "Pôle Emploi peut prendre en charge tout ou partie du coût de votre formation si elle est nécessaire pour votre retour à l’emploi.",
          "conditions_generales": [],
          "conditions": [
            "Demander un devis à l’organisme de formation et le transmettre à votre conseiller·e Pôle Emploi."
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "link": "https://www.pole-emploi.fr/candidat/en-formation/mes-aides-financieres/laide-individuelle-a-la-formatio.html",
          "instructions": "https://www.pole-emploi.fr/candidat/en-formation/mes-aides-financieres/laide-individuelle-a-la-formatio.html",
          "prefix": "l’",
          "type": "bool",
          "unit": "€",
          "periodicite": "ponctuelle",
          "interestFlag": null
        },
        {
          "slug": "provence-alpes-cote-azur-bourse-sanitaire-social",
          "label": "bourse régionale d’études dans les filières sanitaires et du travail social",
          "institution": "region_provence_alpes_cote_azur",
          "description": "Cette aide financière accordée sur critères sociaux s’adresse aux étudiant·e·s et élèves des filières sanitaires et du travail social dont les revenus familiaux ou personnels sont reconnus insuffisants au regard de leurs charges.",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "93"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "lyceen",
              "conditions": []
            },
            {
              "type": "enseignement_superieur",
              "conditions": []
            }
          ],
          "link": "https://www.maregionsud.fr/aides-et-appels-a-projets/detail/bourse-regionale-detudes-dans-les-filieres-sanitaires-et-du-travail-social",
          "teleservice": "https://aidesformation.maregionsud.fr/SignIn?ReturnUrl=%2Fprofile%2F",
          "instructions": "https://www.maregionsud.fr/aides-et-appels-a-projets/detail/bourse-regionale-detudes-dans-les-filieres-sanitaires-et-du-travail-social",
          "prefix": "la",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre",
          "interestFlag": "_interetAidesSanitaireSocial"
        },
        {
          "slug": "provence-alpes-cote-azur-e-pass-jeunes",
          "label": "e-pass jeunes",
          "institution": "region_provence_alpes_cote_azur",
          "description": "L'e-pass jeunes est une carte et une application pour faciliter l'accès de chaque jeune âgé de 15 à 25 ans scolarisé en région Provence-Alpes-Côte d'Azur, à la culture et au sport avec 80 € et des bons plans.",
          "link": "https://www.maregionsud.fr/aides-et-appels-a-projets/detail/e-pass-jeunes",
          "instructions": "https://www.maregionsud.fr/aides-et-appels-a-projets/detail/e-pass-jeunes",
          "prefix": "l’",
          "type": "bool",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "93"
              ]
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 15
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            }
          ],
          "profils": [
            {
              "type": "etudiant"
            },
            {
              "type": "service_civique"
            },
            {
              "type": "apprenti"
            }
          ],
          "periodicite": "ponctuelle"
        },
        {
          "slug": "provence-alpes-cote-azur-fonds-sanitaire-social",
          "label": "fonds d’Aide Régional aux étudiant·e·s du secteur sanitaire et du travail social",
          "institution": "region_provence_alpes_cote_azur",
          "description": "Il s’agit d’une aide financière destinée à améliorer les conditions de vie des élèves et étudiant·e·s inscrit·e·s dans une filière sanitaire ou du travail social en Région Provence-Alpes-Côte d'Azur.",
          "conditions": [
            "Être stagiaire de la formation professionnelle inscrit·e en formation sanitaire de niveau V (aide soignant·e, auxiliaire de puériculture, ambulancier·e).",
            "Être bénéficiaire de la bourse régionale d’études sanitaires et sociales."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "93"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": []
            },
            {
              "type": "lyceen",
              "conditions": []
            }
          ],
          "link": "https://www.maregionsud.fr/aides-et-appels-a-projets/detail/fonds-daide-regional-aux-etudiants-du-secteur-sanitaire-et-du-travail-social",
          "teleservice": "https://aidesformation.maregionsud.fr/SignIn?ReturnUrl=%2Fprofile%2F",
          "instructions": "https://www.maregionsud.fr/aides-et-appels-a-projets/detail/fonds-daide-regional-aux-etudiants-du-secteur-sanitaire-et-du-travail-social",
          "prefix": "le",
          "type": "float",
          "unit": "€",
          "periodicite": "annuelle",
          "legend": "maximum",
          "montant": 600,
          "interestFlag": "_interetAidesSanitaireSocial"
        },
        {
          "slug": "pyrenees-orientales-financement-permis",
          "label": "aide au financement du permis de conduire",
          "institution": "departement_des_pyrenees_orientales",
          "description": "Cette aide au financement du permis B et des frais d'assurance du véhicule est proposée dans le cadre de \"l'Aide Départementale de Retour à l'Emploi\" (ADRE).",
          "conditions": [
            "Être allocataire du RSA.",
            "Effectuer votre demande auprès de votre référent RSA ou du référent de votre <a target=\"_blank\" rel=\"noopener\" title =\"Votre Maison sociale de proximité - Nouvelle fenêtre\" href=\"http://www.ledepartement66.fr/dossier/les-maisons-sociales-de-proximite/\">Maison sociale de proximité</a>."
          ],
          "conditions_generales": [
            {
              "type": "departements",
              "values": [
                "66"
              ]
            }
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "link": "https://mes-aides.pole-emploi.fr/departement-pyrenees-orientales/aide-de-retour-a-l-emploi-pyrenees-orientales",
          "instructions": "https://mes-aides.pole-emploi.fr/departement-pyrenees-orientales/aide-de-retour-a-l-emploi-pyrenees-orientales",
          "prefix": "l’",
          "type": "bool",
          "unit": "€",
          "periodicite": "ponctuelle",
          "interestFlag": "_interetPermisDeConduire"
        },
        {
          "slug": "region-auvergne-rhone-alpes-abonnement-illico",
          "label": "abonnement TER Illico hebdo jeunes",
          "institution": "region_auvergne_rhone_alpes",
          "description": "L’abonnement TER Illico hebdomadaire jeunes vous donne accès à 7 jours de voyages illimités sur le parcours TER de votre choix en Auvergne-Rhône-Alpes ou à destination de Mâcon et Genève Cornavin (via Bellegarde uniquement). ",
          "conditions": [
            "Choisir un trajet fréquent entre deux gares de la région.",
            "Vous procurer l'abonnement dont le prix dépend du trajet choisi (rentabilisé à partir de 4 trajets, soit 2 allers-retours)."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": "<",
              "value": 26
            },
            {
              "type": "regions",
              "values": [
                "84"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.ter.sncf.com/auvergne-rhone-alpes/offres/tarifs/illico-hebdo-jeunes",
          "instructions": "https://www.ter.sncf.com/auvergne-rhone-alpes/offres/tarifs/illico-hebdo-jeunes",
          "prefix": "l’",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-auvergne-rhone-alpes-abonnement-ter-illico",
          "label": "abonnement TER Illico mensuel jeunes",
          "institution": "region_auvergne_rhone_alpes",
          "description": "L’abonnement TER Illico mensuel jeunes vous permet de voyager fréquemment à prix réduit sur l’ensemble de région Auvergne-Rhône-Alpes. ",
          "conditions": [
            "Choisir un trajet fréquent entre deux gares de la région.",
            "Vous procurer l'abonnement TER Illico mensuel jeunes, dont le prix dépend du trajet fréquent choisi."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": "<=",
              "value": 26
            },
            {
              "type": "regions",
              "values": [
                "84"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.ter.sncf.com/auvergne-rhone-alpes/offres/tarifs/illico-mensuel-jeune",
          "instructions": "https://www.ter.sncf.com/auvergne-rhone-alpes/offres/tarifs/illico-mensuel-jeune",
          "prefix": "l’",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-auvergne-rhone-alpes-aide-financement-permis",
          "label": "aide au financement du permis",
          "institution": "region_auvergne_rhone_alpes",
          "description": "Grâce à cette aide, bénéficiez du financement d'une partie de votre permis B.",
          "conditions": [
            "Être en cours de formation au permis de conduire au moment du dépôt de la demande et résider en Auvergne-Rhône-Alpes."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 18
            },
            {
              "type": "age",
              "operator": "<",
              "value": 19
            },
            {
              "type": "regions",
              "values": [
                "84"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.auvergnerhonealpes.fr/aide/385/289-financer-ma-formation-au-permis-de-conduire-orientation-formation.htm",
          "teleservice": "https://aides.auvergnerhonealpes.fr/aides/#/crauraprod/connecte/F_FP_REL_CONDUI/depot/simple",
          "instructions": "https://www.auvergnerhonealpes.fr/aide/385/289-financer-ma-formation-au-permis-de-conduire-orientation-formation.htm",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "autre",
          "legend": "",
          "montant": 200,
          "interestFlag": "_interetPermisDeConduire"
        },
        {
          "slug": "region-auvergne-rhone-alpes-billet-illico-autocars",
          "label": "billet Illico Autocars",
          "institution": "region_auvergne_rhone_alpes",
          "description": "Grâce au billet Illico Autocars, le prix de votre voyage en Auvergne-Rhône-Alpes est réduit de 30% par rapport au tarif normal. La réduction est valable sur les trajets en autocars TER en Auvergne-Rhône-Alpes (y compris Mâcon et Genève Cornavin via Bellagarde) / Cars Régions Express.",
          "conditions": [
            "Vous procurer le billet Illico Autocars."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "84"
              ]
            }
          ],
          "profils": [],
          "link": "https://m.ter.sncf.com/auvergne-rhone-alpes/tarifs-cartes/billets-reduction/illico-autocars",
          "teleservice": "https://m.ter.sncf.com/auvergne-rhone-alpes/products/form",
          "prefix": "le",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-auvergne-rhone-alpes-bourse-merite",
          "label": "bourse au mérite +",
          "institution": "region_auvergne_rhone_alpes",
          "description": "Votre candidature doit être proposée par l'établissement selon plusieurs critères que vous retrouvez <a target=\"_blank\" rel=\"noopener\" title =\"Critères - Nouvelle fenêtre\" href=\"https://www.auvergnerhonealpes.fr/aide/81/289-beneficier-de-la-bourse-au-merite-en-tant-que-lyceen-ou-apprenti-education-lycees.htm\">ici</a>. Rapprochez-vous de votre établissement pour évoquer cette aide et qu'il constitue un dossier.",
          "conditions": [
            "Le paiement de la bourse interviendra une fois le diplôme validé et l'attestation transmise au Conseil Régional."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "84"
              ]
            }
          ],
          "profils": [
            {
              "type": "lyceen",
              "conditions": [
                {
                  "type": "annee_etude",
                  "values": [
                    "terminale"
                  ]
                }
              ]
            },
            {
              "type": "apprenti",
              "conditions": []
            },
            {
              "type": "etudiant",
              "conditions": []
            }
          ],
          "link": "https://www.auvergnerhonealpes.fr/aide/81/289-beneficier-de-la-bourse-au-merite-en-tant-que-lyceen-ou-apprenti-education-lycees.htm",
          "instructions": "https://www.auvergnerhonealpes.fr/aide/81/289-beneficier-de-la-bourse-au-merite-en-tant-que-lyceen-ou-apprenti-education-lycees.htm",
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "periodicite": "autre",
          "montant": 500
        },
        {
          "slug": "region-auvergne-rhone-alpes-bourse-mobilite-internationale-lycee-apprentissage",
          "label": "bourse mobilité internationale Lycéens et Apprentis",
          "institution": "region_auvergne_rhone_alpes",
          "description": "Cette aide participe à votre projet de stage à l'étranger. Le financement correspond à une durée de 3 semaines, quelle que soit la durée effective du stage au sein de la même entreprise.",
          "conditions": [
            "Effectuer votre demande en ligne sur le site de la Région 3 mois au plus et un mois au moins avant la date de départ effective en stage à l'étranger.",
            "Après transmission de votre dossier en ligne à l'établissement de formation, vous devez renvoyer, en ligne, à votre établissement, votre attestation de stage dans les 18 mois maximum."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "84"
              ]
            }
          ],
          "profils": [
            {
              "type": "lyceen",
              "conditions": []
            },
            {
              "type": "apprenti",
              "conditions": []
            }
          ],
          "link": "https://www.auvergnerhonealpes.fr/aide/93/89-se-former-a-l-etranger-avec-la-bourse-region-mobilite-internationale-lyceens-et-apprentis-education-lycees.htm",
          "teleservice": "https://sicorra.auvergnerhonealpes.fr/public",
          "form": "",
          "instructions": "https://www.auvergnerhonealpes.fr/aide/93/89-se-former-a-l-etranger-avec-la-bourse-region-mobilite-internationale-lyceens-et-apprentis-education-lycees.htm",
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "periodicite": "autre",
          "legend": "maximum",
          "montant": 470,
          "interestFlag": "_interetEtudesEtranger"
        },
        {
          "slug": "region-auvergne-rhone-alpes-carte-ter-illico-liberte-jeunes",
          "label": "Carte TER illico Liberté jeunes",
          "institution": "region_auvergne_rhone_alpes",
          "description": "La carte TER illico Liberté jeunes vous permet de bénéficier de -50 % sur tous vos voyages sur l’ensemble de la région Auvergne-Rhône-Alpes.",
          "conditions": [
            "Vous procurer la carte TER illico Liberté jeunes pour un montant annuel de 15€."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": "<=",
              "value": 26
            },
            {
              "type": "regions",
              "values": [
                "84"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.ter.sncf.com/auvergne-rhone-alpes/offres/tarifs/illico-liberte-jeune",
          "teleservice": "https://www.ter.sncf.com/auvergne-rhone-alpes/offres/tarifs/illico-liberte-jeune",
          "instructions": "https://www.ter.sncf.com/auvergne-rhone-alpes/offres/tarifs/illico-liberte-jeune",
          "prefix": "la",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-auvergne-rhone-alpes-formation-sante-sociale",
          "label": "aide d'urgence pour poursuivre ma formation santé-social",
          "institution": "region_auvergne_rhone_alpes",
          "description": "Cette aide vous permet d'éviter d'avoir à interrompre votre parcours de formation suite à une situation d'urgence (maladie, décès, chômage, divorce, etc.).",
          "conditions": [
            "Rencontrer un·e travailleur·euse social·e."
          ],
          "conditions_generales": [
            {
              "type": "formation_sanitaire_social"
            },
            {
              "type": "regions",
              "values": [
                "84"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.auvergnerhonealpes.fr/aide/44/89-obtenir-une-aide-d-urgence-pour-poursuivre-ma-formation-sante-social-orientation-formation.htm",
          "teleservice": "",
          "form": "https://rhone-alpes.commeunservice.com/bourses/piece/modele/recuperer?nomFichier=Dossier_demande_FAU.pdf",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 3500
        },
        {
          "slug": "region-auvergne-rhone-alpes-ter-illico-solidaire",
          "label": "carte de réduction TER Illico solidaire",
          "institution": "region_auvergne_rhone_alpes",
          "description": "Grâce à la carte gratuite TER Illico Solidaire, bénéficiez pendant 1 an de 75% de réduction sur les billets TER tarif normal en Auvergne-Rhône-Alpes.",
          "conditions": [
            "Être dans l'une des situations suivantes : demandeur·euse d’emploi indemnisé·e en dessous du SMIC ou non indemnisé·e ; avoir moins de 26 ans et être inscrit·e en mission locale et participer à un dispositif régional ou national de formation ou d’accompagnement renforcé ; être titulaire d’un contrat aidé ; être bénéficiaire du RSA ou de l’ASPA ; être demandeur·euse d'asile."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "84"
              ]
            }
          ],
          "profils": [],
          "link": "https://mmt.vsct.fr/sites/default/files/swt/CARA/2021-06/Depliant%20illico%20Solidaire-janvier%202019.pdf",
          "teleservice": "https://illicosolidaire.cba.fr/",
          "prefix": "la",
          "type": "bool",
          "unit": "€",
          "periodicite": "annuelle"
        },
        {
          "slug": "region-auvergne-rhone_alpes-bourse-mobilite-internationale-etudiants",
          "label": "bourse Région Mobilité Internationale - Etudiant·e·s",
          "institution": "region_auvergne_rhone_alpes",
          "description": "Cette aide a pour but de soutenir la mobilité internationale des étudiant·e·s en études supérieures de la Région Auvergne-Rhône-Alpes.",
          "conditions": [
            "Être inscrit·e dans un établissement d'enseignement supérieur d'Auvergne-Rhône-Alpes."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "84"
              ]
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": []
            },
            {
              "type": "apprenti",
              "conditions": []
            }
          ],
          "link": "https://www.auvergnerhonealpes.fr/aide/73/89-se-former-a-l-etranger-avec-la-bourse-regionale-mobilite-internationale-etudiant-enseignement-superieur-recherche-innovation.htm",
          "teleservice": "https://sicorra.auvergnerhonealpes.fr/public",
          "instructions": "https://www.auvergnerhonealpes.fr/aide/73/89-se-former-a-l-etranger-avec-la-bourse-regionale-mobilite-internationale-etudiant-enseignement-superieur-recherche-innovation.htm",
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "periodicite": "annuelle",
          "legend": "maximum",
          "montant": 3000,
          "interestFlag": "_interetEtudesEtranger"
        },
        {
          "slug": "region-bourgogne-franche-comte-abonnement-scolaire-bfc",
          "label": "abonnement scolaire BFC",
          "institution": "region_bourgogne_franche_comte",
          "description": "L' abonnement des collégiens et lycéens, externes, demi-pensionnaires, ou internes en Bourgogne-Franche-Comté.",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "27"
              ]
            }
          ],
          "profils": [
            {
              "type": "lyceen",
              "conditions": []
            }
          ],
          "link": "https://www.ter.sncf.com/bourgogne-franche-comte/offres/tarif-train/abonnement-scolaire-college-lycee-rentree",
          "instructions": "https://www.ter.sncf.com/bourgogne-franche-comte/offres/tarif-train/abonnement-scolaire-college-lycee-rentree",
          "prefix": "l’",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-bourgogne-franche-comte-aide-au-permis-de-conduire",
          "label": "aide au permis de conduire",
          "institution": "region_bourgogne_franche_comte",
          "description": "Grâce à l'aide au permis de conduire de la région, bénéficiez du financement d'une partie de votre permis B.",
          "conditions": [
            "Être inscrit·e dans une autoécole bourguignonne-franc-comtoise.",
            "Être rattaché·e au foyer fiscal parental (conditions de ressources financières) ou émancipé·e fiscalement (sans conditions).",
            "Être engagé·e dans un parcours de formation (niveau IV ou inférieur) ou demandeur d’emploi (sous conditions) ou en mission de Service civique ou Contrat d'Engagement Jeune."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 15
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "regions",
              "values": [
                "27"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.bourgognefranchecomte.fr/permisdeconduire",
          "instructions": "https://www.bourgognefranchecomte.fr/permisdeconduire",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "autre",
          "montant": 500,
          "interestFlag": "_interetPermisDeConduire"
        },
        {
          "slug": "region-bourgogne-franche-comte-e-carte-avantages-jeunes",
          "label": "e-Carte Avantages Jeunes",
          "institution": "region_bourgogne_franche_comte",
          "description": "Grâce à la e-carte avantage jeunes, bénéficiez de réductions sur les loisirs et la vie quotidienne pour découvrir la culture, l'art et le sport en Bourgogne-Franche-Comté.",
          "conditions": [
            "Vous procurer la e-carte pour un montant annuel de 8€."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": "<=",
              "value": 30
            },
            {
              "type": "regions",
              "values": [
                "27"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.avantagesjeunes.com/",
          "teleservice": "https://www.avantagesjeunes.com/boutique/etape-1",
          "instructions": "",
          "prefix": "l’",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-bourgogne-franche-comte-etudes-etranger-aquisis",
          "label": "Bourse Aquisis - Études à l'étranger pour étudiant·e·s et apprenti·e·s post-bac",
          "institution": "region_bourgogne_franche_comte",
          "description": "Cette bourse vous permet d’effectuer des périodes d’études dans le monde entier (sauf France métropolitaine et DROM-COM), afin d’acquérir des compétences nouvelles et de vous perfectionner en langues étrangères.",
          "conditions": [
            "Être inscrit·e·s dans un établissement d’enseignement supérieur de Bourgogne-Franche-Comté.",
            "Avoir une période d’études à réaliser à l’étranger dans le cadre de votre cursus de formation."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "27"
              ]
            },
            {
              "type": "quotient_familial",
              "floor": 25830,
              "period": "year"
            }
          ],
          "profils": [
            {
              "type": "apprenti",
              "conditions": []
            },
            {
              "type": "enseignement_superieur",
              "conditions": []
            }
          ],
          "link": "https://www.bourgognefranchecomte.fr/node/382",
          "instructions": "https://www.bourgognefranchecomte.fr/mobilite-internationale",
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 2300,
          "interestFlag": "_interetEtudesEtranger"
        },
        {
          "slug": "region-bourgogne-franche-comte-jeunes-citoyens-du-monde",
          "label": "dispositif Jeunes Citoyens du Monde",
          "institution": "region_bourgogne_franche_comte",
          "description": "La Région Bourgogne-Franche-Comté offre aux jeunes la possibilité d’acquérir une expérience dans le domaine de la solidarité internationale grâce à un soutien financier individuel.",
          "conditions": [
            "Mener une action de développement ou humanitaire à titre bénévole grâce à une structure relevant du secteur public, privé ou mixte."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "27"
              ]
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 18
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 30
            }
          ],
          "profils": [
            {
              "type": "etudiant",
              "conditions": []
            },
            {
              "type": "enseignement_superieur",
              "conditions": []
            },
            {
              "type": "stagiaire",
              "conditions": []
            },
            {
              "type": "apprenti",
              "conditions": []
            },
            {
              "type": "professionnalisation",
              "conditions": []
            },
            {
              "type": "chomeur",
              "conditions": []
            },
            {
              "type": "service_civique",
              "conditions": []
            }
          ],
          "link": "https://www.bourgognefranchecomte.fr/node/407",
          "teleservice": "https://subventions.bourgognefranchecomte.fr/sub/extranet/dispositif-consulter.sub?sigle=INT-JCM",
          "prefix": "le",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 1000,
          "interestFlag": "_interetEtudesEtranger"
        },
        {
          "slug": "region-bourgogne-franche-comte-ordil",
          "label": "oRDIL - Prêt d'ordinateurs aux lycéens",
          "institution": "region_bourgogne_franche_comte",
          "description": "La Région Bourgogne-Franche-Comté met à disposition des lycées publics et privés 6 000 ordinateurs portables de prêt pour l'année scolaire 2021-2022. Les ordinateurs sont attribués aux lycéen·ne·s qui en ont le plus besoin.",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "27"
              ]
            }
          ],
          "profils": [
            {
              "type": "lyceen",
              "conditions": []
            }
          ],
          "link": "https://www.bourgognefranchecomte.fr/ordil",
          "teleservice": "",
          "form": "https://www.bourgognefranchecomte.fr/sites/default/files/2021-10/Convention%20pret%20eleve%20signee.pdf",
          "prefix": "l’aide",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-bourgogne-franche-comte-pass-vae",
          "label": "pASS'VAE",
          "institution": "region_bourgogne_franche_comte",
          "description": "La Région soutient votre démarche de Valorisation des acquis de l'expérience (VAE) en finançant votre parcours. La VAE est l’opportunité de valoriser ses acquis et d’obtenir une certification de valeur nationale (diplôme, titre ou certification).",
          "conditions": [
            "Vérifier que vous faites bien partie des <a target=\"_blank\" rel=\"noopener\" title =\"Vérifier éligibilité - Nouvelle fenêtre\" href=\"https://www.bourgognefranchecomte.fr/node/240\">personnes éligibles</a> à cette aide.",
            "Identifier le <a target=\"_blank\" rel=\"noopener\" title =\"point relais - Nouvelle fenêtre\" href=\"https://www.emfor-bfc.org/orientation-parcours/vae/\">point relais</a> Conseil en VAE le plus proche de votre domicile pour faire votre demande."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "27"
              ]
            }
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            },
            {
              "type": "salarie",
              "conditions": []
            },
            {
              "type": "independant",
              "conditions": []
            },
            {
              "type": "service_civique",
              "conditions": []
            }
          ],
          "link": "https://www.bourgognefranchecomte.fr/node/240",
          "teleservice": "",
          "instructions": "https://www.bourgognefranchecomte.fr/node/240",
          "prefix": "le",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-bourgogne-franche-comte-restauration-familles-boursieres",
          "label": "aides à l'hébergement et la restauration des lycéen·ne·s",
          "institution": "region_bourgogne_franche_comte",
          "description": "Cette aide vous permet d'obtenir une déduction sur vos frais d’hébergement et/ou de restauration facturés par les établissements.",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "27"
              ]
            }
          ],
          "profils": [
            {
              "type": "lyceen",
              "conditions": [
                {
                  "type": "boursier"
                }
              ]
            }
          ],
          "link": "https://www.bourgognefranchecomte.fr/node/322",
          "instructions": "https://www.bourgognefranchecomte.fr/node/322",
          "prefix": "les",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-bourgogne-franche-comte-stages-monde",
          "label": "stages Monde - Jeunes diplômé·e·s et en recherche d'emploi",
          "institution": "region_bourgogne_franche_comte",
          "description": "Cette aide vous permet de financer une expérience professionnelle à l’étranger sous la forme d'une bourse, et d'être accompagné·e dans votre démarche.",
          "conditions": [
            "Être inscrit·e à Pôle emploi avant le début du stage."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "27"
              ]
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 18
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 30
            }
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "link": "https://www.bourgognefranchecomte.fr/node/410",
          "instructions": "https://www.bourgognefranchecomte.fr/node/410",
          "prefix": "les",
          "type": "float",
          "unit": "€",
          "periodicite": "mensuelle",
          "legend": "",
          "montant": 763,
          "interestFlag": "_interetEtudesEtranger"
        },
        {
          "slug": "region-bourgogne-franche-comte-tarif-jeune-26-ans",
          "label": "tarif jeune -26 ans",
          "institution": "region_bourgogne_franche_comte",
          "description": "Le tarif jeune moins de 26 ans vous permet de bénéficier de 50% de réduction sur tous vos trajets en TER Bourgogne-Franche-Comté toute l'année, y compris vers ou depuis Paris, Auvergne-Rhône-Alpes ou Centre-Val de Loire.",
          "conditions": [
            "Choisir vos trajets en TER Bourgogne-Franche-Comté, vers ou depuis Paris, Auvergne-Rhône-Alpes ou Centre-Val de Loire."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": "<=",
              "value": 26
            },
            {
              "type": "regions",
              "values": [
                "27"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.ter.sncf.com/bourgogne-franche-comte/offres/tarif-train/reduction-tarif-jeune-etudiant",
          "teleservice": "https://www.ter.sncf.com/bourgogne-franche-comte/offres/tarif-train/reduction-tarif-jeune-etudiant",
          "instructions": "",
          "prefix": "le",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-bretagne-abonnement-breizhgo",
          "label": "abonnement BreizhGo",
          "institution": "region_bretagne",
          "description": "Les abonnement BreizhGo vous permettent de bénéficier d'une réduction jusqu'à 75 % sur vos trajets en Bretagne et de se déplacer de manière illimitée sur une ligne choisie, y compris le week-end, sur les TER, TGV et réseaux urbains. Par exemple, la ligne entre son domicile et son lieu d’étude ou de travail.",
          "conditions": [
            "Choisir l'abonnement BreizhGo qui convient le mieux à votre trajet et un trajet fréquent.",
            "Vous procurer l'abonnement BreizhGo dont le prix dépend du trajet choisi."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": "<=",
              "value": 26
            },
            {
              "type": "regions",
              "values": [
                "53"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.ter.sncf.com/bretagne/offres/tarifs/quotidien-moins-26",
          "instructions": "https://www.ter.sncf.com/bretagne/offres/tarifs/quotidien-moins-26",
          "prefix": "l’",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-bretagne-aide-qualif-emploi",
          "label": "aide QUALIF Emploi",
          "institution": "region_bretagne",
          "description": "La Région finance une large palette de formations qualifiantes pour favoriser la montée en compétences des breton·ne·s et répondre aux besoins en recrutement des entreprises.",
          "conditions": [
            "Être en recherche d'emploi et souhaiter faire une formation dans un des <a target=\"_blank\" title=\"Secteurs d'activités qui recrutent - Nouvelle fenêtre\" rel=\"noopener\" href=\"https://www.bretagne.bzh/aides/fiches/copie-de-qualif-emploi/\">14 secteurs d’activités</a> qui recrutent en Bretagne."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "53"
              ]
            }
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "link": "https://www.bretagne.bzh/aides/fiches/copie-de-qualif-emploi/",
          "teleservice": "https://aides.bretagne.bzh/account-management/crbr-demandeurs/ux/#/login?redirectTo=https:%2F%2Faides.bretagne.bzh%2Faides%2F%23%2Fcrbr%2Fconnecte%2Fdashboard%2Faccueil&jwtKey=jwt-crbr-portail-depot-demande-aides&footer=https:%2F%2Faides.bretagne.bzh%2Faides%2F%23%2Fcrbr%2Fmentions-legales,Mentions%20l%C3%A9gales,_self",
          "instructions": "https://www.bretagne.bzh/aides/fiches/copie-de-qualif-emploi/",
          "prefix": "l’",
          "type": "bool",
          "unit": "€",
          "periodicite": "ponctuelle"
        },
        {
          "slug": "region-bretagne-aide-qualif-vae",
          "label": "Aide QUALIF VAE",
          "institution": "region_bretagne",
          "description": "Cette aide s’adresse aux personnes en recherche d’emploi qui entament une démarche de validation des acquis de l’expérience (VAE). Il s’agit de financer l’accompagnement individuel nécessaire pour la présentation de leur dossier devant le jury de validation.",
          "conditions": [
            "Être en recherche d'emploi inscrit·e à Pôle Emploi et justifier d’au moins un an d’expérience professionnelle dans le domaine visé."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "53"
              ]
            }
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "link": "https://www.bretagne.bzh/aides/fiches/qualif-vae/",
          "teleservice": "https://www.bretagne.bzh/aides/fiches/qualif-vae/",
          "instructions": "https://www.bretagne.bzh/aides/fiches/qualif-vae/",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "autre",
          "legend": "maximum",
          "montant": 700
        },
        {
          "slug": "region-bretagne-bourse-international-bts-dut",
          "label": "Bourse « Jeunes à l’international » – Etudiant·e·s en BTS et BUT",
          "institution": "region_bretagne",
          "description": "La Région Bretagne finance les étudiant·e·s en BTS et BUT qui souhaitent effectuer un stage professionnel ou un séjour d’études à l’étranger.",
          "conditions": [
            "Être étudiant·e en BTS ou BUT.",
            "Vérifier que vous correspondez aux <a target=\"_blank\" rel=\"noopener\" title =\"conditions spécifiques - Nouvelle fenêtre\" href=\"https://www.bretagne.bzh/aides/fiches/bourse-jeunes-a-linternational-etudiants-en-bts-et-dut/\">conditions spécifiques</a> d'attribution (section \"Bénéficiaires et critères\").",
            "Contacter au préalable votre établissement qui vous indiquera la marche à suivre."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "53"
              ]
            },
            {
              "type": "quotient_familial",
              "floor": 25000,
              "period": "year"
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": [
                {
                  "type": "annee_etude",
                  "values": [
                    "bts_1",
                    "but_1"
                  ]
                }
              ]
            }
          ],
          "link": "https://www.bretagne.bzh/aides/fiches/bourse-jeunes-a-linternational-etudiants-en-bts-et-dut/",
          "teleservice": "https://extranets.region-bretagne.fr/Portail-Aides/jsp/nouveauContexte.action?codeAction=M42-CONNEXION",
          "instructions": "https://www.bretagne.bzh/aides/fiches/bourse-jeunes-a-linternational-etudiants-en-bts-et-dut/",
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "periodicite": "mensuelle",
          "legend": "maximum",
          "montant": 500,
          "interestFlag": "_interetEtudesEtranger"
        },
        {
          "slug": "region-bretagne-bourse-international-formation-sanitaires-sociales",
          "label": "Bourse « Jeunes à l’international » – Étudiant·e·s des formations sanitaires ou sociales",
          "institution": "region_bretagne",
          "description": "La région Bretagne finance les étudiant·e·s des formations sanitaires ou sociales qui souhaitent effectuer un stage professionnel ou un séjour d’études à l’étranger.",
          "conditions": [
            "Être étudiant·e dans le secteur sanitaire et social.",
            "Vérifier que vous correspondez aux <a target=\"_blank\" rel=\"noopener\" title=\"conditions spécifiques - Nouvelle fenêtre\" href=\"https://www.bretagne.bzh/aides/fiches/bourse-jeunes-a-linternational-etudiants-des-formations-sanitaires-ou-sociales/\">conditions spécifiques</a> d'attribution (section \"Bénéficiaires et critères\").",
            "Contacter au préalable votre établissement qui vous indiquera la marche à suivre."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "53"
              ]
            },
            {
              "type": "quotient_familial",
              "floor": 25000,
              "period": "year"
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "etudiant",
              "conditions": []
            }
          ],
          "link": "https://www.bretagne.bzh/aides/fiches/bourse-jeunes-a-linternational-etudiants-des-formations-sanitaires-ou-sociales/",
          "teleservice": "https://extranets.region-bretagne.fr/Portail-Aides/jsp/nouveauContexte.action?codeAction=M42-CONNEXION",
          "instructions": "https://www.bretagne.bzh/aides/fiches/bourse-jeunes-a-linternational-etudiants-des-formations-sanitaires-ou-sociales/",
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "periodicite": "mensuelle",
          "legend": "maximum",
          "montant": 500,
          "interestFlag": "_interetEtudesEtranger"
        },
        {
          "slug": "region-bretagne-bourse-international-lycee-pro",
          "label": "bourse « Jeunes à l’international » – Lycéen·ne·s professionnel·le·s",
          "institution": "region_bretagne",
          "description": "La région Bretagne finance les lycéen·ne·s professionnel·le·s qui souhaitent effectuer un stage professionnel à l’étranger.",
          "conditions": [
            "Être inscrit·e en lycée en formation professionnelle de niveau 3 ou 4 : CAP, CAPA, CAPM, Bac Pro, BMA, FCIL, MC, DTMS, etc. ou en Bac Technologiques « Hôtellerie ».",
            "Contacter au préalable votre établissement qui vous indiquera la marche à suivre."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "53"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "lyceen",
              "conditions": []
            }
          ],
          "link": "https://www.bretagne.bzh/aides/fiches/bourse-jeunes-a-linternational-lyceens-professionnels-et-eleves-des-formations-sanitaires-et-sociales/",
          "teleservice": "https://extranets.region-bretagne.fr/Portail-Aides/jsp/nouveauContexte.action?codeAction=M42-CONNEXION",
          "instructions": "https://www.bretagne.bzh/aides/fiches/bourse-jeunes-a-linternational-lyceens-professionnels-et-eleves-des-formations-sanitaires-et-sociales/",
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 460,
          "interestFlag": "_interetEtudesEtranger"
        },
        {
          "slug": "region-centre-val-de-loire-aide-mobillico",
          "label": "aide Mobillico",
          "institution": "region_centre_val_de_loire",
          "description": "Grâce à cette aide, vos transports domicile-travail sont pris en charge, en complément de la prise en charge de votre employeur, pour limiter vos dépenses à 100€ par mois.",
          "conditions": [
            "Être titulaire d'un abonnement SNCF mensuel ou annuel.",
            "Effectuer des parcours intra ou inter régionaux pour le travail, y compris vers l’Ile-de-France."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "24"
              ]
            }
          ],
          "profils": [
            {
              "type": "salarie",
              "conditions": []
            },
            {
              "type": "independant",
              "conditions": []
            },
            {
              "type": "service_civique",
              "conditions": []
            },
            {
              "type": "apprenti",
              "conditions": []
            },
            {
              "type": "stagiaire",
              "conditions": []
            },
            {
              "type": "professionnalisation",
              "conditions": []
            }
          ],
          "link": "https://www.centre-valdeloire.fr/mobillico",
          "teleservice": "https://aspqol.sphinxonline.net/SurveyServer/s/CRCTOURS/Mobillico/questionnaire.htm",
          "prefix": "l’",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-centre-val-de-loire-carte-rémi-liberté-jeune",
          "label": "carte RÉMI liberté jeune",
          "institution": "region_centre_val_de_loire",
          "description": "Grâce à la carte RÉMI liberté jeune, bénéficiez de jusqu'à -66% sur vos trajets en car ou en train, pour voyager en région Centre-Val de Loire ou vers/depuis les régions voisines (Ile-de-France, Nouvelle Aquitaine, Pays de Loire, Bourgogne Franche Comté et Auvergne-Rhône-Alpes).",
          "conditions": [
            "Vous procurer en ligne la carte RÉMI liberté jeune pour un montant annuel de 20€ ou gratuitement si vous êtes éligible à YEP'S."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 15
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "regions",
              "values": [
                "24"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.ter.sncf.com/centre-val-de-loire/offres/gamme-occasionnels-remi/remiliberte/carte-remi-liberte-jeune",
          "teleservice": "https://vad.keolis.com/ter-centre-commandecarte/#/offres",
          "instructions": "https://www.ter.sncf.com/centre-val-de-loire/offres/gamme-occasionnels-remi/remiliberte/carte-remi-liberte-jeune",
          "prefix": "la",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-centre-val-de-loire-euro-metiers",
          "label": "euro Métiers - Centre-Val de Loire",
          "institution": "region_centre_val_de_loire",
          "description": "Cette aide vous permet de financer votre stage en entreprise à l'étranger.",
          "conditions": [
            "Vous rapprocher de votre organisme de formation."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "24"
              ]
            }
          ],
          "profils": [
            {
              "type": "apprenti",
              "conditions": []
            },
            {
              "type": "lyceen",
              "conditions": []
            }
          ],
          "link": "https://www.europeocentre-valdeloire.eu/euro-metiers-centre/#:~:text=%C2%AB%20Euro%20M%C3%A9tiers%20Centre%2DVal%20de%20Loire%20%C2%BB%20s'adresse,r%C3%A9cemment%20dipl%C3%B4m%C3%A9s%20(tout%20niveau).",
          "instructions": "https://www.europeocentre-valdeloire.eu/euro-metiers-centre/#:~:text=%C2%AB%20Euro%20M%C3%A9tiers%20Centre%2DVal%20de%20Loire%20%C2%BB%20s'adresse,r%C3%A9cemment%20dipl%C3%B4m%C3%A9s%20(tout%20niveau).",
          "prefix": "l’",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre",
          "interestFlag": "_interetEtudesEtranger"
        },
        {
          "slug": "region-centre-val-de-loire-le-combo-parfait-aide-a-la-formation",
          "label": "Combo parfait (aide à la formation)",
          "institution": "region_centre_val_de_loire",
          "description": "Le Combo Parfait regroupe des formations qualifiantes ou pré-qualifiantes à des métiers de proximité, destinées aux jeunes en recherche d’emploi. Durant toute la durée de la formation, vous bénéficierez d’une rémunération (500€/mois). Des aides complémentaires existent (permis, logement, mobilité) : voir",
          "conditions": [
            "Être inscrit·e à une formation Combo Parfait."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 16
            },
            {
              "type": "regions",
              "values": [
                "24"
              ]
            }
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "link": "https://www.yeps.fr/articles/891A0CF9-7DA0-421F-893C-327BAFE976CB",
          "instructions": "https://www.yeps.fr/articles/891A0CF9-7DA0-421F-893C-327BAFE976CB",
          "prefix": "le",
          "type": "float",
          "unit": "€",
          "periodicite": "mensuelle",
          "montant": 500
        },
        {
          "slug": "region-centre-val-de-loire-mobilite-internationale-apprenti",
          "label": "euro Métiers – Apprenti·e·s",
          "institution": "region_centre_val_de_loire",
          "description": "La Région soutient financièrement les stages à l’étranger des apprenti·e·s.",
          "conditions": [
            "Contacter votre établissement pour vérifier qu'il fait partie du dispositif et déposer votre demande auprès d'eux.",
            "Trouver un stage d'une durée de 2 à 3 semaines consécutives, dans un pays de l'Union Européenne ou dans un pays associé au programme."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "24"
              ]
            }
          ],
          "profils": [
            {
              "type": "apprenti",
              "conditions": []
            }
          ],
          "link": "https://www.yeps.fr/blog/articles/730CAD0E-801F-4D86-8E8C-8F1B701A82B2",
          "instructions": "https://www.yeps.fr/blog/articles/730CAD0E-801F-4D86-8E8C-8F1B701A82B2",
          "prefix": "l’",
          "type": "bool",
          "unit": "€",
          "periodicite": "ponctuelle",
          "interestFlag": "_interetEtudesEtranger"
        },
        {
          "slug": "region-corse-aide-au-permis-prima-strada",
          "label": "aide au permis Prima Strada",
          "institution": "region_corse",
          "description": "Grâce à l'aide Prima Strada, financez une partie de votre permis B ou A1. A noter : le permis de conduire sera entièrement pris en charge pour tous les jeunes en situation de handicap. Cette aide n'est pas cumulable avec les aides existantes.",
          "conditions": [
            "Résider en Corse depuis 2 ans.",
            "Justifier d’un engagement citoyen et/ou bénévole dans une structure de son choix (justifier de 20 heures minimum).",
            "Déposer une demande écrite motivée.",
            "Être rattaché·e à un foyer dont le revenu fiscal de référence est inférieur à 18 500 € par part fiscale.",
            "Ne pas être concerné·e par l’attribution d’une aide de droit commun ou d’une autre aide existante.",
            "Ne pas avoir débuté sa formation et ne pas avoir passé l’examen."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 15
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "regions",
              "values": [
                "94"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.isula.corsica/jeunesse/Une-aide-pour-les-jeunes-pour-passer-le-permis-de-conduire-Prima-Strada_a80.html",
          "instructions": "https://www.isula.corsica/jeunesse/attachment/2263155/",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "autre",
          "montant": 500,
          "interestFlag": "_interetPermisDeConduire"
        },
        {
          "slug": "region-corse-bourse-a-la-mobilite-mobighjovani",
          "label": "bourse à la mobilité Mobighjovani",
          "institution": "region_corse",
          "description": "Grâce à la bourse Mobighjovani, profitez d'une aide financière qui vise à couvrir les frais non pris en charge dans les différents programmes de mobilité internationale.",
          "conditions": [
            "Résider en Corse depuis 2 ans.",
            "Déposer une demande écrite motivée.",
            "Être rattaché·e à un foyer dont le revenu fiscal est inférieur à 18 500€ par part fiscale.",
            "Ne pas avoir débuté son projet de mobilité au moment du dépôt de la candidature."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 11
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 30
            },
            {
              "type": "regions",
              "values": [
                "94"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.isula.corsica/jeunesse/Bourse-a-la-mobilite-Mobighjovani_a276.html",
          "instructions": "https://www.isula.corsica/jeunesse/Bourse-a-la-mobilite-Mobighjovani_a276.html",
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "periodicite": "autre",
          "montant": 300,
          "interestFlag": "_interetEtudesEtranger"
        },
        {
          "slug": "region-corse-depenses-rentree",
          "label": "aide aux dépenses de rentrée",
          "institution": "region_corse",
          "description": "La Région aide financièrement les élèves boursier·e·s dans l'achat de leur matériel scolaire.",
          "conditions": [
            "Contacter <a target=\"_blank\" rel=\"noopener\" title=\"votre CROUS - Nouvelle fenêtre\" href=\"https://www.crous-corse.fr/contact/\">votre CROUS</a>."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "94"
              ]
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": [
                {
                  "type": "boursier"
                }
              ]
            }
          ],
          "link": "https://www.crous-corse.fr/bourses/aide-aux-depenses-de-rentree/",
          "instructions": "https://www.crous-corse.fr/bourses/aide-aux-depenses-de-rentree/",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "annuelle",
          "montant": 500
        },
        {
          "slug": "region-corse-faj",
          "label": "fonds d'Aide aux Jeunes",
          "institution": "region_corse",
          "description": "La Région soutient les jeunes dans leur démarche d’insertion grâce à une aide financière.",
          "conditions": [
            "Avoir des ressources ne dépassant pas 551,31 € nets.",
            "Effectuer votre demande auprès de votre référent·e social·e."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "94"
              ]
            },
            {
              "type": "age",
              "operator": ">",
              "value": 18
            },
            {
              "type": "age",
              "operator": "<",
              "value": 25
            }
          ],
          "profils": [],
          "link": "https://www.isula.corsica/assemblea/docs/RapportCP/2020CP171-annexe.pdf",
          "instructions": "https://www.isula.corsica/assemblea/docs/RapportCP/2020CP171-annexe.pdf",
          "prefix": "le",
          "type": "bool",
          "unit": "€",
          "periodicite": "ponctuelle"
        },
        {
          "slug": "region-grand-est-abonnement-primo",
          "label": "abonnement PRIMO",
          "institution": "grand_est",
          "description": "Grâce à l'abonnement PRIMO, voyagez en illimité sur votre trajet préféré du réseau TER Grand Est.",
          "conditions": [
            "Souscrire à l'abonnement PRIMO (hebdomadaire ou mensuel)."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": "<=",
              "value": 26
            },
            {
              "type": "regions",
              "values": [
                "44"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.ter.sncf.com/grand-est/offres/primo/primo_abonnement",
          "teleservice": "https://www.sncf-abo-annuel-ter.com/tapas-tel-web/devisAbonnement?codeRegion=LOR",
          "instructions": "https://www.ter.sncf.com/grand-est/offres/primo/primo_abonnement",
          "prefix": "l’",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-grand-est-carte-primo",
          "label": "carte PRIMO",
          "institution": "grand_est",
          "description": "Grâce à la carte PRIMO, bénéficiez de 50 à 70% de réduction quel que soit votre trajet sur le réseau TER Grand Est.\n",
          "conditions": [
            "Vous procurer la carte PRIMO pour un montant annuel de 15€."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": "<=",
              "value": 26
            },
            {
              "type": "regions",
              "values": [
                "44"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.ter.sncf.com/grand-est/offres/primo/primo_carte#ancre",
          "instructions": "https://www.ter.sncf.com/grand-est/offres/primo/primo_carte#ancre",
          "prefix": "la",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-hauts-de-france-aide-scola-lycee",
          "label": "aide complémentaire à la scolarité des lycéen·ne·s",
          "institution": "hauts_de_france",
          "description": "La Région accorde des aides financières aux lycéen·ne·s rencontrant des difficultés financières afin de participer aux dépenses liées à la scolarité (restauration, hébergement, manuels scolaires, équipement professionnel, transport) ou à une situation exceptionnelle.",
          "conditions": [
            "Faire la demande d'aide auprès de votre établissement."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "32"
              ]
            }
          ],
          "profils": [
            {
              "type": "lyceen",
              "conditions": []
            }
          ],
          "link": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=627",
          "instructions": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=627",
          "prefix": "l’",
          "type": "bool",
          "unit": "€",
          "periodicite": "ponctuelle"
        },
        {
          "slug": "region-hauts-de-france-carte-ter-26-ans",
          "label": "carte TER Hauts-de-France -26 ans",
          "institution": "hauts_de_france",
          "description": "Grâce à la carte TER Hauts-de-France -26 ans, bénéficiez jusqu'à 50% de réductions sur vos billets pour voyager en TER, au départ et à destination des gares des Hauts-de-France, y compris vers Paris, la Normandie et certains départements de la Région Grand-Est. Votre réduction s'applique également sur les billets de vos accompagnants, jusqu'à 3 personnes.",
          "conditions": [
            "Vous procurer la carte TER Hauts-de-France -26 ans pour un montant annuel de 15€."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": "<=",
              "value": 26
            },
            {
              "type": "regions",
              "values": [
                "32"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.ter.sncf.com/hauts-de-france/offres/cartes-de-reduction/carte-ter-26-ans",
          "instructions": "https://www.ter.sncf.com/hauts-de-france/offres/cartes-de-reduction/carte-ter-26-ans",
          "prefix": "la",
          "type": "bool",
          "periodicite": "autre"
        },
        {
          "slug": "region-hauts-de-france-fonds-regional-social-urgence",
          "label": "fonds Régional Social d’Urgence (FRSU)",
          "institution": "hauts_de_france",
          "description": "La Région propose d'apporter une aide financière personnalisée aux étudiant·e·s en difficulté financière.",
          "conditions": [
            "Être inscrit·e dans un établissement d'enseignement supérieur reconnu par le Ministère de l'enseignement supérieur.",
            "Être en grande difficulté financière.",
            "Prendre rendez-vous avec votre CROUS pour effectuer la demande."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "32"
              ]
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 35
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": []
            }
          ],
          "link": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=66",
          "instructions": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=66",
          "prefix": "le",
          "type": "bool",
          "unit": "€",
          "periodicite": "ponctuelle"
        },
        {
          "slug": "region-hauts-de-france-fonds-solidarite-apprentissage",
          "label": "fonds de solidarité des apprenti·e·s (FSA)",
          "institution": "hauts_de_france",
          "description": "Si vous êtes en apprentissage et en difficulté financière, la Région vous propose une aide ponctuelle. Elle peut être mobilisée pour le logement, la santé, l'alimentation, le transport et l'équipement.",
          "conditions": [
            "Constituer votre dossier de demande avec votre organisme de formation."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "32"
              ]
            }
          ],
          "profils": [
            {
              "type": "apprenti",
              "conditions": []
            }
          ],
          "link": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=608",
          "instructions": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=608",
          "prefix": "le",
          "type": "float",
          "unit": "€",
          "periodicite": "autre",
          "legend": "maximum",
          "montant": 800
        },
        {
          "slug": "region-hauts-de-france-vae",
          "label": "accompagnement Validation des Acquis de l'Expérience (VAE)",
          "institution": "hauts_de_france",
          "description": "Cette aide vous permet de financer partiellement votre VAE sous la forme d'un abondement sur votre Compte Formation.",
          "conditions": [
            "Être inscrit·e à Pôle Emploi.",
            "Contacter un·e conseiller·e VAE dans un centre <a target=\"_blank\" rel=\"noopener\" title=\"Interval - Nouvelle fenêtre\" href=\"http://www.interval-vae.fr/\">Interval</a>, <a target=\"_blank\" title=\"Point Information Conseil Point Relais Conseil - Nouvelle fenêtre\" rel=\"noopener\" href=\"http://www.laio.c2rp.fr//\">un Point Information Conseil ou un Point Relais Conseil</a> afin d'être aidé·e dans la constitution de votre dossier."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "32"
              ]
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 18
            }
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            },
            {
              "type": "salarie",
              "conditions": []
            }
          ],
          "link": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=512",
          "instructions": "https://guide-aides.hautsdefrance.fr/spip.php?page=dispositif&id_dispositif=512",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 5000
        },
        {
          "slug": "region-ile-de-france-aide-apprentissage",
          "label": "aide Régionale à l’Apprentissage (ARA)",
          "institution": "region_ile_de_france",
          "description": "L’Aide Régionale à l’Apprentissage (ARA) a pour objectif de soutenir les apprenti·e·s inscrit·e·s en formation de premiers niveaux (3, 4 et 5) en les aidant à faire face à des dépenses liées à l’achat de livres, d’équipement et de documentation professionnelle, de frais de transport, de restauration ou d’hébergement, etc.",
          "conditions": [
            "Être apprenti·e inscrit·e en 2021-2022 en première année dans une formation de niveau 3, 4 et 5 dans un organisme de formation – centre de formation d’apprentis (OF-CFA) francilien.",
            "Faire votre demande auprès de votre OF ou CFA."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "11"
              ]
            }
          ],
          "profils": [
            {
              "type": "apprenti",
              "conditions": []
            }
          ],
          "link": "https://www.iledefrance.fr/aide-regionale-apprentissage-dediee-aux-apprentis-en-premiere-annee-de-formation-de-niveaux-3-4-5",
          "teleservice": "",
          "instructions": "https://www.iledefrance.fr/aide-regionale-apprentissage-dediee-aux-apprentis-en-premiere-annee-de-formation-de-niveaux-3-4-5",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 375
        },
        {
          "slug": "region-ile-de-france-aide-au-permis-de-conduire",
          "label": "aide au permis de conduire",
          "institution": "region_ile_de_france",
          "description": "Grâce à cette aide au permis de conduire, la Région finance tout ou partie du permis B pour les jeunes franciliens en insertion.",
          "conditions": [
            "Vous devez être dans l'un des cas suivants : être stagiaire de la formation professionnelle inscrit·e dans une formation financée par la Région; ou être inscrit·e et suivi·e en mission locale, signataire d’un parcours contractualisé d’accompagnement vers l’emploi et l’autonomie (PACEA), dont le projet professionnel, attesté par le conseiller de la mission locale, nécessite l’obtention du permis; ou être demandeur·euse d’emploi résidant dans un quartier politique de la ville ou en zone rurale."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "11"
              ]
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 18
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            }
          ],
          "profils": [
            {
              "type": "stagiaire",
              "conditions": []
            },
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "link": "https://www.iledefrance.fr/aide-au-permis-de-conduire-pour-jeunes-en-insertion",
          "instructions": "https://www.iledefrance.fr/aide-au-permis-de-conduire-pour-jeunes-en-insertion",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "autre",
          "legend": "maximum",
          "montant": 1300,
          "interestFlag": "_interetPermisDeConduire"
        },
        {
          "slug": "region-ile-de-france-bourse-installtation-des-étudiants-en-kinésithérapie-et-maïeutique",
          "label": "Bourse à l'installation des étudiant·e·s en kinésithérapie et maïeutique",
          "institution": "region_ile_de_france",
          "description": "La Région Ile-de-France soutient l'installation des étudiant·e·s en kinésithérapie et maïeutique (sages-femmes) dans les zones franciliennes carencées en professionnels de santé.",
          "conditions": [
            "Etre inscrit·e en avant-dernière année d'études.",
            "S'engager à s'installer pour 3 ans minimum, en secteur 1, dans une zone carencée, dans les 6 mois suivant l'obtention du diplôme."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "11"
              ]
            }
          ],
          "profils": [
            {
              "type": "etudiant",
              "conditions": [
                {
                  "type": "formation_sanitaire_social"
                }
              ]
            }
          ],
          "link": "https://www.iledefrance.fr/bourse-daide-linstallation-des-etudiants-en-formation-kinesitherapie-et-maieutique",
          "teleservice": "https://mesdemarches.iledefrance.fr",
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "periodicite": "annuelle",
          "montant": 8400,
          "interestFlag": "_interetAidesSanitaireSocial"
        },
        {
          "slug": "region-ile-de-france-bourses-mobilite-bts",
          "label": "bourses Mobilité pour les étudiant·e·s en BTS",
          "institution": "region_ile_de_france",
          "description": "La Région propose une aide financière pour réaliser un stage à l'étranger d'une durée supérieure ou égale à 4 semaines.",
          "conditions": [
            "Être inscrit·e dans une des <a target=\"_blank\" rel=\"noopener\" title=\"filières BTS éligibles - Nouvelle fenêtre\" href=\"https://www.iledefrance.fr/bourses-mobilite-ile-de-france-bts\">filières BTS éligibles</a>.",
            "Être inscrit·e dans l'un des <a target=\"_blank\" rel=\"noopener\" title=\"établissements franciliens partenaires - Nouvelle fenêtre\" href=\"https://mesdemarches.iledefrance.fr/document-collect/cridfprd/root/public?objectId=6539f9e1-9bf6-4201-90cd-18c53cdaf5ff;1.0\">établissements franciliens partenaires</a>."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "11"
              ]
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": [
                {
                  "type": "annee_etude",
                  "values": [
                    "bts_1"
                  ]
                }
              ]
            }
          ],
          "link": "https://www.iledefrance.fr/bourses-mobilite-ile-de-france-bts",
          "teleservice": "https://mesdemarches.iledefrance.fr/",
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 500,
          "interestFlag": "_interetEtudesEtranger"
        },
        {
          "slug": "region-ile-de-france-bourses-mobilite-etudiants-dut-licence-master",
          "label": "Bourses Mobilité pour les étudiant·e·s de BUT, licence, master",
          "imgSrc": "",
          "institution": "region_ile_de_france",
          "description": "La Région Ile-de-France apporte aux étudiant·e·s francilien·ne·s inscrit·e·s en BUT, licence ou master dans un établissement francilien partenaire une aide financière pour réaliser un stage ou une période d'études à l'étranger, d'une durée de 1 à 10 mois maximum, durant l'année universitaire.",
          "conditions": [
            "Être inscrit·e dans une université, un grand établissement ou une école figurant sur la <a target=\"_blank\" rel=\"noopener\" title=\"liste des établissements partenaires - Nouvelle fenêtre\" href=\"https://mesdemarches.iledefrance.fr/document-collect/cridfprd/root/public?objectId=e3ff2b1c-500a-48fd-a2b5-1e3f2b93be81;1.0 \">liste des établissements partenaires</a> de la Région."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "11"
              ]
            },
            {
              "type": "quotient_familial",
              "floor": 19190,
              "period": "year"
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": [
                {
                  "type": "annee_etude",
                  "values": [
                    "bts_1",
                    "but_1",
                    "licence_1",
                    "licence_2",
                    "licence_3",
                    "master_1",
                    "master_2"
                  ]
                }
              ]
            }
          ],
          "link": "https://www.iledefrance.fr/bourses-mobilite-ile-de-france-informations-aux-etudiants-de-dut-licence-master#",
          "teleservice": "https://mesdemarches.iledefrance.fr/account-management/cridfprd-demandeurs/ux/#/login?redirectTo=https:%2F%2Fmesdemarches.iledefrance.fr%2Faides%2F%23%2Fcridfprd%2Fconnecte%2Fdashboard%2Faccueil&jwtKey=jwt-cridfprd-portail-depot-demande-aides&footer=https:%2F%2Fmesdemarches.iledefrance.fr%2Faides%2F%23%2Fcridfprd%2Fmentions-legales,Mentions%20l%C3%A9gales%20et%20RGPD,_self",
          "instructions": "https://www.iledefrance.fr/bourses-mobilite-ile-de-france-informations-aux-etudiants-de-dut-licence-master#",
          "prefix": "les",
          "type": "float",
          "unit": "€",
          "periodicite": "mensuelle",
          "legend": "maximum",
          "montant": 450
        },
        {
          "slug": "region-ile-de-france-carte-imagine-r-scolaire",
          "label": "carte Imagine R Scolaire",
          "institution": "region_ile_de_france",
          "description": "Le forfait imagine R est un forfait annuel destiné aux personnes de moins de 26 ans résidant en Île-de-France. Il permet de voyager autant de fois que l’on veut en Île-de-France, sur tous les modes de transport (y compris Filéo), à l’exception de certaines dessertes directes d’aéroports et des bus touristiques. Il permet également de bénéficier d'<a target=\"_blank\" rel=\"noopener\" title=\"avantages culture - Nouvelle fenêtre\" href=\" https://www.iledefrance-mobilites.fr/navigo-avantages-culture\">avantages culture</a>.",
          "conditions": [
            "Acquérir la carte pour un montant annuel de 350€, payable en 9 mensualités."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": "<=",
              "value": 26
            },
            {
              "type": "regions",
              "values": [
                "11"
              ]
            }
          ],
          "profils": [
            {
              "type": "etudiant",
              "conditions": []
            },
            {
              "type": "apprenti",
              "conditions": []
            }
          ],
          "link": "https://www.iledefrance-mobilites.fr/titres-et-tarifs/detail/forfait-imagine-r-scolaire",
          "teleservice": "https://www.iledefrance-mobilites.fr/je-gere-ma-carte",
          "prefix": "la",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-ile-de-france-carte-scolaire-bus-lignes-regulieres",
          "label": "carte scolaire bus lignes régulières",
          "institution": "region_ile_de_france",
          "description": "La carte scolaire bus lignes régulières et la carte scolaire bus lignes régulières RPI (Regroupement Pédagogique Intercommunal) sont valables pour un aller-retour quotidien pendant la période scolaire entre le domicile et l’école à effectuer sur les lignes de l’entreprise d’OPTILE concernée. Cette carte permet également d'effectuer un aller-retour quotidien entre l’école et le lieu du déjeuner.",
          "conditions": [
            "Être domicilié à 3 km ou plus de l’établissement de scolarisation.",
            "Acquérir la carte dont le prix dépend de la distance parcourue. Certaines collectivités peuvent aider à financer la carte (<a target=\"_blank\" rel=\"noopener\" title=\"Yvelines - Nouvelle fenêtre\" href=\"https://www.78-92.fr/annuaire/aides-et-services/detail/aide-aux-transports-scolaires\">Yvelines</a>, <a target=\"_blank\" rel=\"noopener\" title=\"Essonne - Nouvelle fenêtre\" href=\"https://www.essonne.fr/economie-amenagement-mobilites/mobilites/les-aides-aux-transports\">Essonne</a>, <a target=\"_blank\" rel=\"noopener\" title=\"Val de Marne - Nouvelle fenêtre\" href=\"https://www.valdemarne.fr/a-votre-service/deplacement/transports/subvention-pour-les-transports-scolaires\">Val de Marne</a>, <a target=\"_blank\" rel=\"noopener\" title=\"Val d'Oise - Nouvelle fenêtre\" href=\"https://www.valdoise.fr/1329-transports-scolaires.htm\">Val d’Oise</a>)."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": "<=",
              "value": 21
            },
            {
              "type": "regions",
              "values": [
                "11"
              ]
            }
          ],
          "profils": [
            {
              "type": "etudiant",
              "conditions": []
            }
          ],
          "link": "https://www.iledefrance-mobilites.fr/titres-et-tarifs/detail/carte-scolaire-bus-lignes-regulieres#conditions",
          "instructions": "https://www.iledefrance-mobilites.fr/titres-et-tarifs/detail/carte-scolaire-bus-lignes-regulieres#conditions",
          "prefix": "la",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-ile-de-france-ecoute-etudiants",
          "label": "écoute Étudiant·e·s Île-de-France",
          "institution": "region_ile_de_france",
          "description": "Cette aide vous permet d'évaluer votre état psychologique, d'obtenir des ressources pour vous accompagner et de bénéficier de consultations gratuites avec des psychologues si besoin.",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "11"
              ]
            }
          ],
          "profils": [
            {
              "type": "etudiant",
              "conditions": []
            }
          ],
          "link": "https://www.iledefrance.fr/ecoute-etudiants-ile-de-france-plateforme-daide-pour-les-etudiants-en-souffrance",
          "teleservice": "https://ecouteetudiants-iledefrance.fr/home",
          "prefix": "l’",
          "type": "float",
          "unit": "séances",
          "periodicite": "autre",
          "legend": "maximum",
          "montant": 3
        },
        {
          "slug": "region-ile-de-france-forfait-gratuite-jeunes-en-insertion",
          "label": "forfait gratuité Jeunes en insertion",
          "institution": "region_ile_de_france",
          "description": "Ce forfait permet à certains jeunes stagiaires de la formation professionnelle continue de voyager gratuitement sur tous les modes de transport franciliens, pendant toute la durée de leur formation. Il est valable sur tous les modes de transport (métro, bus, train, RER, tram express, tram), à l’exception de certaines dessertes directes d’aéroports et des bus touristiques.",
          "conditions": [
            "Être stagiaire de la formation professionnelle continue dans l’un des dispositifs suivants du service public régional de formation et d’insertion professionnelle : Avenir Jeunes - Programme Compétences - École de la Deuxième chance."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 16
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            },
            {
              "type": "regions",
              "values": [
                "11"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.iledefrance-mobilites.fr/titres-et-tarifs/detail/le-forfait-gratuite-jeunes-en-insertion",
          "instructions": "https://www.iledefrance-mobilites.fr/titres-et-tarifs/detail/le-forfait-gratuite-jeunes-en-insertion",
          "prefix": "le",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-ile-de-france-forfait-navigo-jeunes-weekend",
          "label": "forfait Navigo Jeunes Weekend",
          "institution": "region_ile_de_france",
          "description": "Le forfait Navigo Jeunes Weekend est réservé aux usager·e·s de moins de 26 ans, valable sur une journée, le samedi, le dimanche ou un jour férié. Il est utilisable sur l’ensemble du réseau de la région Île-de-France, sauf Orlyval, Fileo, le TGV, et les lignes qui n'appliquent pas la tarification francilienne. Il permet également de bénéficier <a target=\"_blank\" title=\"avantages culture - Nouvelle fenêtre\" rel=\"noopener\" href=\" https://www.iledefrance-mobilites.fr/navigo-avantages-culture\">d'avantages culture</a>.",
          "conditions": [
            "Acquérir le forfait Navigo Jeunes Weekend pour le prix journalier de 4,10€ sur les zones 1-3, 8,95€ sur les zones 1-5 et 5,25€ sur les zones 3-5, et le charger sur un Passe Navigo ou l'Application Île-de-France Mobilités."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": "<=",
              "value": 26
            },
            {
              "type": "regions",
              "values": [
                "11"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.iledefrance-mobilites.fr/titres-et-tarifs/detail/navigo-jeunes-week-end#comment-l-obtenir",
          "instructions": "https://www.iledefrance-mobilites.fr/titres-et-tarifs/detail/navigo-jeunes-week-end#comment-l-obtenir",
          "prefix": "le",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-ile-de-france-reduction-50-volontaires-du-service-civique",
          "label": "réduction 50 % Volontaires du Service civique",
          "institution": "region_ile_de_france",
          "description": "Grâce à cette aide, les volontaires du Service Civique et du Corps Européen de Solidarité peuvent bénéficier d’une réduction de 50 % sur leur abonnement Navigo mensuel et hebdomadaire, ainsi que sur les carnets de tickets et les billets origine-destination.",
          "conditions": [
            "Avoir signé un contrat d’engagement avec l’Agence Service Civique pour une mission se déroulant en Île-de-France."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "11"
              ]
            }
          ],
          "profils": [
            {
              "type": "service_civique",
              "conditions": []
            }
          ],
          "link": "https://www.iledefrance-mobilites.fr/titres-et-tarifs/detail/reduction-50-pourcents-volontaires-service-civique",
          "instructions": "https://www.iledefrance-mobilites.fr/titres-et-tarifs/detail/reduction-50-pourcents-volontaires-service-civique",
          "prefix": "la",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-ile-de-france-revenu-jeunes-actifs",
          "label": "revenu Jeunes Actifs",
          "institution": "region_ile_de_france",
          "description": "La Région Île-de-France vous permet d'intégrer l’une de ses formations gratuites et qualifiantes dans l’un des 11 secteurs en tension et rémunérées jusqu'à 4.000 euros pour 6 mois, porté à 5.000 euros pour un jeune en situation de handicap.",
          "conditions": [
            "Effectuer une formation gratuite que vous choisissez dans le <a target=\"_blank\" title=\"Catalogue en ligne - Nouvelle fenêtre\" rel=\"noopener\" href=\"https://formpro.oriane.info/\">catalogue en ligne</a>."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "11"
              ]
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 18
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            }
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            },
            {
              "type": "apprenti",
              "conditions": []
            }
          ],
          "link": "https://www.iledefrance.fr/revenu-jeunes-actifs",
          "instructions": "https://www.iledefrance.fr/revenu-jeunes-actifs",
          "prefix": "le",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 4000
        },
        {
          "slug": "region-ile-de-france-vehicules-propres",
          "label": "Dispositif d'aide aux véhicules propres (électriques ou à hydrogène)",
          "institution": "region_ile_de_france",
          "description": "La Région facilite le remplacement des véhicules thermiques des particuliers franciliens par des véhicules électriques ou à hydrogène.",
          "conditions": [
            "Vérifier que vous correspondez aux <a target=\"_blank\" rel=\"noopener\" title=\"critères d'éligibilité - Nouvelle fenêtre\" href=\" https://www.iledefrance.fr/remplacement-des-voitures-des-particuliers-par-des-vehicules-propres\">critères d'éligibilité</a>.",
            "Déposer votre demande au plus tard dans les 3 mois suivant l’achat du véhicule propre et la destruction de l'ancien."
          ],
          "conditions_generales": [
            {
              "type": "departements",
              "values": [
                "77",
                "78",
                "91",
                "95"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.iledefrance.fr/remplacement-des-voitures-des-particuliers-par-des-vehicules-propres",
          "teleservice": "https://mesdemarches.iledefrance.fr/account-management/cridfprd-demandeurs/ux/#/login?redirectTo=https:%2F%2Fmesdemarches.iledefrance.fr%2Faides%2F%23%2Fcridfprd%2Fconnecte%2Fdashboard%2Faccueil&jwtKey=jwt-cridfprd-portail-depot-demande-aides&footer=https:%2F%2Fmesdemarches.iledefrance.fr%2Faides%2F%23%2Fcridfprd%2Fmentions-legales,Mentions%20l%C3%A9gales%20et%20RGPD,_self",
          "instructions": "https://www.iledefrance.fr/remplacement-des-voitures-des-particuliers-par-des-vehicules-propres",
          "prefix": "le",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 6000
        },
        {
          "slug": "region-la-reunion-etudier-au-quebec",
          "label": "étudier au Québec",
          "institution": "region_la_reunion",
          "description": "La Région propose une aide financière aux étudiant·e·s souhaitant partir étudier au Québec.",
          "prefix": "l’aide",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "04"
              ]
            },
            {
              "type": "quotient_familial",
              "floor": 26631,
              "period": "year"
            }
          ],
          "profils": [
            {
              "type": "lyceen",
              "conditions": [
                {
                  "type": "annee_etude",
                  "values": [
                    "terminale"
                  ]
                }
              ]
            },
            {
              "type": "enseignement_superieur",
              "conditions": []
            },
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "conditions": [
            "Choisir une formation et un centre de formation éligibles au dispositif Québec."
          ],
          "interestFlag": "_interetEtudesEtranger",
          "type": "bool",
          "unit": "€",
          "periodicite": "ponctuelle",
          "link": "https://regionreunion.com/aides-services/article/etudier-et-vivre-au-quebec-t-interesse",
          "form": "https://regionreunion.com/IMG/pdf/dossier-ams-2022-2025-octobre-2021.pdf",
          "instructions": "https://regionreunion.com/aides-services/article/etudier-et-vivre-au-quebec-t-interesse"
        },
        {
          "slug": "region-normandie-abonnement-tempo-26-ans",
          "participation": {
            "type": "Variable",
            "legende": "Payer l'abonnement dont le prix est calculé en fonction du trajet fréquent choisi."
          },
          "label": "abonnement Tempo - 26 ans",
          "institution": "region_normandie",
          "description": "La carte Tempo vous permet, ainsi qu'à 4 accompagnants, de bénéficier de 50% de réduction maximum sur vos trajets en Normandie ou vers Paris. ",
          "conditions": [
            "Choisir un trajet fréquent entre deux gares normandes.",
            "Souscrire à l'abonnement annuel ou mensuel dont le prix dépend du trajet fréquent choisi."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": "<=",
              "value": 26
            },
            {
              "type": "regions",
              "values": [
                "28"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.ter.sncf.com/normandie/offres/tempo-nomad/abonnement-normandie-moins26",
          "teleservice": "https://www.sncf-abo-annuel-ter.com/tapas-tel-web/devisAbonnement?codeRegion=NHO",
          "instructions": "https://www.ter.sncf.com/normandie/offres/tempo-nomad/abonnement-normandie-moins26",
          "prefix": "l’",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-normandie-atouts-normandie-aide-bafa-bafd-bnssa-1er-secours-et-chantiers-de-bénévoles",
          "label": "Atouts Normandie - Aide au BAFA, BAFD, BNSSA 1er Secours et chantiers de bénévoles",
          "imgSrc": "img/benefits/logo-atouts.jpg",
          "institution": "region_normandie",
          "description": "La Région Normandie soutient l'engagement des jeunes normand·e·s âgé·e·s de 15 à 25 ans dans la réalisation d'une formation au titre du BAFA, BAFD, BNSSA, d'un 1er secours ou d'une participation à un chantier de bénévoles organisé en Normandie, en France ou à l’international.",
          "conditions": [
            "Créer un compte sur <a target=\"_blank\" rel=\"noopener\" title=\"Atouts Normandie - Nouvelle fenêtre\" href=\"https://atouts.normandie.fr/\">cette page</a>.",
            "Adhérer pour 10€ à Atouts Normandie."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "28"
              ]
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 15
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            }
          ],
          "profils": [],
          "link": "https://atouts.normandie.fr/",
          "teleservice": "https://atouts.normandie.fr/",
          "prefix": "l’aide",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 40,
          "interestFlag": "_interetBafa"
        },
        {
          "slug": "region-normandie-dispositif-avenir",
          "label": "dispositif #Avenir",
          "institution": "region_normandie",
          "description": "La Région finance une formation permettant d’effectuer un état des lieux de votre parcours personnel et professionnel.",
          "conditions": [
            "Être en démarche d’orientation, de reconversion professionnelle, de renforcement de vos compétences ou à la recherche d’un accompagnement dans la mise en œuvre d'un projet de retour à l’emploi."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "28"
              ]
            }
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "link": "https://www.normandie.fr/avenir",
          "teleservice": "https://monespace-aides.normandie.fr/account-management/crno-demandeurs/ux/#/login?redirectTo=https:%2F%2Fmonespace-aides.normandie.fr%2Faides%2F%23%2Fcrno%2Fconnecte%2FF_NTEL009FORM%2Fdepot%2Fsimple&jwtKey=jwt-crno-portail-depot-demande-aides&footer=https:%2F%2Fmonespace-aides.normandie.fr%2Faides%2F%23%2Fcrno%2Fmentions-legales,Mentions%20l%C3%A9gales,_self;https:%2F%2Faides.normandie.fr%2Fbesoin-d-aide,Nous%20contacter,_blank",
          "instructions": "",
          "prefix": "le",
          "type": "bool",
          "unit": "€",
          "periodicite": "ponctuelle"
        },
        {
          "slug": "region-normandie-pass-monde-etudes-secondaires",
          "label": "pass Monde Etudes secondaires",
          "imgSrc": "img/benefits/logo-pass-monde.jpg",
          "institution": "region_normandie",
          "description": "L’aide Pass Monde accompagne la mobilité individuelle des normand·e·s inscrit·e·s dans un cursus d’étude du secondaire en formation initiale ou continue (lycéen·ne·s, apprenti·e·s, jeunes en contrats de professionnalisation et stagiaires de la formation professionnelle).",
          "conditions": [
            "Être inscrit·e dans un établissement normand ou dans une autre région de France si la filière ou le type de cursus n'existe pas en Normandie.",
            "Effectuer une mobilité d'un minimum de 4 semaines (financement jusqu'à 26 semaines maximum).",
            "Déposer la demande avant le départ en mobilité.",
            "Présenter un projet qui se déroule en Europe ou hors Europe (DOM TOM non éligibles)."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 15
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 30
            },
            {
              "type": "regions",
              "values": [
                "28"
              ]
            },
            {
              "type": "quotient_familial",
              "floor": 30000,
              "period": "year"
            }
          ],
          "profils": [
            {
              "type": "lyceen"
            },
            {
              "type": "apprenti"
            },
            {
              "type": "professionnalisation"
            },
            {
              "type": "stagiaire",
              "conditions": []
            }
          ],
          "link": "https://www.normandie.fr/pass-monde-etudes-secondaires",
          "teleservice": "https://atouts.normandie.fr/beneficiaires/Views/Accueil.aspx",
          "instructions": "",
          "prefix": "le",
          "type": "float",
          "unit": "€",
          "periodicite": "autre",
          "legend": "Maximum",
          "montant": 880,
          "interestFlag": "_interetEtudesEtranger"
        },
        {
          "slug": "region-normandie-programme-savoirs",
          "label": "programme « Savoirs »",
          "institution": "region_normandie",
          "description": "La Région propose une offre de formation visant à développer et renforcer les compétences des personnes en recherche d’emploi.",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "28"
              ]
            }
          ],
          "conditions": [
            "Choisir un des modules dans la <a target=\"_blank\" rel=\"noopener\" title=\"liste - Nouvelle fenêtre\" href=\"https://parcours-metier.normandie.fr/ftlv-dispositif-socle\">liste</a>.",
            "Faire la demande auprès de votre conseiller·e Pôle Emploi, Mission Locale, Cap Emploi, PLIE ou votre référent.e social.e pour être orienté·e vers un organisme de formation prestataire."
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "link": "https://parcours-metier.normandie.fr/ftlv-dispositif-socle",
          "instructions": "https://parcours-metier.normandie.fr/ftlv-dispositif-socle",
          "prefix": "le",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-normandie-qualif-collectif",
          "label": "qUALIF collectif",
          "institution": "region_normandie",
          "description": "La Région vous propose de suivre une action de formation qualifiante et d’obtenir un diplôme ou un titre professionnel.",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "28"
              ]
            }
          ],
          "conditions": [
            "Contacter l’organisme de formation ou solliciter votre conseiller·e en évolution professionnelle (Missions locales, Pôle emploi et Cap emploi).",
            "Être sorti·e de formation initiale (statut scolaire et apprentissage) depuis plus de 9 mois."
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "link": "https://parcours-metier.normandie.fr/ftlv-qualif-collectif",
          "instructions": "https://parcours-metier.normandie.fr/ftlv-qualif-collectif",
          "prefix": "le",
          "type": "bool",
          "unit": "€",
          "periodicite": "ponctuelle"
        },
        {
          "slug": "region-normandie-une-formation-un-emploi",
          "label": "une Formation, un Emploi",
          "institution": "region_normandie",
          "description": "La Région finance la formation en alternance des personnes en recherche d'emploi qui seront embauché·e·s par la suite en entreprise pour une durée minimum de 6 mois. Elle assure également la rémunération des stagiaires non indemnisé·e·s par Pôle Emploi.",
          "prefix": "l’aide",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "28"
              ]
            }
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "conditions": [
            "Être sorti·e de formation initiale (statut scolaire et apprentissage) depuis plus de 9 mois.",
            "Avoir trouvé une entreprise et remplir <a target=\"_blank\" rel=\"noopener\" title=\"formulaire - Nouvelle fenêtre\" href=\"https://parcours-metier.normandie.fr/sites/default/files/2018-11/Formulaire%20Entreprise%20-%20UF%20UE-%202018-interactifv2.pdf\">ce formulaire</a> avec elle."
          ],
          "type": "bool",
          "unit": "€",
          "periodicite": "autre",
          "link": "https://parcours-metier.normandie.fr/ftlv-dispositif-une-formation-un-emploi",
          "instructions": "https://parcours-metier.normandie.fr/ftlv-dispositif-une-formation-un-emploi"
        },
        {
          "slug": "region-normandie-vae",
          "label": "accompagnement à la VAE (Validation des Acquis de l'Expérience)",
          "institution": "region_normandie",
          "description": "La Région soutient les candidat·e·s en assurant la prise en charge de l’accompagnement méthodologique à l’écriture du dossier et la préparation à l’entretien.",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "28"
              ]
            }
          ],
          "conditions": [],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "link": "https://www.normandie.fr/dispositif-daccompagnement-la-validation-des-acquis-de-lexperience",
          "teleservice": "https://monespace-aides.normandie.fr/account-management/crno-demandeurs/ux/#/login?redirectTo=https:%2F%2Fmonespace-aides.normandie.fr%2Faides%2F%23%2Fcrno%2Fconnecte%2FF_NTEL002ORIEN%2Fdepot%2Fsimple&jwtKey=jwt-crno-portail-depot-demande-aides&footer=https:%2F%2Fmonespace-aides.normandie.fr%2Faides%2F%23%2Fcrno%2Fmentions-legales,Mentions%20l%C3%A9gales,_self;https:%2F%2Faides.normandie.fr%2Fbesoin-d-aide,Nous%20contacter,_blank",
          "prefix": "l’",
          "type": "bool",
          "unit": "€",
          "periodicite": "ponctuelle"
        },
        {
          "slug": "region-nouvelle-aquitaine-accompagnement-vae",
          "label": "Accompagnement à la VAE (Validation des Acquis de l'Expérience)",
          "institution": "region_nouvelle_aquitaine",
          "description": "La Région soutient les démarches VAE en finançant l’accompagnement réalisé par des organismes habilités.",
          "prefix": "l’",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "75"
              ]
            }
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "conditions": [
            "Justifier d’au moins un an d’expérience en lien avec le diplôme visé.",
            "Avoir rencontré le Point Régional Conseil VAE (05 57 57 55 66)."
          ],
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 2000,
          "link": "https://les-aides.nouvelle-aquitaine.fr/economie-et-emploi/vae-accompagnement-de-la-validation-des-acquis-de-lexperience",
          "instructions": "https://les-aides.nouvelle-aquitaine.fr/economie-et-emploi/vae-accompagnement-de-la-validation-des-acquis-de-lexperience"
        },
        {
          "slug": "region-nouvelle-aquitaine-aide-à-la-restauration-des-lycéen·ne·s",
          "label": "aide à la restauration des lycéen·ne·s",
          "institution": "region_nouvelle_aquitaine",
          "description": "La Région propose un tarif réduit pour la restauration des lycéen·ne·s demi-pensionnaires ou internes inscrit·e·s dans un lycée public ou EREA.",
          "prefix": "l’",
          "conditions_generales": [
            {
              "type": "departements",
              "values": [
                "24",
                "33",
                "40",
                "47",
                "64"
              ]
            }
          ],
          "profils": [
            {
              "type": "lyceen",
              "conditions": []
            }
          ],
          "conditions": [
            "Bénéficier de l'Allocation de Rentrée Scolaire."
          ],
          "type": "bool",
          "unit": "€",
          "periodicite": "annuelle",
          "link": "https://les-aides.nouvelle-aquitaine.fr/jeunesse/aide-la-restauration-pour-les-lyceens",
          "instructions": "https://les-aides.nouvelle-aquitaine.fr/jeunesse/aide-la-restauration-pour-les-lyceens"
        },
        {
          "slug": "region-nouvelle-aquitaine-billet-jeunes-28ans",
          "label": "billet jeunes -28ans",
          "institution": "region_nouvelle_aquitaine",
          "description": "Grâce au Billet Jeunes, voyagez en Nouvelle-Aquitaine à partir de 4€. Le billet jeunes est nominatif, utilisable pour un seul trajet et une date déterminée. Il vous permet de voyager sur toute la Nouvelle Aquitaine et vers les régions Centre Val-de-Loire, Pays de la Loire, Occitanie, Auvergne Rhône Alpes.",
          "conditions": [
            "Choisir un trajet en Nouvelle-Aquitaine.",
            "Payer le Billet Jeune dont le prix est calculé en fonction du trajet."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "75"
              ]
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 28
            }
          ],
          "profils": [],
          "link": "https://www.ter.sncf.com/nouvelle-aquitaine/offres/cartes-abonnements/billet-jeunes-nouvelle-aquitaine",
          "instructions": "https://www.ter.sncf.com/nouvelle-aquitaine/offres/cartes-abonnements/billet-jeunes-nouvelle-aquitaine",
          "prefix": "l’aide",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-nouvelle-aquitaine-brevets-bafa-bafd-bnssa",
          "label": "aides aux brevets : BAFA, BAFD, BNSSA",
          "institution": "region_nouvelle_aquitaine",
          "description": "La Région prend en charge une partie des Brevets d’Aptitude aux Fonctions d’Animateur·ice et de Directeur·ice (BAFA et BAFD) et du Brevet national de Sécurité et de Sauveteur·euse aquatique (BNSSA).",
          "prefix": "l’",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "75"
              ]
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 30
            }
          ],
          "profils": [],
          "conditions": [
            "Vérifier que le revenu fiscal de référence de votre foyer est inférieur à 9 700 € par part fiscale."
          ],
          "interestFlag": "_interetBafa",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 400,
          "link": "https://les-aides.nouvelle-aquitaine.fr/economie-et-emploi/aides-aux-brevets-bafa-bafd-bnssa",
          "teleservice": "https://mes-demarches.nouvelle-aquitaine.fr/craPortailFO/",
          "instructions": "https://les-aides.nouvelle-aquitaine.fr/economie-et-emploi/aides-aux-brevets-bafa-bafd-bnssa"
        },
        {
          "slug": "region-nouvelle-aquitaine-erasmus-stage-infra-bac",
          "label": "Erasmus+ Stage",
          "institution": "region_nouvelle_aquitaine",
          "description": "La Région finance en partie la réalisation d'un stage professionnel à l'étranger d'une durée de 2 semaines à 6 mois.",
          "prefix": "l’aide",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "75"
              ]
            }
          ],
          "profils": [
            {
              "type": "apprenti",
              "conditions": []
            }
          ],
          "conditions": [
            "Être inscrit·e en apprentissage niveau CAP, BEP ou baccalauréat dans un établissement d’enseignement et de formation professionnelle faisant partie du Consortium régional."
          ],
          "interestFlag": "_interetEtudesEtranger",
          "type": "bool",
          "unit": "€",
          "periodicite": "ponctuelle",
          "link": "https://les-aides.nouvelle-aquitaine.fr/amenagement-du-territoire/erasmus-stage-public-infra-bac",
          "teleservice": "https://cas.aquitaine.fr/cas/login?service=https%3A%2F%2Fmes-demarches.nouvelle-aquitaine.fr%2FcraPortailFO%2Flogin%2Fcas"
        },
        {
          "slug": "region-nouvelle-aquitaine-erasmus-stage",
          "label": "erasmus+ Stage",
          "institution": "region_nouvelle_aquitaine",
          "description": "La Région finance en partie la réalisation d'un stage professionnel à l'étranger d'une durée de 2 à 12 mois.",
          "prefix": "l’aide",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "75"
              ]
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": []
            }
          ],
          "conditions": [
            "Étudier dans un établissement faisant partie du Consortium régional (<a target=\"_blank\" title=\"Liste des établissements - Nouvelle fenêtre\" rel=\"noopener\" href=\"https://les-aides.nouvelle-aquitaine.fr/amenagement-du-territoire/erasmus-stage-public-post-bac-2020-2022\">voir liste</a>).",
            "Faire valider son dossier par l'établissement avant la date de début du séjour."
          ],
          "interestFlag": "_interetEtudesEtranger",
          "type": "float",
          "unit": "€",
          "periodicite": "mensuelle",
          "legend": "maximum",
          "montant": 520,
          "link": "https://les-aides.nouvelle-aquitaine.fr/amenagement-du-territoire/erasmus-stage-public-post-bac-2020-2022",
          "teleservice": "https://cas.aquitaine.fr/cas/login?service=https%3A%2F%2Fmes-demarches.nouvelle-aquitaine.fr%2FcraPortailFO%2Flogin%2Fcas"
        },
        {
          "slug": "region-nouvelle-aquitaine-fonds-aide-mobilite-emploi",
          "label": "fonds d'aide à la mobilité vers l'emploi",
          "institution": "region_nouvelle_aquitaine",
          "description": "La Région vous soutient financièrement dans votre projet de mobilité en participant à vos frais de logement et/ou de transport.",
          "prefix": "le",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "75"
              ]
            }
          ],
          "profils": [
            {
              "type": "salarie",
              "conditions": []
            }
          ],
          "conditions": [
            "Être sorti·e de formation depuis moins de 6 mois."
          ],
          "type": "float",
          "unit": "€",
          "periodicite": "annuelle",
          "legend": "maximum",
          "montant": 2000,
          "link": "https://les-aides.nouvelle-aquitaine.fr/amenagement-du-territoire/fonds-daide-la-mobilite-vers-lemploi",
          "teleservice": "https://mes-demarches.nouvelle-aquitaine.fr/craPortailFO/externe/creationDossier.do?codeDispositif=FPC11-20"
        },
        {
          "slug": "region-nouvelle-aquitaine-fonds-social-formation",
          "label": "Fonds social formation",
          "institution": "region_nouvelle_aquitaine",
          "description": "La Région vous aide à financer votre parcours de formation dans le cas où, face à un imprévu, vous ne parveniez plus à régler vos dépenses de logement et/ou de transport.",
          "prefix": "le",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "75"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            },
            {
              "type": "stagiaire",
              "conditions": []
            },
            {
              "type": "apprenti",
              "conditions": []
            },
            {
              "type": "etudiant",
              "conditions": []
            }
          ],
          "conditions": [
            "Compléter <a target=\"_blank\" rel=\"noopener\" title=\"attestation - Nouvelle fenêtre\" href=\"https://les-aides.nouvelle-aquitaine.fr/sites/default/files/2021-05/Attestation_Structure-Formation_FSF.pdf\">l'attestation</a> avec votre référent·e de formation."
          ],
          "type": "float",
          "unit": "€",
          "periodicite": "annuelle",
          "legend": "maximum",
          "montant": 1000,
          "link": "https://les-aides.nouvelle-aquitaine.fr/amenagement-du-territoire/fonds-social-formation",
          "teleservice": "https://cas.aquitaine.fr/cas/login?service=https%3A%2F%2Fmes-demarches.nouvelle-aquitaine.fr%2FcraPortailFO%2Flogin%2Fcas",
          "instructions": "https://les-aides.nouvelle-aquitaine.fr/amenagement-du-territoire/fonds-social-formation"
        },
        {
          "slug": "region-nouvelle-aquitaine-pass-abonne-28-ans",
          "label": "pass abonné -28 ans",
          "institution": "region_nouvelle_aquitaine",
          "description": "Grâce au Pass Abonné -28 (hebdo, mensuel ou annuel), vous bénéficiez d'un pourcentage de réduction de 33 % par rapport à un Pass abonné Tout Public, sur vos déplacements quotidiens sur un parcours déterminé en Nouvelle-Aquitaine.\n",
          "conditions": [
            "Choisir un trajet fréquent en Nouvelle-Aquitaine et vers les régions Centre Val-de-Loire, Pays de la Loire, Occitanie, Auvergne Rhône Alpes.",
            "Payer le pass dont le prix est calculé en fonction du trajet fréquent choisi."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": "<=",
              "value": 28
            },
            {
              "type": "regions",
              "values": [
                "75"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.ter.sncf.com/nouvelle-aquitaine/offres/cartes-abonnements/pass-abonne-moins-28",
          "teleservice": "https://www.sncf-abo-annuel-ter.com/tapas-tel-web/?codeRegion=AQU",
          "prefix": "le",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-nouvelle-aquitaine-sas-jeunes-orientation-active-vers-emploi",
          "label": "Sas jeunes : orientation active vers l'emploi",
          "institution": "region_nouvelle_aquitaine",
          "description": "La Région propose un accompagnement individualisé pour construire son parcours vers un emploi.",
          "prefix": "le",
          "conditions_generales": [
            {
              "type": "departements",
              "values": [
                "19",
                "23",
                "87"
              ]
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 17
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            }
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "conditions": [
            "Être accompagné·e par une Mission Locale du Limousin et effectuer votre demande auprès d'eux."
          ],
          "type": "bool",
          "unit": "€",
          "periodicite": "ponctuelle",
          "link": "https://les-aides.nouvelle-aquitaine.fr/economie-et-emploi/sas-jeunes-orientation-active-vers-lemploi",
          "instructions": "https://les-aides.nouvelle-aquitaine.fr/economie-et-emploi/sas-jeunes-orientation-active-vers-lemploi"
        },
        {
          "slug": "region-nouvelle-aquitaine-sejour-etudes-etranger",
          "label": "Séjour d'étude à l'étranger",
          "institution": "region_nouvelle_aquitaine",
          "description": "La Région finance en partie la réalisation d'un séjour d'études à l'étranger d'une durée minimale de 8 semaines.",
          "prefix": "les",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "75"
              ]
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": []
            },
            {
              "type": "apprenti",
              "conditions": []
            },
            {
              "type": "stagiaire",
              "conditions": []
            }
          ],
          "conditions": [
            "Être inscrit·e dans un établissement de la Région et réaliser un séjour d'études à l’étranger dans le cadre de votre cursus en formation initiale ou continue."
          ],
          "interestFlag": "_interetEtudesEtranger",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 2900,
          "link": "https://les-aides.nouvelle-aquitaine.fr/amenagement-du-territoire/sejours-detude-letranger-public-post-bac",
          "teleservice": "https://mes-demarches.nouvelle-aquitaine.fr/craPortailFO/externe/creationDossier.do?codeDispositif=MI_VOLET3"
        },
        {
          "slug": "region-nouvelle-aquitaine-soutien-scolaire-service-gratuit-aide-devoirs",
          "label": "soutien scolaire : service gratuit d'aide aux devoirs",
          "institution": "region_nouvelle_aquitaine",
          "description": "La Région propose un soutien scolaire personnalisé par téléphone pour les matières suivantes : français, anglais, espagnol, mathématiques et physique.",
          "prefix": "le",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "75"
              ]
            }
          ],
          "profils": [
            {
              "type": "lyceen",
              "conditions": []
            },
            {
              "type": "apprenti",
              "conditions": []
            }
          ],
          "conditions": [
            "Appeler le 05 57 57 50 00 du lundi au jeudi de 18h à 21h."
          ],
          "type": "bool",
          "unit": "€",
          "periodicite": "autre",
          "link": "https://les-aides.nouvelle-aquitaine.fr/economie-et-emploi/soutien-scolaire-service-gratuit-daide-aux-devoirs",
          "instructions": "https://les-aides.nouvelle-aquitaine.fr/economie-et-emploi/soutien-scolaire-service-gratuit-daide-aux-devoirs"
        },
        {
          "slug": "region-nouvelle-aquitaine-stage-etranger",
          "label": "stage à l'étranger (post-bac)",
          "institution": "region_nouvelle_aquitaine",
          "description": "La Région finance une partie de votre stage à l'étranger de 2 semaines minimum.",
          "prefix": "les",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "75"
              ]
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": []
            },
            {
              "type": "apprenti",
              "conditions": []
            },
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "conditions": [
            "Ne pas bénéficier, pour le même stage, d’une autre aide à la mobilité de la Région ou d’une indemnisation mensuelle de stage supérieure à 700 €.",
            "Ne pas dépasser le plafond de revenus fixé à 50 000 €."
          ],
          "interestFlag": "_interetEtudesEtranger",
          "type": "float",
          "unit": "€",
          "periodicite": "mensuelle",
          "legend": "maximum",
          "montant": 400,
          "link": "https://les-aides.nouvelle-aquitaine.fr/amenagement-du-territoire/stages-letranger-public-post-bac",
          "teleservice": "https://cas.aquitaine.fr/cas/login?service=https%3A%2F%2Fmes-demarches.nouvelle-aquitaine.fr%2FcraPortailFO%2Flogin%2Fcas",
          "instructions": "https://les-aides.nouvelle-aquitaine.fr/amenagement-du-territoire/stages-letranger-public-post-bac"
        },
        {
          "slug": "region-occitanie-aide-au-merite-pour-les-etudiants-en-formations-sanitaires-et-sociales",
          "label": "aide au mérite pour les étudiants en formations sanitaires et sociales",
          "institution": "region_occitanie",
          "description": "Cette aide au mérite d’un montant de 900 € est versée aux étudiants et étudiantes boursiers du supérieur du secteur sanitaire et social, titulaires d’un baccalauréat mention très bien.",
          "conditions": [
            "Être titulaire d’une mention « très bien » à la dernière session du baccalauréat français.",
            "Être inscrit·e dans une formation sanitaire et sociale ouvrant droit à bourse.",
            "Faire sa demande avant le 31 octobre de l'année scolaire en cours."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "76"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": [
                {
                  "type": "boursier"
                }
              ]
            }
          ],
          "link": "https://www.laregion.fr/Aide-au-merite",
          "teleservice": "https://www.laregion.fr/Aide-au-merite",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "autre",
          "montant": 900,
          "interestFlag": "_interetAidesSanitaireSocial"
        },
        {
          "slug": "region-occitanie-bourse-de-stage-erasmus-formations-sanitaires-sociales",
          "label": "bourse de stage ERASMUS+ formations sanitaire et sociale",
          "institution": "region_occitanie",
          "description": "La Région favorise la mobilité de stage des étudiant·e·s de la filière Sanitaire et Sociale.",
          "prefix": "la",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "76"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [],
          "conditions": [
            "Être inscrit·e dans l’<a target=\"_blank\" rel=\"noopener\" title=\"établissements (catégorie \"bénéficiaires\") - Nouvelle fenêtre\" href=\"https://www.laregion.fr/Dispositif-de-bourse-de-stage-ERASMUS\">un des 20 établissements (catégorie \"bénéficiaires\")</a>.",
            "Effectuer un stage entre 2 et 12 mois à temps plein dans une structure située dans un pays éligible au programme ERASMUS+."
          ],
          "interestFlag": "_interetEtudesEtranger",
          "type": "float",
          "unit": "€",
          "periodicite": "mensuelle",
          "legend": "maximum",
          "montant": 450,
          "link": "https://www.laregion.fr/Dispositif-de-bourse-de-stage-ERASMUS",
          "instructions": "https://www.laregion.fr/Dispositif-de-bourse-de-stage-ERASMUS"
        },
        {
          "slug": "region-occitanie-financement-formations-sanitaires-sociales-niveaux-v-iv",
          "label": "financement des formations sanitaires et sociales de niveaux V et IV",
          "institution": "region_occitanie",
          "description": "La Région Occitanie finance gratuitement les formations sanitaires et sociales de niveaux V et IV pour les élèves inscrit·e·s en formation d'aide-soignant·e, auxiliaire de puériculture, ambulancier·e, accompagnant·e éducatif·ve et social·e, moniteur·rice éducateur·rice ou technicien·ne en intervention sociale et familiale.",
          "conditions": [
            "Être inscrit·e dans un établissement de formation agréé par la Région Occitanie.",
            "Être en poursuite de scolarité ou inscrit·e comme demandeur·euse d’emploi avant la date d’entrée en formation."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "76"
              ]
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": []
            },
            {
              "type": "chomeur",
              "conditions": []
            },
            {
              "type": "lyceen",
              "conditions": [
                {
                  "type": "annee_etude",
                  "values": [
                    "terminale"
                  ]
                }
              ]
            }
          ],
          "link": "https://www.laregion.fr/Financement-des-formations-sanitaires-et-sociales-de-niveaux-V-et-IV",
          "form": "",
          "instructions": "https://www.laregion.fr/Financement-des-formations-sanitaires-et-sociales-de-niveaux-V-et-IV",
          "prefix": "le",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-occitanie-frequencio-jeunes",
          "label": "FréquenciO' Jeunes",
          "institution": "region_occitanie",
          "description": "FréquenciO' Jeunes vous permet de bénéficier d’un nombre illimité de déplacements en liO TER sur le trajet de votre choix en Occitanie.",
          "conditions": [
            "Choisir un trajet fréquent entre deux gares d'Occitanie."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": "<",
              "value": 26
            },
            {
              "type": "regions",
              "values": [
                "76"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.ter.sncf.com/occitanie/offres/tarifs-occitanie/frequent-jeunes",
          "instructions": "https://cdn.ter.sncf.com/medias/PDF/occitanie/BROCHURE-FREQUENCIO-TOUT-PUBLIC_tcm76-136443_tcm76-200824.pdf",
          "prefix": "l’aide",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-occitanie-libertio-jeunes",
          "label": "libertiO' Jeunes",
          "institution": "region_occitanie",
          "description": "Jusqu'à 26 ans inclus, voyagez à -50% tout le temps et partout en Occitanie avec liO Train sur simple justificatif de votre âge.",
          "conditions_generales": [
            {
              "type": "age",
              "operator": "<=",
              "value": 26
            },
            {
              "type": "regions",
              "values": [
                "76"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.ter.sncf.com/occitanie/offres/tarifs-occitanie/jeunes",
          "instructions": "https://www.ter.sncf.com/occitanie/offres/tarifs-occitanie/jeunes",
          "prefix": "l’aide",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-occitanie-mobilite-internationale-etudiant·e·s",
          "label": "aides à la mobilité internationale des étudiant·e·s",
          "institution": "region_occitanie",
          "description": "La Région soutient financièrement les étudiant·e·s qui effectuent une mobilité à l’étranger (stage ou séjour d'études) dans le cadre de leur cursus de formation au sein d’<a target=\"_blank\" rel=\"noopener\" title=\"établissements partenaires - Nouvelle fenêtre\" href=\"https://www.laregion.fr/Aides-mobilite-etudiants\">un des établissements partenaires</a>.",
          "prefix": "les",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "76"
              ]
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": []
            }
          ],
          "conditions": [
            "Effectuer votre stage ou séjour d’études dans le cadre de votre cursus de formation et entrer dans l'année d'obtention de votre diplôme."
          ],
          "interestFlag": "_interetEtudesEtranger",
          "type": "float",
          "unit": "€",
          "periodicite": "mensuelle",
          "legend": "",
          "montant": 300,
          "link": "https://www.laregion.fr/Aides-mobilite-etudiants",
          "instructions": "https://www.laregion.fr/Aides-mobilite-etudiants"
        },
        {
          "slug": "region-occitanie-pass-mutuelle-etudiant·e",
          "label": "pass Mutuelle Etudiant·e",
          "institution": "region_occitanie",
          "description": "La Région propose une aide spécifique pour favoriser l’accès aux soins des étudiant·e·s n'étant pas déjà couvert·e·s par une mutuelle.",
          "prefix": "le",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "76"
              ]
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": [
                {
                  "type": "boursier"
                }
              ]
            }
          ],
          "conditions": [
            "Ne pas être éligible à la Complémentaire Santé Solidaire.",
            "Adhérer à l'<a target=\"_blank\" rel=\"noopener\" title=\"mutuelles partenaires - Nouvelle fenêtre\" href=\"https://www.laregion.fr/Pass-Mutuelle-Etudiant-e\">une des mutuelles partenaires (catégorie \"Conditions d'éligibilité\")</a>."
          ],
          "type": "float",
          "unit": "€",
          "periodicite": "annuelle",
          "legend": "maximum",
          "montant": 100,
          "link": "https://www.laregion.fr/Pass-Mutuelle-Etudiant-e",
          "instructions": "https://www.laregion.fr/Pass-Mutuelle-Etudiant-e"
        },
        {
          "slug": "region-occitanie-remuneration-stagiaires-formation-professionnelle",
          "label": "rémunération des stagiaires de la formation professionnelle",
          "institution": "region_occitanie",
          "description": "La Région vous accompagne en finançant votre formation, et selon certaines conditions, peut vous accorder une rémunération durant votre période de formation professionnelle.",
          "prefix": "la",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "76"
              ]
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 16
            }
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "conditions": [
            "Suivre une formation professionnelle agréée et financée par la Région.",
            "Ne pas être indemnisé·e par Pôle Emploi."
          ],
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 685,
          "link": "https://www.laregion.fr/Remuneration-des-stagiaires-de-la-formation-professionnelle",
          "instructions": "https://www.laregion.fr/Remuneration-des-stagiaires-de-la-formation-professionnelle"
        },
        {
          "slug": "region-occitanie-revenu-ecologique-jeune",
          "label": "revenu Écologique Jeune",
          "institution": "region_occitanie",
          "description": "La Région vous propose une aide si vous souhaitez développer un projet professionnel qui contribue à la protection de la nature et à la lutte contre le réchauffement climatique. Elle consiste en un accompagnement et une aide financière forfaitaire.",
          "conditions": [
            "Avoir un projet qui est validé dans le cadre d’un accompagnement par une structure labellisée."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "76"
              ]
            },
            {
              "type": "beneficiaire_rsa"
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 29
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 18
            }
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            },
            {
              "type": "stagiaire",
              "conditions": []
            },
            {
              "type": "independant",
              "conditions": []
            }
          ],
          "link": "https://www.laregion.fr/Revenu-Ecologique-Jeunes",
          "teleservice": "https://www.laregion.fr/Revenu-Ecologique-Jeunes",
          "form": "",
          "instructions": "",
          "prefix": "le",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 1000
        },
        {
          "slug": "region-occitanie-revenu-ecologique-jeunes",
          "label": "Revenu Écologique Jeunes",
          "institution": "region_occitanie",
          "description": "Cette aide vous permet de vous former à un métier vert ou à créer une activité qui a un impact positif sur l'environnement. Elle consiste en une formation, un accompagnement et une aide forfaitaire (sous conditions de ressources).",
          "prefix": "le",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "76"
              ]
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 18
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 29
            }
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "type": "float",
          "unit": "€",
          "periodicite": "mensuelle",
          "legend": "maximum",
          "montant": 1000,
          "link": "https://www.laregion.fr/Revenu-Ecologique-Jeunes",
          "teleservice": "",
          "instructions": "https://www.laregion.fr/Revenu-Ecologique-Jeunes"
        },
        {
          "slug": "region-pays-de-la-loire-abonnement-tutti-26-illimite",
          "label": "abonnement Tutti -26 ans illimité",
          "institution": "region_pays_de_la_loire",
          "description": "L'Abonnement Tutti -26 illimité permet la libre circulation sur votre parcours habituel, en train ou en autocar régional dans les Pays de la Loire. Il s'adresse à toutes et tous (salarié·e·s, étudiant·e·s, stagiaires...) pour simplifier vos déplacements quotidiens.",
          "conditions": [
            "Choisir un trajet fréquent entre deux gares du Pays de la Loire.",
            "Payer l'abonnement dont le prix est calculé en fonction du trajet fréquent choisi."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": "<",
              "value": 26
            },
            {
              "type": "regions",
              "values": [
                "52"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.ter.sncf.com/pays-de-la-loire/offres/tarifs-et-abonnements/je-voyage-beaucoup/forfait-tutti-illimite",
          "teleservice": "https://www.sncf-abo-annuel-ter.com/tapas-tel-web/devisAbonnement.html",
          "prefix": "l’",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-pays-de-la-loire-abonnement-tutti",
          "label": "abonnement Tutti illimité",
          "institution": "region_pays_de_la_loire",
          "description": "L'Abonnement tutti illimité permet la libre circulation sur votre parcours habituel en train ou en autocar régional dans les Pays de la Loire. Il s'adresse à toutes et tous (salarié·e·s, étudiant·e·s, stagiaires...) pour simplifier vos déplacements quotidiens.",
          "conditions": [
            "Choisir un trajet fréquent entre deux gares du Pays de la Loire.",
            "Payer l'abonnement dont le prix est calculé en fonction du trajet fréquent choisi."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "52"
              ]
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 26
            }
          ],
          "profils": [],
          "link": "https://www.ter.sncf.com/pays-de-la-loire/offres/tarifs-et-abonnements/je-voyage-beaucoup/forfait-tutti-illimite",
          "teleservice": "https://www.sncf-abo-annuel-ter.com/tapas-tel-web/devisAbonnement.html",
          "instructions": "",
          "prefix": "l’",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-pays-de-la-loire-acces-emploi-parcours-tpme",
          "label": "aCCÈS Emploi - parcours TPME",
          "institution": "region_pays_de_la_loire",
          "description": "La Région finance une formation d'adaptation à l'emploi pour vous permettre d'ajuster vos compétences avant une embauche.",
          "prefix": "l’aide",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "52"
              ]
            }
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "conditions": [
            "Prendre rendez-vous avec votre conseiller·e Pôle emploi."
          ],
          "type": "bool",
          "unit": "€",
          "periodicite": "autre",
          "link": "https://www.paysdelaloire.fr/les-aides/region-formation-acces-emploi-parcours-tpme",
          "instructions": "https://www.paysdelaloire.fr/les-aides/region-formation-acces-emploi-parcours-tpme"
        },
        {
          "slug": "region-pays-de-la-loire-carte-mezzo-26-ans",
          "label": "carte Mezzo - 26 ans",
          "institution": "region_pays_de_la_loire",
          "description": "Ouverte à toutes et tous, la carte de réduction mezzo vous offre -50% (sur le tarif normal) sur tous vos voyages, 7j/7 et sur tout le réseau Aléop en TER. ",
          "conditions": [
            "Vous procurer la carte Mezzo pour un montant annuel de 20€."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": "<",
              "value": 26
            },
            {
              "type": "regions",
              "values": [
                "52"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.ter.sncf.com/pays-de-la-loire/offres/tarifs-et-abonnements/je-voyage-un-peu/carte-mezzo/%5Btab%5Dlescartes",
          "instructions": "https://www.ter.sncf.com/pays-de-la-loire/offres/tarifs-et-abonnements/je-voyage-un-peu/carte-mezzo/%5Btab%5Dlescartes",
          "prefix": "la",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-pays-de-la-loire-carte-mezzo",
          "label": "carte mezzo",
          "institution": "region_pays_de_la_loire",
          "description": "Ouverte à toutes et tous, la carte de réduction mezzo vous offre -50% (sur le tarif normal) sur tous vos voyages, 7j/7 et sur tout le réseau Aléop en TER. ",
          "conditions": [
            "Vous procurer la carte Mezzo pour un montant annuel de 30€."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 26
            },
            {
              "type": "regions",
              "values": [
                "52"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.ter.sncf.com/pays-de-la-loire/offres/tarifs-et-abonnements/je-voyage-un-peu/carte-mezzo/%5Btab%5Dlescartes",
          "instructions": "https://www.ter.sncf.com/pays-de-la-loire/offres/tarifs-et-abonnements/je-voyage-un-peu/carte-mezzo/%5Btab%5Dlescartes",
          "prefix": "la",
          "type": "bool",
          "unit": "€",
          "periodicite": "annuelle"
        },
        {
          "slug": "region-pays-de-la-loire-ehop-emploi",
          "label": "Covoiturage Solidaire pour l'emploi - Éhop",
          "institution": "region_pays_de_la_loire",
          "description": "L'aide Éhop vous permet de trouver facilement un covoiturage pour vous rendre sur votre lieu de travail. Le service de mise en relation est gratuit et le prix du covoiturage est limité à 8 centimes par kilomètre.",
          "conditions_generales": [
            {
              "type": "departements",
              "values": [
                "44"
              ]
            }
          ],
          "profils": [
            {
              "type": "stagiaire",
              "conditions": []
            },
            {
              "type": "apprenti",
              "conditions": []
            },
            {
              "type": "professionnalisation",
              "conditions": []
            },
            {
              "type": "salarie",
              "conditions": []
            },
            {
              "type": "independant",
              "conditions": []
            },
            {
              "type": "service_civique",
              "conditions": []
            }
          ],
          "link": "https://ehopcovoiturons-nous.fr/particuliers",
          "teleservice": "https://m.ouestgo.fr/#/solidary-transport/home/request",
          "prefix": "le",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-pays-de-la-loire-fonds-social-regional-urgence",
          "label": "fonds social régional d'urgence",
          "institution": "region_pays_de_la_loire",
          "description": "La Région propose une aide pour sécuriser les parcours de formation des stagiaires de la formation professionnelle continue, en cas de situation d'urgence pouvant entraver la continuité de cette formation.",
          "prefix": "le",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "52"
              ]
            }
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            },
            {
              "type": "stagiaire",
              "conditions": []
            }
          ],
          "conditions": [
            "Être en formation professionnelle engagé·e en parcours \"Région Formation\".",
            "Vous rapprocher de votre organisme de formation pour monter le dossier."
          ],
          "type": "bool",
          "unit": "€",
          "periodicite": "autre",
          "link": "https://www.paysdelaloire.fr/les-aides/fonds-social-regional-durgence",
          "teleservice": "https://les-aides.paysdelaloire.fr/account-management/prod-demandeurs/ux/#/login?redirectTo=https:%2F%2Fles-aides.paysdelaloire.fr%2Faides%2F%23%2Fprod%2Fconnecte%2FF_FSU%2Fdepot%2Fsimple&jwtKey=jwt-prod-portail-depot-demande-aides&footer=https:%2F%2Fles-aides.paysdelaloire.fr%2Faides%2F%23%2Fprod%2Fmentions-legales,Mentions%20l%C3%A9gales,_self",
          "instructions": "https://www.paysdelaloire.fr/les-aides/fonds-social-regional-durgence"
        },
        {
          "slug": "region-pays-de-la-loire-prime-job-etudiant",
          "label": "prime Job Etudiant",
          "institution": "region_pays_de_la_loire",
          "description": "La Région offre une prime à tous les jeunes qui cumulent emploi et études.",
          "prefix": "l’aide",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "52"
              ]
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 26
            }
          ],
          "profils": [
            {
              "type": "etudiant",
              "conditions": []
            }
          ],
          "conditions": [
            "Exercer une activité rémunérée en Pays de la Loire (hors cadre des études) durant la période scolaire de référence.",
            "Être titulaire du <a target=\"_blank\" rel=\"noopener\" title=\"E-pass Jeunes - Nouvelle fenêtre\" href=\"https://www.epassjeunes-paysdelaloire.fr/\">E-pass Jeunes</a>."
          ],
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 200,
          "link": "https://www.paysdelaloire.fr/les-aides/job-etudiant-prime-de-200-euros",
          "teleservice": "https://www.epassjeunes-paysdelaloire.fr/",
          "instructions": "https://www.paysdelaloire.fr/les-aides/job-etudiant-prime-de-200-euros"
        },
        {
          "slug": "region-pays-de-la-loire-visa-sanitaire-et-social",
          "label": "VISA sanitaire et social",
          "institution": "region_pays_de_la_loire",
          "description": "La Région finance des formations aux métiers du secteur sanitaire et social.",
          "prefix": "l’aide",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "52"
              ]
            }
          ],
          "profils": [
            {
              "type": "etudiant",
              "conditions": []
            },
            {
              "type": "chomeur",
              "conditions": []
            },
            {
              "type": "salarie",
              "conditions": []
            }
          ],
          "conditions": [
            "Vous inscrire via Parcoursup si vous êtes étudiant·e, ou effectuer la demande auprès de Pôle Emploi si vous êtes demandeur·euse d'emploi."
          ],
          "interestFlag": "_interetAidesSanitaireSocial",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre",
          "link": "https://www.paysdelaloire.fr/les-aides/region-formation-visa-sanitaire-et-social",
          "instructions": "https://www.paysdelaloire.fr/les-aides/region-formation-visa-sanitaire-et-social"
        },
        {
          "slug": "region-provence-alpes-cote-azur-accompagnement-vae",
          "label": "Accompagnement à la VAE (Validation des Acquis de l'Expérience)",
          "institution": "region_provence_alpes_cote_azur",
          "description": "La Région vous accompagne dans votre démarche de VAE.",
          "prefix": "l’",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "93"
              ]
            }
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "conditions": [
            "Être inscrit·e à Pôle Emploi."
          ],
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 2400,
          "link": "https://www.maregionsud.fr/aides-et-appels-a-projets/detail/validation-des-acquis-de-lexperience",
          "teleservice": "https://aidesformation.maregionsud.fr/SignIn?ReturnUrl=%2Fprofile%2F",
          "instructions": "https://www.maregionsud.fr/aides-et-appels-a-projets/detail/validation-des-acquis-de-lexperience"
        },
        {
          "slug": "region-provence-alpes-cote-azur-carte-zou",
          "label": "carte Zou!",
          "institution": "region_provence_alpes_cote_azur",
          "description": "Grâce à la carte Zou!, bénéficiez de 50% de réduction sur tous vos déplacements en TER en région Sud Provence-Alpes-Côte d'Azur - vos accompagnants (3 maximum) bénéficient également de cette réduction - et de 75% de réduction sur votre trajet privilégié.",
          "conditions": [
            "Effectuer des trajets ayant pour origine une gare située en région Sud Provence-Alpes-Côte d'Azur.",
            "Vous procurer la carte Zou! pour un montant de 15 €."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": "<=",
              "value": 26
            },
            {
              "type": "regions",
              "values": [
                "93"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.ter.sncf.com/sud-provence-alpes-cote-d-azur/offres/cartes-abonnements/zou-50-75-26",
          "instructions": "https://www.ter.sncf.com/sud-provence-alpes-cote-d-azur/offres/cartes-abonnements/zou-50-75-26",
          "prefix": "la",
          "type": "bool",
          "periodicite": "autre"
        },
        {
          "slug": "region-provence-alpes-cote-azur-chequier-pass-sante-jeunes",
          "label": "chéquier PASS Santé Jeunes",
          "institution": "region_provence_alpes_cote_azur",
          "description": "Grâce au chéquier PASS Santé Jeunes, accédez gratuitement à un ensemble de prestations chez les professionnels de santé et les psychologues libéraux.",
          "conditions": [
            "Être dans l'un des cas suivants : inscrit·e dans un lycée, dans un centre de formation d'apprentis (CFA), dans un établissement de formation sanitaire et sociale, dans un établissement d'enseignement supérieur, dans une mission locale, à Pôle Emploi, ou bien en stage de formation professionnelle, en contrat de professionnalisation, en contrat Emploi d'avenir, ou encore au service civique en Provence-Alpes-Côte d'Azur."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 15
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 26
            },
            {
              "type": "regions",
              "values": [
                "93"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.maregionsud.fr/aides-et-appels-a-projets/detail/pass-sante-jeunes",
          "teleservice": "https://passantejeunes.maregionsud.fr/Account/LogOn?ReturnUrl=%2fAccount%2fLogOn%3fReturnUrl%3d%252fAccount%252fLogOn%253fReturnUrl%253d%25252f",
          "form": "",
          "instructions": "https://www.maregionsud.fr/aides-et-appels-a-projets/detail/pass-sante-jeunes",
          "prefix": "le",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-provence-alpes-cote-azur-pass-mutuelles",
          "label": "pass Mutuelles",
          "institution": "region_provence_alpes_cote_azur",
          "description": "La Région vous aide à financer votre complémentaire santé.",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "93"
              ]
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": [
                {
                  "type": "boursier"
                }
              ]
            }
          ],
          "conditions": [
            "Souscrire une complémentaire santé auprès d’<a target=\"_blank\" title=\"Organismes conventionnés - Nouvelle fenêtre\" rel=\"noopener\" href=\"https://www.maregionsud.fr/aides-et-appels-a-projets/detail/pass-mutuelles\">un des 7 organismes</a> conventionnés par la Région."
          ],
          "interestFlag": "_interetAidesSanitaireSocial",
          "prefix": "le",
          "type": "float",
          "unit": "€",
          "periodicite": "autre",
          "montant": 100,
          "link": "https://www.maregionsud.fr/aides-et-appels-a-projets/detail/pass-mutuelles",
          "instructions": "https://www.maregionsud.fr/aides-et-appels-a-projets/detail/pass-mutuelles"
        },
        {
          "slug": "region-provence-alpes-cote-azur-pass-sud-formation",
          "label": "pass Sud Formation",
          "institution": "region_provence_alpes_cote_azur",
          "description": "La Région vous aide à financer votre formation professionnelle dans un secteur qui recrute.",
          "prefix": "le",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "93"
              ]
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 16
            }
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "conditions": [
            "Être inscrit·e à Pôle Emploi ou Cap Emploi.",
            "Intégrer une formation dont le coût est inférieur à 10 000€ TTC.",
            "Vous assurer que cette formation n’est financée par aucune autre structure."
          ],
          "type": "bool",
          "unit": "€",
          "periodicite": "autre",
          "link": "https://www.maregionsud.fr/aides-et-appels-a-projets/detail/pass-sud-formation-pour-financer-votre-formation-professionnelle",
          "instructions": "https://www.maregionsud.fr/aides-et-appels-a-projets/detail/pass-sud-formation-pour-financer-votre-formation-professionnelle"
        },
        {
          "slug": "region-provence-alpes-cote-azur-pass-zou-etudes",
          "label": "pass ZOU ! Études",
          "institution": "region_provence_alpes_cote_azur",
          "description": "Le pass ZOU ! Études permet des déplacements illimités en bus et train ZOU ! du 1er septembre au 31 août, y compris les week-ends et vacances, pour les trajets scolaires ou de loisirs.",
          "conditions": [
            "Etre scolarisé·e en région Provence-Alpes-Côte d’Azur.",
            "Vous procurer le pass ZOU ! dont le prix varie de 35 €/an à 70 €/an."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 3
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 26
            },
            {
              "type": "regions",
              "values": [
                "93"
              ]
            }
          ],
          "profils": [
            {
              "type": "lyceen",
              "conditions": []
            },
            {
              "type": "apprenti",
              "conditions": []
            },
            {
              "type": "stagiaire",
              "conditions": []
            },
            {
              "type": "professionnalisation",
              "conditions": []
            }
          ],
          "link": "https://www.ter.sncf.com/sud-provence-alpes-cote-d-azur/offres/cartes-abonnements/pass-zou-etudes",
          "teleservice": "https://services-zou.maregionsud.fr/fr/billetterie/action/8#zouetudes",
          "instructions": "https://www.ter.sncf.com/sud-provence-alpes-cote-d-azur/offres/cartes-abonnements/pass-zou-etudes",
          "prefix": "le",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region-provence-alpes-cote-azur-prame-sanitaire-et-social",
          "label": "programme Régional d’Aide à la Mobilité Etudiante (PRAME) - Sanitaire et social",
          "institution": "region_provence_alpes_cote_azur",
          "description": "Cette aide a pour objectif de favoriser la mobilité des étudiant·e·s du secteur sanitaire et social en donnant accès à une bourse régionale dans le cadre de l’accomplissement d’un stage à l’étranger durant leur cursus.",
          "conditions": [
            "Être inscrit·e en formation initiale dans un établissement régional de formations sanitaires et/ou sociales public ou privé conventionné avec la Région.",
            "Préparer une formation agréée/autorisée par la Région.",
            "Avoir validé un diplôme de niveau IV.",
            "Avoir passé votre baccalauréat ou validé une année d’études supérieures en Provence-Alpes-Côte d'Azur.",
            "Bénéficier d'une indemnité de stage inférieure à 100 € par semaine, et ne pas bénéficier d'Erasmus ou d'une autre aide à la mobilité internationale."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "93"
              ]
            },
            {
              "type": "quotient_familial",
              "floor": 24000,
              "period": "year"
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [],
          "link": "https://www.maregionsud.fr/aides-et-appels-a-projets/detail/programme-regional-daide-a-la-mobilite-etudiante-prame-sanitaire-et-social-2021-2022",
          "teleservice": "https://aidesformation.maregionsud.fr/",
          "prefix": "le",
          "instructions": "https://www.maregionsud.fr/aides-et-appels-a-projets/detail/programme-regional-daide-a-la-mobilite-etudiante-prame-sanitaire-et-social-2021-2022",
          "type": "float",
          "unit": "€",
          "periodicite": "annuelle",
          "legend": "maximum",
          "montant": 2000,
          "interestFlag": "_interetEtudesEtranger"
        },
        {
          "slug": "region-provence-alpes-cote-azur-programme-regional-aide-mobilite-etudiante-prame-bts",
          "label": "programme Régional d’Aide à la Mobilité Etudiante (PRAME) - BTS",
          "institution": "region_provence_alpes_cote_azur",
          "description": "Cette aide a pour objectif de favoriser la mobilité des étudiant·e·s en donnant accès à une bourse régionale dans le cadre de l’accomplissement d’un stage à l’étranger durant leur cursus.",
          "prefix": "le",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "93"
              ]
            },
            {
              "type": "quotient_familial",
              "floor": 24000,
              "period": "year"
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": [
                {
                  "type": "annee_etude",
                  "values": [
                    "bts_1"
                  ]
                }
              ]
            }
          ],
          "conditions": [
            "Ne pas bénéficier d'une allocation Erasmus, d'une autre aide à la mobilité internationale ou d’une indemnité de stage supérieure à 100 € par semaine."
          ],
          "interestFlag": "_interetEtudesEtranger",
          "type": "float",
          "unit": "€",
          "periodicite": "annuelle",
          "legend": "maximum",
          "montant": 2000,
          "link": "https://www.maregionsud.fr/aides-et-appels-a-projets/detail/programme-regional-daide-a-la-mobilite-etudiante-prame-bts-2021-2022",
          "teleservice": "https://aidesformation.maregionsud.fr/SignIn?ReturnUrl=%2Fprofile%2F",
          "instructions": "https://www.maregionsud.fr/aides-et-appels-a-projets/detail/programme-regional-daide-a-la-mobilite-etudiante-prame-bts-2021-2022"
        },
        {
          "slug": "region-provence-alpes-cote-azur-programme-regional-aide-mobilite-etudiante-prame",
          "label": "Programme Régional d’Aide à la Mobilité Etudiante (PRAME)",
          "institution": "region_provence_alpes_cote_azur",
          "description": "La Région propose une bourse pour effectuer un stage à l’étranger dans le cadre de votre cursus de formation.",
          "prefix": "le",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "93"
              ]
            },
            {
              "type": "quotient_familial",
              "period": "year",
              "floor": 24000
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 30
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": []
            }
          ],
          "conditions": [
            "Ne pas être bénéficiaire d'une allocation Erasmus, d'une autre aide à la mobilité internationale ni percevoir une indemnité de stage supérieure à 100 € par semaine."
          ],
          "interestFlag": "_interetEtudesEtranger",
          "type": "float",
          "unit": "€",
          "periodicite": "annuelle",
          "legend": "maximum",
          "montant": 2000,
          "link": "https://www.maregionsud.fr/aides-et-appels-a-projets/detail/programme-regional-daide-a-la-mobilite-etudiante-prame-enseignement-superieur-2021-2022",
          "teleservice": "https://aidesformation.maregionsud.fr/"
        },
        {
          "slug": "region-provence-alpes-cote-azur-remuneration-stagiaires-formation-professionnelle",
          "label": "rémunération des stagiaires de la formation professionnelle",
          "institution": "region_provence_alpes_cote_azur",
          "description": "La Région propose une rémunération à destination des stagiaires de la formation professionnelle préparant une formation sanitaire de niveau V.",
          "prefix": "la",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "93"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "conditions": [
            "Être inscrit·e à Pôle emploi ou auprès d’une mission locale.",
            "Ne pas être en continuité de parcours scolaire."
          ],
          "interestFlag": "_interetAidesSanitaireSocial",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre",
          "link": "https://www.maregionsud.fr/aides-et-appels-a-projets/detail/remuneration-des-stagiaires-de-la-formation-professionnelle",
          "teleservice": "https://aidesformation.maregionsud.fr/SignIn?ReturnUrl=%2Fprofile%2F",
          "instructions": "https://www.maregionsud.fr/aides-et-appels-a-projets/detail/remuneration-des-stagiaires-de-la-formation-professionnelle"
        },
        {
          "slug": "region-provence-alpes-cote-azur-un-parrain-un-emploi",
          "label": "un Parrain, un Emploi",
          "institution": "region_provence_alpes_cote_azur",
          "description": "La Région propose un programme de coaching individuel pour vous aider à trouver un emploi.",
          "prefix": "l’aide",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "93"
              ]
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 26
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": []
            },
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "conditions": [
            "Être titulaire d’un Bac +2.",
            "Être inscrit·e à Pôle Emploi."
          ],
          "type": "bool",
          "unit": "€",
          "periodicite": "autre",
          "link": "https://www.maregionsud.fr/aides-et-appels-a-projets/detail/un-parrain-un-emploi",
          "teleservice": "https://www.pole-emploi.fr/region/provence-alpes-cote-d-azur/employeur/un-parrain-un-emploi.html"
        },
        {
          "slug": "region_auvergne_rhone_alpes-bourse-du-secteur-santé-social",
          "label": "bourse du secteur santé et social",
          "institution": "region_auvergne_rhone_alpes",
          "description": "Cette aide s'adresse à toute personne sans emploi, inscrite dans un établissement de formation situé en Auvergne-Rhône-Alpes et agréé par la Région, pour suivre l'une des formations <a target=\"_blank\" rel=\"noopener\" title =\"Liste des formations - Nouvelle fenêtre\" href=\"https://www.auvergnerhonealpes.fr/aide/47/89-obtenir-une-bourse-pour-suivre-une-formation-dans-le-secteur-sante-social-orientation-formation.htm\">listées ici</a>. La bourse est attribuée sur critères sociaux.",
          "conditions": [],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "84"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            },
            {
              "type": "enseignement_superieur",
              "conditions": []
            }
          ],
          "link": "https://www.auvergnerhonealpes.fr/aide/47/89-obtenir-une-bourse-pour-suivre-une-formation-dans-le-secteur-sante-social-orientation-formation.htm",
          "teleservice": "https://rhone-alpes.commeunservice.com/bourses/jsp/nouveauContexte.action?codeAction=M42-ACCUEIL#FSS",
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 5736,
          "interestFlag": "_interetAidesSanitaireSocial"
        },
        {
          "slug": "region_auvergne_rhone_alpes-financement-de-formations-dans-le-secteur-de-la-santé",
          "label": "financement de formations dans le secteur de la santé",
          "institution": "region_auvergne_rhone_alpes",
          "description": "La Région finance les formations pour devenir aide-soignant·e, auxiliaire de puériculture ou ambulancier·e.",
          "conditions": [
            "Constituer et déposer votre dossier auprès de l'intermédiaire designé par la Région."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "84"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "etudiant",
              "conditions": []
            },
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "link": "https://www.auvergnerhonealpes.fr/aide/335/289-financer-ma-formation-dans-le-secteur-de-la-sante-orientation-formation.htm",
          "instructions": "https://www.auvergnerhonealpes.fr/aide/335/289-financer-ma-formation-dans-le-secteur-de-la-sante-orientation-formation.htm",
          "prefix": "le",
          "type": "bool",
          "unit": "€",
          "periodicite": "ponctuelle",
          "interestFlag": "_interetAidesSanitaireSocial"
        },
        {
          "slug": "region_bourgogne_franche_comte-bourses-détudes-sanitaires-et-sociales",
          "label": "bourses d'études sanitaires et sociales",
          "institution": "region_bourgogne_franche_comte",
          "description": "Une bourse d’études est accordée par la Région sur critères sociaux aux étudiant·e·s en travail social et aux élèves et étudiant·e·s inscrit·e·s dans les instituts et écoles de formation de certaines professions de santé.",
          "conditions": [
            "Être inscrit·e dans les instituts autorisés/agréés par la Région."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "27"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": []
            },
            {
              "type": "lyceen",
              "conditions": [
                {
                  "type": "annee_etude",
                  "values": [
                    "terminale"
                  ]
                }
              ]
            }
          ],
          "link": "https://www.bourgognefranchecomte.fr/demande-de-bourse-sanitaire-et-sociale",
          "teleservice": "https://ibssp.bourgognefranchecomte.fr/etudiant.php/compte/login",
          "instructions": "https://www.bourgognefranchecomte.fr/demande-de-bourse-sanitaire-et-sociale",
          "prefix": "les",
          "type": "bool",
          "unit": "€",
          "periodicite": "annuelle",
          "interestFlag": "_interetAidesSanitaireSocial"
        },
        {
          "slug": "region_bretagne-aide-au-premier-équipement-professionnel-des-lycéen-ne-s",
          "label": "Aide au premier équipement professionnel des lycéen·ne·s",
          "institution": "region_bretagne",
          "description": "L'aide au premier équipement professionnel est une aide financière qui vous est versée si vous êtes lycéen·ne. Elle vous aidera à acheter l’équipement professionnel indispensable à votre activité.",
          "conditions": [
            "Être lycéen·ne en première année de formation professionnelle de niveau 3 et 4 dans un lycée breton.",
            "OU Être lycéen·ne intégrant une formation professionnelle sans suivre une classe de seconde ou une première année de CAP, dans le cadre d’une réorientation de parcours scolaire (élèves « passerelles »)."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "53"
              ]
            }
          ],
          "profils": [
            {
              "type": "lyceen",
              "conditions": []
            }
          ],
          "link": "https://www.bretagne.bzh/aides/fiches/premier-equipement-professionnel/",
          "teleservice": "",
          "instructions": "https://www.bretagne.bzh/aides/fiches/premier-equipement-professionnel/",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 300
        },
        {
          "slug": "region_bretagne-b-mouve-–-mobilité-des-formations-sanitaires-et-sociales",
          "label": "b-Mouve – Mobilité des formations sanitaires et sociales",
          "institution": "region_bretagne",
          "description": "La subvention B-Mouve vise à permettre aux élèves et étudiant·e·s des formations sanitaires et sociales de la Région Bretagne de réaliser des stages ou des séjours d'études en Europe. Le montant de l’aide dépend du profil des bénéficiaires.",
          "conditions": [
            "Réaliser un séjour en Europe ou à l’international dans le cadre de votre formation ou de votre mission.",
            "Etudier au sein d'un des <a target=\"_blank\" rel=\"noopener\" title =\"Etablissements éligibles - Nouvelle fenêtre\" href=\"https://www.bretagne.bzh/app/uploads/Etablissements-eligibles.docx\">établissements éligibles</a>.",
            "Transmettre un dossier complet à la Région."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "53"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": []
            },
            {
              "type": "lyceen",
              "conditions": []
            }
          ],
          "link": "https://www.bretagne.bzh/aides/fiches/bmouve-bretagne-mobilite-ouverture-europe/",
          "teleservice": "https://extranets.region-bretagne.fr/Portail-Aides/jsp/nouveauContexte.action?codeAction=M42-CONNEXION",
          "prefix": "l’aide",
          "type": "bool",
          "unit": "€",
          "periodicite": "ponctuelle",
          "interestFlag": "_interetEtudesEtranger"
        },
        {
          "slug": "region_bretagne-les-aides-prepa",
          "label": "aides PREPA",
          "institution": "region_bretagne",
          "description": "En amont d’une formation qualifiante, la gamme « PREPA » permet aux publics de se préparer avant d’intégrer une formation qualifiante ou d’accéder directement à un emploi. 3 aides existent :\n\n<a target=\"_blank\" rel=\"noopener\" title =\"PREPA Projet - Nouvelle fenêtre\" href=\"https://www.bretagne.bzh/aides/fiches/copie-de-prepa-projet/\">PREPA Projet</a>\n : une formation pour concrétiser son projet, \n\n<a target=\"_blank\" rel=\"noopener\" title =\"PREPA Clés - Nouvelle fenêtre\" href=\"https://www.bretagne.bzh/aides/fiches/prepa-cles/\">PREPA Clés</a> : une formation pour développer ses compétences et \n\n<a target=\"_blank\" rel=\"noopener\" title =\"PREPA Avenir - Nouvelle fenêtre\" href=\"https://www.bretagne.bzh/aides/fiches/prepa-avenir/\">PREPA Avenir</a> : une formation pour construire son avenir",
          "conditions": [
            "Être en recherche d’emploi, sans emploi ou en emploi précaire, inscrit·e ou non à Pôle Emploi."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "53"
              ]
            }
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "link": "https://www.bretagne.bzh/aides/?mot-clef=%22prepa%22&cloture=0&showall=0",
          "teleservice": "https://aides.bretagne.bzh/account-management/crbr-demandeurs/ux/#/login?redirectTo=https:%2F%2Faides.bretagne.bzh%2Faides%2F%23%2Fcrbr%2Fconnecte%2Fdashboard%2Faccueil&jwtKey=jwt-crbr-portail-depot-demande-aides&footer=https:%2F%2Faides.bretagne.bzh%2Faides%2F%23%2Fcrbr%2Fmentions-legales,Mentions%20l%C3%A9gales,_self",
          "instructions": "",
          "prefix": "les",
          "type": "bool",
          "unit": "€",
          "periodicite": "ponctuelle"
        },
        {
          "slug": "region_bretagne-qualif-sanitaire-et-social",
          "label": "bourse QUALIF Sanitaire et Social",
          "institution": "region_bretagne",
          "description": "La région Bretagne finance les formations agréées et les bourses d'études dans le secteur sanitaire et social, sous conditions de ressources.",
          "conditions": [
            "Être étudiant·e dans le secteur sanitaire et social.",
            "Vérifier que vos ressources correspondent aux seuils fixés par la région."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "53"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "etudiant",
              "conditions": []
            },
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "link": "https://www.bretagne.bzh/aides/fiches/copie-de-qualif-sanitaire-et-social/",
          "teleservice": "https://extranets.region-bretagne.fr/Portail-Aides/jsp/nouveauContexte.action?codeAction=M42-CONNEXION",
          "instructions": "https://www.bretagne.bzh/aides/fiches/copie-de-qualif-sanitaire-et-social/",
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "periodicite": "annuelle",
          "legend": "maximum",
          "montant": 5670,
          "interestFlag": "_interetAidesSanitaireSocial"
        },
        {
          "slug": "region_bretagne-éhop-covoiturage-solidaire-pour-lemploi",
          "label": "Covoiturage Solidaire pour l'emploi - Éhop",
          "institution": "region_bretagne",
          "description": "L'aide Éhop vous permet de trouver facilement un covoiturage pour vous rendre sur votre lieu de travail. Le service de mise en relation est gratuit et le prix du covoiturage est limité à 8 centimes par kilomètre.",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "53"
              ]
            }
          ],
          "profils": [
            {
              "type": "stagiaire",
              "conditions": []
            },
            {
              "type": "apprenti",
              "conditions": []
            },
            {
              "type": "professionnalisation",
              "conditions": []
            },
            {
              "type": "salarie",
              "conditions": []
            },
            {
              "type": "independant",
              "conditions": []
            },
            {
              "type": "service_civique",
              "conditions": []
            }
          ],
          "link": "https://ehopcovoiturons-nous.fr/particuliers",
          "teleservice": "https://m.ouestgo.fr/#/solidary-transport/home/request",
          "prefix": "le",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region_centre_val_de_loire-aides-pour-la-formation-sanitaire-et-social",
          "label": "bourses pour les formations sanitaires et sociales",
          "institution": "region_centre_val_de_loire",
          "description": "La Région attribue des bourses d’études pour les personnes rentrant en formation dans un lycée professionnel ou un institut régional agréé, soumises à conditions de ressources.",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "24"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "lyceen",
              "conditions": []
            },
            {
              "type": "chomeur",
              "conditions": []
            },
            {
              "type": "enseignement_superieur",
              "conditions": []
            }
          ],
          "link": "https://www.centre-valdeloire.fr/vivre/accompagner-la-formation/secteur-sanitaire-et-social/les-aides-pour-la-formation-sanitaire-et",
          "teleservice": "https://www.aress.regioncentre-valdeloire.fr/basscep/",
          "instructions": "https://www.centre-valdeloire.fr/vivre/accompagner-la-formation/secteur-sanitaire-et-social/les-aides-pour-la-formation-sanitaire-et",
          "prefix": "les",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 5679,
          "interestFlag": "_interetAidesSanitaireSocial"
        },
        {
          "slug": "region_corse-bourses-d’études-de-formations-sanitaires-et-sociales",
          "label": "bourses d’études de formations sanitaires et sociales",
          "institution": "region_corse",
          "description": "La Collectivité de Corse finance les formations sanitaires et sociales via une bourse d'étude sur critères sociaux.",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "94"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "lyceen",
              "conditions": []
            },
            {
              "type": "enseignement_superieur",
              "conditions": []
            },
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "link": "https://www.isula.corsica/Borse-di-studii-di-furmazione-sanitarie-e-suciale-Bourses-d-etudes-de-formations-sanitaires-et-sociales_a1541.html",
          "form": "https://www.isula.corsica/attachment/1979793/",
          "prefix": "les",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre",
          "interestFlag": "_interetAidesSanitaireSocial"
        },
        {
          "slug": "region_ile_de_france-aide-individuelle-régionale-vers-l’emploi",
          "label": "aide individuelle régionale vers l’emploi",
          "institution": "region_ile_de_france",
          "description": "La Région finance des formations facilitant le retour, l'accès ou le maintien en emploi sur des secteurs en tension de recrutement en Île-de-France.",
          "conditions": [
            "Être en recherche d'emploi inscrit·e à Pôle Emploi.",
            "Suivre une formation qui remplit les <a target=\"_blank\" rel=\"noopener\" title =\"Critères d'éligibilité - Nouvelle fenêtre\" href=\"https://www.iledefrance.fr/aide-individuelle-regionale-vers-lemploi-aire-2\">critères d'éligibilité</a>.",
            "Faire votre demande auprès de votre organisme de formation."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "11"
              ]
            }
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "link": "https://www.iledefrance.fr/aide-individuelle-regionale-vers-lemploi-aire-2",
          "instructions": "https://www.iledefrance.fr/aide-individuelle-regionale-vers-lemploi-aire-2",
          "prefix": "l’",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region_ile_de_france-aide-régionale-au-passage-du-diplôme-daccès-aux-études-universitaires-daeu",
          "label": "aide régionale au passage du Diplôme d'accès aux études universitaires (DAEU)",
          "institution": "region_ile_de_france",
          "description": "La Région Ile-de-France favorise l'accès à l'enseignement supérieur de tous les Francilien·ne·s à travers une aide incitative au passage du diplôme d'accès aux études universitaires (DAEU).",
          "conditions": [
            "Ne pas être titulaire du baccalauréat.",
            "Vous inscrire en DAEU dans une université francilienne."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "11"
              ]
            }
          ],
          "profils": [
            {
              "type": "lyceen",
              "conditions": []
            },
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "link": "https://www.iledefrance.fr/aide-regionale-pour-le-diplome-dacces-aux-etudes-universitaires-daeu",
          "teleservice": "https://mesdemarches.iledefrance.fr/account-management/cridfprd-demandeurs/ux/#/login?redirectTo=https:%2F%2Fmesdemarches.iledefrance.fr%2Faides%2F%23%2Fcridfprd%2Fconnecte%2Fdashboard%2Faccueil&jwtKey=jwt-cridfprd-portail-depot-demande-aides&footer=https:%2F%2Fmesdemarches.iledefrance.fr%2Faides%2F%23%2Fcridfprd%2Fmentions-legales,Mentions%20l%C3%A9gales%20et%20RGPD,_self",
          "instructions": "",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "autre",
          "montant": 1000
        },
        {
          "slug": "region_ile_de_france-aide-régionale-aux-inscriptions-aux-concours",
          "label": "Aide régionale aux inscriptions aux concours",
          "institution": "region_ile_de_france",
          "description": "La Région Ile-de-France soutient les élèves de 2ème année des classes préparatoires aux grandes écoles (CPGE) pour le paiement des frais de concours : inscription, hébergement, transport.",
          "conditions": [
            "Vous rapprocher de votre établissement qui vous indiquera la marche à suivre."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "11"
              ]
            },
            {
              "type": "quotient_familial",
              "floor": 10140,
              "period": "year"
            }
          ],
          "profils": [
            {
              "type": "etudiant",
              "conditions": [
                {
                  "type": "annee_etude",
                  "values": [
                    "cpge_1"
                  ]
                }
              ]
            }
          ],
          "link": "https://lycees.iledefrance.fr/dotation-budgetaire",
          "teleservice": "",
          "instructions": "https://lycees.iledefrance.fr/dotation-budgetaire",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 846
        },
        {
          "slug": "region_ile_de_france-aide-régionale-à-léquipement-individuel-dans-les-filières-pro-et-technologiques",
          "label": "aide régionale à l'équipement individuel dans les filières pro et technologiques",
          "institution": "region_ile_de_france",
          "description": "Pour garantir les meilleures conditions d'études, la Région Ile-de-France met des équipements et matériels pédagogiques individuels à disposition des élèves des formations relevant de la spécialité production, de certaines spécialités de service, de la spécialité \"autres disciplines artistiques et spécialités artistiques plurivalentes\" et de la formation prépa-métiers niveau 3ème.",
          "conditions": [
            "Vous rapprocher de votre établissement qui vous mettra les équipements et matériels à disposition."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "11"
              ]
            }
          ],
          "profils": [
            {
              "type": "etudiant",
              "conditions": [
                {
                  "type": "annee_etude",
                  "values": [
                    "cap_1",
                    "terminale",
                    "bts_1"
                  ]
                }
              ]
            }
          ],
          "link": "https://lycees.iledefrance.fr/dotation-budgetaire",
          "instructions": "https://lycees.iledefrance.fr/dotation-budgetaire",
          "prefix": "l’",
          "type": "bool",
          "unit": "€",
          "periodicite": "ponctuelle"
        },
        {
          "slug": "region_ile_de_france-bourses-pour-les-élèves-et-étudiant-e-s-inscrit-e-s-en-formations-sanitaires-et-sociales",
          "label": "bourses pour les élèves et étudiant·e·s inscrit·e·s en formations sanitaires et sociales",
          "institution": "region_ile_de_france",
          "description": "La Région Ile-de-France verse, sous conditions de ressources, une bourse aux élèves et étudiant·e·s en formation paramédicale ou sociale.",
          "conditions": [
            "Etre de nationalité française ou ressortissant·e d'un pays de l'Union européenne ou étranger en situation régulière en France.",
            "Être élève ou étudiant·e en formation initiale, inscrit·e en cursus complet dans un établissement dont la formation est agréée par la Région Ile-de-France."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "11"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "lyceen",
              "conditions": []
            },
            {
              "type": "enseignement_superieur",
              "conditions": []
            },
            {
              "type": "chomeur",
              "conditions": []
            }
          ],
          "link": "https://www.iledefrance.fr/formations-sanitaires-et-sociales-quelles-aides-financieres-et-pour-qui",
          "teleservice": "https://fss-ext.iledefrance.fr/fss/",
          "instructions": "https://www.iledefrance.fr/formations-sanitaires-et-sociales-quelles-aides-financieres-et-pour-qui",
          "prefix": null,
          "type": "bool",
          "unit": "€",
          "periodicite": "mensuelle",
          "interestFlag": "_interetAidesSanitaireSocial"
        },
        {
          "slug": "region_la_reunion-bourse-régionale-sanitaire-et-sociale",
          "label": "bourse Régionale Sanitaire et Sociale",
          "institution": "region_la_reunion",
          "description": "La Région Réunion alloue une bourse régionale aux élèves et étudiant·e·s des filières sanitaires et sociales. La bourse est attribuée selon la situation sociale de l’apprenant, sous réserve des critères d'éligibilité.",
          "conditions": [
            "Suivre une formation initiale en cursus complet sanctionnée par un diplôme d’État et dispensée dans un des <a target=\"_blank\" rel=\"noopener\" title =\"Etablissements agréés - Nouvelle fenêtre\" href=\"https://regionreunion.com/IMG/pdf/rab_bss_2021-2022-2.pdf\">établissements agréés</a> et financés par la Région Réunion."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "04"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": []
            }
          ],
          "link": "https://regionreunion.com/aides-services/article/bourse-regionale-sanitaire-et-socialebourse-ecole-de-gestion-et-de-commerce-de-la-reunion-egcr",
          "teleservice": "https://connexion-demarches.cr-reunion.fr/accounts/register/",
          "instructions": "https://regionreunion.com/aides-services/article/bourse-regionale-sanitaire-et-socialebourse-ecole-de-gestion-et-de-commerce-de-la-reunion-egcr",
          "prefix": "la",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre",
          "interestFlag": "_interetAidesSanitaireSocial"
        },
        {
          "slug": "region_normandie-aide-à-laccès-au-permis-de-conduire-permis-b",
          "label": "aide à l'accès au permis de conduire - Permis B",
          "institution": "region_normandie",
          "description": "La Région Normandie apporte une aide spécifique au financement du permis de conduire pour certain·e·s stagiaires en ciblant les métiers pour lesquels l'obtention du permis de conduire est une condition presque absolue pour l'insertion durable dans l'emploi.",
          "conditions": [
            "Être stagiaire de la formation professionnelle.",
            "Être en formation à un métier pour lequel le permis B est indispensable <a target=\"_blank\" title=\"Liste des formations - Nouvelle fenêtre\" rel=\"noopener\" href=\"https://www.normandie.fr/sites/default/files/documents_importes/fiche_details_aide_permis.pdf\">(voir liste)</a>.",
            "Avoir obtenu le code de la route."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "28"
              ]
            }
          ],
          "profils": [
            {
              "type": "stagiaire",
              "conditions": []
            }
          ],
          "link": "https://www.normandie.fr/aide-lacces-au-permis-de-conduire-permis-b",
          "teleservice": "https://monespace-aides.normandie.fr/account-management/crno-demandeurs/ux/#/login?redirectTo=https:%2F%2Fmonespace-aides.normandie.fr%2Faides%2F%23%2Fcrno%2Fconnecte%2Fdashboard%2Faccueil&jwtKey=jwt-crno-portail-depot-demande-aides&footer=https:%2F%2Fmonespace-aides.normandie.fr%2Faides%2F%23%2Fcrno%2Fmentions-legales,Mentions%20l%C3%A9gales,_self;https:%2F%2Faides.normandie.fr%2Fbesoin-d-aide,Nous%20contacter,_blank",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 600,
          "interestFlag": "_interetPermisDeConduire"
        },
        {
          "slug": "region_normandie-aides-aux-formations-paramédicales-sociales-ou-de-santé",
          "label": "aides aux formations paramédicales sociales ou de santé",
          "institution": "region_normandie",
          "description": "La Région Normandie finance des aides pour les étudiant·e·s et élèves des formations sanitaires, sociales et de santé.",
          "conditions": [
            "Être inscrit·e dans une des <a target=\"_blank\" rel=\"noopener\" title =\"Formations éligibles - Nouvelle fenêtre\" href=\"https://www.crous-normandie.fr/wp-content/uploads/sites/41/2019/01/Liste-des-formations-paramSodicales-de-la-RSogion-Normandie-1.pdf\">formations éligibles</a>."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "28"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": []
            }
          ],
          "link": "https://www.crous-normandie.fr/bourses/aides-paramedicales-sociales-de-sante/",
          "teleservice": "https://www.messervices.etudiant.gouv.fr/envole/",
          "instructions": "https://www.crous-normandie.fr/bourses/aides-paramedicales-sociales-de-sante/",
          "prefix": "les",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre",
          "interestFlag": "_interetAidesSanitaireSocial"
        },
        {
          "slug": "region_normandie-atouts-normandie-avantages-loisirs",
          "label": "Atouts Normandie - Avantages Loisirs",
          "institution": "region_normandie",
          "description": "La Région Normandie soutient la découverte de pratiques culturelles, sportives et liées à l'engagement des jeunes normands de 15 à 25 ans.",
          "conditions": [
            "Créer un compte sur <a target=\"_blank\" rel=\"noopener\" title =\"Créer un compte - Nouvelle fenêtre\" href=\"https://atouts.normandie.fr/\">cette page</a>.",
            "Adhérer à Atouts Normandie pour 10 €."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "28"
              ]
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 15
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 25
            }
          ],
          "profils": [],
          "link": "https://www.normandie.fr/sites/default/files/2021-07/Flyer%20Loisirs%20Atouts%20Normandie.pdf",
          "teleservice": "https://atouts.normandie.fr/",
          "prefix": "l’aide",
          "type": "float",
          "unit": "€",
          "periodicite": "annuelle",
          "legend": "maximum",
          "montant": 170
        },
        {
          "slug": "region_normandie-pass-monde-etudes-supérieures-1",
          "label": "pass Monde Etudes supérieures",
          "imgSrc": "img/benefits/logo-pass-monde.jpg",
          "institution": "region_normandie",
          "description": "La Région Normandie accompagne les jeunes normand·e·s âgé·e·s de 15 à 30 ans inscrit·e·s dans un cursus d’études supérieures en formation initiale ou continue de niveau III, II et I  et effectuant une mobilité internationale individuelle (stage en milieu professionnel, séjour d'études en établissement étranger, mission d'étude et d'enquête sur le terrain).",
          "conditions": [
            "Être inscrit·e dans un établissement normand ou dans une autre région de France si la filière ou le type de cursus n'existe pas en Normandie.",
            "Effectuer une mobilité d'un minimum de 4 semaines (financement jusqu'à 26 semaines maximum).",
            "Déposer la demande avant le départ en mobilité.",
            "Présenter un projet qui se déroule en Europe ou hors Europe (DOM TOM non éligibles)."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "28"
              ]
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 15
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 30
            },
            {
              "type": "quotient_familial",
              "floor": 30000,
              "period": "year"
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": []
            },
            {
              "type": "apprenti",
              "conditions": []
            },
            {
              "type": "stagiaire",
              "conditions": []
            },
            {
              "type": "professionnalisation",
              "conditions": []
            }
          ],
          "link": "https://www.normandie.fr/pass-monde",
          "teleservice": "https://atouts.normandie.fr/beneficiaires/Views/Accueil.aspx",
          "prefix": "le",
          "type": "float",
          "unit": "€",
          "periodicite": "autre",
          "legend": "Maximum",
          "montant": 1440,
          "interestFlag": "_interetEtudesEtranger"
        },
        {
          "slug": "region_normandie-pass-monde-volontariat",
          "label": "Pass Monde Volontariat",
          "imgSrc": "img/benefits/logo-pass-monde.jpg",
          "institution": "region_normandie",
          "description": "La Région Normandie accompagne les jeunes normand·e·s âgé·e·s de 15 à 30 ans engagé·e·s dans une mobilité internationale avec l’Office Franco-Allemand pour la Jeunesse ou l’Office Franco-Québécois pour la Jeunesse ou l’un des volontariats suivants : le Service Volontaire Européen/Corps Européen de Solidarité, le Service Civique International, le Volontariat de Solidarité Internationale.",
          "conditions": [
            "Être inscrit·e dans un établissement normand ou dans une autre région de France si la filière ou le type de cursus n'existe pas en Normandie.",
            "Effectuer une mobilité d'un minimum de 4 semaines (financement jusqu'à 26 semaines maximum).",
            "Déposer la demande avant le départ en mobilité.",
            "Présenter un projet qui se déroule en Europe ou hors Europe (DOM TOM non éligibles).",
            "Répondre aux critères d'éligibilité des différents types de volontariat."
          ],
          "conditions_generales": [
            {
              "type": "age",
              "operator": ">=",
              "value": 15
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 30
            },
            {
              "type": "regions",
              "values": [
                "28"
              ]
            },
            {
              "type": "quotient_familial",
              "floor": 30000,
              "period": "year"
            }
          ],
          "profils": [
            {
              "type": "lyceen",
              "conditions": []
            },
            {
              "type": "apprenti",
              "conditions": []
            },
            {
              "type": "stagiaire",
              "conditions": []
            },
            {
              "type": "professionnalisation",
              "conditions": []
            },
            {
              "type": "chomeur",
              "conditions": []
            },
            {
              "type": "salarie",
              "conditions": []
            },
            {
              "type": "independant",
              "conditions": []
            },
            {
              "type": "service_civique",
              "conditions": []
            }
          ],
          "link": "https://www.normandie.fr/pass-monde-volontariat",
          "teleservice": "https://atouts.normandie.fr/beneficiaires/Views/Accueil.aspx",
          "prefix": "le",
          "type": "float",
          "unit": "€",
          "periodicite": "annuelle",
          "legend": "Maximum",
          "montant": 1440,
          "interestFlag": "_interetEtudesEtranger"
        },
        {
          "slug": "region_nouvelle_aquitaine-bourses-pour-les-étudiants-en-formations-sociales-paramédicales-et-de-santé",
          "label": "bourses d'études des formations sociales, paramédicales et de santé",
          "institution": "region_nouvelle_aquitaine",
          "description": "La Région Nouvelle-Aquitaine finance des bourses d'études dans le secteur social, paramédical et de santé.",
          "conditions": [
            "Effectuer votre formation dans un institut agréé par la Région."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "75"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": []
            }
          ],
          "link": "https://les-aides.nouvelle-aquitaine.fr/amenagement-du-territoire/bourses-detudes-sur-criteres-sociaux-2022-etudiants-en-formations-sociales-paramedicales-et-de-sante?Profil=Apprenti____%C3%89tudiant____Jeune%20actif____Lyc%C3%A9en____Particulier&Jeunesse=Accompagnement%20scolaire____Apprentissage____%C3%89ducation%20et%20formation____Engagement%20et%20citoyennet%C3%A9____Enseignement%20sup%C3%A9rieur____Insertion%20professionnelle____Lyc%C3%A9es____Mobilit%C3%A9%20internationale____Orientation____Sanitaire%20et%20social&Type=Aides",
          "teleservice": "https://mes-demarches.nouvelle-aquitaine.fr/craPortailFO/",
          "instructions": "",
          "prefix": "les",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre",
          "interestFlag": "_interetAidesSanitaireSocial"
        },
        {
          "slug": "region_nouvelle_aquitaine-chèque-livre",
          "label": "chèque Livre",
          "institution": "region_nouvelle_aquitaine",
          "description": "Grâce au Chèque Livre, la Région Nouvelle-Aquitaine offre un e-coupon de 20 euros à utiliser pour l’achat de livres, bandes dessinées, dans plus de <a target=\"_blank\" rel=\"noopener title =\"Librairies référencées - Nouvelle fenêtre\" \"href=\"<https://jeunes.nouvelle-aquitaine.fr/formation/accompagnement-scolaire/le-cheque-livre>\">140 librairies référencées</a> présentes sur l’ensemble du territoire régional.",
          "conditions": [],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "75"
              ]
            }
          ],
          "profils": [
            {
              "type": "lyceen",
              "conditions": []
            },
            {
              "type": "apprenti",
              "conditions": []
            }
          ],
          "link": "https://jeunes.nouvelle-aquitaine.fr/formation/accompagnement-scolaire/le-cheque-livre",
          "teleservice": "https://www.mesdemarches.aidesrentree.fr/Beneficiaires/Views/Accueil.aspx",
          "form": "",
          "instructions": "",
          "prefix": "le",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "montant": 20
        },
        {
          "slug": "region_nouvelle_aquitaine-stages-à-létranger-–-infra-bac",
          "label": "stage à l'étranger (lycéen·ne·s)",
          "institution": "region_nouvelle_aquitaine",
          "description": "La Région finance une partie de votre stage à l'étranger d'une durée de 2 à 4 semaines.",
          "prefix": "les",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "75"
              ]
            }
          ],
          "profils": [
            {
              "type": "lyceen",
              "conditions": []
            },
            {
              "type": "apprenti",
              "conditions": []
            }
          ],
          "conditions": [],
          "interestFlag": "_interetEtudesEtranger",
          "type": "float",
          "unit": "€",
          "periodicite": "mensuelle",
          "legend": "maximum",
          "montant": 400,
          "link": "https://les-aides.nouvelle-aquitaine.fr/amenagement-du-territoire/stages-letranger-public-infra-bac",
          "teleservice": "https://cas.aquitaine.fr/cas/login?service=https%3A%2F%2Fmes-demarches.nouvelle-aquitaine.fr%2FcraPortailFO%2Flogin%2Fcas",
          "instructions": "https://les-aides.nouvelle-aquitaine.fr/amenagement-du-territoire/stages-letranger-public-infra-bac"
        },
        {
          "slug": "region_occitanie-éco-chèque-mobilité-achat-d’une-voiture-électrique-ou-hybride-rechargeable-d’occasion",
          "label": "\"Éco-chèque mobilité\" - Achat d’une voiture électrique ou hybride rechargeable d’occasion",
          "institution": "region_occitanie",
          "description": "La Région Occitanie propose une aide financière pour toute personne souhaitant acquérir une voiture électrique ou hybride rechargeable à hauteur de 30% du coût d'acquisition.",
          "conditions": [
            "Acheter votre voiture d’occasion (12 mois au moins) à compter du 1er janvier 2022, auprès d’un professionnel de la vente d’automobile exerçant son activité sur le territoire de la Région.",
            "Justifier d’un revenu fiscal de référence inférieur ou égal à 50 000 €.",
            "Faire la demande dans les 6 mois suivants l’achat de votre voiture."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "76"
              ]
            },
            {
              "type": "age",
              "operator": ">=",
              "value": 18
            }
          ],
          "profils": [],
          "link": "https://www.laregion.fr/Eco-cheque-mobilite-voiture-electrique-ou-hybride",
          "teleservice": "https://mesaidesenligne.laregion.fr/aides/#/croccitanie/connecte/F_DMP_E_CHEQ_VO/depot/simple",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "autre",
          "legend": "maximum",
          "montant": 4000
        },
        {
          "slug": "region_pays_de_la_loire-aide-au-financement-du-permis-de-conduire",
          "label": "Aide au financement du permis de conduire",
          "institution": "region_pays_de_la_loire",
          "description": "La Région des Pays de la Loire propose une aide financière pour passer le permis de conduire (permis B).",
          "conditions": [
            "Être inscrit·e à Pôle Emploi."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "52"
              ]
            }
          ],
          "profils": [
            {
              "type": "chomeur",
              "conditions": [
                {
                  "type": "age",
                  "operator": "<=",
                  "value": 26
                }
              ]
            }
          ],
          "link": "https://mes-aides.pole-emploi.fr/region-pays-de-la-loire/aide-regionale-au-permis-de-conduire-pays-de-la-loire",
          "teleservice": "https://www.moncompteformation.gouv.fr/espace-prive/html/#/",
          "instructions": "https://mes-aides.pole-emploi.fr/region-pays-de-la-loire/aide-regionale-au-permis-de-conduire-pays-de-la-loire",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 1600,
          "interestFlag": "_interetPermisDeConduire"
        },
        {
          "slug": "region_pays_de_la_loire-aide-à-l’achat-d’un-vélo-pliant-ou-à-assistance-électrique-vae",
          "label": "aide à l’achat d’un vélo pliant ou à assistance électrique (VAE)",
          "institution": "region_pays_de_la_loire",
          "description": "La Région des Pays de la Loire encourage les abonné·e·s du TER Pays de la Loire à s’équiper de vélo pliant et de vélo à assistance électrique en finançant une partie du coût d'achat.",
          "conditions": [
            "Être abonné·e du TER Pays de la Loire."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "52"
              ]
            }
          ],
          "profils": [],
          "link": "https://www.paysdelaloire.fr/les-aides/aide-lachat-dun-velo-pliant-ou-assistance-electrique-vae-pour-les-abonnes-aleop",
          "teleservice": "https://les-aides.paysdelaloire.fr/account-management/prod-demandeurs/ux/#/login?redirectTo=https:%2F%2Fles-aides.paysdelaloire.fr%2Fles-aides%2F%23%2Fprod%2Fconnecte%2FVELO_PLIANT_TER%2Fdepot%2Fsimple&jwtKey=jwt-prod-portail-depot-demande-aides&footer=https:%2F%2Fles-aides.paysdelaloire.fr%2Fles-aides%2F%23%2Fprod%2Fmentions-legales,Mentions%20l%C3%A9gales,_self",
          "prefix": "l’",
          "type": "bool",
          "unit": "€",
          "periodicite": "autre"
        },
        {
          "slug": "region_pays_de_la_loire-bourses-pour-les-élèves-et-étudiant-e-s-en-formation-sanitaire-et-sociale",
          "label": "bourses pour les élèves et étudiant·e·s en formation sanitaire et sociale",
          "institution": "region_pays_de_la_loire",
          "description": "La Région finance des bourses pour les élèves et étudiant·e·s dont les revenus familiaux ou personnels sont reconnus insuffisants au regard de leurs charges.",
          "conditions": [
            "Suivre une formation agréée ou autorisée par la Région Pays de la Loire."
          ],
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "52"
              ]
            },
            {
              "type": "formation_sanitaire_social"
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": []
            }
          ],
          "link": "https://www.paysdelaloire.fr/les-aides/bourses-regionales-pour-les-eleves-et-etudiants-en-formation-initiale-sociale-paramedicale-et-de?sous_thematique=215",
          "teleservice": "https://maboursesanitaireetsociale.paysdelaloire.fr/Profil-public/public/paysdelaloire/bourse/accueil.do",
          "prefix": "les",
          "type": "bool",
          "unit": "€",
          "periodicite": "ponctuelle",
          "interestFlag": "_interetAidesSanitaireSocial"
        },
        {
          "slug": "region_pays_de_la_loire-envoléo",
          "label": "envoléo",
          "institution": "region_pays_de_la_loire",
          "description": "La Région prend en charge une partie du financement d'un séjour d'études ou d'un stage à l'étranger de 3 mois minimum réalisé dans le cadre du cursus de formation.",
          "prefix": "l’aide",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "52"
              ]
            },
            {
              "type": "age",
              "operator": "<=",
              "value": 28
            }
          ],
          "profils": [
            {
              "type": "enseignement_superieur",
              "conditions": []
            }
          ],
          "conditions": [
            "Être présélectionné·e par votre établissement de formation pour candidater au programme."
          ],
          "interestFlag": "_interetEtudesEtranger",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "montant": 1000,
          "link": "https://www.paysdelaloire.fr/les-aides/envoleo",
          "teleservice": "https://tsenvoleo.paysdelaloire.fr/MobiliteCRPL/workflow_url?ECITIZ_ACTIVITY_PATH=ActeurDemandeurIdentifie&USERID=CreationCompte&ECITIZ_DECONNECTION_URL=close.htm",
          "instructions": "https://www.paysdelaloire.fr/les-aides/envoleo"
        },
        {
          "slug": "region_pays_de_la_loire-fonds-social-lycéen-régional",
          "label": "fonds social lycéen régional",
          "institution": "region_pays_de_la_loire",
          "description": "La Région propose une aide financière aux familles en difficulté pour leurs dépenses d'hébergement, de restauration et de transport.",
          "prefix": "le",
          "conditions_generales": [
            {
              "type": "regions",
              "values": [
                "52"
              ]
            }
          ],
          "profils": [
            {
              "type": "lyceen",
              "conditions": []
            }
          ],
          "conditions": [
            "Prendre contact auprès de votre établissement et constituer un dossier."
          ],
          "type": "bool",
          "unit": "€",
          "periodicite": "autre",
          "link": "https://www.paysdelaloire.fr/les-aides/fonds-social-lyceen-regional",
          "instructions": "https://www.paysdelaloire.fr/les-aides/fonds-social-lyceen-regional"
        }
      ]
    },
    "benefits_openfisca": {
      "name": "benefits_openfisca",
      "label": "Aides complexes",
      "label_singular": "Aide complexe",
      "identifier_field": "label",
      "folder": "data/benefits/openfisca",
      "create": true,
      "delete": false,
      "editor": {
        "preview": true
      },
      "slug": "{{label}}",
      "extension": "yml",
      "fields": [
        {
          "label": "Nom de l'aide",
          "name": "label",
          "widget": "string"
        },
        {
          "label": "Logo",
          "name": "imgSrc",
          "widget": "hidden",
          "allow_multiple": false,
          "required": false,
          "public_folder": "img/benefits",
          "media_folder": "/public/img/benefits",
          "hint": "Utile si vous voulez afficher le logo de l'aide à la place de l'image de l'institution."
        },
        {
          "label": "Institution",
          "name": "institution",
          "widget": "institution",
          "collection": "institutions",
          "value_field": "{{slug}}",
          "display_fields": [
            "{{name}}"
          ],
          "search_fields": [
            "slug",
            "name"
          ],
          "allow_multiple": false,
          "options_length": -1,
          "filter": {
            "label": "Type :",
            "default": "*",
            "target": "type",
            "fields": [
              {
                "label": "Organisation nationale",
                "value": "national"
              },
              {
                "label": "Région",
                "value": "region"
              },
              {
                "label": "Département",
                "value": "departement"
              },
              {
                "label": "CAF locale",
                "value": "caf"
              },
              {
                "label": "EPCI (Métropole, inter-communauté, etc.)",
                "value": "epci"
              },
              {
                "label": "Commune",
                "value": "commune"
              },
              {
                "label": "Autre",
                "value": "autre"
              }
            ]
          },
          "hint": "Si votre institution n'apparaît pas dans la liste, vous pouvez [l'ajouter ici](https://contribuer-aides-jeunes.netlify.app/admin/#/collections/institutions/new)."
        },
        {
          "label": "Courte description",
          "name": "description",
          "widget": "description"
        },
        {
          "label": "Article défini",
          "name": "prefix",
          "hint": "L'ajout d'un article défini permet la formation de phrase grammaticalement correcte.\nPar exemple, dans la phrase « Comment obtenir l'aide exceptionnelle ? », on choisit le préfixe « l' ». Pour « Comment obtenir les aides au logement ? », on choisit le préfixe « les ».\n",
          "widget": "select",
          "required": true,
          "options": [
            "le",
            "la",
            "les",
            "l’",
            "une",
            "un",
            "l’aide"
          ]
        },
        {
          "label": "Conditions non prises en compte par le simulateur",
          "label_singular": "Condition non prise en compte par le simulateur",
          "hint": "Certains critères d'éligibilité ne peuvent pas être demandés (trop précis)\nni pris en compte (trop complexe) dans le cadre d'un simulateur grand public.\nCette liste permet d'informer les usagers sur ces critères supplémentaires.\nPar exemple : Signer un contrat d’engagement réciproque (CER) avec votre département\nou un Projet Personnalisé d’Accès à l’Emploi (PPAE) avec Pôle emploi.\n",
          "name": "conditions",
          "widget": "list",
          "collapsed": false,
          "field": {
            "label": "Texte",
            "name": "text",
            "widget": "string",
            "pattern": [
              "\\.$",
              "La condition doit commencer par un verbe à l'infinitif et se terminer par un point"
            ]
          }
        },
        {
          "label": "Faut-il limiter l'affichage de l'aide en fonction d'un intérêt particulier ?",
          "name": "interestFlag",
          "hint": "En fin de parcours, des questions sont posées pour connaitre certains intérêts des usagers.\nCela permet d'éviter d'afficher certaines aides qui ne seraient pas pertinentes pour les usagers.\n",
          "widget": "select",
          "required": false,
          "options": [
            {
              "label": "Oui, l'afficher en cas d'intérêt pour le BAFA ou le BAFD.",
              "value": "_interetBafa"
            },
            {
              "label": "Oui, l'afficher en cas d'intérêt pour passer le permis de conduire.",
              "value": "_interetPermisDeConduire"
            },
            {
              "label": "Oui, l'afficher en cas d'intérêt pour faire des études à l'étranger.",
              "value": "_interetEtudesEtranger"
            },
            {
              "label": "Oui, l'afficher en cas d'intérêt pour faire une formation dans le sanitaire et social.",
              "value": "_interetAidesSanitaireSocial"
            }
          ]
        },
        {
          "label": "Type du résultat",
          "name": "type",
          "default": "float",
          "widget": "select",
          "options": [
            {
              "label": "Valeur numérique",
              "value": "float"
            },
            {
              "label": "Éligibilité (Oui / Non)",
              "value": "bool"
            },
            {
              "label": "Autre",
              "value": "mixed"
            }
          ]
        },
        {
          "label": "Unité lorsque le type de résultat est une valeur numérique",
          "hint": "Il s'agit de spécifier l'unité du montant.",
          "name": "unit",
          "widget": "select",
          "default": "€",
          "required": false,
          "options": [
            {
              "label": "€",
              "value": "€"
            },
            {
              "label": "%",
              "value": "%"
            },
            {
              "label": "séances",
              "value": "séances"
            }
          ]
        },
        {
          "label": "Périodicité de l'aide",
          "name": "periodicite",
          "widget": "select",
          "hint": "Cette variable permet de définir la fréquence de versement de l'aide.",
          "required": true,
          "default": "autre",
          "options": [
            {
              "label": "Ponctuelle",
              "value": "ponctuelle"
            },
            {
              "label": "Mensuelle (/ mois)",
              "value": "mensuelle"
            },
            {
              "label": "Annuelle (/ an)",
              "value": "annuelle"
            },
            {
              "label": "Autre",
              "value": "autre"
            }
          ]
        },
        {
          "label": "Légende associée à la valeur numérique",
          "hint": "Cette légende permet d'ajouter des informations complémentaires sur le montant. Par exemple, si vous écrivez \"maximum\" dans ce champ, et que vous avez indiqué \"200 €\" en montant, cela affichera \"200 € maximum\".",
          "name": "legend",
          "widget": "string",
          "required": false
        },
        {
          "label": "Lien vers la page d'informations de référence",
          "hint": "Vers un site institutionnel de préférence (par exemple, pour les aides nationales il s'agit souvent de service-public.fr)",
          "name": "link",
          "widget": "string",
          "pattern": [
            "^(https?://)",
            "Le champ doit contenir une url valide"
          ]
        },
        {
          "label": "Lien vers un téléservice",
          "hint": "Lorsqu'il y a la possibilité de faire la démarche en ligne.",
          "name": "teleservice",
          "widget": "string",
          "pattern": [
            "^(https?://)",
            "Le champ doit contenir une url valide"
          ],
          "required": false,
          "required_group": "cta"
        },
        {
          "label": "Lien vers un formulaire à imprimer",
          "name": "form",
          "widget": "string",
          "pattern": [
            "^(https?://)",
            "Le champ doit contenir une url valide"
          ],
          "required": false,
          "required_group": "cta"
        },
        {
          "label": "Lien vers des instructions à suivre",
          "name": "instructions",
          "widget": "string",
          "pattern": [
            "^(https?://)",
            "Le champ doit contenir une url valide"
          ],
          "required": false,
          "required_group": "cta"
        },
        {
          "label": "Niveau de fiabilité lorsque le résultat est un montant",
          "name": "floorAt",
          "widget": "select",
          "required": false,
          "options": [
            {
              "label": "10 (pour arrondir à la dizaine inférieure)",
              "value": 10
            },
            {
              "label": "1 (pour l'euro inférieur)",
              "value": 1
            },
            {
              "label": "0.01 (pour éviter l'arrondi)",
              "value": 0.01
            }
          ]
        },
        {
          "label": "Entité de rattachement de la variable dans OpenFisca",
          "name": "entity",
          "widget": "select",
          "hint": "Cette variable fait le lien avec le moteur de calculs. Tant que vous ne savez pas quoi y indiquer, nous recommandons de laisser Ménage.",
          "options": [
            {
              "label": "Famille",
              "value": "familles"
            },
            {
              "label": "Foyer Fiscal",
              "value": "foyers_fiscaux"
            },
            {
              "label": "Individu",
              "value": "individus"
            },
            {
              "label": "Ménage",
              "value": "menages"
            }
          ]
        },
        {
          "label": "Période de la variable dans OpenFisca",
          "name": "openfiscaPeriod",
          "widget": "select",
          "hint": "Cette variable fait le lien avec le moteur de calculs. Tant que vous ne savez pas quoi y indiquer, nous recommandons de laisser Mois.",
          "options": [
            {
              "label": "Mois",
              "value": "thisMonth"
            },
            {
              "label": "Année",
              "value": "thisYear"
            }
          ]
        },
        {
          "label": "Masquer l'aide",
          "name": "private",
          "required": false,
          "widget": "hidden"
        },
        {
          "label": "Pondération de l'aide sur la page de résultat",
          "name": "top",
          "widget": "hidden"
        },
        {
          "label": "Affiche un message d'avertissement spécifique",
          "name": "warning",
          "widget": "hidden"
        },
        {
          "label": "Liens vers plusieurs téléservices",
          "name": "teleservices",
          "widget": "hidden"
        },
        {
          "label": "Aide calculée en fonction des revenus N-2",
          "name": "isBaseRessourcesYearMinusTwo",
          "widget": "hidden"
        },
        {
          "label": "Aide restreinte aux usagers de la MSA",
          "name": "msa",
          "widget": "hidden"
        },
        {
          "label": "Liens vers des formulaires de demande de l'aide",
          "name": "forms",
          "widget": "hidden"
        },
        {
          "label": "L'aide ne couvre qu'une partie des frais",
          "name": "participation",
          "widget": "hidden"
        },
        {
          "label": "Référence aux institutions supervisant l'aide",
          "name": "etablissements",
          "widget": "hidden"
        },
        {
          "label": "L'aide OpenFisca a été remise à zéro récemment",
          "name": "setToZeroRecently",
          "widget": "hidden"
        },
        {
          "label": "L'attribution de l'aide est dépendante du capital personnel",
          "name": "isBaseRessourcesPatrimoine",
          "widget": "hidden"
        },
        {
          "label": "Identifiant OpenFisca de l'aide",
          "name": "openfisca_eligibility_source",
          "widget": "hidden"
        }
      ],
      "items": [
        {
          "slug": "aah",
          "label": "allocation aux adultes handicapés",
          "institution": "caf",
          "description": "L’allocation aux adultes handicapés (AAH) est une aide financière qui permet d’assurer un revenu minimum. Cette aide est attribuée sous réserve de respecter 4 critères : le taux d’incapacité, l’âge, la nationalité et les ressources. L’AAH peut se cumuler soit avec le complément de ressources, soit avec la majoration pour la vie autonome ou, dans certains cas, l’aide à l’autonomie.\n",
          "link": "https://www.service-public.fr/particuliers/vosdroits/F12242",
          "instructions": "https://www.service-public.fr/particuliers/vosdroits/F12242",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "isBaseRessourcesYearMinusTwo": true,
          "forms": {
            "mdph": "https://www.formulaires.modernisation.gouv.fr/gf/cerfa_13788.do"
          },
          "msa": true,
          "periodicite": "mensuelle",
          "floorAt": 10
        },
        {
          "slug": "aeeh",
          "label": "allocation d’éducation de l’enfant handicapé (AEEH)",
          "institution": "caf",
          "description": "L’allocation d’éducation de l’enfant handicapé (AEEH) est une prestation destinée à compenser les frais d’éducation et de soins apportés à un enfant en situation de handicap. Cette aide est versée à la personne qui en assume la charge.\n",
          "link": "https://www.service-public.fr/particuliers/vosdroits/F14809",
          "instructions": "https://www.service-public.fr/particuliers/vosdroits/F14809",
          "forms": {
            "mdph": "https://www.formulaires.modernisation.gouv.fr/gf/cerfa_15692.do"
          },
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "floorAt": 1,
          "entity": "familles",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "mensuelle",
          "msa": true
        },
        {
          "slug": "af",
          "label": "allocations familiales",
          "institution": "caf",
          "description": "Les allocations familiales sont réservées aux personnes ayant au moins 2 enfants de moins de 20 ans à charge. Le montant des prestations dépend des ressources, du nombre d’enfants à charge et de leurs âges. Elles sont versées tous les mois. Dans les DOM, les allocations sont versées à partir du premier enfant.",
          "conditions": [
            "Résider <abbr title=\"Métropole, Guadeloupe, Guyane, Martinique ou Réunion\">en France</abbr> plus de <abbr title=\"180 jours, potentiellement discontinus\">6 mois</abbr> cette année."
          ],
          "link": "https://www.service-public.fr/particuliers/vosdroits/F13213",
          "form": "https://wwwd.caf.fr/wps/portal/caffr/aidesetservices/lesservicesenligne/faireunedemandedeprestation/allocationsfamilialesouchangementdesituation",
          "forms": {
            "caf": "https://wwwd.caf.fr/wps/portal/caffr/aidesetservices/lesservicesenligne/faireunedemandedeprestation/allocationsfamilialesouchangementdesituation",
            "msa": "http://www.msa.fr/lfr/documents/11566/132715/D%C3%A9claration+de+situation+pour+les+prestations+familiales+et+aides+au+logement.pdf"
          },
          "isBaseRessourcesYearMinusTwo": true,
          "prefix": "les",
          "type": "float",
          "unit": "€",
          "entity": "familles",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "mensuelle",
          "msa": true
        },
        {
          "slug": "agepi",
          "label": "aide à la garde d’enfants pour parent isolé",
          "institution": "pole_emploi",
          "description": "L’aide à la garde d’enfants pour parent isolé (Agepi) est un dispositif de Pole Emploi pour les personnes qui reprennent un emploi.\n",
          "link": "https://www.service-public.fr/particuliers/vosdroits/F1814",
          "instructions": "https://www.service-public.fr/particuliers/vosdroits/F1814",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "floorAt": 1,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "mensuelle"
        },
        {
          "slug": "aide_financement_permis_apprenti",
          "label": "aide au financement du permis de conduire pour les apprentis",
          "institution": "etat",
          "description": "Cette aide permet aux apprentis majeurs de financer leur une partie du coût de la formation au permis de conduire.",
          "link": "https://travail-emploi.gouv.fr/formation-professionnelle/formation-en-alternance-10751/apprentissage/apprentis-aide-permis",
          "instructions": "https://travail-emploi.gouv.fr/formation-professionnelle/formation-en-alternance-10751/apprentissage/apprentis-aide-permis#Comment-obtenir-l-aide-au-financement-du-permis-de-conduire-B-nbsp",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "floorAt": 1,
          "periodicite": "ponctuelle",
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "interestFlag": "_interetPermisDeConduire"
        },
        {
          "slug": "aide_formation_gen",
          "label": "aide aux apprenants de la Grande École du Numérique",
          "institution": "etat",
          "description": "La Grande École du numérique (GEN) est une labellisation des formations courtes et qualifiantes permettent d’acquérir un socle professionnalisant de compétences numériques. Cette aide délivrée par le CROUS s’adresse à des personnes diplômées en situation de recherche d’emploi ou dépourvues de qualification professionnelle ou de diplôme.",
          "link": "https://www.grandeecolenumerique.fr/espace-apprenants/se-renseigner-sur-les-aides",
          "teleservice": "https://dse.phm.education.gouv.fr/GEN/",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "floorAt": 0.01,
          "entity": "individus",
          "periodicite": "mensuelle",
          "openfiscaPeriod": "thisMonth",
          "conditions": [
            "Ne pas percevoir une aide à l’insertion, une aide à la formation professionnelle ou une bourse d’enseignement supérieur sur critères sociaux.",
            "Ne pas suivre une formation avec un contrat d’apprentissage ou de professionnalisation ou être en congé individuel de formation."
          ]
        },
        {
          "slug": "aide_logement",
          "label": "aides au logement",
          "periodicite": "mensuelle",
          "unit": "€",
          "type": "float",
          "description": "Les aides au logement regroupent trois aides différentes non cumulables : l’aide personnalisée au logement (Apl), l’allocation de logement familiale (Alf) et l’allocation de logement sociale (Als). Elles concernent les personnes ayant de faibles ressources, locataires ou remboursant le prêt de leur résidence principale. Elles sont versées par la Caf ou la MSA.",
          "conditions": [
            "Résider au moins 8 mois par an dans le logement que vous avez décrit.",
            "Le logement doit être <a target=\"_blank\" rel=\"noopener\" href=\"https://www.caf.fr/aides-et-services/connaitre-vos-droits-selon-votre-situation/vous-louez-ou-vous-achetez-un-logement/vous-occupez-un-logement-insalubre-ou-non-decent\" aria-describedby=\"9 mètres carrés par personne, fenêtre, WC, eau potable, électricité\" title=\"Logement insalubre ou non-décent - Nouvelle fenêtre\" >décent</a>."
          ],
          "link": "https://www.service-public.fr/particuliers/vosdroits/N20360",
          "teleservice": "https://wwwd.caf.fr/wps/portal/caffr/aidesetservices/lesservicesenligne/faireunedemandedeprestation/demanderlaideaulogement/",
          "teleservices": {
            "caf": "https://wwwd.caf.fr/wps/portal/caffr/aidesetservices/lesservicesenligne/faireunedemandedeprestation/demanderlaideaulogement/",
            "msa": "http://www.msa.fr/lfr/c/bookmarks/open_entry?entryId=98643"
          },
          "entity": "familles",
          "openfiscaPeriod": "thisMonth",
          "isBaseRessourcesPatrimoine": true,
          "floorAt": 10,
          "prefix": "les",
          "msa": true,
          "institution": "caf"
        },
        {
          "slug": "aide_merite_montant",
          "label": "Aide au mérite",
          "institution": "etat",
          "description": "L'aide au mérite concerne les étudiants ayant reçu une mention \"très bien\" durant la dernière session du baccalauréat.",
          "link": "https://www.etudiant.gouv.fr/fr/aide-au-merite-1291",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "annuelle",
          "conditions": [
            "avoir obtenu la mention « très bien » à la dernière session du baccalauréat ou durant celle de l'année précédente."
          ]
        },
        {
          "slug": "aide_mobilite_internationale_eligibilite",
          "label": "aide à la mobilité internationale",
          "institution": "etat",
          "description": "L'aide à la mobilité internationale vous permet de suivre une formation supérieure à l'étranger dans le cadre d'un programme d'échanges ou d'effectuer un stage international.",
          "link": "https://www.etudiant.gouv.fr/fr/bourses-erasmus-et-aide-la-mobilite-internationale-ami-67",
          "instructions": "https://www.etudiant.gouv.fr/fr/bourses-erasmus-et-aide-la-mobilite-internationale-ami-67",
          "conditions": [
            "Ne pas cumuler plus de 9 mois d'aide à la mobilité internationale au cours de l'ensemble de vos études supérieures."
          ],
          "prefix": "l’",
          "type": "bool",
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "interestFlag": "_interetEtudesEtranger",
          "periodicite": "autre"
        },
        {
          "slug": "aide_mobilite_master",
          "label": "aide à la mobilité Master",
          "institution": "etat",
          "description": "Cette aide facilite la mobilité géographique des étudiants titulaires d’un diplôme national de licence qui s’inscrivent pour la première fois en première année de diplôme national de master qui fait suite à l’année d’obtention de la licence dans une région académique différente.",
          "link": "https://www.service-public.fr/particuliers/vosdroits/F34343",
          "instructions": "https://www.etudiant.gouv.fr/fr/aide-la-mobilite-en-master-1504",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "floorAt": 1,
          "periodicite": "ponctuelle",
          "entity": "individus",
          "openfiscaPeriod": "thisMonth"
        },
        {
          "slug": "aide_mobilite_parcoursup",
          "label": "aide à la mobilité ParcourSup",
          "institution": "etat",
          "description": "L'aide à la mobilité Parcoursup est destinée au bachelier qui effectue son entrée dans l'enseignement supérieur en dehors de son académie de résidence.",
          "conditions": [
            "Accepter définitivement une proposition d’admission dans Parcoursup."
          ],
          "link": "https://www.service-public.fr/particuliers/vosdroits/F34643",
          "teleservice": "https://amp.etudiant.gouv.fr",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "floorAt": 1,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth"
        },
        {
          "slug": "aide_permis_demandeur_emploi",
          "label": "aide à l’obtention du permis de conduire",
          "institution": "pole_emploi",
          "description": "L’aide à l’obtention du permis de conduire permet une prise en charge totale ou partielle par Pôle emploi des frais d'apprentissage du permis de conduire (permis B). L'aide est attribuée dans la limite des enveloppes budgétaires disponibles qui varient en fonction des régions.",
          "link": "https://www.service-public.fr/particuliers/vosdroits/F1719",
          "conditions": [
            "Être inscrit comme demandeur d'emploi depuis au moins 6 ou bien avoir une promesse d’embauche en CDI, CDD ou contrat d'intérim d'au moins 3 mois.",
            "Ne pas bénéficier d'un autre dispositif d'aide au permis de la part d'un organisme public ou privé."
          ],
          "instructions": "https://www.pole-emploi.fr/candidat/vos-recherches/les-aides-financieres/aide-a-lobtention-du-permis-de-c.html",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "floorAt": 1,
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "interestFlag": "_interetPermisDeConduire"
        },
        {
          "slug": "aide_permis_pro_btp",
          "label": "aide au financement du permis voiture (B) pour les apprentis en BTP",
          "institution": "pro_btp",
          "description": "Cette aide est un coup de pouce de Pro BTP pour favoriser l’autonomie et l’insertion des apprentis de 15 à 30 ans en finançant <a target=\"_blank\" rel=\"noopener\" title=\"le permis voiture (B) - Nouvelle fenêtre\" href=\"https://www.service-public.fr/particuliers/vosdroits/F2828\">le permis voiture (B)</a>.",
          "conditions": [
            "Être adhérent pour la retraite à PRO BTP.",
            "Être inscrit dans une école de conduite."
          ],
          "link": "https://www.probtp.com/part/apprenti/aide-permis.html",
          "instructions": "https://www.probtp.com/part/apprenti/aide-permis.html",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "floorAt": 1,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "ponctuelle",
          "interestFlag": "_interetPermisDeConduire"
        },
        {
          "slug": "apa_eligibilite",
          "label": "Allocation personnalisée d‘autonomie",
          "description": "L’allocation personnalisée d’autonomie (APA) est une aide réservée aux plus de 60 ans en perte d’autonomie. À travers un plan d’action, elle favorise le maintien à domicile et l’amélioration de la qualité de vie des personnes âgées en établissement en subventionnant des services d’aides à la personne. Sa gestion est confiée aux conseils départementaux.",
          "conditions": [
            "Faire évaluer votre perte d’autonomie (classement GIR) à domicile par les services sociaux de votre département.",
            "Accepter le plan d’aide proposé par votre département.",
            "Résider depuis plus de trois mois dans votre département.",
            "Ne pas percevoir <a target=\"_blank\" rel=\"noopener\" title=\"Service Public.fr - Peut-on cumuler l‘APA avec d‘autres revenus ? - Nouvelle fenêtre\" href=\"https://www.service-public.fr/particuliers/vosdroits/F11678\">certaines autres aides à l‘autonomie</a> non cumulables avec l‘APA."
          ],
          "link": "https://www.pour-les-personnes-agees.gouv.fr/preserver-son-autonomie-s-informer-et-anticiper/perte-d-autonomie-evaluation-et-droits/lallocation-personnalisee-dautonomie-apa",
          "instructions": "https://www.pour-les-personnes-agees.gouv.fr/preserver-son-autonomie-s-informer-et-anticiper/perte-d-autonomie-evaluation-et-droits/lallocation-personnalisee-dautonomie-apa#anchor5",
          "prefix": "l’",
          "type": "bool",
          "top": 6,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "institution": "departements",
          "periodicite": "autre"
        },
        {
          "slug": "asf",
          "label": "allocation de soutien familial",
          "institution": "caf",
          "description": "L’allocation de soutien familial (ASF) est destinée soit au parent qui élève seul un enfant non reconnu, privé de l’aide d’un parent ou dont l’autre parent est décédé, soit à la personne seule ou en couple qui recueille un enfant. L’ASF est versée par la Caf ou la MSA tous les mois.",
          "conditions": [
            "Toucher une pension alimentaire d’un montant inférieur à celui de l’ASF ou ne pas toucher en intégralité une pension alimentaire attribuée par une décision de justice. À noter : la Caf ou la MSA peut vous aider à <a target=\"_blank\" rel=\"noopener\" title=\"Agence de recouvrement des impayés de pensions alimentaires (ARIPA) - Nouvelle fenêtre\" href=\"https://www.pension-alimentaire.caf.fr/\">récupérer les sommes dues</a>."
          ],
          "link": "https://www.caf.fr/aides-et-services/s-informer-sur-les-aides/solidarite-et-insertion/l-allocation-de-soutien-familial-asf-0",
          "form": "https://wwwd.caf.fr/wps/portal/caffr/aidesetservices/lesservicesenligne/faireunedemandedeprestation/allocationdesoutienfamilial/!ut/p/a1/",
          "forms": {
            "caf": "https://wwwd.caf.fr/wps/portal/caffr/aidesetservices/lesservicesenligne/faireunedemandedeprestation/allocationdesoutienfamilial/!ut/p/a1/",
            "msa": "http://www.msa.fr/lfr/documents/11566/48472/Demande+d%27allocation+de+soutien+familial+%28ASF%29.pdf"
          },
          "floorAt": 10,
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "entity": "familles",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "mensuelle",
          "msa": true
        },
        {
          "slug": "asi",
          "label": "allocation supplémentaire d’invalidité",
          "institution": "assurance_maladie",
          "description": "L’allocation supplémentaire d’invalidité (Asi) est une prestation accordée à certaines personnes invalides. Elle s’adresse à celles et ceux qui ont de faibles ressources et n’ont pas atteint l’âge de départ à la retraite. Elle est versée tous les mois et s’ajoute, dans une certaine limite, aux revenus personnels.",
          "link": "https://www.service-public.fr/particuliers/vosdroits/F16940",
          "form": "http://www.ameli.fr/fileadmin/user_upload/formulaires/S4151.pdf",
          "forms": {
            "general": "http://www.ameli.fr/fileadmin/user_upload/formulaires/S4151.pdf",
            "cnav": "https://www.lassuranceretraite.fr/portail-info/files/live/sites/pub-bootstrap/files/Guides%20et%20formulaires/demande-asi.pdf",
            "msa": "https://www.formulaires.modernisation.gouv.fr/gf/cerfa_13435.do",
            "rsi": "https://www.rsi.fr/fileadmin/mediatheque/.Espace_telechargement/Formulaires/formulaire_allocation_supplementaire_invalidite.pdf"
          },
          "floorAt": 10,
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "mensuelle",
          "msa": true
        },
        {
          "slug": "aspa",
          "label": "allocation de solidarité aux personnes âgées",
          "institution": "assurance_retraite",
          "description": "L’allocation de solidarité aux personnes âgées (Aspa) est une prestation accordée aux personnes retraitées ayant de faibles ressources. Elle est versée tous les mois par la Carsat (ou la MSA si vous dépendez du régime agricole). Elle s’ajoute, dans une certaine limite, aux revenus personnels. Elle remplace le minimum vieillesse depuis 2006.",
          "conditions": [
            "Résider <abbr title=\"Métropole, Guadeloupe, Guyane, Martinique ou Réunion\">en France</abbr> plus de <abbr title=\"180 jours, potentiellement discontinus\">6 mois</abbr> cette année.",
            "L’ASPA n’est pas compatible avec l’AAH. Avant de faire votre demande, renseignez-vous auprès de votre MDPH : le basculement vers le régime de l’ASPA n’est plus obligatoire pour les personnes qui atteignent depuis 2017 les 62 ans.",
            "Avoir demandé toutes les retraites (générale, réversion, complémentaire…) auxquelles vous avez droit.",
            "Votre conjoint·e doit avoir demandé toutes les retraites (générale, réversion, complémentaire…) auxquelles il ou elle a droit."
          ],
          "link": "https://www.service-public.fr/particuliers/vosdroits/F16871",
          "form": "https://www.lassuranceretraite.fr/portail-info/files/live/sites/pub/files/PDF/demande-aspa.pdf",
          "forms": {
            "cnav": "https://www.lassuranceretraite.fr/portail-info/files/live/sites/pub/files/PDF/demande-aspa.pdf",
            "msa": "https://www.formulaires.modernisation.gouv.fr/gf/cerfa_14953.do",
            "rsi": "https://www.rsi.fr/fileadmin/mediatheque/.Espace_telechargement/Formulaires/Formulaire_allocation_solidarite_personnes_agees.pdf"
          },
          "floorAt": 10,
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "entity": "familles",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "mensuelle",
          "msa": true
        },
        {
          "slug": "ass",
          "label": "allocation de solidarité spécifique",
          "institution": "pole_emploi",
          "description": "L’allocation de solidarité spécifique (ASS) est une aide financière attribuée aux personnes ayant épuisé leurs droits au chômage. Elle peut être versée à taux plein ou à taux réduit. En cas de reprise d’activité, elle peut être maintenue.\n",
          "conditions": [
            "Avoir travaillé au moins 5 ans au cours des 10 ans avant la fin de votre dernier contrat de travail."
          ],
          "link": "https://www.service-public.fr/particuliers/vosdroits/F12484",
          "instructions": "https://www.service-public.fr/particuliers/vosdroits/F12484",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "floorAt": 10,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "mensuelle"
        },
        {
          "slug": "bourse_college",
          "label": "bourse de collège",
          "institution": "education_nationale",
          "description": "La bourse de collège est une aide destinée à favoriser la scolarité des collégiens. Elle est versée aux familles ayant de faibles ressources. Son montant dépend du nombre d’enfants à charge.\n",
          "conditions": [
            "Déposer votre dossier à partir de l’été 2022 pour l’année scolaire 2022-2023.",
            "Pour les élèves scolarisés en collège public, la demande se fait en ligne sur le portail Scolarité Services de votre établissement."
          ],
          "link": "https://www.service-public.fr/particuliers/vosdroits/F984",
          "instructions": "http://www.education.gouv.fr/cid117994/scolarite-services-aide-a-la-premiere-connexion-des-parents.html",
          "isBaseRessourcesYearMinusTwo": true,
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "entity": "familles",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "annuelle"
        },
        {
          "slug": "bourse_criteres_sociaux",
          "label": "bourse sur critères sociaux",
          "institution": "etat",
          "description": "La bourse d'enseignement supérieur sur critères sociaux (BCS) est accordée à l'étudiant qui a des difficultés matérielles pour poursuivre des études supérieures.",
          "conditions": [
            "Ne pas en avoir déjà bénéficié 7 (sept) fois."
          ],
          "link": "https://www.service-public.fr/particuliers/vosdroits/F12214",
          "instructions": "https://www.service-public.fr/particuliers/vosdroits/F12214",
          "prefix": "une",
          "type": "float",
          "unit": "€",
          "floorAt": 0.01,
          "periodicite": "mensuelle",
          "entity": "individus",
          "openfiscaPeriod": "thisMonth"
        },
        {
          "slug": "bourse_lycee",
          "label": "bourse de lycée",
          "institution": "education_nationale",
          "description": "La bourse de lycée est accordée aux responsables d’un lycéen qui ont de faibles ressources. Si l’élève entre au lycée ou s’il n’a jamais touché de bourse de lycée, il pourra y prétendre selon les ressources et les charges de sa famille. Une nouvelle demande doit être effectuée en cas de redoublement ou réorientation.\n",
          "conditions": [
            "Déposer votre dossier avant le 20 juin 2022 pour l’année scolaire 2022-2023.",
            "Pour les élèves scolarisés en lycée public, la demande se fait en ligne sur le portail Scolarité-Services."
          ],
          "link": "https://www.service-public.fr/particuliers/vosdroits/F616",
          "form": "https://www.service-public.fr/simulateur/calcul/11319",
          "forms": {
            "2017-18": "https://www.service-public.fr/simulateur/calcul/11319",
            "2018-19": "https://www.service-public.fr/simulateur/calcul/11319"
          },
          "isBaseRessourcesYearMinusTwo": true,
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "entity": "familles",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "annuelle"
        },
        {
          "slug": "brest_metropole_transport",
          "label": "tarification solidaire transports",
          "institution": "brest_metropole",
          "description": "Les familles aux ressources modestes bénéficient de forfaits mensuels à tarif réduit pour les transports : les forfaits Tempo, Tango et Rythmo. Tous les membres du foyer peuvent en bénéficier.\n",
          "conditions": [
            "Présenter un <a target=\"_blank\" rel=\"noopener\" title=\"justificatif de votre quotient familial - Nouvelle fenêtre\" href=\"http://www.caf.fr/allocataires/vies-de-famille/changement-de-situation/changement-familial/le-quotient-familial-c-est-quoi\">justificatif de votre quotient familial</a> délivré par votre Caf."
          ],
          "link": "https://www.bibus.fr/fr/titres-tarifs/titres/forfait-solidaire-mensuel-tempo",
          "instructions": "https://www.bibus.fr/fr/titres-tarifs/titres/forfait-solidaire-mensuel-tempo",
          "prefix": "la",
          "floorAt": 0.01,
          "type": "float",
          "unit": "€",
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "legend": "au lieu de 38.50 € / mois",
          "periodicite": "mensuelle",
          "participation": true
        },
        {
          "slug": "caah",
          "label": "majoration pour la vie autonome",
          "institution": "caf",
          "description": "La majoration pour la vie autonome (MVA) est une aide financière qui peut s’ajouter à l’allocation aux adultes handicapés (AAH). Elle permet de faire face aux dépenses liées à votre handicap (par exemple, adaptation de votre logement).\n",
          "link": "https://www.service-public.fr/particuliers/vosdroits/F12903",
          "instructions": "https://www.service-public.fr/particuliers/vosdroits/F12903",
          "isBaseRessourcesYearMinusTwo": true,
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "floorAt": 1,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "mensuelle",
          "msa": true
        },
        {
          "slug": "cambrai_aide_mobilite_permis_b",
          "label": "aide au financement du permis voiture (B)",
          "institution": "ville_cambrai",
          "description": "Cette aide est un coup de pouce de la ville de Cambrai pour favoriser l’autonomie et l’insertion des jeunes de 18 à 25 ans en finançant <a target=\"_blank\" rel=\"noopener\" title=\"le permis voiture (B) - Nouvelle fenêtre\" href=\"https://www.service-public.fr/particuliers/vosdroits/F2828\">le permis voiture (B)</a>.",
          "conditions": [
            "Avoir obtenu le code de la route.",
            "Avoir passé la formation secourisme premiers secours PSC1.",
            "Habiter à Cambrai depuis plus d'un an."
          ],
          "link": "https://www.villedecambrai.com/jeunesse/bourses-et-aides/aide-au-permis-de-conduire",
          "instructions": "https://www.villedecambrai.com/jeunesse/bourses-et-aides/aide-au-permis-de-conduire",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "floorAt": 1,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "ponctuelle",
          "interestFlag": "_interetPermisDeConduire"
        },
        {
          "slug": "carte_sncf_eleve_apprenti_eligibilite",
          "label": "carte SNCF élèves et apprenti·e·s",
          "institution": "sncf",
          "description": "La carte SNCF élèves et apprenti·e·s permet d'obtenir des réductions sur ses trajets TGV INOUI et INTERCITES. Pour l'obtenir rendez-vous en boutique.",
          "link": "https://www.sncf.com/fr/offres-voyageurs/cartes-tarifs-grandes-lignes/eleves-apprentis",
          "instructions": "https://www.sncf.com/fr/offres-voyageurs/cartes-tarifs-grandes-lignes/eleves-apprentis",
          "prefix": "la",
          "type": "bool",
          "periodicite": "annuelle",
          "entity": "individus",
          "openfiscaPeriod": "thisMonth"
        },
        {
          "slug": "cf",
          "label": "complément familial",
          "institution": "caf",
          "description": "Le complément familial s’ajoute aux allocations familiales à partir du troisième enfant à charge âgé de plus de 3 ans et de moins de 21 ans. Il est destiné aux familles ayant de faibles ressources. Dans les DOM, le complément familial concerne tous les enfants à charge âgés entre 3 et 5 ans.",
          "link": "https://www.service-public.fr/particuliers/vosdroits/F13214",
          "form": "https://wwwd.caf.fr/wps/portal/caffr/aidesetservices/lesservicesenligne/faireunedemandedeprestation/allocationsfamilialesouchangementdesituation",
          "forms": {
            "caf": "https://wwwd.caf.fr/wps/portal/caffr/aidesetservices/lesservicesenligne/faireunedemandedeprestation/allocationsfamilialesouchangementdesituation",
            "msa": "http://www.msa.fr/lfr/documents/11566/132715/D%C3%A9claration+de+situation+pour+les+prestations+familiales+et+aides+au+logement.pdf"
          },
          "isBaseRessourcesYearMinusTwo": true,
          "prefix": "le",
          "type": "float",
          "unit": "€",
          "entity": "familles",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "mensuelle",
          "msa": true
        },
        {
          "slug": "cheque_energie",
          "label": "chèque Énergie",
          "institution": "etat",
          "description": "Le Chèque Énergie peut être utilisé pour toutes les dépenses d’énergie (électricité, gaz, fioul, bois, etc.) et les travaux de rénovation énergétique. Il est envoyé automatiquement chaque année en fonction de votre situation fiscale et de votre ménage.\n",
          "conditions": [
            "Résider en France au 1er janvier de l‘année.",
            "Effectuer chaque année votre déclaration auprès des impôts.",
            "Utiliser votre Chèque Énergie reçu en avril avant le 31 mars de l’année suivante."
          ],
          "link": "https://www.chequeenergie.gouv.fr/beneficiaire/informations",
          "instructions": "https://www.chequeenergie.gouv.fr/beneficiaire/cheque/paiement-en-ligne",
          "isBaseRessourcesYearMinusTwo": true,
          "prefix": "le",
          "type": "float",
          "unit": "€",
          "entity": "menages",
          "periodicite": "annuelle",
          "openfiscaPeriod": "thisYear"
        },
        {
          "slug": "contrat_engagement_jeune",
          "label": "contrat d'Engagement Jeunes",
          "institution": "etat",
          "description": "Le Contrat d'Engagement Jeune est un parcours de 6 à 12 mois pour vous aider à trouver un emploi. Concrètement, c'est un accompagnement par un·e conseiller·e dédié·e, un programme intensif de 15 à 20 heures par semaine composé de différents types d’activités, et une allocation en fonction de vos ressources.",
          "prefix": "la",
          "conditions": [
            "Faire votre demande d´accompagnement auprès de votre <a target=\"_blank\" title=\"Missions Locales - Nouvelle fenêtre\" rel=\"noopener\" href=\"https://www.unml.info/les-missions-locales/annuaire/\">Mission Locale</a> ou de votre <a target=\"_blank\" title=\"Agences Pôle Emploi - Nouvelle fenêtre\" rel=\"noopener\" href=\"https://www.pole-emploi.fr/annuaire/votre-pole-emploi.html\">agence Pôle Emploi</a>.",
            "Être indépendant·e, notamment ne pas bénéficier d'un soutien financier familial."
          ],
          "type": "float",
          "unit": "€",
          "periodicite": "mensuelle",
          "link": "https://www.service-public.fr/particuliers/vosdroits/F32700",
          "instructions": "https://www.service-public.fr/particuliers/vosdroits/F32700",
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "setToZeroRecently": true,
          "etablissements": [
            "mission_locale"
          ]
        },
        {
          "slug": "cotes_d_armor_fonds_solidarite_logement_energie_eligibilite",
          "label": "fonds de solidarité pour le logement - Aide au paiement des factures d’énergie",
          "institution": "departement_cotes_d_armor",
          "description": "Le fonds de solidarité accorde des aides aux personnes qui rencontrent des difficultés pour assurer les dépenses de leur logement. L’aide « impayé énergie » correspond plus spécifiquement à l’aide au maintien des fournitures en eau, gaz, électricité, fioul et bois.\n",
          "conditions": [
            "Occuper, à titre de résidence principale, un logement sur le territoire du département des Côtes d'Armor."
          ],
          "link": "https://cotesdarmor.fr/vos-services/acceder-ou-se-maintenir-dans-son-logement",
          "form": "https://cotesdarmor.fr/sites/default/files/2019-01/DOSSIER%20DE%20DEMANDE%20-%20Impay%C3%A9%20Energie%20.pdf",
          "prefix": "le",
          "type": "bool",
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "autre"
        },
        {
          "slug": "crous_logement_eligibilite",
          "label": "logement dans une résidence CROUS",
          "institution": "etat",
          "description": "Les logements gérés par le Crous sont destinés aux étudiants dont les familles disposent de faibles ressources. Plusieurs types de logement peuvent être proposés en fonction des résidences.",
          "conditions": [
            "Avoir rempli votre Dossier social étudiant (DSE) <strong>avant le 15 mai</strong> et avoir coché la case « Je demande un logement »."
          ],
          "link": "https://www.etudiant.gouv.fr/fr/bourse-et-logement-constituez-votre-dossier-social-etudiant-dse-409#item4",
          "teleservice": "https://trouverunlogement.lescrous.fr",
          "prefix": "un",
          "type": "bool",
          "top": 2,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "openfisca_eligibility_source": "bourse_criteres_sociaux",
          "periodicite": "annuelle"
        },
        {
          "slug": "crous_repas_un_euro_eligibilite",
          "label": "repas du CROUS à 1€",
          "institution": "etat",
          "description": "Le repas du CROUS à 1€ permet à tous les étudiants, boursiers ou non, de bénéficier de deux repas par jour au tarif de 1 euro.",
          "conditions": [
            "Activer votre <a target=\"_blank\" rel=\"noopener\" title=\"compte Izly - Nouvelle fenêtre\" href=\"https://www.etudiant.gouv.fr/fr/izly-votre-solution-de-paiement-sur-le-campus-1285\">compte Izly<a/>."
          ],
          "link": "https://www.etudiant.gouv.fr/fr/le-repas-au-crous-passe-1-euro-pour-tous-les-etudiants-2314",
          "instructions": "https://www.etudiant.gouv.fr/fr/izly-votre-solution-de-paiement-sur-le-campus-1285",
          "prefix": "le",
          "type": "bool",
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "autre"
        },
        {
          "slug": "css_participation_forfaitaire",
          "participation": true,
          "label": "complémentaire santé solidaire",
          "institution": "assurance_maladie",
          "description": "La Complémentaire Santé Solidaire (CSS) est une protection complémentaire santé (mutuelle). Elle remplace la Complémentaire Maladie Universelle Complémentaire (CMU-C) et l’Aide au paiement d’une Complémentaire Santé (ACS) à compter du 1ᵉʳ novembre 2019. Une fois attribuée, la CSS est accordée pour un an.",
          "prefix": "la",
          "conditions": [
            "Résider <abbr title=\"Métropole, Guadeloupe, Guyane, Martinique ou Réunion\">en France</abbr> depuis plus de 3 mois."
          ],
          "type": "mixed",
          "periodicite": "mensuelle",
          "link": "https://www.service-public.fr/particuliers/vosdroits/F10027",
          "teleservice": "https://www.complementaire-sante-solidaire.gouv.fr/cmu-c-demarche.php",
          "form": "https://www.complementaire-sante-solidaire.gouv.fr/fichier-utilisateur/fichiers/S3711%20HOMO%20COMPLEMENTAIRE%20SANTE%20SOLIDAIRE%20non%20secu%2009_2019.pdf",
          "entity": "familles",
          "openfiscaPeriod": "thisMonth",
          "msa": true
        },
        {
          "slug": "depart1825_montant_maximum",
          "label": "départ 18-25",
          "institution": "etat",
          "description": "Le programme Départ 18-25 permet aux jeunes de financer jusqu’à 75% de leurs vacances pour 200€ maximum par personne et par an.",
          "conditions": [
            "Réserver vos vacances sur <a target=\"_blank\" rel=\"noopener\" title=\"un site partenaire - Nouvelle fenêtre\" href=\"https://www.lesstations.com/\">un site partenaire</a>.",
            "Financer au moins 50€ de vos vacances."
          ],
          "link": "https://depart1825.com/nos-sejours/",
          "teleservice": "https://www.lesstations.com/",
          "prefix": "l’aide",
          "type": "float",
          "unit": "€",
          "legend": "maximum",
          "top": 4,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "annuelle"
        },
        {
          "slug": "eure_et_loir_eligibilite_aide_menagere_personne_agee",
          "label": "aide ménagère pour les personnes âgées",
          "institution": "departement_eure_et_loir",
          "description": "L'aide ménagère est une aide en nature accordée aux personnes âgées relevant du GIR 5 et 6 qui, du fait de leur état de santé ou de leur âge, ont besoin de recourir à un personnel pour effectuer les actes de la vie courante.",
          "link": "https://eurelien.fr/guide/action-et-aides-sociales",
          "prefix": "l’",
          "type": "bool",
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "autre"
        },
        {
          "slug": "eure_et_loir_eligibilite_aide_menagere_personne_handicap",
          "label": "aide ménagère à domicile",
          "institution": "departement_eure_et_loir",
          "description": "L’aide-ménagère est accordée aux personnes en situation de handicap ayant besoin, pour demeurer à leur domicile, d’une aide matérielle pour effectuer les actes de la vie courante.  La participation financière du bénéficiaire est définie par le Conseil départemental.",
          "conditions": [
            "Ne pas déjà bénéficier de l’Allocation compensatrice pour tierce personne (ACTP), la Majoration Tierce Personne (MTP versée par la CPAM) ou encore des prestations d’aide-ménagère servies par les caisses de retraite."
          ],
          "link": "https://eurelien.fr/guide/autonomie",
          "prefix": "l’",
          "type": "bool",
          "floorAt": 10,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "autre"
        },
        {
          "slug": "eure_et_loir_eligibilite_ash_personne_handicap",
          "label": "aide sociale à l’hébergement",
          "institution": "departement_eure_et_loir",
          "description": "L’aide sociale à l’hébergement (ASH) permet aux personnes dont l’état de santé ou le handicap nécessite l’entrée en établissement social ou médico-social (hébergement permanent, hébergement temporaire, accueil de jour), de bénéficier d’une prise en charge des frais de séjour en foyer d’hébergement, en foyer occupationnel, en foyer de vie et en foyer d’accueil médicalisé.",
          "conditions": [
            "Contribuer aux frais de séjour dans des conditions définies dans le cadre du règlement départemental d’aide sociale.",
            "Faire évaluer votre besoin par la Maison départementale de l’autonomie (MDA)."
          ],
          "link": "https://www.eurelien.fr/guide/autonomie",
          "prefix": "l’",
          "type": "bool",
          "floorAt": 10,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "ponctuelle"
        },
        {
          "slug": "eure_et_loir_eligibilite_cmi_invalidite",
          "label": "carte mobilité inclusion invalidité",
          "institution": "departement_eure_et_loir",
          "description": "Cette carte permet d’obtenir une priorité d’accès aux places assises dans les lieux publics, les salles d’attente et les transports en commun, de bénéficier d’avantages fiscaux et de réductions tarifaires.\n",
          "conditions": null,
          "link": "https://www.eurelien.fr/guide/autonomie",
          "prefix": "la",
          "type": "bool",
          "floorAt": 10,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "autre"
        },
        {
          "slug": "eure_et_loir_eligibilite_cmi_priorite",
          "label": "carte mobilité inclusion priorité",
          "institution": "departement_eure_et_loir",
          "description": "Cette carte permet d’obtenir une priorité d’accès aux places assises dans les lieux publics, les salles d’attente et les transports en commun.",
          "conditions": [],
          "link": "https://www.eurelien.fr/guide/autonomie",
          "prefix": "la",
          "type": "bool",
          "floorAt": 10,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "autre"
        },
        {
          "slug": "eure_et_loir_eligibilite_cmi_stationnement",
          "label": "carte mobilité inclusion stationnement",
          "institution": "departement_eure_et_loir",
          "description": "Cette carte donne droit au stationnement sur les places réservées aux personnes en situation de handicap sur le stationnement public.",
          "link": "https://www.eurelien.fr/guide/autonomie",
          "prefix": "la",
          "type": "bool",
          "floorAt": 10,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "autre"
        },
        {
          "slug": "eure_et_loir_eligibilite_fsl_acces_logement",
          "label": "aide à l'accès au logement du fonds de solidarité pour le logement",
          "institution": "departement_eure_et_loir",
          "description": "Cette une aide, sous forme de subvention ou de prêt remboursable, est accordée pour le dépôt de garantie, le 1er loyer, les frais d’agence, la garantie de loyers et de charges locatives, les dettes locatives en vue d’un relogement par le même bailleur, l’assurance habitation (6 premiers mois), les frais de déménagement, l’ouverture des compteurs, l’achat d’appareils ménagers et de mobilier de première nécessité.\n",
          "conditions": [
            "Avoir des ressources inférieures ou égales à 60% du seuil de pauvreté (ressources hors aide personnelle au logement, allocation logement, allocation de rentrée scolaire, allocation d’éducation spéciale et ses compléments)."
          ],
          "link": "https://www.eurelien.fr/guide/action-et-aides-sociales#guide-block-30",
          "prefix": "l’",
          "type": "bool",
          "entity": "menages",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "ponctuelle"
        },
        {
          "slug": "eure_et_loir_eligibilite_pch_domicile",
          "label": "prestation de compensation à domicile",
          "institution": "departement_eure_et_loir",
          "description": "La Prestation de compensation du handicap à domicile (PCH) a pour but de compenser les conséquences du handicap. C’est une aide personnalisée aux besoins de chaque bénéficiaire. Elle peut financer des aides humaines, techniques, pour l’aménagement du logement et/ou du véhicule, les surcoûts liés au transport, des aides animalières, des charges spécifiques (service de téléalarme, etc.) ou exceptionnelles.",
          "conditions": [
            "Ne pas déjà bénéficier d’Allocation compensatrice pour tierce personne (ACTP), d’Allocation personnalisée d’autonomie (APA) ou d’Allocation d’éducation de l’enfant handicapé (AEEH).",
            "Faire évaluer votre situation personnelle par la maison départementale de l'autonomie (MDA)."
          ],
          "link": "https://www.eurelien.fr/guide/autonomie",
          "prefix": "la",
          "type": "bool",
          "floorAt": 10,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "autre"
        },
        {
          "slug": "eure_et_loir_eligibilite_pch_etablissement",
          "label": "prestation de compensation du handicap en établissement",
          "institution": "departement_eure_et_loir",
          "description": "La Prestation de compensation du handicap en établissement a pour but de compenser les conséquences du handicap durant les périodes d’interruption d’hospitalisation ou d’hébergement en établissement. C’est une aide personnalisée, adaptée aux besoins de chaque bénéficiaire. Elle peut financer des aides humaines, techniques, pour l’aménagement du logement et/ou du véhicule, les surcoûts liés au transport, etc.",
          "conditions": [
            "Faire évaluer votre besoin par la Maison départementale de l’autonomie (MDA)."
          ],
          "link": "https://www.eurelien.fr/guide/autonomie",
          "prefix": "la",
          "type": "bool",
          "floorAt": 10,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "autre"
        },
        {
          "slug": "eure_et_loir_fsl_eligibilite_installation_logement",
          "label": "aide à l'installation dans le logement du fonds de solidarité pour le logement",
          "institution": "departement_eure_et_loir",
          "description": "Cette aide pour des équipements de première nécessité est destinée à favoriser l'installation dans le cadre d'un premier accès au logement sur le département.\n",
          "conditions": [
            "Avoir des ressources inférieures ou égales à 60% du seuil de pauvreté (ressources hors aide personnelle au logement, allocation logement, allocation de rentrée scolaire, allocation d’éducation spéciale et ses compléments)."
          ],
          "link": "https://www.eurelien.fr/guide/action-et-aides-sociales#guide-block-30",
          "prefix": "l’",
          "type": "bool",
          "entity": "menages",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "ponctuelle"
        },
        {
          "slug": "eure_et_loir_fsl_eligibilite_maintien_fourniture",
          "label": "aide au maintien des fournitures « Énergie-Eau-Téléphone » du fonds de solidarité pour le logement",
          "institution": "departement_eure_et_loir",
          "description": "Cette aide peut être versée au créancier afin de maintenir les flux (Énergie-Eau-Téléphone) et de permettre de vivre décemment dans son logement.\n",
          "conditions": [
            "Être locataire ou propriétaire.",
            "Avoir des ressources inférieures ou égales à 60% du seuil de pauvreté (ressources hors aide personnelle au logement, allocation logement, allocation de rentrée scolaire, allocation d’éducation spéciale et ses compléments)."
          ],
          "link": "https://www.eurelien.fr/guide/action-et-aides-sociales#guide-block-30",
          "prefix": "l’",
          "type": "bool",
          "entity": "menages",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "autre"
        },
        {
          "slug": "eure_et_loir_fsl_eligibilite_maintien_logement",
          "label": "aide au maintien dans le logement du fonds de solidarité pour le logement",
          "institution": "departement_eure_et_loir",
          "description": "Cette aide, sous forme de subvention ou de prêt remboursable, peut être accordée pour la mise en jeu du cautionnement, les impayés de loyer et le nettoyage et les petits travaux du logement.\n",
          "conditions": [
            "Être locataire ou propriétaire.",
            "Avoir des ressources inférieures ou égales à 60% du seuil de pauvreté (ressources hors aide personnelle au logement, allocation logement, allocation de rentrée scolaire, allocation d’éducation spéciale et ses compléments)."
          ],
          "link": "https://www.eurelien.fr/guide/action-et-aides-sociales#guide-block-30",
          "prefix": "l’",
          "type": "bool",
          "entity": "menages",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "ponctuelle"
        },
        {
          "slug": "eurometropole_strasbourg_tarification_solidaire_transport_montant",
          "label": "tarification solidaire sur le réseau C.T.S.",
          "institution": "strasbourg_eurometropole",
          "description": "La tarification solidaire des transports de l'Eurométropole de Strasbourg est à destination des foyers aux ressources faibles. Ils peuvent profiter en illimité des trams et des bus C.T.S.",
          "link": "https://www.strasbourg.eu/tarification-solidaire-transports-en-commun",
          "instructions": "https://www.cts-strasbourg.eu/fr/Titres-de-transport/tarifs/tarification-solidaire/",
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "periodicite": "mensuelle",
          "legend": "/ mois au lieu de 51.80 €",
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "floorAt": 0.01
        },
        {
          "slug": "fsl_eligibilite",
          "label": "Aide au maintien dans votre logement",
          "description": "Dans le cadre du Fonds de Solidarité Logement, des aides financières sont mises en place pour vous aider à rester dans votre logement et à payer vos factures liées à votre logement (eau, électricité, etc.).",
          "entity": "menages",
          "openfiscaPeriod": "thisMonth",
          "type": "bool",
          "prefix": "une",
          "warning": true,
          "top": 6,
          "link": "https://www.service-public.fr/particuliers/vosdroits/F1334",
          "institution": "departements_et_metropoles",
          "periodicite": "ponctuelle"
        },
        {
          "slug": "garantie_pret_etudiant_eligibilite",
          "label": "prêt étudiant garanti par l'État",
          "institution": "etat",
          "description": "Le prêt étudiant est ouvert à l'ensemble des étudiants sans conditions de ressources et sans caution parentale ou d'un tiers. Avec la possibilité de rembourser l'emprunt de manière différée.",
          "link": "https://www.etudiant.gouv.fr/fr/pret-etudiant-garanti-par-l-etat-1723",
          "instructions": "https://www.etudiant.gouv.fr/fr/pret-etudiant-garanti-par-l-etat-1723#item2",
          "prefix": "le",
          "type": "bool",
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "ponctuelle"
        },
        {
          "slug": "grand_est_fluo_67_eligible_gratuite",
          "label": "transports gratuits sur le réseau Fluo 67",
          "institution": "grand_est",
          "description": "La gratuité des transports est destinée aux demandeurs d'emploi et aux bénéficiaires du RSA. Elle permet de se déplacer sans frais sur le réseau de bus Fluo 67.",
          "conditions": [
            "être titulaire d’une <a target=\"_blank\" rel=\"noopener\" title=\"carte Badgeo fluo - Nouvelle fenêtre\" href=\"https://www.ctbr67.fr/tarifs/billettique-fluo-grandest-67/carte-badgeo-fluo-grandest-67/\">carte Badgeo fluo</a> (de couleur rouge) dont la demande est gratuite."
          ],
          "link": "https://www.ctbr67.fr/tarifs/tarifs-reduits-gratuite/",
          "instructions": "https://www.ctbr67.fr/tarifs/tarifs-reduits-gratuite/",
          "type": "bool",
          "prefix": "les",
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "autre"
        },
        {
          "slug": "grand_est_fluo_67_tarification_solidaire",
          "label": "tarification solidaire des transports du réseau Fluo 67",
          "institution": "grand_est",
          "description": "La tarification solidaire des transports est un tarif réduit sur les abonnements et les tickets à l'unité pour prendre les bus du réseau Fluo 67.",
          "conditions": [
            "Être titulaire d’une <a target=\"_blank\" rel=\"noopener\" title=\"carte Badgeo fluo - Nouvelle fenêtre\" href=\"https://www.ctbr67.fr/tarifs/billettique-fluo-grandest-67/carte-badgeo-fluo-grandest-67/\">carte Badgeo fluo</a> (de couleur rouge) dont la demande est gratuite."
          ],
          "link": "https://www.ctbr67.fr/tarifs/tarifs-reduits-gratuite/",
          "instructions": "https://www.ctbr67.fr/tarifs/tarifs-reduits-gratuite/",
          "prefix": "la",
          "legend": "/ mois au lieu de 52.50 €",
          "periodicite": "mensuelle",
          "floorAt": 1,
          "type": "float",
          "unit": "€",
          "entity": "individus",
          "openfiscaPeriod": "thisMonth"
        },
        {
          "slug": "gratuite_musees_monuments",
          "label": "accès gratuit aux musées et monuments nationaux",
          "institution": "etat",
          "description": "Les jeunes de moins de 26 ans habitant dans l'Union européenne peuvent accéder gratuitement à certains musées et monuments nationaux, sur simple présentation d'une pièce d'identité.",
          "conditions": [
            "Présenter au guichet du musée ou du monument concerné avec une pièce justifiant de son âge et de sa résidence. Cette pièce peut être une carte d'identité, un passeport ou une carte de séjour."
          ],
          "link": "https://www.service-public.fr/particuliers/vosdroits/F20348",
          "prefix": "l’",
          "type": "bool",
          "top": 2,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "ponctuelle"
        },
        {
          "slug": "hauts_de_france_aide_permis",
          "label": "Aide pour le permis de conduire",
          "institution": "hauts_de_france",
          "description": "La région Hauts-de-France prend en charge 90% du prix du permis pour aider ses habitants de 18-30 ans à passer leur permis de conduire.",
          "conditions": [
            "être agé de 18 à 30 ans.",
            "être en apprentissage, demandeur d'emploi, stagiaire ou dans un parcours contractualisé d’accompagnement vers l’emploi et l’autonomie (PACEA)."
          ],
          "link": "https://www.hautsdefrance.fr/permis-de-conduire-aide-2/",
          "teleservice": "https://www.hautsdefrance.fr/testez-votre-eligibilite-aide-au-permis-de-conduire/",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "interestFlag": "_interetPermisDeConduire",
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "legend": "maximum",
          "periodicite": "ponctuelle"
        },
        {
          "slug": "illkirch_graffenstaden_coupon_arts_et_sports",
          "label": "coupon Arts et Sports",
          "institution": "ville_illkirch_graffenstaden",
          "description": "Cette aide permet aux bénéficiaires du RSA qui reprennent une activité de faire face très rapidement aux premières dépenses et aux besoins de trésorerie. Son montant varie entre 300 et 700 euros en fonction de la reprise d'activité (CCD, CDI, etc.).",
          "link": "http://www.illkirch.eu/solidarite-et-sante/ccas/coupon-arts-sports/",
          "instructions": "http://www.illkirch.eu/solidarite-et-sante/ccas/coupon-arts-sports/",
          "prefix": "le",
          "floorAt": 1,
          "entity": "familles",
          "openfiscaPeriod": "thisMonth",
          "type": "float",
          "unit": "€",
          "legend": "en coupons",
          "periodicite": "ponctuelle"
        },
        {
          "slug": "iwuy_aide_mobilite_permis",
          "label": "aide au financement du permis",
          "institution": "ville_iwuy",
          "description": "Cette aide est un coup de pouce de la ville de Iwuy pour favoriser l’autonomie et l’insertion des jeunes de 18 à 25 ans en finançant <a target=\"_blank\" rel=\"noopener\" title=\"le permis voiture (B) - Nouvelle fenêtre\" href=\"https://www.service-public.fr/particuliers/vosdroits/F2828\">le permis voiture (B)</a>.",
          "conditions": [
            "Avoir obtenu le code de la route.",
            "Habiter à Iwuy depuis plus d'un an.",
            "Être inscrit en Mission Locale.",
            "Effectuer une mission bénévole au service de la collectivité."
          ],
          "link": "http://www.iwuy.fr/page.php?id=6",
          "instructions": "http://www.iwuy.fr/page.php?id=6",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "floorAt": 1,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "ponctuelle",
          "interestFlag": "_interetPermisDeConduire"
        },
        {
          "slug": "le_cateau_aide_mobilite_permis",
          "label": "aide au financement du permis",
          "institution": "ville_le_cateau",
          "description": "Cette aide est un coup de pouce de la ville de Le Cateau-Cambrésis pour favoriser l’autonomie et l’insertion des jeunes de 18 à 25 ans en finançant <a target=\"_blank\" rel=\"noopener\" title=\"le permis voiture (B) - Nouvelle fenêtre\" href=\"https://www.service-public.fr/particuliers/vosdroits/F2828\">le permis voiture (B)</a>.",
          "conditions": [
            "Avoir obtenu le code de la route.",
            "Habiter à Le Cateau-Cambrésis depuis plus d'un an."
          ],
          "link": "https://www.lecateau.fr/vivre-au-cateau/enfance-jeunesse/jeunesse/aide-au-permis-de-conduire.html",
          "instructions": "https://www.lecateau.fr/vivre-au-cateau/enfance-jeunesse/jeunesse/aide-au-permis-de-conduire.html",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "floorAt": 1,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "ponctuelle",
          "interestFlag": "_interetPermisDeConduire"
        },
        {
          "slug": "les_rues_des_vignes_aide_mobilite_permis",
          "label": "aide au financement du permis",
          "institution": "ville_les_rues_des_vignes",
          "description": "Cette aide est un coup de pouce de la ville de Les Rues-des-Vignes pour favoriser l’autonomie et l’insertion des jeunes de 16 à 25 ans en finançant <a target=\"_blank\" rel=\"noopener\" title=\"le permis voiture (B) - Nouvelle fenêtre\" href=\"https://www.service-public.fr/particuliers/vosdroits/F2828\">le permis voiture (B)</a>.",
          "conditions": [
            "Avoir obtenu le code de la route.",
            "Habiter à Les Rues-des-Vignes depuis plus de 6 mois.",
            "Être inscrit en Mission Locale."
          ],
          "link": "https://www.lesruesdesvignes.fr/vie-pratique/demarches-administratives/aides-sociales",
          "instructions": "https://www.lesruesdesvignes.fr/vie-pratique/demarches-administratives/aides-sociales",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "floorAt": 1,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "ponctuelle",
          "interestFlag": "_interetPermisDeConduire"
        },
        {
          "slug": "livret_epargne_populaire_taux",
          "label": "livret d’épargne populaire",
          "unit": "%",
          "type": "float",
          "description": "Le livret d’épargne populaire (LEP) est un placement réservé aux personnes disposant de revenus modestes. Il offre de nombreux avantages parmi lesquels un taux d’intérêt garanti supérieur aux autres livrets réglementés.",
          "conditions": [
            "Avoir votre domicile fiscal situé en France.",
            "Effectuer chaque année votre déclaration auprès des impôts.",
            "Présenter à l’établissement bancaire votre avis d’imposition indiquant votre revenu fiscal."
          ],
          "periodicite": "annuelle",
          "link": "https://www.service-public.fr/particuliers/vosdroits/F2367",
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "isBaseRessourcesYearMinusTwo": true,
          "floorAt": 0.01,
          "top": 20,
          "prefix": "le",
          "institution": "banque_de_france"
        },
        {
          "slug": "locapass_eligibilite",
          "label": "Loca-Pass",
          "teleservice": "https://locapass.actionlogement.fr",
          "prefix": "l’aide",
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "top": 5,
          "institution": "action_logement",
          "description": "L'avance LOCA-PASS® est une aide gratuite sous forme d'un prêt à 0 % pour financer tout ou partie de votre dépôt de garantie.",
          "conditions": [
            "Ne pas être employé·e par une entreprise publique."
          ],
          "link": "https://www.actionlogement.fr/l-avance-loca-pass?utm_source=mes-aides-jeunes&utm_medium=resultats",
          "type": "bool",
          "floorAt": 0.01,
          "periodicite": "ponctuelle"
        },
        {
          "slug": "logement_social_eligible",
          "label": "logement social",
          "institution": "cohesion_territoires",
          "description": "Le logement social est destiné aux personnes et familles dont les ressources ne dépassent pas un certain seuil et répondant à certaines conditions d’accès. Aucun frais de dossier n’est réclamé lors de la demande, ni lors de la signature du bail. Il n’est pas obligatoire de résider dans une commune pour y déposer un dossier.\n",
          "conditions": [
            "Résider <abbr title=\"Métropole, Guadeloupe, Guyane, Martinique ou Réunion\">en France</abbr> plus de <abbr title=\"180 jours, potentiellement discontinus\">6 mois</abbr> cette année.",
            "Vous adresser aux organismes HLM du département où vous souhaiter résider à travers le téléservice que nous présentons ou bien auprès de la préfecture du département, à la mairie de la ville ou encore des organismes collecteurs du 1% logement.",
            "Renouveler chaque année votre demande jusqu’à obtention d’un logement."
          ],
          "link": "https://www.service-public.fr/particuliers/vosdroits/F869",
          "teleservice": "https://www.demande-logement-social.gouv.fr/creation/accesCriteresEligibilites.do",
          "isBaseRessourcesYearMinusTwo": true,
          "top": 10,
          "prefix": "le",
          "type": "bool",
          "entity": "familles",
          "openfiscaPeriod": "thisMonth",
          "private": true,
          "periodicite": "ponctuelle"
        },
        {
          "slug": "loire_atlantique_aide_mobilite_permis_am",
          "label": "aide au financement du permis scooter (AM)",
          "institution": "departement_loire_atlantique",
          "description": "Cette aide est un coup de pouce du Département de Loire Atlantique pour favoriser\nl’autonomie et l’insertion des jeunes de 14 à 24 ans en finançant\n<a target=\"_blank\" rel=\"noopener\" title=\"le permis scooter (AM) - Nouvelle fenêtre\" href=\"https://www.service-public.fr/particuliers/vosdroits/F2890\">le permis scooter (AM)</a>.\n",
          "conditions": [
            "Occuper, à titre de résidence principale, un logement sur le territoire du département de Loire Atlantique depuis <strong>plus d’un an<strong>.",
            "Ne pas bénéficier d’une autre aide au permis de conduire.",
            "Être inscrit·e dans une auto-école."
          ],
          "link": "https://www.loire-atlantique.fr/jcms/classement-des-contenus/guides-aides/vous-etes/jeune/l-aide-au-permis-de-conduire-pour-les-jeunes-voiture-et-cyclomoteur-fr-t1_17944",
          "instructions": "https://www.loire-atlantique.fr/44/jeunesse/l-aide-au-permis-de-conduire-pour-les-jeunes-voiture-et-cyclomoteur/c_1154102#idTitre5",
          "prefix": "l’",
          "floorAt": 1,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "ponctuelle",
          "type": "float",
          "unit": "€",
          "private": true,
          "interestFlag": "_interetPermisDeConduire"
        },
        {
          "slug": "loire_atlantique_aide_mobilite_permis_b",
          "label": "aide au financement du permis voiture (B)",
          "institution": "departement_loire_atlantique",
          "description": "Cette aide est un coup de pouce du Département de Loire Atlantique pour favoriser l’autonomie et l’insertion des jeunes de 17 à 24 ans en finançant <a target=\"_blank\" rel=\"noopener\" title=\"le permis voiture (B) - Nouvelle fenêtre\" href=\"https://www.service-public.fr/particuliers/vosdroits/F2828\">le permis voiture (B)</a>.\n",
          "conditions": [
            "Occuper, à titre de résidence principale, un logement sur le territoire du département de Loire Atlantique depuis <strong>plus d’un an<strong>.",
            "Ne pas bénéficier d’une autre aide au permis de conduire.",
            "Être inscrit·e dans une auto-école."
          ],
          "link": "https://www.loire-atlantique.fr/jcms/classement-des-contenus/guides-aides/vous-etes/jeune/l-aide-au-permis-de-conduire-pour-les-jeunes-voiture-et-cyclomoteur-fr-t1_17944",
          "instructions": "https://www.loire-atlantique.fr/44/jeunesse/l-aide-au-permis-de-conduire-pour-les-jeunes-voiture-et-cyclomoteur/c_1154102#idTitre5",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "floorAt": 1,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "ponctuelle",
          "private": true,
          "interestFlag": "_interetPermisDeConduire"
        },
        {
          "slug": "mobili_jeune",
          "label": "Mobili-Jeune",
          "institution": "action_logement",
          "description": "L’aide MOBILI-JEUNE® s’adresse aux jeunes de moins de 30 ans, en formation en alternance. Elle permet de prendre en charge une partie du loyer (entre 10 € et 100 € maximum) chaque mois et pendant un an.",
          "conditions": [
            "Ne pas être employé·e par une entreprise publique."
          ],
          "link": "https://www.actionlogement.fr/l-aide-mobili-jeune?utm_source=mes-aides-jeunes&utm_medium=resultats",
          "teleservice": "https://mobilijeune.actionlogement.fr",
          "prefix": "l’aide",
          "type": "float",
          "unit": "€",
          "floorAt": 1,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "mensuelle"
        },
        {
          "slug": "mon_job_mon_logement",
          "label": "aide au logement « Mon job mon logement »",
          "institution": "action_logement",
          "description": "L’aide au logement « Mon job mon logement » s’adresse aux actifs et aux alternants pour les aider à se loger près de leur emploi.",
          "conditions": [
            "Ne pas avoir reçu l'aide à la mobilité et l’aide jeunes actifs.",
            "Ne pas être employé·e par une entreprise publique."
          ],
          "link": "https://www.actionlogement.fr/aide-mon-job-mon-logement",
          "teleservice": "https://piv.actionlogement.fr/simulateur-mobilite",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "floorAt": 1,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "ponctuelle"
        },
        {
          "slug": "msa_armorique_aide_permis",
          "label": "aide au financement du permis",
          "institution": "mutualite_sociale_agricole",
          "description": "Cette aide est un coup de pouce de la Mutualité Sociale Agricole (MSA) Armorique pour favoriser l’autonomie et l’insertion des jeunes de moins de 25 ans en finançant <a target=\"_blank\" rel=\"noopener\" title=\"le permis voiture (B) - Nouvelle fenêtre\" href=\"https://www.service-public.fr/particuliers/vosdroits/F2828\">le permis voiture (B)</a> ou <a target=\"_blank\" rel=\"noopener\" title=\"le permis moto (A) - Nouvelle fenêtre\" href=\"https://www.service-public.fr/particuliers/vosdroits/F2830\">le permis moto (A)</a>.",
          "conditions": [
            "Avoir obtenu le code de la route.",
            "Ne pas avoir obtenu <a href=\"https://armorique.msa.fr/lfy/aide-a-la-mobilite-pour-l-emploi\" target=\"_blank\" title=\"Aide à la mobilité - Nouvelle fenêtre\" rel=\"noopener\">l’aide à la mobilité</a>.",
            "Ne pas avoir de capitaux supérieur à 50 000 € pour une personne seule et 75 000 € pour un couple."
          ],
          "link": "https://armorique.msa.fr/lfy/l-aide-au-permis-de-conduire",
          "form": "https://armorique.msa.fr/lfp/documents/98925/100861102/Formulaire+PASS+aide+%C3%A0+l%27obtention+du+code+de+la+route.pdf",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "floorAt": 1,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "ponctuelle",
          "interestFlag": "_interetPermisDeConduire"
        },
        {
          "slug": "msa_haute_normandie_aide_permis",
          "label": "aide au financement du permis",
          "institution": "mutualite_sociale_agricole",
          "description": "Cette aide est un coup de pouce de la Mutualité Sociale Agricole (MSA) Haute-Normandie pour favoriser l’autonomie et l’insertion des jeunes de 18 à 25 ans en finançant <a target=\"_blank\" rel=\"noopener\" title=\"le permis voiture (B) - Nouvelle fenêtre\" href=\"https://www.service-public.fr/particuliers/vosdroits/F2828\">le permis voiture (B)</a>.",
          "link": "https://hautenormandie.msa.fr/lfy/aide-au-permis-de-conduire",
          "form": "https://hautenormandie.msa.fr/lfy/documents/98860/1080430/Formulaire+aide+au+permis+de+conduire.pdf",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "floorAt": 1,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "ponctuelle",
          "interestFlag": "_interetPermisDeConduire"
        },
        {
          "slug": "msa_midi_pyrenees_sud_aide_permis",
          "label": "aide au financement du permis",
          "institution": "mutualite_sociale_agricole",
          "description": "Cette aide est un coup de pouce de la Mutualité Sociale Agricole (MSA) Midi-Pyrénées Sud pour favoriser l’autonomie et l’insertion des jeunes de 17 à 25 ans en finançant <a target=\"_blank\" rel=\"noopener\" title=\"le permis voiture (B) - Nouvelle fenêtre\" href=\"https://www.service-public.fr/particuliers/vosdroits/F2828\">le permis voiture (B)</a>.",
          "conditions": [
            "Avoir obtenu le code de la route."
          ],
          "link": "https://mps.msa.fr/lfy/famille/aide-permis-de-conduire",
          "form": "https://mps.msa.fr/lfy/documents/98775/99816930/Imprim%C3%A9+2021+-+Demande+pour+aide+au+permis+de+conduire+maj+2021+05.pdf",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "floorAt": 1,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "ponctuelle",
          "interestFlag": "_interetPermisDeConduire"
        },
        {
          "slug": "msa_nord_pas_de_calais_aide_permis",
          "label": "aide au financement du permis",
          "institution": "mutualite_sociale_agricole",
          "description": "Cette aide est un coup de pouce de la Mutualité Sociale Agricole (MSA) Nord Pas-de-Calais pour favoriser l’autonomie et l’insertion des jeunes de 16 à 25 ans en finançant <a target=\"_blank\" rel=\"noopener\" title=\"le permis voiture (B) - Nouvelle fenêtre\" href=\"https://www.service-public.fr/particuliers/vosdroits/F2828\">le permis voiture (B)</a>.",
          "conditions": [
            "Avoir obtenu le code de la route."
          ],
          "link": "https://nord-pasdecalais.msa.fr/lfy/aide-au-permis",
          "form": "https://nord-pasdecalais.msa.fr/lfy/documents/98870/58000036/Formulaire+Aide+au+permis",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "floorAt": 1,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "ponctuelle",
          "interestFlag": "_interetPermisDeConduire"
        },
        {
          "slug": "msa_portes_de_bretagne_aide_permis",
          "label": "aide au financement du permis",
          "institution": "mutualite_sociale_agricole",
          "description": "Coup de pouce de la Mutualité Sociale Agricole (MSA) Portes de Bretagne pour favoriser l’autonomie et l’insertion des jeunes de moins de 25 ans en finançant <a target=\"_blank\" rel=\"noopener\" title=\"le permis voiture (B) - Nouvelle fenêtre\" href=\"https://www.service-public.fr/particuliers/vosdroits/F2828\">le permis voiture (B)</a> ou <a target=\"_blank\" rel=\"noopener\" title=\"le permis moto (A) - Nouvelle fenêtre\" href=\"https://www.service-public.fr/particuliers/vosdroits/F2830\">le permis moto (A)</a>.",
          "conditions": [
            "Avoir obtenu le code de la route.",
            "Ne pas avoir obtenu <a href=\"https://portesdebretagne.msa.fr/lfy/aide-mobilite-emploi\" target=\"_blank\" title=\"Aide à la mobilité - Nouvelle fenêtre\" rel=\"noopener\">l’aide à la mobilité</a>.",
            "Ne pas avoir de capitaux supérieurs à 78 000 € pour une personne seule et 117 000 € pour un couple."
          ],
          "link": "https://portesdebretagne.msa.fr/lfy/aide-au-permis-de-conduire",
          "form": "https://portesdebretagne.msa.fr/lfp/documents/98950/94128666/Formulaire+Aide+permis+conduire.pdf",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "floorAt": 1,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "ponctuelle",
          "interestFlag": "_interetPermisDeConduire"
        },
        {
          "slug": "nantes_metropole_tarification_solidaire_transport",
          "label": "tarification solidaire des transports",
          "institution": "nantes_metropole",
          "description": "La tarification solidaire des transports de Nantes Métropole est à destination des foyers aux ressources faibles. Ils peuvent profiter en illimité des trams, des bus et Navibus, des transports scolaires, des TER dans le périmètre de l'agglomération et de certaines lignes LILA.",
          "link": "https://metropole.nantes.fr/tan-tarifs-solidaires",
          "instructions": "https://metropole.nantes.fr/tan-tarifs-solidaires#serviceTocEntry3",
          "prefix": "la",
          "type": "float",
          "unit": "%",
          "legend": "de réduction",
          "periodicite": "ponctuelle",
          "entity": "menages",
          "openfiscaPeriod": "thisMonth"
        },
        {
          "slug": "nouvelle_aquitaine_carte_solidaire",
          "label": "carte Solidaire pour les transports",
          "institution": "region_nouvelle_aquitaine",
          "description": "La Carte Solidaire permet de bénéficier de réductions importantes sur les trajets en trains TER et cars régionaux en Nouvelle-Aquitaine. Elle est destinée aux personnes qui ont de faibles revenus ou bénéficient de certaines prestations.\n",
          "link": "https://transports.nouvelle-aquitaine.fr/toute-lactualite-des-transports/un-tarif-solidaire-pour-les-trajets-en-trains-ter-et-cars-regionaux",
          "instructions": "https://cartesolidaire-nouvelle-aquitaine.cba.fr/",
          "prefix": "la",
          "type": "float",
          "unit": "%",
          "legend": "de réduction",
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "ponctuelle"
        },
        {
          "slug": "occitanie_carte_jeune_region",
          "label": "carte jeune région",
          "institution": "region_occitanie",
          "description": "La Région Occitanie soutient les jeunes grâce au dispositif Carte Jeune Région qui leur permet de bénéficier d'avantages comme un ordinateur portable, manuels scolaires, une aide à la formation aux premiers secours, ...",
          "link": "https://www.laregion.fr/la-carte-jeune-region-c-est-quoi-35189",
          "teleservice": "https://www.laregion.fr/Demander-la-Carte-Jeune-Region",
          "prefix": "l’",
          "type": "bool",
          "floorAt": 1,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "annuelle"
        },
        {
          "slug": "occitanie_carte_transport_scolaire_lio",
          "label": "carte transport scolaire liO",
          "description": "Dès la rentrée, les transports scolaires régionaux d'Occitanie sont gratuits pour les élèves de la maternelle au lycée.",
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "type": "bool",
          "prefix": "la",
          "link": "https://lio.laregion.fr/transport-scolaire",
          "institution": "region_occitanie",
          "periodicite": "annuelle"
        },
        {
          "slug": "paje_base",
          "label": "prestation d’accueil du jeune enfant – Allocation de base",
          "institution": "caf",
          "description": "L’allocation de base de la prestation d’accueil du jeune enfant (Paje) a pour objet d’aider à assurer les dépenses liées à l’entretien et l’éducation d’un enfant. Elle est destinée aux parents d’un enfant de moins de 3 ans ayant de faibles ressources. Elle est versée par la Caf ou la MSA.",
          "conditions": [
            "Résider <abbr title=\"Métropole, Guadeloupe, Guyane, Martinique ou Réunion\">en France</abbr> plus de <abbr title=\"180 jours, potentiellement discontinus\">6 mois</abbr> cette année."
          ],
          "link": "https://www.service-public.fr/particuliers/vosdroits/F13218",
          "form": "https://wwwd.caf.fr/wps/portal/caffr/aidesetservices/lesservicesenligne/faireunedemandedeprestation/allocationsfamilialesouchangementdesituation",
          "forms": {
            "caf": "https://wwwd.caf.fr/wps/portal/caffr/aidesetservices/lesservicesenligne/faireunedemandedeprestation/allocationsfamilialesouchangementdesituation",
            "msa": "http://www.msa.fr/lfr/documents/11566/132715/D%C3%A9claration+de+situation+pour+les+prestations+familiales+et+aides+au+logement.pdf"
          },
          "isBaseRessourcesYearMinusTwo": true,
          "prefix": "la",
          "type": "float",
          "unit": "€",
          "entity": "familles",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "mensuelle",
          "msa": true
        },
        {
          "slug": "paris_complement_sante",
          "label": "Complément Santé Paris",
          "institution": "paris",
          "description": "Le Complément Santé Paris est destiné aux personnes âgées de 65 ans ou plus et aux personnes en situation de handicap. L’aide peut être versée à partir de 60 ans pour les personnes reconnues inaptes au travail. Elle a pour but d’aider les foyers modestes à régler leurs frais de mutuelle. L’aide est accordée pour une durée maximale d’un an.\n",
          "conditions": [
            "Percevoir les avantages légaux auxquels vous pouvez prétendre (Couverture Maladie Universelle Complémentaire, Aide à la Complémentaire Santé).",
            "Adhérer à titre payant à un organism e de protection complémentaire."
          ],
          "link": "https://www.paris.fr/pages/simulateur-mes-aides-gouv-fr-pour-la-ville-de-paris-3626/#complement-sante-paris",
          "form": "https://cdn.paris.fr/paris/2019/07/24/c3175c019a067ac0b1bf2714742f0bbc.pdf",
          "prefix": "le",
          "type": "float",
          "unit": "€",
          "entity": "familles",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "annuelle"
        },
        {
          "slug": "paris_energie_familles",
          "label": "Paris Énergie Familles",
          "institution": "paris",
          "description": "L’allocation Paris Énergie Familles est réservée aux familles ayant un ou plusieurs enfants à charge, sous condition d’imposition. Cette aide permet de les soutenir dans leurs dépenses d’électricité et/ou de gaz. Paris Énergie Famille est directement versée aux fournisseurs d’énergie. L’aide est accordée pour un an. Elle peut être renouvelée en présentant un nouveau dossier.\n",
          "link": "https://www.paris.fr/pages/simulateur-mes-aides-gouv-fr-pour-la-ville-de-paris-3626/#paris-energie-familles",
          "form": "https://cdn.paris.fr/paris/2019/07/24/ef5b3f1bcd3a62ec75576748e88a0dd7.pdf",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "entity": "familles",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "annuelle"
        },
        {
          "slug": "paris_forfait_familles",
          "label": "Paris Forfait Familles",
          "institution": "paris",
          "description": "Paris Forfait Familles est une aide destinée aux familles nombreuses avec au moins trois enfants à charge. Elle peut se cumuler avec l’Allocation de Soutien aux Parents d’Enfants Handicapés. L’aide est accordée pour une durée maximale d’un an. Elle peut être renouvelée en présentant un nouveau dossier.\n",
          "link": "https://www.paris.fr/pages/simulateur-mes-aides-gouv-fr-pour-la-ville-de-paris-3626/#paris-forfait-familles",
          "form": "https://cdn.paris.fr/paris/2019/07/24/8fa764ddcfa7659c447e2b383b8cb986.pdf",
          "prefix": "l’aide",
          "type": "float",
          "unit": "€",
          "entity": "familles",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "annuelle"
        },
        {
          "slug": "paris_logement",
          "label": "Paris Logement",
          "institution": "paris",
          "description": "L’allocation Paris Logement est destinée aux foyers modestes pour leur permettre de mieux supporter leurs dépenses de logement. Ils doivent être locataires en titre et titulaires du bail du logement occupé à titre principal. L’aide est accordée pour une durée maximale d’un an. À partir du 2<sup>e</sup> renouvellement, Paris Logement peut être accordée pour une durée maximale de deux ans.\n",
          "conditions": [
            "Avoir demandé le versement des aides logement auxquelles vous avez droit auprès de la CAF.",
            "Ne toucher aucune autre prestation logement de la part de la mairie de Paris ou du département."
          ],
          "link": "https://www.paris.fr/pages/aides-au-logement-3827/#paris-logement",
          "form": "https://cdn.paris.fr/paris/2020/01/13/9951415db06e3d73e54625d5972c7229.pdf",
          "prefix": "l’aide",
          "type": "float",
          "unit": "€",
          "entity": "familles",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "mensuelle"
        },
        {
          "slug": "paris_logement_aspeh",
          "label": "Allocation de Soutien aux Parents d’Enfants Handicapés",
          "institution": "paris",
          "description": "L’Allocation de Soutien aux Parents d’Enfants Handicapés est réservée aux familles ayant à charge un ou plusieurs enfants handicapés. Elle est accordée pour un an et renouvelable. Si l’enfant handicapé vit au domicile, l’aide est versée tous les mois.\n",
          "conditions": [
            "Indiquer le nombre de jours passés par l’enfant handicapé au domicile, lorsqu’il est placé dans un établissement spécialisé."
          ],
          "link": "https://www.paris.fr/pages/simulateur-mes-aides-gouv-fr-pour-la-ville-de-paris-3626/#allocation-de-soutien-aux-parents-d-enfants-handicapes",
          "form": "https://cdn.paris.fr/paris/2019/07/24/3c8e229bd25b112eaf9227769873a230.pdf",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "entity": "familles",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "mensuelle"
        },
        {
          "slug": "paris_logement_familles",
          "label": "Paris Logement Famille",
          "institution": "paris",
          "description": "L’allocation Paris Logement Famille est destinée aux familles d’au moins deux enfants ou ayant un enfant handicapé. Elle leur permet de mieux supporter leurs dépenses de logement. L’aide est accordée pour une durée maximale d’un an. Elle peut être renouvelée en présentant un nouveau dossier.\n",
          "conditions": [
            "Avoir demandé le versements des aides logement auxquelles vous avez droit auprès de la CAF.",
            "Ne toucher aucune autre prestation logement de la part de la mairie de Paris ou du département."
          ],
          "link": "https://www.paris.fr/pages/simulateur-mes-aides-gouv-fr-pour-la-ville-de-paris-3626/#paris-logement-familles",
          "form": "https://cdn.paris.fr/paris/2019/07/24/736d309a8cdbf436475f7514f443a08b.pdf",
          "prefix": "l’aide",
          "type": "float",
          "unit": "€",
          "entity": "familles",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "mensuelle"
        },
        {
          "slug": "paris_logement_plfm",
          "label": "Paris Logement Familles Monoparentales",
          "institution": "paris",
          "description": "L’allocation Paris Logement Famille Monoparentale est destinée aux parents seuls, ayant un ou plusieurs enfants à charge. Elle leur permet de mieux supporter leurs dépenses de logement. Elle est ouverte aux locataires, aux propriétaires et aux personnes accédant à la propriété. L’aide est accordée pour un an. Elle peut être renouvelée en présentant un nouveau dossier.\n",
          "conditions": [
            "Avoir demandé le versement des aides logement auxquelles vous avez droit auprès de la CAF.",
            "Ne toucher aucune autre prestation logement de la part de la mairie de Paris ou du département."
          ],
          "link": "https://www.paris.fr/pages/simulateur-mes-aides-gouv-fr-pour-la-ville-de-paris-3626/#paris-logement-famille-monoparentale",
          "form": "https://cdn.paris.fr/paris/2019/07/24/75686b9338b397db4b05069015762d8b.pdf",
          "prefix": "l’aide",
          "type": "float",
          "unit": "€",
          "entity": "familles",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "mensuelle"
        },
        {
          "slug": "paris_logement_psol",
          "label": "Paris Solidarité",
          "institution": "paris",
          "description": "Paris Solidarité est destinée aux personnes âgées de 65 ans ou plus et aux personnes en situation de handicap. L’aide peut être versée tous les mois à partir de 60 ans pour les personnes reconnus inaptes au travail. Elle a pour but de garantir aux foyers modestes un minimum de ressources. L’aide est accordée pour une durée maximale d’un an.\n",
          "conditions": [
            "Percevoir tous les avantages légaux auxquels vous pouvez prétendre.",
            "Ne pas avoir déjà bénéficié du dispositif sur une durée de deux ans."
          ],
          "link": "https://www.paris.fr/pages/simulateur-mes-aides-gouv-fr-pour-la-ville-de-paris-3626/#paris-solidarite",
          "form": "https://cdn.paris.fr/paris/2019/07/24/44a441e89f01b513b678ecde6e088ce4.pdf",
          "prefix": "l’aide",
          "type": "float",
          "unit": "€",
          "entity": "familles",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "mensuelle"
        },
        {
          "slug": "paris_pass_access",
          "label": "Pass Paris Access’",
          "institution": "paris",
          "description": "Le Pass Paris Access’ est destiné aux personnes en situation de handicap. Il permet de voyager gratuitement sur l’ensemble du réseau des transports en commun d’Île-de-France (zones 1 à 5).\n",
          "link": "https://www.paris.fr/pages/aides-aux-transports-3848/#pass-paris-access",
          "form": "https://cdn.paris.fr/paris/2019/11/18/ec559b1825e317b94c547d813dd1fd7d.pdf",
          "prefix": "le",
          "type": "bool",
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "annuelle"
        },
        {
          "slug": "paris_pass_seniors",
          "label": "Pass Paris Seniors",
          "institution": "paris",
          "description": "Le Pass Paris Seniors est destiné aux personnes âgées de 65 ans ou plus (et à partir de 60 ans pour les personnes reconnues inaptes au travail). Il permet de voyager gratuitement sur l’ensemble du réseau des transports en commun d’Île-de-France (zones 1 à 5).\n",
          "link": "https://www.paris.fr/pages/aides-aux-transports-3848/#pass-paris-seniors",
          "form": "https://cdn.paris.fr/paris/2020/01/10/03d74c0a9d051a5736302fc861d66ef5.pdf",
          "prefix": "le",
          "type": "bool",
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "annuelle"
        },
        {
          "slug": "pass_culture",
          "label": "pass culture",
          "institution": "etat",
          "description": "Le pass Culture est un dispositif du ministère de la Culture, qui permet d’avoir accès entre ses 15 et 18 ans à une application pour découvrir et réserver selon vos envies les propositions culturelles de proximité et offres numériques (livres, concerts, théâtres, musées, cours de musique, abonnements numériques, etc.).",
          "link": "https://pass.culture.fr/",
          "instructions": "https://pass.culture.fr/nosapplications/",
          "prefix": "le",
          "type": "float",
          "unit": "€",
          "floorAt": 1,
          "periodicite": "ponctuelle",
          "entity": "individus",
          "openfiscaPeriod": "thisMonth"
        },
        {
          "slug": "pays_de_la_loire_epass_jeunes_culture_sport",
          "label": "e.pass jeunes Culture Sport",
          "institution": "region_pays_de_la_loire",
          "description": "L'e.pass Culture Sport de la Région Pays de la Loire représente des avantages dématérialisés. Pour un coût de 8 € vous bénéficiez de plus de 200 € d'avantages auprès de 6 000 partenaires : des entrées gratuites et des réductions tout au long de l'année pour des activités et évènements.",
          "link": "https://www.epassjeunes-paysdelaloire.fr/#!",
          "instructions": "https://www.epassjeunes-paysdelaloire.fr/#!",
          "prefix": "l’",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "floorAt": 10,
          "interestFlag": null,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth"
        },
        {
          "slug": "pays_de_la_loire_epass_jeunes_eligibilite",
          "label": "e.pass jeunes Santé",
          "institution": "region_pays_de_la_loire",
          "description": "L'e.pass jeunes Santé de la Région des Pays de la Loire vous permet d'accéder à un parcours de soin gratuit et anonyme.",
          "link": "https://www.epassjeunes-paysdelaloire.fr/#!",
          "prefix": "l’",
          "type": "bool",
          "floorAt": 10,
          "interestFlag": null,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "annuelle"
        },
        {
          "slug": "ppa",
          "label": "prime d’activité",
          "periodicite": "mensuelle",
          "type": "float",
          "unit": "€",
          "description": "La prime d’activité complète les revenus d’activité professionnelle des travailleurs de 18 ans ou plus, des étudiants salariés et apprentis et des non-salariés. La demande peut se faire à travers un téléservice sur, selon votre cas, le site de la Caf ou de la MSA. Elle remplace le RSA activité et la prime pour l’emploi depuis 2016.",
          "link": "https://www.service-public.fr/particuliers/vosdroits/F2882",
          "teleservice": "https://wwwd.caf.fr/wps/portal/caffr/aidesetservices/lesservicesenligne/faireunedemandedeprestation/demanderlaprimedactivite/#/solidariteetactivite",
          "teleservices": {
            "caf": "https://wwwd.caf.fr/wps/portal/caffr/aidesetservices/lesservicesenligne/faireunedemandedeprestation/demanderlaprimedactivite/",
            "msa": "http://www.msa.fr/lfr/c/bookmarks/open_entry?entryId=44739105"
          },
          "floorAt": 1,
          "prefix": "la",
          "entity": "familles",
          "openfiscaPeriod": "thisMonth",
          "msa": true,
          "institution": "caf"
        },
        {
          "slug": "pret_formation_permis_eligibilite",
          "label": "prêt pour le permis de conduire à un euro par jour",
          "institution": "etat",
          "description": "Le permis à un euro par jour permet d'étaler, sans aucun frais supplémentaire, le paiement de la formation au permis de conduire.\nCe dispositif permet aux jeunes de 15 à 25 ans, selon certaines conditions, de lisser le coût de la formation à la catégorie A et B du permis de conduire à raison d'un euro par jour.\nIl s'agit d'un prêt à taux zéro dont les intérêts sont pris en charge par l’État.\n",
          "conditions": [
            "Suivre votre formation dans une école de conduite <a target=\"_blank\" rel=\"noopener\" title=\"Liste des écoles de conduite partenaires - Nouvelle fenêtre\" href=\"https://www.securite-routiere.gouv.fr/sites/default/files/2021-03/liste_etablissements_labellises_au_01_03_2021.xls\">partenaire</a>.",
            "Ne pas avoir déjà bénéficié pour <a target=\"_blank\" rel=\"noopener\" title=\"catégorie de permis (A1, A2, B) - Nouvelle fenêtre\" href=\"https://www.service-public.fr/particuliers/vosdroits/N530\">la même catégorie de permis (A1, A2, B)</a> du prêt pour le permis de conduire à un euro par jour."
          ],
          "link": "https://www.securite-routiere.gouv.fr/passer-son-permis-de-conduire/financement-du-permis-de-conduire/permis-1-eu-par-jour",
          "instructions": "https://www.securite-routiere.gouv.fr/passer-son-permis-de-conduire/financement-du-permis-de-conduire/permis-1-eu-par-jour/etapes-de",
          "prefix": "le",
          "type": "bool",
          "floorAt": 10,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "interestFlag": "_interetPermisDeConduire",
          "periodicite": "ponctuelle"
        },
        {
          "slug": "rennes_metropole_transport",
          "label": "tarification solidaire transports",
          "institution": "rennes_metropole",
          "description": "La tarification solidaire est une réduction de 50 %, 85 % ou 100 % (gratuité) de l’abonnement mensuel du réseau de transports en commun Star. La réduction s’applique également au service Handistar. Elle est accordée aux familles de Rennes Métropole ayant de faibles ressources. La tarification est accordée à tous les membres du foyer.\n",
          "link": "http://metropole.rennes.fr/pratique/infos-demarches/deplacements-stationnement-voirie/tarification-solidaire-des-transports/",
          "instructions": "http://metropole.rennes.fr/pratique/infos-demarches/deplacements-stationnement-voirie/tarification-solidaire-des-transports/#c33081",
          "prefix": "la",
          "type": "float",
          "unit": "%",
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "legend": "de l’abonnement STAR",
          "periodicite": "mensuelle"
        },
        {
          "slug": "rsa",
          "label": "revenu de solidarité active",
          "periodicite": "mensuelle",
          "type": "float",
          "unit": "€",
          "description": "Le revenu de solidarité active (RSA) assure aux personnes sans ressources un niveau minimum de revenu variable selon la composition du foyer. Le RSA, le RSA parent isolé et le RSA jeunes parents sont simulés. Financé par les conseils départementaux, son versement se fait à travers la Caf ou la MSA. Les services sociaux de votre département vous orienteront vers l’organisme à qui adresser votre demande.",
          "conditions": [
            "Signer un <a target=\"_blank\" rel=\"noopener\" href=\"http://social-sante.gouv.fr/affaires-sociales/lutte-contre-l-exclusion/droits-et-aides/le-revenu-de-solidarite-active-rsa/article/quels-sont-les-droits-et-devoirs-des-beneficiaires-du-rsa\" title=\"Détails sur les droits et devoirs des bénéficiaires du RSA - Nouvelle fenêtre\">contrat d’engagement réciproque</a> (CER) avec votre département ou un <a target=\"_blank\" rel=\"noopener\" href=\"https://www.service-public.fr/particuliers/vosdroits/F14926\" title=\"Projet Personnalisé d’Accès à l’Emploi - Nouvelle fenêtre\">Projet Personnalisé d’Accès à l’Emploi</a> (PPAE) avec Pôle emploi.",
            "Résider en France plus de 9 mois par an."
          ],
          "link": "https://www.service-public.fr/particuliers/vosdroits/N19775",
          "teleservice": "https://wwwd.caf.fr/redirect/s/Redirect?page=demandeRsa",
          "forms": {
            "caf": "https://www.formulaires.modernisation.gouv.fr/gf/cerfa_15481.do",
            "msa": "https://www.formulaires.modernisation.gouv.fr/gf/cerfa_15481.do"
          },
          "setToZeroRecently": true,
          "isBaseRessourcesPatrimoine": true,
          "entity": "familles",
          "openfiscaPeriod": "thisMonth",
          "prefix": "le",
          "floorAt": 10,
          "msa": true,
          "institution": "caf"
        },
        {
          "slug": "saint_louis_eligibilite_residence",
          "label": "accompagnement personnalisé",
          "institution": "ville_saint_louis",
          "description": "Le centre communal d'action sociale de Saint-Louis vous accompagne au quotidien dans vos démarches de demandes d'aides. Les informations que vous avez intégrées dans le simulateur seront automatiquement pré-remplies dans le formulaire du centre communal d'action sociale.",
          "prefix": "un",
          "link": "https://saintlouis.re/solidarite/117-ccas",
          "teleservice": {
            "name": "redirection",
            "query": {
              "vers": "ccas_saint_louis_preprod"
            }
          },
          "type": "bool",
          "entity": "menages",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "autre",
          "skip_schema_check": true
        },
        {
          "slug": "seances_sante_psy_etudiant",
          "label": "Santé Psy Étudiant",
          "institution": "etat",
          "description": "Pendant la crise sanitaire, vous pouvez bénéficier de séances de 45 minutes avec un psychologue gratuitement, sans avancer de frais.",
          "link": "https://www.service-public.fr/particuliers/actualites/A14726",
          "instructions": "https://santepsy.etudiant.gouv.fr",
          "conditions": [
            "avoir pris contact avec un médecin."
          ],
          "prefix": "l’",
          "unit": "séances",
          "type": "float",
          "periodicite": "ponctuelle",
          "floorAt": 1,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth"
        },
        {
          "slug": "seine_saint_denis_cheque_reussite",
          "label": "chèque réussite",
          "institution": "departement_seine_saint_denis",
          "description": "Le chèque réussite est destiné aux élèves entrant en 6e dans un collège public de la Seine-Saint-Denis.\nIl est réservé à l’achat de biens scolaires, c’est-à-dire des fournitures, des livres, des équipements numériques et culturels.\n",
          "conditions": [
            "Faire la demande en ligne <strong>avant le 11 octobre 2020</strong>.",
            "Utiliser les bons d’achats dans les commerces partenaires <strong>avant le 7 décembre 2020</strong>."
          ],
          "link": "https://seinesaintdenis.fr/enfance-education-jeunesse/colleges/article/un-cheque-reussite-de-200-eur-pour-les-sixiemes",
          "teleservice": "https://chequereussite.up-gestion.com/CD93/homepage",
          "prefix": "le",
          "floorAt": 1,
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "en bons d’achat",
          "entity": "familles",
          "openfiscaPeriod": "thisMonth"
        },
        {
          "slug": "tisseo_transport_reduction",
          "label": "réduction sur les titres de transports",
          "institution": "toulouse_metropole",
          "description": "Des réductions de 70%, 80% ou 100% (gratuité) sont mises en place sur le réseau Tisséo pour cetaiens personnes (jeunes, familles à faibles revenus, personnes bénéficiant de certaines prestations, etc.).\n",
          "link": "http://www.mon-tarif-tisseo.fr",
          "form": "https://www.tisseo.fr/les-tarifs/e-agence",
          "prefix": "une",
          "type": "float",
          "unit": "%",
          "legend": "de réduction",
          "entity": "individus",
          "openfiscaPeriod": "thisMonth",
          "periodicite": "ponctuelle"
        },
        {
          "slug": "vendee_bourses_jeunes_benevoles_montant_max",
          "label": "Bourses aux jeunes bénévoles vendéens",
          "institution": "departement_vendee",
          "description": "Le Département de la Vendée attribue des bourses d'études ou pour passer le permis de conduire aux jeunes de 16-25 ans qui sont engagés dans des actions bénévoles.",
          "conditions": [
            "justifier pour les mineurs d'un engagement d'au moins un an dans une ou plusieurs associations domiciliées en Vendée.",
            "apporter pour les majeurs la preuve d'une responsabilité dans le Bureau d'une association depuis au moins un an ou d'une implication régulière d'au moins deux ans."
          ],
          "link": "https://www.benevolat.vendee.fr/Le-plan-Vendee-Double-Coeur/La-bourse-aux-jeunes-benevoles-vendeens",
          "form": "https://benevolat.vendee.fr/content/download/3539930/114782465/file/formulaire_bourse2021.pdf",
          "prefix": "les",
          "type": "float",
          "unit": "€",
          "periodicite": "ponctuelle",
          "legend": "maximum",
          "floorAt": 10,
          "interestFlag": null,
          "entity": "individus",
          "openfiscaPeriod": "thisMonth"
        },
        {
          "slug": "visale_eligibilite",
          "label": "Garantie Visale",
          "teleservice": "https://fo.visale.fr/#!/register/locataire",
          "prefix": "la ",
          "entity": "menages",
          "openfiscaPeriod": "thisMonth",
          "top": 5,
          "institution": "action_logement",
          "description": "La garantie Visale est une caution accordée par Action logement au locataire qui veut louer un logement. Elle couvre les loyers et charges impayés de la résidence principale du locataire jusqu'à 36 mensualités.",
          "conditions": [
            "Ne pas être employé·e par une entreprise publique."
          ],
          "link": "https://www.service-public.fr/particuliers/vosdroits/F33453",
          "type": "bool",
          "periodicite": "ponctuelle"
        }
      ]
    },
    "institutions": {
      "name": "institutions",
      "label": "Institutions",
      "label_singular": "institution",
      "identifier_field": "name",
      "folder": "data/institutions",
      "create": true,
      "delete": false,
      "editor": {
        "preview": false
      },
      "slug": "{{name}}",
      "extension": "yml",
      "fields": [
        {
          "label": "Nom de votre institution",
          "hint": "Département de la Gironde, Ville de Canéjan, Mauges Communauté, etc.",
          "name": "name",
          "widget": "string"
        },
        {
          "label": "Logo",
          "name": "imgSrc",
          "widget": "image",
          "allow_multiple": false,
          "pattern": [
            "(\\.png|\\.jpg|\\.jpeg)",
            "Seul les images au format JPG et PNG sont supportées"
          ]
        },
        {
          "label": "Type géographique",
          "name": "type",
          "widget": "select",
          "options": [
            {
              "label": "Organisation nationale",
              "value": "national"
            },
            {
              "label": "Région",
              "value": "region"
            },
            {
              "label": "Département",
              "value": "departement"
            },
            {
              "label": "CAF locale",
              "value": "caf"
            },
            {
              "label": "EPCI (Métropole, inter-communauté, etc.)",
              "value": "epci"
            },
            {
              "label": "Commune",
              "value": "commune"
            },
            {
              "label": "Autre",
              "value": "autre"
            }
          ]
        },
        {
          "label": "Code INSEE",
          "hint": "Pour les communes, départements, régions",
          "name": "code_insee",
          "widget": "string",
          "required": false,
          "required_group": "identifier"
        },
        {
          "label": "Code SIREN",
          "hint": "En l'absence de code INSEE",
          "name": "code_siren",
          "widget": "string",
          "required": false,
          "required_group": "identifier"
        },
        {
          "label": "Article défini",
          "name": "prefix",
          "widget": "hidden",
          "required": false
        },
        {
          "label": "Etablissements",
          "name": "etablissements",
          "widget": "hidden",
          "required": false
        },
        {
          "label": "repository",
          "name": "repository",
          "widget": "hidden",
          "required": false
        }
      ],
      "items": [
        {
          "slug": "action_logement",
          "name": "Action Logement",
          "imgSrc": "img/logo_action_logement.png",
          "type": "national"
        },
        {
          "slug": "assurance_maladie",
          "name": "Assurance maladie",
          "imgSrc": "img/logo_assurance_maladie.png",
          "type": "national",
          "etablissements": [
            "cpam"
          ]
        },
        {
          "slug": "assurance_retraite",
          "name": "Assurance retraite",
          "imgSrc": "img/logo_assurance_retraite.png",
          "type": "national",
          "etablissements": [
            "carsat"
          ]
        },
        {
          "slug": "banque_de_france",
          "name": "La Banque de France",
          "imgSrc": "img/logo_banque_de_france.png",
          "type": "national"
        },
        {
          "slug": "brest_metropole",
          "name": "Brest Métropole",
          "imgSrc": "img/logo_brest_metropole.png",
          "prefix": "de",
          "repository": "france-local",
          "type": "epci",
          "code_siren": "242900314"
        },
        {
          "slug": "ca_dagen",
          "name": "Communauté d'agglomération d'Agen",
          "imgSrc": "img/logo_ca_dagen.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "200035459"
        },
        {
          "slug": "ca_de_bethune_bruay_artois_lys_romane",
          "name": "Communauté d'agglomération de Béthune-Bruay, Artois-Lys Romane",
          "imgSrc": "img/logo_ca_de_bethune_bruay_artois_lys_romane.jpg",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "200072460"
        },
        {
          "slug": "ca_de_la_porte_du_hainaut",
          "name": "Communauté d'agglomération de la Porte du Hainaut",
          "imgSrc": "img/logo_ca_de_la_porte_du_hainaut.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "200042190"
        },
        {
          "slug": "ca_de_la_provence_verte",
          "name": "Communauté d'agglomération de la Provence Verte",
          "imgSrc": "img/logo_ca_de_la_provence_verte.jpg",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "200068104"
        },
        {
          "slug": "ca_de_saintes",
          "name": "Communauté d'agglomération de Saintes",
          "imgSrc": "img/logo_ca_de_saintes.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "200036473"
        },
        {
          "slug": "ca_du_bocage_bressuirais",
          "name": "Communauté d'agglomération du Bocage Bressuirais",
          "imgSrc": "img/logo_ca_du_bocage_bressuirais.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "200040244"
        },
        {
          "slug": "ca_flers_agglo",
          "name": "Communauté d'agglomération Flers Agglo",
          "imgSrc": "img/logo_ca_flers_agglo.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "200035814"
        },
        {
          "slug": "ca_grand_albigeois",
          "name": "Communauté d'agglomeration Grand Albigeois",
          "imgSrc": "img/logo_communaute_agglomeration_grand_albigeois.png",
          "type": "epci",
          "code_siren": "248100737"
        },
        {
          "slug": "ca_grand_chatellerault",
          "name": "Communauté d'agglomération Grand Châtellerault",
          "imgSrc": "img/logo_ca_grand_chatellerault.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "248600413"
        },
        {
          "slug": "ca_grand_lac",
          "name": "Communauté d'agglomération Grand Lac",
          "imgSrc": "img/logo_ca_grand_lac.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "200068674"
        },
        {
          "slug": "ca_lannion_tregor_communaute",
          "name": "Communauté d'agglomération Lannion-Trégor Communauté",
          "imgSrc": "img/logo_ca_lannion_tregor_communaute.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "200065928"
        },
        {
          "slug": "ca_le_grand_perigueux",
          "name": "Communauté d'agglomération Le Grand Périgueux",
          "imgSrc": "img/logo_ca_le_grand_perigueux.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "200040392"
        },
        {
          "slug": "ca_luberon_monts_de_vaucluse",
          "name": "Communauté d'agglomération Luberon Monts de Vaucluse",
          "imgSrc": "img/logo_ca_luberon_monts_de_vaucluse.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "200040442"
        },
        {
          "slug": "ca_pays_de_l_or",
          "name": "Communauté d'agglomération du Pays de l’Or",
          "imgSrc": "img/logo_communaute_agglomeration_pays_de_l_or.png",
          "type": "epci",
          "code_siren": "243400470"
        },
        {
          "slug": "ca_pays_de_saint_malo_agglomeration",
          "name": "Communauté d'agglomération du Pays de Saint Malo Agglomération",
          "imgSrc": "img/logo_ca_pays_de_saint_malo_agglomeration.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "243500782"
        },
        {
          "slug": "ca_quimperle_communaute",
          "name": "Communauté d'agglomération Quimperlé Communauté",
          "imgSrc": "img/logo_ca_quimperle_communaute.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "242900694"
        },
        {
          "slug": "ca_saint_brieuc_armor_agglomeration",
          "name": "Communauté d'agglomération Saint-Brieuc Armor Agglomération",
          "imgSrc": "img/logo_ca_saint_brieuc_armor_agglomeration.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "200069409"
        },
        {
          "slug": "ca_saumur_val_de_loire",
          "name": "Communauté d'agglomération Saumur Val de Loire",
          "imgSrc": "img/logo_epci_saumur.png",
          "type": "epci",
          "code_siren": "200071876"
        },
        {
          "slug": "ca_sete_agglopole_mediterranee",
          "name": "Communauté d'agglomération Sète Agglopôle Méditerranée",
          "imgSrc": "img/logo_ca_sete_agglopole_mediterranee.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "200066355"
        },
        {
          "slug": "ca_tarbes_lourdes_pyrenees",
          "name": "Communauté d'agglomération de Tarbes Lourdes Pyrénées",
          "imgSrc": "img/logo_epci_tarbes_lourdes_pyrenees.png",
          "type": "epci",
          "code_siren": "200069300"
        },
        {
          "slug": "ca_tulle_agglo",
          "name": "Communauté d'agglomération Tulle Agglo",
          "imgSrc": "img/logo_ca_tulle_agglo.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "241927201"
        },
        {
          "slug": "ca_vienne_condrieu",
          "name": "Communauté d'agglomération Vienne Condrieu",
          "imgSrc": "img/logo_ca_vienne_condrieu.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "200077014"
        },
        {
          "slug": "ca_villefranche_beaujolais_saone",
          "name": "Communauté d'agglomération Villefranche Beaujolais Saône",
          "imgSrc": "img/logo_ca_villefranche_beaujolais_saone.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "200040590"
        },
        {
          "slug": "caf",
          "name": "Caisse d’allocations familiales",
          "imgSrc": "img/logo_caf.png",
          "type": "national",
          "etablissements": [
            "caf"
          ]
        },
        {
          "slug": "caf_ain",
          "name": "CAF de l'Ain",
          "imgSrc": "img/logo_caf_ain.png",
          "type": "caf",
          "code_siren": "779311224"
        },
        {
          "slug": "caf_aisne",
          "name": "CAF de l'Aisne",
          "imgSrc": "img/logo_caf_aisne.png",
          "type": "caf",
          "code_siren": "534155403"
        },
        {
          "slug": "caf_allier",
          "name": "CAF de l'Allier",
          "imgSrc": "img/logo_caf_allier.png",
          "type": "caf",
          "code_siren": "775548555"
        },
        {
          "slug": "caf_alpes_de_haute_provence",
          "name": "CAF des Alpes-de-Haute-Provence",
          "imgSrc": "img/logo_caf_alpes_de_haute_provence.png",
          "type": "caf",
          "code_siren": "775549371"
        },
        {
          "slug": "caf_alpes_maritimes",
          "name": "CAF des Alpes-Maritimes",
          "imgSrc": "img/logo_caf_alpes_maritimes.png",
          "type": "caf",
          "code_siren": "782620520"
        },
        {
          "slug": "caf_ardeche",
          "name": "CAF de l'Ardèche",
          "imgSrc": "img/logo_caf_ardeche.png",
          "type": "caf",
          "code_siren": "534224282"
        },
        {
          "slug": "caf_ardennes",
          "name": "CAF des Ardennes",
          "imgSrc": "img/logo_caf_ardennes.png",
          "type": "caf",
          "code_siren": "780254702"
        },
        {
          "slug": "caf_ariege",
          "name": "CAF de l'Ariège",
          "imgSrc": "img/logo_caf_ariege.png",
          "type": "caf",
          "code_siren": "776656209"
        },
        {
          "slug": "caf_aube",
          "name": "CAF de l'Aube",
          "imgSrc": "img/logo_caf_aube.png",
          "type": "caf",
          "code_siren": "780349759"
        },
        {
          "slug": "caf_aude",
          "name": "CAF de l'Aude",
          "imgSrc": "img/logo_caf_aude.png",
          "type": "caf",
          "code_siren": "775555642"
        },
        {
          "slug": "caf_aveyron",
          "name": "CAF de l'Aveyron",
          "imgSrc": "img/logo_caf_aveyron.png",
          "type": "caf",
          "code_siren": "776744005"
        },
        {
          "slug": "caf_bas_rhin",
          "name": "CAF du Bas-Rhin",
          "imgSrc": "img/logo_caf_bas_rhin.png",
          "type": "caf",
          "code_siren": "778868497"
        },
        {
          "slug": "caf_bouches_du_rhone",
          "name": "CAF des Bouches-du-Rhône",
          "imgSrc": "img/logo_caf_bouches_du_rhone.png",
          "type": "caf",
          "code_siren": "775558364"
        },
        {
          "slug": "caf_calvados",
          "name": "CAF du Calvados",
          "imgSrc": "img/logo_caf_calvados.png",
          "type": "caf",
          "code_siren": "775561343"
        },
        {
          "slug": "caf_cantal",
          "name": "CAF du Cantal",
          "imgSrc": "img/logo_caf_cantal.png",
          "type": "caf",
          "code_siren": "775562531"
        },
        {
          "slug": "caf_charente",
          "name": "CAF de la Charente",
          "imgSrc": "img/logo_caf_charente.png",
          "type": "caf",
          "code_siren": "781172366"
        },
        {
          "slug": "caf_charente_maritime",
          "name": "CAF de la Charente-Maritime",
          "imgSrc": "img/logo_caf_charente_maritime.png",
          "type": "caf",
          "code_siren": "775564669"
        },
        {
          "slug": "caf_cher",
          "name": "CAF du Cher",
          "imgSrc": "img/logo_caf_cher.png",
          "type": "caf",
          "code_siren": "775021801"
        },
        {
          "slug": "caf_correze",
          "name": "CAF de la Corrèze",
          "imgSrc": "img/logo_caf_correze.png",
          "type": "caf",
          "code_siren": "777927138"
        },
        {
          "slug": "caf_corse_du_sud",
          "name": "CAF de Corse-du-Sud",
          "imgSrc": "img/logo_caf_corse_du_sud.png",
          "type": "caf",
          "code_siren": "782993133"
        },
        {
          "slug": "caf_cote_or",
          "name": "CAF de Côte-d'Or",
          "imgSrc": "img/logo_caf_cote_or.png",
          "type": "caf",
          "code_insee": "21",
          "code_siren": "778213348"
        },
        {
          "slug": "caf_cotes_armor",
          "name": "CAF des Côtes-d'Armor",
          "imgSrc": "img/logo_caf_cotes_armor.png",
          "type": "caf",
          "code_siren": "777461336"
        },
        {
          "slug": "caf_creuse",
          "name": "CAF de la Creuse",
          "imgSrc": "img/logo_caf_creuse.png",
          "type": "caf",
          "code_siren": "777998881"
        },
        {
          "slug": "caf_deux_sevres",
          "name": "CAF des Deux-Sèvres",
          "imgSrc": "img/logo_caf_deux_sevres.png",
          "type": "caf",
          "code_siren": "781459599"
        },
        {
          "slug": "caf_dordogne",
          "name": "CAF de Dordogne",
          "imgSrc": "img/logo_caf_dordogne.png",
          "type": "caf",
          "code_siren": "303336192"
        },
        {
          "slug": "caf_doubs",
          "name": "CAF du Doubs",
          "imgSrc": "img/logo_caf_doubs.png",
          "type": "caf",
          "code_siren": "534738778"
        },
        {
          "slug": "caf_drome",
          "name": "CAF de la Drôme",
          "imgSrc": "img/logo_caf_drome.png",
          "type": "caf",
          "code_siren": "775573397"
        },
        {
          "slug": "caf_essonne",
          "name": "CAF de l'Essonne",
          "imgSrc": "img/logo_caf_essonne.png",
          "type": "caf",
          "code_siren": "381016534"
        },
        {
          "slug": "caf_eure",
          "name": "CAF de l'Eure",
          "imgSrc": "img/logo_caf_eure.png",
          "type": "caf",
          "code_siren": "780808010"
        },
        {
          "slug": "caf_eure_et_loir",
          "name": "CAF de l'Eure-et-Loir",
          "imgSrc": "img/logo_caf_eure_et_loir.png",
          "type": "caf",
          "code_siren": "775103955"
        },
        {
          "slug": "caf_finistere",
          "name": "CAF du Finistère",
          "imgSrc": "img/logo_caf_finistere.png",
          "type": "caf",
          "code_siren": "535326656"
        },
        {
          "slug": "caf_gard",
          "name": "CAF du Gard",
          "imgSrc": "img/logo_caf_gard.png",
          "type": "caf",
          "code_siren": "775103955"
        },
        {
          "slug": "caf_gers",
          "name": "CAF du Gers",
          "imgSrc": "img/logo_caf_gers.png",
          "type": "caf",
          "code_siren": "776986671"
        },
        {
          "slug": "caf_gironde",
          "name": "CAF de Gironde",
          "imgSrc": "img/logo_caf_gironde.png",
          "type": "caf",
          "code_siren": "781847488"
        },
        {
          "slug": "caf_guadeloupe",
          "name": "CAF de Guadeloupe",
          "imgSrc": "img/logo_caf_guadeloupe.png",
          "type": "caf",
          "code_siren": "314560822"
        },
        {
          "slug": "caf_guyane",
          "name": "CAF de Guyane",
          "imgSrc": "img/logo_caf_guyane.png",
          "type": "caf",
          "code_siren": "315190751"
        },
        {
          "slug": "caf_haut_rhin",
          "name": "CAF du Haut-Rhin",
          "imgSrc": "img/logo_caf_haut_rhin.png",
          "type": "caf",
          "code_siren": "778953844"
        },
        {
          "slug": "caf_haute_corse",
          "name": "CAF de Haute-Corse",
          "imgSrc": "img/logo_caf_haute_corse.png",
          "type": "caf",
          "code_siren": "327398152"
        },
        {
          "slug": "caf_haute_garonne",
          "name": "CAF de Haute-Garonne",
          "imgSrc": "img/logo_caf_haute_garonne.png",
          "type": "caf",
          "code_siren": "776950446"
        },
        {
          "slug": "caf_haute_loire",
          "name": "CAF de la Haute-Loire",
          "imgSrc": "img/logo_caf_haute_loire.png",
          "type": "caf",
          "code_siren": "779145598"
        },
        {
          "slug": "caf_haute_marne",
          "name": "CAF de la Haute-Marne",
          "imgSrc": "img/logo_caf_haute_marne.png",
          "type": "caf",
          "code_siren": "775613227"
        },
        {
          "slug": "caf_haute_saone",
          "name": "CAF de Haute-Saône",
          "imgSrc": "img/logo_caf_haute_saone.png",
          "type": "caf",
          "code_siren": "778542837"
        },
        {
          "slug": "caf_haute_savoie",
          "name": "CAF de la Haute-Savoie",
          "imgSrc": "img/logo_caf_haute_savoie.png",
          "type": "caf",
          "code_siren": "776531576"
        },
        {
          "slug": "caf_haute_vienne",
          "name": "CAF de la Haute-Vienne",
          "imgSrc": "img/logo_caf_haute_vienne.png",
          "type": "caf",
          "code_siren": "778073189"
        },
        {
          "slug": "caf_hautes_alpes",
          "name": "CAF des Hautes-Alpes",
          "imgSrc": "img/logo_caf_hautes_alpes.png",
          "type": "caf",
          "code_siren": "782437586"
        },
        {
          "slug": "caf_hautes_pyrenees",
          "name": "CAF des Hautes-Pyrénées",
          "imgSrc": "img/logo_caf_hautes_pyrenees.png",
          "type": "caf",
          "code_siren": "777169046"
        },
        {
          "slug": "caf_hauts_de_seine",
          "name": "CAF des Hauts-de-Seine",
          "imgSrc": "img/logo_caf_hauts_de_seine.png",
          "type": "caf",
          "code_siren": "381050996"
        },
        {
          "slug": "caf_herault",
          "name": "CAF de l'Hérault",
          "imgSrc": "img/logo_caf_herault.png",
          "type": "caf",
          "code_siren": "534089529"
        },
        {
          "slug": "caf_ille_et_vilaine",
          "name": "CAF d'Ille-et-Vilaine",
          "imgSrc": "img/logo_caf_ille_et_vilaine.png",
          "type": "caf",
          "code_siren": "777749375"
        },
        {
          "slug": "caf_indre",
          "name": "CAF de l'Indre",
          "imgSrc": "img/logo_caf_indre.png",
          "type": "caf",
          "code_siren": "775189038"
        },
        {
          "slug": "caf_indre_et_loire",
          "name": "CAF d'Indre-et-Loire",
          "imgSrc": "img/logo_caf_indre_et_loire.png",
          "type": "caf",
          "code_siren": "775347875"
        },
        {
          "slug": "caf_isere",
          "name": "CAF de l'Isère",
          "imgSrc": "img/logo_caf_isere.png",
          "type": "caf",
          "code_siren": "535363071"
        },
        {
          "slug": "caf_jura",
          "name": "CAF du Jura",
          "imgSrc": "img/logo_caf_jura.png",
          "type": "caf",
          "code_siren": "778422832"
        },
        {
          "slug": "caf_la_reunion",
          "name": "CAF de La Réunion",
          "imgSrc": "img/logo_caf_la_reunion.png",
          "type": "caf",
          "code_siren": "314635368"
        },
        {
          "slug": "caf_landes",
          "name": "CAF des Landes",
          "imgSrc": "img/logo_caf_landes.png",
          "type": "caf",
          "code_siren": "782099121"
        },
        {
          "slug": "caf_loir_et_cher",
          "name": "CAF de Loir-et-Cher",
          "imgSrc": "img/logo_caf_loir_et_cher.png",
          "type": "caf",
          "code_siren": "775369598"
        },
        {
          "slug": "caf_loire",
          "name": "CAF de la Loire",
          "imgSrc": "img/logo_caf_loire.png",
          "type": "caf",
          "code_siren": "534216080"
        },
        {
          "slug": "caf_loire_atlantique",
          "name": "CAF de Loire-Atlantique",
          "imgSrc": "img/logo_caf_loire_atlantique.png",
          "type": "caf",
          "code_siren": "786019554"
        },
        {
          "slug": "caf_loiret",
          "name": "CAF du Loiret",
          "imgSrc": "img/logo_caf_loiret.png",
          "type": "caf",
          "code_siren": "775513708"
        },
        {
          "slug": "caf_lot",
          "name": "CAF du Lot",
          "imgSrc": "img/logo_caf_lot.png",
          "type": "caf",
          "code_siren": "777053125"
        },
        {
          "slug": "caf_lot_et_garonne",
          "name": "CAF de Lot-et-Garonne",
          "imgSrc": "img/logo_caf_lot_et_garonne.png",
          "type": "caf",
          "code_siren": "782152888"
        },
        {
          "slug": "caf_lozere",
          "name": "CAF de Lozère",
          "imgSrc": "img/logo_caf_lozere.png",
          "type": "caf",
          "code_siren": "776115255"
        },
        {
          "slug": "caf_maine_et_loire",
          "name": "CAF de Maine-et-Loire",
          "imgSrc": "img/logo_caf_maine_et_loire.png",
          "type": "caf",
          "code_siren": "534172481"
        },
        {
          "slug": "caf_manche",
          "name": "CAF de la Manche",
          "imgSrc": "img/logo_caf_manche.png",
          "type": "caf",
          "code_siren": "780860292"
        },
        {
          "slug": "caf_marne",
          "name": "CAF de Marne",
          "imgSrc": "img/logo_caf_marne.png",
          "type": "caf",
          "code_siren": "780428975"
        },
        {
          "slug": "caf_martinique",
          "name": "CAF de Martinique",
          "imgSrc": "img/logo_caf_martinique.png",
          "type": "caf",
          "code_siren": "314307828"
        },
        {
          "slug": "caf_mayenne",
          "name": "CAF de Mayenne",
          "imgSrc": "img/logo_caf_mayenne.png",
          "type": "caf",
          "code_siren": "775613995"
        },
        {
          "slug": "caf_mayotte",
          "name": "CAF de Mayotte",
          "imgSrc": "img/logo_caf_mayotte.png",
          "type": "caf",
          "code_siren": "751790916"
        },
        {
          "slug": "caf_meurthe_et_moselle",
          "name": "CAF de Meurthe-et-Moselle",
          "imgSrc": "img/logo_caf_meurthe_et_moselle.png",
          "type": "caf",
          "code_siren": "775615529"
        },
        {
          "slug": "caf_meuse",
          "name": "CAF de la Meuse",
          "imgSrc": "img/logo_caf_meuse.png",
          "type": "caf",
          "code_siren": "783382344"
        },
        {
          "slug": "caf_morbihan",
          "name": "CAF du Morbihan",
          "imgSrc": "img/logo_caf_morbihan.png",
          "type": "caf",
          "code_siren": "777907700"
        },
        {
          "slug": "caf_moselle",
          "name": "CAF de Moselle",
          "imgSrc": "img/logo_caf_moselle.png",
          "type": "caf",
          "code_siren": "780004032"
        },
        {
          "slug": "caf_nievre",
          "name": "CAF de la Nièvre",
          "imgSrc": "img/logo_caf_nievre.png",
          "type": "caf",
          "code_siren": "778477737"
        },
        {
          "slug": "caf_nord",
          "name": "CAF du Nord",
          "imgSrc": "img/logo_caf_nord_quadrichromie.jpg",
          "type": "caf",
          "code_siren": "534175179"
        },
        {
          "slug": "caf_oise",
          "name": "CAF de l'Oise",
          "imgSrc": "img/logo_caf_oise.png",
          "type": "caf",
          "code_siren": "534224613"
        },
        {
          "slug": "caf_orne",
          "name": "CAF de l'Orne",
          "imgSrc": "img/logo_caf_orne.png",
          "type": "caf",
          "code_siren": "775629256"
        },
        {
          "slug": "caf_paris",
          "name": "CAF de Paris",
          "imgSrc": "img/logo_caf_paris.png",
          "type": "caf",
          "code_siren": "380992255"
        },
        {
          "slug": "caf_pas_de_calais",
          "name": "CAF du Pas-de-Calais",
          "imgSrc": "img/logo_caf_pas_de_calais.png",
          "type": "caf",
          "code_siren": "534214051"
        },
        {
          "slug": "caf_puy_de_dome",
          "name": "CAF du Puy-de-Dôme",
          "imgSrc": "img/logo_caf_puy_de_dome.png",
          "type": "caf",
          "code_siren": "775634264"
        },
        {
          "slug": "caf_pyrenees_atlantiques",
          "name": "CAF des Pyrénées-Atlantiques",
          "imgSrc": "img/logo_caf_pyrenees_atlantiques.png",
          "type": "caf",
          "code_siren": "831358262"
        },
        {
          "slug": "caf_pyrenees_orientales",
          "name": "CAF des Pyrénées-Orientales",
          "imgSrc": "img/logo_caf_pyrenees_orientales.png",
          "type": "caf",
          "code_siren": "775640238"
        },
        {
          "slug": "caf_rhone",
          "name": "CAF du Rhône",
          "imgSrc": "img/logo_caf_rhone.png",
          "type": "caf",
          "code_siren": "534037254"
        },
        {
          "slug": "caf_saone_et_loire",
          "name": "CAF de Saône-et-Loire",
          "imgSrc": "img/logo_caf_saone_et_loire.png",
          "type": "caf",
          "code_siren": "778600130"
        },
        {
          "slug": "caf_sarthe",
          "name": "CAF de Sarthe",
          "imgSrc": "img/logo_caf_sarthe.png",
          "type": "caf",
          "code_siren": "786338871"
        },
        {
          "slug": "caf_savoie",
          "name": "CAF de la Savoie",
          "imgSrc": "img/logo_caf_savoie.png",
          "type": "caf",
          "code_siren": "775653330"
        },
        {
          "slug": "caf_seine_et_marne",
          "name": "CAF Seine et Marne",
          "imgSrc": "img/logo_caf_seine_et_marne.png",
          "type": "caf",
          "code_siren": "784971343"
        },
        {
          "slug": "caf_seine_maritime",
          "name": "CAF de Seine Maritime",
          "imgSrc": "img/logo_caf_seine_maritime.png",
          "type": "caf",
          "code_siren": "534092499"
        },
        {
          "slug": "caf_seine_st_denis",
          "name": "CAF de Seine-Saint-Denis",
          "imgSrc": "img/logo_caf_seine_st_denis.png",
          "type": "caf",
          "code_siren": "380980300"
        },
        {
          "slug": "caf_somme",
          "name": "CAF de la Somme",
          "imgSrc": "img/logo_caf_somme.png",
          "type": "caf",
          "code_siren": "775710791"
        },
        {
          "slug": "caf_tarn",
          "name": "CAF du Tarn",
          "imgSrc": "img/logo_caf_tarn.png",
          "type": "caf",
          "code_siren": "777187691"
        },
        {
          "slug": "caf_tarn_et_garonne",
          "name": "CAF du Tarn-et-Garonne",
          "imgSrc": "img/logo_caf_tarn_et_garonne.png",
          "type": "caf",
          "code_siren": "777306184"
        },
        {
          "slug": "caf_territoire_de_belfort",
          "name": "CAF du Territoire de Belfort",
          "imgSrc": "img/logo_caf_territoire_de_belfort.png",
          "type": "caf",
          "code_siren": "778714964"
        },
        {
          "slug": "caf_val_de_marne",
          "name": "CAF du Val-de-Marne",
          "imgSrc": "img/logo_caf_val_de_marne.png",
          "type": "caf",
          "code_siren": "381202282"
        },
        {
          "slug": "caf_val_oise",
          "name": "CAF du Val-d'Oise",
          "imgSrc": "img/logo_caf_val_oise.png",
          "type": "caf",
          "code_siren": "381002534"
        },
        {
          "slug": "caf_var",
          "name": "CAF du Var",
          "imgSrc": "img/logo_caf_var.png",
          "type": "caf",
          "code_siren": "783169196"
        },
        {
          "slug": "caf_vaucluse",
          "name": "CAF du Vaucluse",
          "imgSrc": "img/logo_caf_vaucluse.png",
          "type": "caf",
          "code_siren": "775714124"
        },
        {
          "slug": "caf_vendee",
          "name": "CAF de Vendée",
          "imgSrc": "img/logo_caf_vendee.png",
          "type": "caf",
          "code_siren": "786448050"
        },
        {
          "slug": "caf_vienne",
          "name": "CAF de la Vienne",
          "imgSrc": "img/logo_caf_vienne.png",
          "type": "caf",
          "code_siren": "775716202"
        },
        {
          "slug": "caf_vosges",
          "name": "CAF des Vosges",
          "imgSrc": "img/logo_caf_vosges.png",
          "type": "caf",
          "code_siren": "775717333"
        },
        {
          "slug": "caf_yonne",
          "name": "CAF de l'Yonne",
          "imgSrc": "img/logo_caf_yonne.png",
          "type": "caf",
          "code_siren": "778649525"
        },
        {
          "slug": "caf_yvelines",
          "name": "CAF des Yvelines",
          "imgSrc": "img/logo_caf_yvelines.png",
          "type": "caf",
          "code_siren": "381067784"
        },
        {
          "slug": "cc_aunis_atlantique",
          "name": "Communauté de communes Aunis Atlantique",
          "imgSrc": "img/epci_aunis.jpg",
          "type": "epci",
          "code_siren": "200041499"
        },
        {
          "slug": "cc_caux_austreberthe",
          "name": "Communauté de communes Caux-Austreberthe",
          "imgSrc": "img/logo_epci_caux.png",
          "type": "epci",
          "code_siren": "247600646"
        },
        {
          "slug": "cc_charlieu_belmont",
          "name": "Communauté de communes de Charlieu-Belmont",
          "imgSrc": "img/logo_epci_charlieu_belmont.jpg",
          "type": "epci",
          "code_insee": "",
          "code_siren": "200035202"
        },
        {
          "slug": "cc_chateaubriant_derval",
          "name": "Communauté de communes Châteaubriant-Derval",
          "imgSrc": "img/logo_cc_chateaubriant_derval.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "200072726"
        },
        {
          "slug": "cc_coutance_mer_et_bocage",
          "name": "Communauté de communes de Coutance Mer et Bocage",
          "imgSrc": "img/epci_coutances.png",
          "type": "epci",
          "code_siren": "200067023"
        },
        {
          "slug": "cc_des_portes_de_rosheim",
          "name": "Communauté de communes des Portes de Rosheim",
          "imgSrc": "img/logo_cc_des_portes_de_rosheim.jpg",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "246700744"
        },
        {
          "slug": "cc_dieulefit_bourdeaux",
          "name": "Communauté de communes Dieulefit-Bourdeaux",
          "imgSrc": "img/logo_cc_dieulefit_bourdeaux.jpg",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "242600492"
        },
        {
          "slug": "cc_douarnenez_communaute",
          "name": "Communauté de communes Douarnenez Communauté",
          "imgSrc": "img/logo_cc_douarnenez_communaute.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "242900645"
        },
        {
          "slug": "cc_du_haut_poitou",
          "name": "Communauté de communes du Haut-Poitou",
          "imgSrc": "img/logo_cc_du_haut_poitou.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "200069763"
        },
        {
          "slug": "cc_du_pays_rhenan",
          "name": "Communauté de communes du Pays Rhénan",
          "imgSrc": "img/logo_cc_du_pays_rhenan.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "200041325"
        },
        {
          "slug": "cc_ernee",
          "name": "Communauté de communes de l'Ernée",
          "imgSrc": "img/logo_cc_ernee.jpg",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "245300355"
        },
        {
          "slug": "cc_flandre_interieure",
          "name": "Communauté de communes de Flandre Intérieure",
          "imgSrc": "img/logo_cc_flandre_interieure.jpg",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "200040947"
        },
        {
          "slug": "cc_genevois",
          "name": "Communauté de communes du Genevois",
          "imgSrc": "img/logo_cc_genevois.jpg",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "247400690"
        },
        {
          "slug": "cc_giennoises",
          "name": "Communauté des communes Giennoises",
          "imgSrc": "img/logo_epci_giennoises.png",
          "type": "epci",
          "code_siren": "244500211"
        },
        {
          "slug": "cc_grand_lieu",
          "name": "Communauté de communes de Grand Lieu",
          "imgSrc": "img/epci_grand_lieu.png",
          "type": "epci",
          "code_siren": "244400438"
        },
        {
          "slug": "cc_grand_saint_emilionnais",
          "name": "Communauté de communes du Grand Saint Emilionnais",
          "imgSrc": "img/logo_cc_grand_saint_emilionnais.jpg",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "200035533"
        },
        {
          "slug": "cc_les_balcons_du_dauphine",
          "name": "Communauté de communes Les Balcons du Dauphiné",
          "imgSrc": "img/logo_cc_les_balcons_du_dauphine.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "200068542"
        },
        {
          "slug": "cc_loire_layon_aubance",
          "name": "Communauté de communes Loire Layon Aubance",
          "imgSrc": "img/logo_cc_loire_layon_aubance.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "200071553"
        },
        {
          "slug": "cc_pays_des_achards",
          "name": "Communauté de communes du Pays des Achards",
          "imgSrc": "img/logo_cc_pays_des_achards.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "248500530"
        },
        {
          "slug": "cc_pays_saint_gilles_croix_de_vie",
          "name": "Communauté de communes du Pays de Saint Gilles-Croix-de-Vie",
          "imgSrc": "img/logo_cc_pays_saint_gilles_croix_de_vie.jpg",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "200023778"
        },
        {
          "slug": "cc_rives_de_moselle",
          "name": "Communauté de communes Rives de Moselle",
          "imgSrc": "img/logo_cc_rives_de_moselle.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "200039949"
        },
        {
          "slug": "cc_sevre_et_loire",
          "name": "Communauté de communes de Sèvre-et-Loire",
          "imgSrc": "img/logo_epci_sevres_loire.png",
          "type": "epci",
          "code_siren": "200067866"
        },
        {
          "slug": "cc_sud_estuaire",
          "name": "Communauté de communes du Sud Estuaire",
          "imgSrc": "img/logo_epci_sud_estuaire.png",
          "type": "epci",
          "code_siren": "244400586"
        },
        {
          "slug": "cc_sud_nivernais",
          "name": "Communauté de communes du Sud Nivernais",
          "imgSrc": "img/logo_epci_sud_nivernais.jpg",
          "type": "epci",
          "code_siren": "200067700"
        },
        {
          "slug": "cc_terre_des_deux_caps",
          "name": "Communauté de communes de la Terre des Deux Caps",
          "imgSrc": "img/logo_cc_terre_des_deux_caps.png",
          "prefix": "de",
          "type": "epci",
          "code_siren": "246200380"
        },
        {
          "slug": "cc_terres_de_montaigu_cc_montaigu_rocheserviere",
          "name": "Communauté de communes Terres de Montaigu, CC Montaigu-Rocheservière",
          "imgSrc": "img/logo_cc_terres_de_montaigu_cc_montaigu_rocheserviere.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "200070233"
        },
        {
          "slug": "cc_vallons_de_haute_bretagne",
          "name": "Communauté de communes Vallons de Haute Bretagne",
          "imgSrc": "img/logo_epci_vallons.png",
          "type": "epci",
          "code_siren": "200043990"
        },
        {
          "slug": "cc_vendee_grand_littoral",
          "name": "Communauté de communes Vendée Grand Littoral",
          "imgSrc": "img/logo_cc_vendee_grand_littoral.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "200071900"
        },
        {
          "slug": "cc_yvetot_normandie",
          "name": "Communauté de communes Yvetot Normandie",
          "imgSrc": "img/logo_cc_yvetot_normandie.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "247600620"
        },
        {
          "slug": "cohesion_territoires",
          "name": "Ministère de la Cohésion des territoires",
          "imgSrc": "img/logo_ministere_cohesion_territoires.png",
          "type": "national"
        },
        {
          "slug": "collectivité-européenne-dalsace",
          "name": "Collectivité Européenne d'Alsace",
          "imgSrc": "img/logo-alsace.png",
          "type": "autre",
          "code_insee": "6AE"
        },
        {
          "slug": "cu_caen_la_mer",
          "name": "Communauté urbaine de Caen la mer ",
          "imgSrc": "img/logo_communaute_urbaine_caen_la_mer.png",
          "type": "epci",
          "code_siren": "200065597"
        },
        {
          "slug": "cu_du_grand_reims",
          "name": "Communauté urbaine du Grand Reims",
          "imgSrc": "img/logo_cu_du_grand_reims.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "200067213"
        },
        {
          "slug": "cu_dunkerque",
          "name": "Communauté urbaine de Dunkerque",
          "imgSrc": "img/logo_communaute_urbaine_dunkerque.png",
          "type": "epci",
          "code_siren": "245900428"
        },
        {
          "slug": "cu_grand_besancon_metropole",
          "name": "Communauté urbaine Grand Besançon Métropole",
          "imgSrc": "img/logo_cu_grand_besancon_metropole.png",
          "prefix": "de la",
          "type": "epci",
          "code_siren": "242500361"
        },
        {
          "slug": "cu_grand_poitiers",
          "name": "Communauté urbaine du Grand Poitiers",
          "imgSrc": "img/logo_epci_poitiers.png",
          "type": "epci",
          "code_siren": "200069854"
        },
        {
          "slug": "departement_aisne",
          "name": "Département de l'Aisne",
          "imgSrc": "img/logo_cd02.png",
          "type": "departement",
          "code_insee": "02"
        },
        {
          "slug": "departement_bouches_du_rhone",
          "name": "Département des Bouches-du-Rhône",
          "imgSrc": "img/logo_cd13.png",
          "type": "departement",
          "code_insee": "13"
        },
        {
          "slug": "departement_cote_or",
          "name": "Département Côte d'Or",
          "imgSrc": "img/logo_cd21.png",
          "type": "departement",
          "code_insee": "21"
        },
        {
          "slug": "departement_cotes_d_armor",
          "name": "Côtes d'Armor",
          "imgSrc": "img/logo_cd22.jpg",
          "prefix": "des",
          "repository": "france-local",
          "type": "departement",
          "code_insee": "22"
        },
        {
          "slug": "departement_de_la_charente",
          "name": "Département de la Charente",
          "imgSrc": "img/logo_charente.jpeg",
          "type": "departement",
          "code_insee": "16"
        },
        {
          "slug": "departement_des_pyrenees_orientales",
          "name": "Département des Pyrénées-Orientales",
          "imgSrc": "img/logo_pyrenees-orientales.jpeg",
          "type": "departement",
          "code_insee": "66"
        },
        {
          "slug": "departement_du_haut_rhin",
          "name": "Département du Haut-Rhin",
          "imgSrc": "img/logo_haut-rhin.png",
          "type": "departement",
          "code_insee": "68"
        },
        {
          "slug": "departement_eure_et_loir",
          "name": "Département de l’Eure-et-Loir",
          "imgSrc": "img/logo_cd28.png",
          "type": "departement",
          "code_insee": "28"
        },
        {
          "slug": "departement_finistere",
          "name": "Département du Finistère",
          "imgSrc": "img/logo_cd29.png",
          "type": "departement",
          "code_insee": "29"
        },
        {
          "slug": "departement_haute_saone",
          "name": "Département de la Haute-Saône",
          "imgSrc": "img/logo_cd70.png",
          "type": "departement",
          "code_insee": "70"
        },
        {
          "slug": "departement_herault",
          "name": "Département de l'Hérault",
          "imgSrc": "img/logo_cd34.png",
          "type": "departement",
          "code_insee": "34"
        },
        {
          "slug": "departement_jura",
          "name": "Département du Jura",
          "imgSrc": "img/logo_cd39.png",
          "type": "departement",
          "code_insee": "39"
        },
        {
          "slug": "departement_loire_atlantique",
          "name": "Département de loire Atlantique",
          "imgSrc": "img/logo_cd44.png",
          "type": "departement",
          "code_insee": "44"
        },
        {
          "slug": "departement_lot",
          "name": "Département du Lôt",
          "imgSrc": "img/logo_cd46.png",
          "prefix": "le",
          "type": "departement",
          "code_insee": "46"
        },
        {
          "slug": "departement_mayenne",
          "name": "Département de la Mayenne",
          "imgSrc": "img/logo_departement_mayenne.png",
          "type": "departement",
          "code_insee": "53"
        },
        {
          "slug": "departement_nord",
          "name": "Département du Nord",
          "imgSrc": "img/logo_cd59.png",
          "type": "departement",
          "code_insee": "59"
        },
        {
          "slug": "departement_oise",
          "name": "Département de l'Oise",
          "imgSrc": "img/logo_cd60.png",
          "type": "departement",
          "code_insee": "60"
        },
        {
          "slug": "departement_puy_de_dome",
          "name": "Département du Puy-de-Dôme",
          "imgSrc": "img/logo_cd63.png",
          "type": "departement",
          "code_insee": "63"
        },
        {
          "slug": "departement_saone_et_loire",
          "name": "Département de Saône-et-Loire",
          "imgSrc": "img/logo_cd71.png",
          "type": "departement",
          "code_insee": "71"
        },
        {
          "slug": "departement_seine_saint_denis",
          "name": "Département de Seine-Saint-Denis",
          "imgSrc": "img/logo_cd93.png",
          "type": "departement",
          "code_insee": "93"
        },
        {
          "slug": "departement_somme",
          "name": "Département de la Somme",
          "imgSrc": "img/logo_cd80.png",
          "type": "departement",
          "code_insee": "80"
        },
        {
          "slug": "departement_val_d_oise",
          "name": "Département du Val-d'Oise",
          "imgSrc": "img/logo_cd95.png",
          "type": "departement",
          "code_insee": "95"
        },
        {
          "slug": "departement_vendee",
          "name": "Département de la Vendée",
          "imgSrc": "img/logo_cd85.png",
          "type": "departement",
          "code_insee": "85"
        },
        {
          "slug": "departements",
          "name": "Les conseils départementaux",
          "imgSrc": "img/logo_cd_generique.png",
          "etablissements": [
            "mds",
            "cdas",
            "centre_social",
            "edas",
            "sdsei"
          ],
          "type": "national"
        },
        {
          "slug": "departements_et_metropoles",
          "name": "Départements et métropoles de France",
          "imgSrc": "img/logo_etat_francais.png",
          "repository": "france-local",
          "prefix": "les",
          "type": "national"
        },
        {
          "slug": "education_nationale",
          "name": "Éducation nationale",
          "imgSrc": "img/logo_education_nationale.png",
          "type": "national"
        },
        {
          "slug": "etat",
          "name": "L’État français",
          "imgSrc": "img/logo_etat_francais.png",
          "type": "national"
        },
        {
          "slug": "grand_est",
          "name": "Grand Est",
          "imgSrc": "img/logo_grandest.png",
          "type": "region",
          "code_insee": "44"
        },
        {
          "slug": "guadeloupe",
          "name": "Guadeloupe",
          "imgSrc": "img/logo_guadeloupe.png",
          "type": "region",
          "code_insee": "01"
        },
        {
          "slug": "guyane",
          "name": "Guyane",
          "imgSrc": "img/logo-guyane.jpeg",
          "type": "region",
          "code_insee": "03"
        },
        {
          "slug": "hauts_de_france",
          "name": "Hauts de France",
          "imgSrc": "img/logo_hauts_de_france.png",
          "type": "region",
          "code_insee": "32"
        },
        {
          "slug": "intercommunalite_agglomeration_la_rochelle",
          "name": "Agglomération de La Rochelle",
          "imgSrc": "img/logo_intercommunalite_la_rochelle.png",
          "type": "epci",
          "code_siren": "241700434"
        },
        {
          "slug": "intercommunalite_amiens",
          "name": "Métropole d'Amiens",
          "imgSrc": "img/logo_intercommunalite_amiens.png",
          "type": "epci",
          "code_siren": "248000531"
        },
        {
          "slug": "intercommunalite_angers_loire",
          "name": "Angers Loire Métropole",
          "imgSrc": "img/logo_intercommunalite_angers_loire.png",
          "type": "epci",
          "code_siren": "244900015"
        },
        {
          "slug": "intercommunalite_annemasse",
          "name": "Agglomération d'Annemasse",
          "imgSrc": "img/logo_intercommunalite_annemasse.png",
          "type": "epci",
          "code_siren": "200011773"
        },
        {
          "slug": "intercommunalite_ardenne",
          "name": "Ardenne Métropole",
          "imgSrc": "img/logo_intercommunalite_ardenne.png",
          "type": "epci",
          "code_siren": "200041630"
        },
        {
          "slug": "intercommunalite_arras",
          "name": "Communauté Urbaine d’Arras",
          "imgSrc": "img/logo_intercommunalite_arras.png",
          "type": "epci",
          "code_siren": "200033579"
        },
        {
          "slug": "intercommunalite_aurillac",
          "name": "Communauté d'Agglomération du Bassin d'Aurillac",
          "imgSrc": "img/logo_caba.png",
          "type": "epci",
          "code_siren": "241500230"
        },
        {
          "slug": "intercommunalite_blois",
          "name": "Communauté d'Agglomération de Blois",
          "imgSrc": "img/logo_blois.png",
          "type": "epci",
          "code_siren": "200030385"
        },
        {
          "slug": "intercommunalite_bordeaux",
          "name": "Métropole de Bordeaux",
          "imgSrc": "img/logo_intercommunalite_bordeaux.png",
          "type": "epci",
          "code_siren": "243300316"
        },
        {
          "slug": "intercommunalite_boulonnais",
          "name": "Communauté d'agglomération du Boulonnais",
          "imgSrc": "img/logo__boulonnais.png",
          "type": "epci",
          "code_siren": "246200729"
        },
        {
          "slug": "intercommunalite_bourges",
          "name": "Communauté d'Agglomération de Bourges",
          "imgSrc": "img/logo_intercommunalite_bourges.png",
          "type": "epci",
          "code_siren": "241800507"
        },
        {
          "slug": "intercommunalite_bretagne_romantique",
          "name": "Communauté de Communes Bretagne romantique",
          "imgSrc": "img/logo_intercommunalite_bretagne_romantique.png",
          "type": "epci",
          "code_siren": "243500733"
        },
        {
          "slug": "intercommunalite_brive",
          "name": "Agglomération de Brive",
          "imgSrc": "img/logo_agglomeration_brive.png",
          "type": "epci",
          "code_siren": "200043172"
        },
        {
          "slug": "intercommunalite_cap_cotentin",
          "name": "Cap Cotentin",
          "imgSrc": "img/logo_intercommunalite_cap_cotentin.png",
          "type": "epci",
          "code_siren": "200067205"
        },
        {
          "slug": "intercommunalite_castres_mazamet",
          "name": "Agglomération de Castres-Mazamet",
          "imgSrc": "img/logo_castres_mazamet.png",
          "type": "epci",
          "code_siren": "248100430"
        },
        {
          "slug": "intercommunalite_chateauroux",
          "name": "Châteauroux Métropole",
          "imgSrc": "img/logo_intercommunalite_chateauroux.png",
          "type": "epci",
          "code_siren": "243600327"
        },
        {
          "slug": "intercommunalite_choletais",
          "name": "Agglomération du Choletais",
          "imgSrc": "img/logo_le_choletais.png",
          "type": "epci",
          "code_siren": "200071678"
        },
        {
          "slug": "intercommunalite_epinal",
          "name": "Communauté d'Agglomération Épinal",
          "imgSrc": "img/logo_epinal.png",
          "type": "epci",
          "code_siren": "200068757"
        },
        {
          "slug": "intercommunalite_erdre_et_gesvres",
          "name": "Communauté de Communes d’Erdre et Gesvres",
          "imgSrc": "img/logo_intercommunalite_erdre_et_gesvres.png",
          "type": "epci",
          "code_siren": "244400503"
        },
        {
          "slug": "intercommunalite_golfe_du_morbihan_vannes_agglomeration",
          "name": "Golfe du Morbihan - Vannes Agglomération",
          "imgSrc": "img/logo_golfe_du_morbihan_vannes.png",
          "type": "epci",
          "code_siren": "200067932"
        },
        {
          "slug": "intercommunalite_grand_annecy",
          "name": "Grand Annecy",
          "imgSrc": "img/logo_intercommunalite_grand_annecy.png",
          "type": "epci",
          "code_siren": "200066793"
        },
        {
          "slug": "intercommunalite_grand_avignon",
          "name": "Grand Avignon",
          "imgSrc": "img/logo_intercommunalite_grand_avignon.png",
          "type": "epci",
          "code_siren": "248400251"
        },
        {
          "slug": "intercommunalite_grand_montauban",
          "name": "Grand-Montauban",
          "imgSrc": "img/logo_intercommunalite_grand_montauban.png",
          "type": "epci",
          "code_siren": "248200099"
        },
        {
          "slug": "intercommunalite_la_roche_sur_yon_agglomeration",
          "name": "La Roche-sur-Yon Agglomération",
          "imgSrc": "img/logo_intercommunalite_la_roche_sur_yon.png",
          "type": "epci",
          "code_siren": "248500589"
        },
        {
          "slug": "intercommunalite_laval_agglomeration",
          "name": "Laval Agglomération",
          "imgSrc": "img/logo_intercommunalite_laval.png",
          "type": "epci",
          "code_siren": "200083392"
        },
        {
          "slug": "intercommunalite_les_sables_d_olonne",
          "name": "Les Sables d'Olonne",
          "imgSrc": "img/logo_intercommunalite_les_sables_d_olonne.png",
          "type": "epci",
          "code_siren": "200071165"
        },
        {
          "slug": "intercommunalite_lorient_agglomeration",
          "name": "Lorient Agglomération",
          "imgSrc": "img/logo_lorient_agglomeration.png",
          "type": "epci",
          "code_siren": "200042174"
        },
        {
          "slug": "intercommunalite_metropole_lyon",
          "name": "Métropole de Lyon",
          "imgSrc": "img/logo_grand_lyon_la_metropole.png",
          "type": "epci",
          "code_siren": "200046977"
        },
        {
          "slug": "intercommunalite_mont_de_marsan_agglo",
          "name": "Mont de Marsan Agglo",
          "imgSrc": "img/logo_mont_de_marsans.png",
          "type": "epci",
          "code_siren": "244000808"
        },
        {
          "slug": "intercommunalite_montlucon_communaute",
          "name": "Montluçon Communauté",
          "imgSrc": "img/logo_montlucon_communaute.png",
          "type": "epci",
          "code_siren": "200071082"
        },
        {
          "slug": "intercommunalite_montpellier_mediterranee_metropole",
          "name": "Montpellier Méditerranée Métropole",
          "imgSrc": "img/logo_montpellier_mediterranee_metropole.png",
          "type": "epci",
          "code_siren": "243400017"
        },
        {
          "slug": "intercommunalite_nevers",
          "name": "Agglomération de Nevers",
          "imgSrc": "img/logo_nevers_agglomeration.png",
          "type": "epci",
          "code_siren": "245804406"
        },
        {
          "slug": "intercommunalite_nice_cote_d_azur",
          "name": "Métropole Nice Côte d’Azur",
          "imgSrc": "img/logo_metropole_nice_cote_d_azur.png",
          "type": "epci",
          "code_siren": "200030195"
        },
        {
          "slug": "intercommunalite_orleans_metropole",
          "name": "Orléans Métropole",
          "imgSrc": "img/logo_orleans_metropole.png",
          "type": "epci",
          "code_siren": "244500468"
        },
        {
          "slug": "intercommunalite_pau_bearn_pyrenees",
          "name": "Communauté d'Agglomération de Pau Béarn Pyrénées",
          "imgSrc": "img/logo_pau_bearn_pyrenees.png",
          "type": "epci",
          "code_siren": "200067254"
        },
        {
          "slug": "intercommunalite_pays_basque",
          "name": "Communauté d'Agglomération Pays Basque",
          "imgSrc": "img/logo_pays_basque.png",
          "type": "epci",
          "code_siren": "200067106"
        },
        {
          "slug": "intercommunalite_pays_de_laon",
          "name": "Pays de Laon",
          "imgSrc": "img/logo_pays_de_laon.png",
          "type": "epci",
          "code_siren": "200043495"
        },
        {
          "slug": "intercommunalite_pays_de_lumbres",
          "name": "Pays de Lumbres",
          "imgSrc": "img/logo_pays_de_lumbres.png",
          "type": "epci",
          "code_siren": "246201016"
        },
        {
          "slug": "intercommunalite_pays_de_montbeliard_agglomeration",
          "name": "Pays de Montbéliard Agglomération",
          "imgSrc": "img/logo_pays_de_montbeliard.png",
          "type": "epci",
          "code_siren": "200065647"
        },
        {
          "slug": "intercommunalite_pays_de_saint_omer",
          "name": "Pays de Saint-Omer",
          "imgSrc": "img/logo_pays_saint_omer.png",
          "type": "epci",
          "code_siren": "200069037"
        },
        {
          "slug": "intercommunalite_pays_de_sainte_odile",
          "name": "Communauté de communes Pays de Sainte Odile",
          "imgSrc": "img/logo_pays_de_sainte_odile.png",
          "type": "epci",
          "code_siren": "246701080"
        },
        {
          "slug": "intercommunalite_pays_des_herbiers",
          "name": "Pays des Herbiers",
          "imgSrc": "img/logo_pays_des_herbiers.png",
          "type": "epci",
          "code_siren": "248500621"
        },
        {
          "slug": "intercommunalite_pays_issoire",
          "name": "Agglomération du Pays d'Issoire",
          "imgSrc": "img/logo_pays_issoire.png",
          "type": "epci",
          "code_siren": "200070407"
        },
        {
          "slug": "intercommunalite_pontivy_communaute",
          "name": "Pontivy Communauté",
          "imgSrc": "img/logo_pontivy_-communaute.png",
          "type": "epci",
          "code_siren": "245614433"
        },
        {
          "slug": "intercommunalite_quimper_bretagne_occidentale",
          "name": "Quimper Bretagne occidentale",
          "imgSrc": "img/logo_quimper_bretagne_occidentale.png",
          "type": "epci",
          "code_siren": "200068120"
        },
        {
          "slug": "intercommunalite_riviera_francaise",
          "name": "Communauté de la Riviera française",
          "imgSrc": "img/logo_intercommunaute_riviera_francaise.png",
          "type": "epci",
          "code_siren": "240600551"
        },
        {
          "slug": "intercommunalite_rochefort_ocean",
          "name": "Communauté d'Agglomération Rochefort Océan",
          "imgSrc": "img/logo_rochefort.png",
          "type": "epci",
          "code_siren": "200041762"
        },
        {
          "slug": "intercommunalite_sophia_antipolis",
          "name": "Communauté d'Agglomération Sophie Antipolis",
          "imgSrc": "img/logo_intercommunalite_sophia_antipolis.png",
          "type": "epci",
          "code_siren": "240600585"
        },
        {
          "slug": "intercommunalite_sorgues_du_comtat",
          "name": "Communauté de communes des Sorgues du Comtat",
          "imgSrc": "img/logo_intercommunalite_sorgues_du_comtat.png",
          "type": "epci",
          "code_siren": "248400293"
        },
        {
          "slug": "intercommunalite_toulon_provence_mediterranee",
          "name": "Métropole Toulon-Provence-Méditerranée",
          "imgSrc": "img/logo_toulon_provence_mediterranee.png",
          "type": "epci",
          "code_siren": "248300543"
        },
        {
          "slug": "intercommunalite_valence_romans",
          "name": "Agglomération de Valence Romans",
          "imgSrc": "img/logo_valence_romans.png",
          "type": "epci",
          "code_siren": "200068781"
        },
        {
          "slug": "intercommunalite_valenciennes_metropole",
          "name": "Valenciennes Métropole",
          "imgSrc": "img/logo_valenciennes_metropole.png",
          "type": "epci",
          "code_siren": "245901160"
        },
        {
          "slug": "intercommunalite_vallons_du_lyonnais",
          "name": "Communauté de Communes des Vallons du Lyonnais",
          "imgSrc": "img/logo_intercommunalite_vallons_du_lyonnais.png",
          "type": "epci",
          "code_siren": "246900724"
        },
        {
          "slug": "martinique",
          "name": "Martinique",
          "imgSrc": "img/logo_martinique.png",
          "type": "region",
          "code_insee": "02"
        },
        {
          "slug": "mutualite_sociale_agricole",
          "name": "Mutualité Sociale Agricole",
          "imgSrc": "img/logo_mutualite_sociale_agricole.png",
          "type": "national",
          "repository": "france-local"
        },
        {
          "slug": "nantes_metropole",
          "name": "Nantes Métropole",
          "imgSrc": "img/logo_nantes_metropole.png",
          "type": "epci",
          "code_siren": "244400404"
        },
        {
          "slug": "paris",
          "name": "Ville de Paris",
          "imgSrc": "img/logo_paris.png",
          "prefix": "de la",
          "repository": "paris",
          "type": "commune",
          "code_insee": "75056"
        },
        {
          "slug": "pole_emploi",
          "name": "Pôle emploi",
          "imgSrc": "img/logo_pole_emploi.png",
          "etablissements": [
            "pole_emploi"
          ],
          "type": "national"
        },
        {
          "slug": "pro_btp",
          "name": "Pro BTP",
          "imgSrc": "img/logo_pro_btp.png",
          "type": "national"
        },
        {
          "slug": "region_auvergne_rhone_alpes",
          "name": "Auvergne-Rhône-Alpes",
          "imgSrc": "img/logo_auvergne_rhone_alpes.jpg",
          "repository": "france-local",
          "type": "region",
          "code_insee": "84"
        },
        {
          "slug": "region_bourgogne_franche_comte",
          "name": "Bourgogne-Franche-Comté",
          "imgSrc": "img/logo_bourgogne_franche_comte.png",
          "type": "region",
          "code_insee": "27"
        },
        {
          "slug": "region_bretagne",
          "name": "Bretagne",
          "imgSrc": "img/logo_bretagne.png",
          "type": "region",
          "code_insee": "53"
        },
        {
          "slug": "region_centre_val_de_loire",
          "name": "Centre-Val de Loire",
          "imgSrc": "img/logo_centre_val_de_loire.png",
          "type": "region",
          "code_insee": "24"
        },
        {
          "slug": "region_corse",
          "name": "Région Corse",
          "imgSrc": "img/logo_region_corse.png",
          "type": "region",
          "code_insee": "94"
        },
        {
          "slug": "region_ile_de_france",
          "name": "Île-de-France",
          "imgSrc": "img/logo_ile_de_france.png",
          "type": "region",
          "code_insee": "11"
        },
        {
          "slug": "region_la_reunion",
          "name": "La Réunion",
          "imgSrc": "img/logo_la_reunion.jpeg",
          "type": "region",
          "code_insee": "04"
        },
        {
          "slug": "region_normandie",
          "name": "Normandie",
          "imgSrc": "img/logo_normandie.png",
          "type": "region",
          "code_insee": "28"
        },
        {
          "slug": "region_nouvelle_aquitaine",
          "name": "Nouvelle-Aquitaine",
          "imgSrc": "img/logo_nouvelle_aquitaine.png",
          "repository": "france-local",
          "type": "region",
          "code_insee": "75"
        },
        {
          "slug": "region_occitanie",
          "name": "Occitanie",
          "imgSrc": "img/logo_occitanie.png",
          "repository": "france-local",
          "type": "region",
          "code_insee": "76"
        },
        {
          "slug": "region_pays_de_la_loire",
          "name": "Pays de la Loire",
          "imgSrc": "img/logo_pays_de_la_loire.png",
          "type": "region",
          "code_insee": "52"
        },
        {
          "slug": "region_provence_alpes_cote_azur",
          "name": "Provence-Alpes-Côte d'Azur",
          "imgSrc": "img/logo_provence_alpes_cote_azur.jpg",
          "type": "region",
          "code_insee": "93"
        },
        {
          "slug": "rennes_metropole",
          "name": "Rennes Métropole",
          "imgSrc": "img/logo_rennes_metropole.png",
          "prefix": "de",
          "repository": "france-local",
          "type": "epci",
          "code_siren": "243500139"
        },
        {
          "slug": "saint_etienne_metropole",
          "name": "Saint-Etienne Métropole",
          "imgSrc": "img/logo_saint_etienne_metropole.jpg",
          "prefix": "de",
          "type": "epci",
          "code_siren": "244200770"
        },
        {
          "slug": "sncf",
          "name": "SNCF",
          "imgSrc": "img/logo_sncf.png",
          "type": "national"
        },
        {
          "slug": "strasbourg_eurometropole",
          "name": "Eurométropole de Strasbourg",
          "imgSrc": "img/logo_strasbourg_eurometropole.png",
          "type": "epci",
          "code_siren": "246700488"
        },
        {
          "slug": "toulouse_metropole",
          "name": "Toulouse Métropole",
          "imgSrc": "img/logo_toulouse_metropole.png",
          "prefix": "de",
          "repository": "france-local",
          "type": "epci",
          "code_siren": "243100518"
        },
        {
          "slug": "ville_aix_les_bains",
          "name": "Ville d’Aix-les-Bains",
          "imgSrc": "img/logo_aix_les_bains.png",
          "type": "commune",
          "code_insee": "73008"
        },
        {
          "slug": "ville_alfortville",
          "name": "Alfortville",
          "imgSrc": "img/logo_alfortville.png",
          "prefix": "d’",
          "repository": "france-local",
          "type": "commune",
          "code_insee": "94002"
        },
        {
          "slug": "ville_arcachon",
          "name": "Ville d'Arcachon",
          "imgSrc": "img/logo_arcachon.png",
          "type": "commune",
          "code_insee": "33009"
        },
        {
          "slug": "ville_aubiere",
          "name": "Ville d’Aubière",
          "imgSrc": "img/logo_aubiere.png",
          "type": "commune",
          "code_insee": "63014"
        },
        {
          "slug": "ville_avesnes_sur_helpe",
          "name": "Ville d’Avesnes-sur-Helpe",
          "imgSrc": "img/logo_avesnes_sur_helpe.png",
          "type": "commune",
          "code_insee": "59036"
        },
        {
          "slug": "ville_baisieux",
          "name": "Ville de Baisieux",
          "imgSrc": "img/logo_baisieux.png",
          "type": "commune",
          "code_insee": "59044"
        },
        {
          "slug": "ville_bar_le_duc",
          "name": "Ville de Bar-le-Duc",
          "imgSrc": "img/logo_ville_bar_le_duc.png",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "55029"
        },
        {
          "slug": "ville_betheny",
          "name": "Ville de Bétheny",
          "imgSrc": "img/logo_betheny.png",
          "type": "commune",
          "code_insee": "51055"
        },
        {
          "slug": "ville_bethune",
          "name": "Ville de Béthune",
          "imgSrc": "img/logo_bethune.png",
          "type": "commune",
          "code_insee": "62119"
        },
        {
          "slug": "ville_billy_berclau",
          "name": "Ville de Billy-Berclau",
          "imgSrc": "img/logo_billy_berclau.png",
          "type": "commune",
          "code_insee": "62132"
        },
        {
          "slug": "ville_bonningues_les_calais",
          "name": "Ville de Bonningues-lès-Calais",
          "imgSrc": "img/logo_ville_bonningues_les_calais.png",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "62156"
        },
        {
          "slug": "ville_bourg_en_bresse",
          "name": "Ville de Bourg-en-Bresse",
          "imgSrc": "img/logo_bourg_en_bresse.png",
          "type": "commune",
          "code_insee": "01053"
        },
        {
          "slug": "ville_caen",
          "name": "Ville de Caen",
          "imgSrc": "img/logo_caen.png",
          "type": "commune",
          "code_insee": "14118"
        },
        {
          "slug": "ville_cambrai",
          "name": "Ville de Cambrai",
          "imgSrc": "img/logo_cambrai.png",
          "type": "commune",
          "code_insee": "59122"
        },
        {
          "slug": "ville_camon",
          "name": "Ville de Camon",
          "imgSrc": "img/logo_ville_camon.jpg",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "80164"
        },
        {
          "slug": "ville_carpiquet",
          "name": "Ville de Carpiquet",
          "imgSrc": "img/logo_carpiquet.png",
          "type": "commune",
          "code_insee": "14137"
        },
        {
          "slug": "ville_carvin",
          "name": "Ville de Carvin",
          "imgSrc": "img/logo_carvin.png",
          "type": "commune",
          "code_insee": "62215"
        },
        {
          "slug": "ville_cebazat",
          "name": "Ville de Cébazat",
          "imgSrc": "img/logo_cebazat.png",
          "type": "commune",
          "code_insee": "63063"
        },
        {
          "slug": "ville_chalette_sur_loing",
          "name": "Ville de Châlette-sur-Loing",
          "imgSrc": "img/logo_ville_chalette_sur_loing.png",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "45068"
        },
        {
          "slug": "ville_charenton_le_pont",
          "name": "Ville de Charenton-le-Pont",
          "imgSrc": "img/logo_charenton_le_pont.png",
          "type": "commune",
          "code_insee": "94018"
        },
        {
          "slug": "ville_colmar",
          "name": "Ville de Colmar",
          "imgSrc": "img/logo_colmar.png",
          "type": "commune",
          "code_insee": "68066"
        },
        {
          "slug": "ville_combloux",
          "name": "Ville de Combloux",
          "imgSrc": "img/logo_combloux.png",
          "type": "commune",
          "code_insee": "74083"
        },
        {
          "slug": "ville_comines",
          "name": "Ville de Comines",
          "imgSrc": "img/logo_comines.png",
          "type": "commune",
          "code_insee": "59152"
        },
        {
          "slug": "ville_cormelles_le_royal",
          "name": "Ville de Cormelles le Royal",
          "imgSrc": "img/logo_cormelles_le_royal.png",
          "type": "commune",
          "code_insee": "14181"
        },
        {
          "slug": "ville_correze",
          "name": "Ville de Corrèze",
          "imgSrc": "img/logo_ville_correze.png",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "19062"
        },
        {
          "slug": "ville_courbevoie",
          "name": "Ville de Courbevoie",
          "imgSrc": "img/logo_courbevoie.png",
          "type": "commune",
          "code_insee": "92026"
        },
        {
          "slug": "ville_cournon_auvergne",
          "name": "Ville de Cournon-d’Auvergne",
          "imgSrc": "img/logo_cournon_auvergne.png",
          "type": "commune",
          "code_insee": "63124"
        },
        {
          "slug": "ville_d_amiens",
          "name": "Ville d'Amiens",
          "imgSrc": "img/logo_amiens.png",
          "type": "commune",
          "code_insee": "80021"
        },
        {
          "slug": "ville_d_elbeuf",
          "name": "Ville d’Elbeuf",
          "imgSrc": "img/logo_ville_elbeuf.jpg",
          "type": "commune",
          "code_insee": "76231"
        },
        {
          "slug": "ville_daix",
          "name": "Ville de Daix",
          "imgSrc": "img/logo_daix.png",
          "type": "commune",
          "code_insee": "21223"
        },
        {
          "slug": "ville_dardilly",
          "name": "Ville de Dardilly",
          "imgSrc": "img/logo_ville_dardilly.png",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "69072"
        },
        {
          "slug": "ville_de_barentin",
          "name": "Ville de Barentin",
          "imgSrc": "img/logo_barentin.jpg",
          "type": "commune",
          "code_insee": "76057"
        },
        {
          "slug": "ville_de_blacé",
          "name": "Ville de Blacé",
          "imgSrc": "img/logo_blace.png",
          "type": "commune",
          "code_insee": "69023"
        },
        {
          "slug": "ville_de_drumettaz_clarafond",
          "name": "Ville de Drumettaz-Clarafond",
          "imgSrc": "img/logo_drumettaz.jpg",
          "type": "commune",
          "code_insee": "73103"
        },
        {
          "slug": "ville_de_guichen_pont_rean",
          "name": "Ville de Guichen Pont-Réan",
          "imgSrc": "img/logo_guichen_pont_rean.png",
          "type": "commune",
          "code_insee": "35126"
        },
        {
          "slug": "ville_de_larcay",
          "name": "Ville de Larçay",
          "imgSrc": "img/logo_ville_larcay.png",
          "type": "commune",
          "code_insee": "37124"
        },
        {
          "slug": "ville_de_lescar",
          "name": "Ville de Lescar",
          "imgSrc": "img/logo_lescar.jpg",
          "type": "commune",
          "code_insee": "64335"
        },
        {
          "slug": "ville_de_louvigny",
          "name": "Ville de Louvigny",
          "imgSrc": "img/logo-louvigny.png",
          "type": "commune",
          "code_insee": "14383"
        },
        {
          "slug": "ville_de_pavilly",
          "name": "Ville de Pavilly",
          "imgSrc": "img/logo_pavilly.png",
          "type": "commune",
          "code_insee": "76495"
        },
        {
          "slug": "ville_de_rueil-malmaison",
          "name": "Ville de Rueil-Malmaison",
          "imgSrc": "img/logo_rueil_malmaison.png",
          "type": "commune",
          "code_insee": "92063"
        },
        {
          "slug": "ville_de_sainghin_en_melantois",
          "name": "Ville de Sainghin en Melantois",
          "imgSrc": "img/logo_sainghin_en_melantois.png",
          "type": "commune",
          "code_insee": "59523"
        },
        {
          "slug": "ville_de_saint_jean_de_vedas",
          "name": "Ville de Saint-Jean-de-Védas",
          "imgSrc": "img/logo_saint_jean_de_vedas.png",
          "type": "commune",
          "code_insee": "34270"
        },
        {
          "slug": "ville_de_saint_remy",
          "name": "Ville de Saint-Rémy",
          "imgSrc": "img/logo_saint_remy.png",
          "type": "commune",
          "code_insee": "71475"
        },
        {
          "slug": "ville_de_voglans",
          "name": "Ville de Voglans",
          "imgSrc": "img/logo_voglans.png",
          "type": "commune",
          "code_insee": "73329"
        },
        {
          "slug": "ville_deauville",
          "name": "Ville de Deauville",
          "imgSrc": "img/logo_ville_deauville.png",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "14220"
        },
        {
          "slug": "ville_divonne_les_bains",
          "name": "Ville de Divonne-les-Bains",
          "imgSrc": "img/logo_ville_divonne_les_bains.png",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "01143"
        },
        {
          "slug": "ville_drancy",
          "name": "Ville de Drancy",
          "imgSrc": "img/logo_drancy.png",
          "type": "commune",
          "code_insee": "93029"
        },
        {
          "slug": "ville_dunkerque",
          "name": "Ville de Dunkerque",
          "imgSrc": "img/logo_dunkerque.png",
          "type": "commune",
          "code_insee": "59183"
        },
        {
          "slug": "ville_entzheim",
          "name": "Ville de Entzheim",
          "imgSrc": "img/logo_ville_entzheim.jpg",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "67124"
        },
        {
          "slug": "ville_epernay",
          "name": "Ville de Épernay",
          "imgSrc": "img/logo_ville_epernay.png",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "51230"
        },
        {
          "slug": "ville_evian_les_bains",
          "name": "Ville de Évian-les-Bains",
          "imgSrc": "img/logo_ville_evian_les_bains.png",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "74119"
        },
        {
          "slug": "ville_faches_thumesnil",
          "name": "Ville de Faches-Thumesnil",
          "imgSrc": "img/logo_faches_thumesnil.png",
          "type": "commune",
          "code_insee": "59220"
        },
        {
          "slug": "ville_figeac",
          "name": "Ville de Figeac",
          "imgSrc": "img/logo_figeac.png",
          "type": "commune",
          "code_insee": "46102"
        },
        {
          "slug": "ville_fontenay_sous_bois",
          "name": "Ville de Fontenay-sous-Bois",
          "imgSrc": "img/logo_fontenay_sous_bois.png",
          "type": "commune",
          "code_insee": "94033"
        },
        {
          "slug": "ville_frejus",
          "name": "Ville de Fréjus",
          "imgSrc": "img/logo_frejus.png",
          "type": "commune",
          "code_insee": "83061"
        },
        {
          "slug": "ville_geispolsheim",
          "name": "Ville de Geispolsheim",
          "imgSrc": "img/logo_ville_geispolsheim.png",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "67152"
        },
        {
          "slug": "ville_genneviliers",
          "name": "Ville de Genneviliers",
          "imgSrc": "img/logo_gennevilliers.png",
          "type": "commune",
          "code_insee": "92036"
        },
        {
          "slug": "ville_gouesnou",
          "name": "Ville de Gouesnou",
          "imgSrc": "img/logo_ville_gouesnou.png",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "29061"
        },
        {
          "slug": "ville_grande_synthe",
          "name": "Ville de Grande Synthe",
          "imgSrc": "img/logo_grande_synthe.png",
          "type": "commune",
          "code_insee": "59271"
        },
        {
          "slug": "ville_granville",
          "name": "Ville de Granville",
          "imgSrc": "img/logo_ville_granville.png",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "50218"
        },
        {
          "slug": "ville_grentheville",
          "name": "Ville de Grentheville",
          "imgSrc": "img/logo_grentheville.png",
          "type": "commune",
          "code_insee": "14319"
        },
        {
          "slug": "ville_gruson",
          "name": "Ville de Gruson",
          "imgSrc": "img/logo_gruson.png",
          "type": "commune",
          "code_insee": "59275"
        },
        {
          "slug": "ville_hangenbieten",
          "name": "Ville de Hangenbieten",
          "imgSrc": "img/logo_ville_hangenbieten.png",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "67182"
        },
        {
          "slug": "ville_hem",
          "name": "Ville de Hem",
          "imgSrc": "img/logo_hem.png",
          "type": "commune",
          "code_insee": "59299"
        },
        {
          "slug": "ville_herouville_saint_clair",
          "name": "Ville d’Hérouville Saint-Clair",
          "imgSrc": "img/logo_herouville_saint_clair.png",
          "type": "commune",
          "code_insee": "14327"
        },
        {
          "slug": "ville_ifs",
          "name": "Ville d’Ifs",
          "imgSrc": "img/logo_ifs.png",
          "type": "commune",
          "code_insee": "14341"
        },
        {
          "slug": "ville_illkirch_graffenstaden",
          "name": "Ville d'Illkirch",
          "imgSrc": "img/logo_illkirch.png",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "67218"
        },
        {
          "slug": "ville_istres",
          "name": "Ville de Istres",
          "imgSrc": "img/logo_ville_istres.png",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "13047"
        },
        {
          "slug": "ville_iwuy",
          "name": "Ville de Iwuy",
          "imgSrc": "img/logo_iwuy.jpg",
          "type": "commune",
          "code_insee": "59322"
        },
        {
          "slug": "ville_la_madeleine",
          "name": "Ville de La Madeleine",
          "imgSrc": "img/logo_la_madeleine.png",
          "type": "commune",
          "code_insee": "59368"
        },
        {
          "slug": "ville_la_motte_servolex",
          "name": "Ville de La Motte-Servolex",
          "imgSrc": "img/logo_ville_la_motte_servolex.png",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "73179"
        },
        {
          "slug": "ville_le_bourget_du_lac",
          "name": "Ville de Le Bourget-du-Lac",
          "imgSrc": "img/logo_ville_le_bourget_du_lac.png",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "73051"
        },
        {
          "slug": "ville_le_cateau",
          "name": "Ville de Le Cateau-Cambrésis",
          "imgSrc": "img/logo_le_cateau.png",
          "type": "commune",
          "code_insee": "59136"
        },
        {
          "slug": "ville_les_rues_des_vignes",
          "name": "Ville de Les Rues-des-Vignes",
          "imgSrc": "img/logo_les_rues_des_vignes.png",
          "type": "commune",
          "code_insee": "59517"
        },
        {
          "slug": "ville_lezennes",
          "name": "Ville de Lezennes",
          "imgSrc": "img/logo_lezennes.png",
          "type": "commune",
          "code_insee": "59346"
        },
        {
          "slug": "ville_lievin",
          "name": "Ville de Liévin",
          "imgSrc": "img/logo_ville_lievin.png",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "62510"
        },
        {
          "slug": "ville_mandelieu_la_napoule",
          "name": "Ville de Mandelieu-La Napoule",
          "imgSrc": "img/logo_mandelieu_la_napoule.png",
          "type": "commune",
          "code_insee": "06079"
        },
        {
          "slug": "ville_marignier",
          "name": "Ville de Marignier",
          "imgSrc": "img/logo_marignier.png",
          "type": "commune",
          "code_insee": "74164"
        },
        {
          "slug": "ville_marquette_lez_lille",
          "name": "Ville de Marquette-lez-Lille",
          "imgSrc": "img/logo_marquette_lez_lille.png",
          "type": "commune",
          "code_insee": "59386"
        },
        {
          "slug": "ville_martigues",
          "name": "Ville de Martigues",
          "imgSrc": "img/logo_martigues.png",
          "type": "commune",
          "code_insee": "13056"
        },
        {
          "slug": "ville_mer",
          "name": "Ville de Mer",
          "imgSrc": "img/logo_mer.png",
          "type": "commune",
          "code_insee": "41136"
        },
        {
          "slug": "ville_merignac",
          "name": "Ville de Mérignac",
          "imgSrc": "img/logo_ville_merignac.png",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "33281"
        },
        {
          "slug": "ville_meyreuil",
          "name": "Ville de Meyreuil",
          "imgSrc": "img/logo_meyreuil.png",
          "type": "commune",
          "code_insee": "13060"
        },
        {
          "slug": "ville_mondeville",
          "name": "Ville de Mondeville",
          "imgSrc": "img/logo_mondeville.png",
          "type": "commune",
          "code_insee": "14437"
        },
        {
          "slug": "ville_mons_en_baroeul",
          "name": "Ville de Mons-en-Barœul",
          "imgSrc": "img/logo_mons_en_baroeul.png",
          "type": "commune",
          "code_insee": "59410"
        },
        {
          "slug": "ville_montbeliard",
          "name": "Ville de Montbéliard",
          "imgSrc": "img/logo_montbeliard.png",
          "type": "commune",
          "code_insee": "25388"
        },
        {
          "slug": "ville_montlouis_sur_loire",
          "name": "Ville de Montlouis-sur-Loire",
          "imgSrc": "img/logo_montlouis_sur_loire.png",
          "type": "commune",
          "code_insee": "37156"
        },
        {
          "slug": "ville_montval_sur_loir",
          "name": "Ville de Montval-sur-Loir",
          "imgSrc": "img/logo_chateau_du_loir.png",
          "type": "commune",
          "code_insee": "72071"
        },
        {
          "slug": "ville_mornant",
          "name": "Ville de Mornant",
          "imgSrc": "img/logo_ville_mornant.png",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "69141"
        },
        {
          "slug": "ville_mougins",
          "name": "Ville de Mougins",
          "imgSrc": "img/logo_mougins.png",
          "type": "commune",
          "code_insee": "06085"
        },
        {
          "slug": "ville_nanterre",
          "name": "Ville de Nanterre",
          "imgSrc": "img/logo_nanterre.png",
          "type": "commune",
          "code_insee": "92050"
        },
        {
          "slug": "ville_ouistreham_riva_bella",
          "name": "Ville de Ouistreham Riva-Bella",
          "imgSrc": "img/logo_ouistreham_riva_bella.png",
          "type": "commune",
          "code_insee": "14488"
        },
        {
          "slug": "ville_pantin",
          "name": "Ville de Pantin",
          "imgSrc": "img/logo_pantin.png",
          "type": "commune",
          "code_insee": "93055"
        },
        {
          "slug": "ville_perros_guirec",
          "name": "Ville de Perros-Guirec",
          "imgSrc": "img/logo_ville_perros_guirec.png",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "22168"
        },
        {
          "slug": "ville_plobsheim",
          "name": "Ville de Plobsheim",
          "imgSrc": "img/logo_ville_plobsheim.jpg",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "67378"
        },
        {
          "slug": "ville_ploemeur",
          "name": "Ville de Ploemeur",
          "imgSrc": "img/logo_ploemeur.png",
          "type": "commune",
          "code_insee": "56162"
        },
        {
          "slug": "ville_plougastel_daoulas",
          "name": "Ville de Plougastel-Daoulas",
          "imgSrc": "img/logo_ville_plougastel_daoulas.jpg",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "29189"
        },
        {
          "slug": "ville_plouzane",
          "name": "Ville de Plouzané",
          "imgSrc": "img/logo_plouzane.png",
          "type": "commune",
          "code_insee": "29212"
        },
        {
          "slug": "ville_quesnoy_sur_deule",
          "name": "Ville de Quesnoy-sur-Deûle",
          "imgSrc": "img/logo_ville_quesnoy_sur_deule.png",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "59482"
        },
        {
          "slug": "ville_queven",
          "name": "Ville de Quéven",
          "imgSrc": "img/logo_ville_queven.png",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "56185"
        },
        {
          "slug": "ville_reims",
          "name": "Ville de Reims",
          "imgSrc": "img/logo_reims.png",
          "type": "commune",
          "code_insee": "51454"
        },
        {
          "slug": "ville_rivery",
          "name": "Ville de Rivery",
          "imgSrc": "img/logo_rivery.png",
          "type": "commune",
          "code_insee": "80674"
        },
        {
          "slug": "ville_romagnat",
          "name": "Ville de Romagnat",
          "imgSrc": "img/logo_romagnat.png",
          "type": "commune",
          "code_insee": "63307"
        },
        {
          "slug": "ville_ronchin",
          "name": "Ville de Ronchin",
          "imgSrc": "img/logo_ronchin.png",
          "type": "commune",
          "code_insee": "59507"
        },
        {
          "slug": "ville_sable_sur_sarthe",
          "name": "Ville de Sablé-sur-Sarthe",
          "imgSrc": "img/logo_sable_sur_sarthe.png",
          "type": "commune",
          "code_insee": "72264"
        },
        {
          "slug": "ville_saint_germain_la_blanche_herbe",
          "name": "Ville de Saint-Germain la Blanche Herbe",
          "imgSrc": "img/logo_saint_germain_la_blanche_herbe.png",
          "type": "commune",
          "code_insee": "14587"
        },
        {
          "slug": "ville_saint_jacques_de_la_lande",
          "name": "Ville de Saint-Jacques de la Lande",
          "imgSrc": "img/logo_saint_jacques_de-_la_lande.png",
          "type": "commune",
          "code_insee": "35281"
        },
        {
          "slug": "ville_saint_louis",
          "name": "Ville de Saint-Louis",
          "imgSrc": "img/logo_ville_saint_louis.jpg",
          "type": "commune",
          "code_insee": "97414"
        },
        {
          "slug": "ville_saint_martin_de_crau",
          "name": "Ville de Saint-Martin-de-Crau",
          "imgSrc": "img/logo_saint_martin_de_crau.png",
          "type": "commune",
          "code_insee": "13097"
        },
        {
          "slug": "ville_saint_pol_de_leon",
          "name": "Ville de Saint-Pol-de-Léon",
          "imgSrc": "img/logo_ville_saint_pol_de_leon.png",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "29259"
        },
        {
          "slug": "ville_sainte_foy_les_lyon",
          "name": "Ville de Sainte Foy-Lès-Lyon",
          "imgSrc": "img/logo_sainte_foy_les_lyon.png",
          "type": "commune",
          "code_insee": "69202"
        },
        {
          "slug": "ville_sarlat",
          "name": "Ville de Sarlat",
          "imgSrc": "img/logo_sarlat.png",
          "type": "commune",
          "code_insee": "24520"
        },
        {
          "slug": "ville_talant",
          "name": "Ville de Talant",
          "imgSrc": "img/logo_ville_talant.png",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "21617"
        },
        {
          "slug": "ville_thiais",
          "name": "Ville de Thiais",
          "imgSrc": "img/logo_thiais.png",
          "type": "commune",
          "code_insee": "94073"
        },
        {
          "slug": "ville_thionville",
          "name": "Ville de Thionville",
          "imgSrc": "img/logo_thionville.png",
          "type": "commune",
          "code_insee": "57672"
        },
        {
          "slug": "ville_trouville_sur_mer",
          "name": "Ville de Trouville-sur-Mer",
          "imgSrc": "img/logo_ville_trouville_sur_mer.png",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "14715"
        },
        {
          "slug": "ville_vaucresson",
          "name": "Ville de Vaucresson",
          "imgSrc": "img/ville_vaucresson.png",
          "type": "commune",
          "code_insee": "92076"
        },
        {
          "slug": "ville_vauvert",
          "name": "Ville de Vauvert",
          "imgSrc": "img/logo_ville_vauvert.png",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "30341"
        },
        {
          "slug": "ville_vendenheim",
          "name": "Ville de Vendenheim",
          "imgSrc": "img/logo_ville_vendenheim.jpg",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "67506"
        },
        {
          "slug": "ville_verson",
          "name": "Ville de Verson",
          "imgSrc": "img/logo_verson.png",
          "type": "commune",
          "code_insee": "14738"
        },
        {
          "slug": "ville_vif",
          "name": "Ville de Vif",
          "imgSrc": "img/logo_vif.png",
          "type": "commune",
          "code_insee": "38545"
        },
        {
          "slug": "ville_wasquehal",
          "name": "Ville de Wasquehal",
          "imgSrc": "img/logo_wasquehal.png",
          "type": "commune",
          "code_insee": "59646"
        },
        {
          "slug": "ville_wasselonne",
          "name": "Ville de Wasselonne",
          "imgSrc": "img/logo_ville_wasselonne.png",
          "prefix": "de la",
          "type": "commune",
          "code_insee": "67520"
        }
      ]
    },
    "contribution-pages": {
      "name": "contribution-pages",
      "label": "Pages",
      "label_singular": "page",
      "create": true,
      "delete": false,
      "files": [
        {
          "label": "Accueil",
          "name": "home",
          "file": "contribuer/content/home.md",
          "fields": [
            {
              "label": "Titre",
              "name": "title",
              "widget": "string"
            },
            {
              "label": "Corps",
              "name": "body",
              "widget": "markdown"
            },
            {
              "label": "Contributions",
              "name": "categories",
              "widget": "list",
              "fields": [
                {
                  "label": "Nom",
                  "name": "name",
                  "widget": "string"
                },
                {
                  "label": "Idées",
                  "name": "ideas",
                  "widget": "list",
                  "field": {
                    "label": "Nom",
                    "name": "name",
                    "widget": "string"
                  }
                }
              ]
            }
          ]
        }
      ],
      "items": []
    },
    "tasks": {
      "name": "tasks",
      "label": "Contributions",
      "label_singular": "contribution",
      "folder": "contribuer/content/tasks",
      "create": true,
      "delete": false,
      "slug": "{{slug}}",
      "fields": [
        {
          "label": "Nom",
          "name": "title",
          "widget": "string"
        },
        {
          "label": "Description",
          "name": "body",
          "widget": "markdown",
          "required": false
        }
      ],
      "items": []
    }
  }
}