<template>
  <slot />
</template>

<script>
export default {
  name: "IFrameLayout",
}
</script>

<style>
html,
body {
  height: unset !important;
}
</style>
