<template>
  <div class="notification warning">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "WarningMessage",
}
</script>
